import React from 'react';
import { SvgIcon } from '@material-ui/core';
export const PersonAddIcon = (props) => (
  <SvgIcon {...props} viewBox="0 0 20 20">
    <path
      d="M7 0C4.79086 0 3 1.79086 3 4C3 6.20914 4.79086 8 7 8C9.20914 8 11 6.20914 11 4C11 1.79086 9.20914 0 7 0ZM2.00873 9C0.903151 9 0 9.88687 0 11C0 12.6912 0.83281 13.9663 2.13499 14.7966C3.41697 15.614 5.14526 16 7 16C7.41085 16 7.8155 15.9811 8.21047 15.9427C7.45316 15.0003 7 13.8031 7 12.5C7 11.1704 7.47182 9.95094 8.25716 9L2.00873 9ZM12.5 17C14.9853 17 17 14.9853 17 12.5C17 10.0147 14.9853 8 12.5 8C10.0147 8 8 10.0147 8 12.5C8 14.9853 10.0147 17 12.5 17ZM12.5 10C12.7761 10 13 10.2239 13 10.5V12H14.5C14.7761 12 15 12.2239 15 12.5C15 12.7761 14.7761 13 14.5 13H13V14.5C13 14.7761 12.7761 15 12.5 15C12.2239 15 12 14.7761 12 14.5V13H10.5C10.2239 13 10 12.7761 10 12.5C10 12.2239 10.2239 12 10.5 12H12V10.5C12 10.2239 12.2239 10 12.5 10Z"
      fill="currentColor"
    />
  </SvgIcon>
);
