import React, { useEffect } from 'react';
import styled from '@emotion/styled';
import { Tooltip } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';

const AdicionarProfissionais = ({
  dados,
  handleAtualizarDados,
  handleDadosInvalidos,
}) => {
  const profissionais = dados?.profissionaisQuantidade || [];

  useEffect(() => {
    handleDadosInvalidos(verificaQuantidade(profissionais));

  }, []);

  const verificaQuantidade = (profissionais) => {
    const quantidadeInvalida = profissionais.some(
      (item) => item.id === 1 && item.quantidade === 0
    );

    return quantidadeInvalida;
  };

  const handleQuantidade = (quantidade, profissional) => {
    const profissionaisQuantidade = profissionais.map((item) => {
      return item.label === profissional.label ? { ...item, quantidade } : item;
    });

    handleDadosInvalidos(verificaQuantidade(profissionaisQuantidade));
    handleAtualizarDados({ profissionaisQuantidade });
  };

  return (
    <Content>
      {profissionais.map((profissional, index) => {
        const { label, informacao, quantidade } = profissional;
        return (
          <Container key={index}>
            <ContentLabel>
              {label}
              <Tooltip title={informacao} placement="top-start">
                <InfoIcon color="action" />
              </Tooltip>
            </ContentLabel>
            <ContentQuantidade>
              <Button
                disabled={quantidade === 0}
                onClick={() => handleQuantidade(quantidade - 1, profissional)}
              >
                -
              </Button>
              <span>{quantidade}</span>
              <Button
                onClick={() => handleQuantidade(quantidade + 1, profissional)}
              >
                +
              </Button>
            </ContentQuantidade>
          </Container>
        );
      })}
    </Content>
  );
};

export default AdicionarProfissionais;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
`;

const Container = styled.div`
  display: flex;
  width: calc(100% - 18px);
  padding: 8px;
  border-radius: 10px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  gap: 16px;
  background-color: #f2f2f2;
  justify-content: space-between;
`;

const ContentLabel = styled.div`
  display: flex;
  gap: 4px;
  align-items: center;
`;

const Button = styled.button`
  background-color: ${(props) => (props.disabled ? '#d3d3d3' : '#000')};
  border: 0;
  cursor: pointer;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  color: #fff;
  display: flex;
  justify-content: center;
`;

const ContentQuantidade = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 4px;
`;
