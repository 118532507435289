import React from 'react';
import MaskedInput from "react-text-mask";
import { withStyles } from '@material-ui/core';
import InputForm from "./InputForm";
import string from "../../utils/string";
import { emailMask } from 'text-mask-addons';


const styles = () => ({
root:{
  width:"100% !important",
},
input:{
  background:"#f2f2f2",
  border:"none",
  width:"100%",
  "&:focus":{
    border:"2px solid #000000 !important"
  },
}
});
const removePhoneEmailMask = (newValue) =>{
  newValue = string.removeSpecialChars(newValue)
  newValue = newValue.replace("@","")
  newValue = newValue.replace(".","")

  return newValue
}
export const PhoneEmailMask = (props) => {
    const { inputRef, ...other } = props;

    const checkPhoneEmail = (value) =>{
      if(!value) return false
      value = removePhoneEmailMask(value)
      const phoneMask = [
        '(',
        /\d/, /\d/,
        ')', ' ',
        /\d/, /\d/, /\d/, /\d/,
        '-',
        /\d/, /\d/, /\d/, /\d/, /\d/];

    const cellphoneMask = [
        '(',
        /\d/, /\d/,
        ')', ' ',
        /\d/, /\d/, /\d/, /\d/, /\d/,
        '-',
        /\d/, /\d/, /\d/, /\d/];

     const isNumber = !isNaN(Number(value))

     if (!isNumber) {
      return emailMask
    }
      if(value?.length < 11){
      return phoneMask
      }
      return cellphoneMask
  }

    return <MaskedInput
        ref={ref => {
            inputRef(ref ? ref.inputElement : null);
        }}
        placeholderChar={'\u2000'}
        showMask={props.showMask}
        mask={checkPhoneEmail(props.value)}
        {...other}
    />
};



export const InputPhoneEmailMaskForm = withStyles(styles)(({classes,...props}) => {

    return <InputForm
          classes={classes}
          className={classes}
          inputComponent={PhoneEmailMask} {...props}  onClick={(e) => {
            if (string.removeSpecialChars(e.target.value).length === 0)
                e.target.setSelectionRange(0, 0);
        }}
          />
});
