import { VendaStatus } from '../enum/VendaStatus';

export default function getVendaStatusColors(status){
  switch(status ?? ""){
    case VendaStatus.ATIVA:
      return {
        color: "#007F79",
        backgroundColor: "#E1F3F4"
      }
    case VendaStatus.ABERTA:
      return {
        color: "#3B7ECE",
        backgroundColor: "#E5F3FC"
      }
    case VendaStatus.PERIODO_GRATIS:
      return {
        color: "#D84E24",
        backgroundColor: "#FAEAE8"
      }
    case VendaStatus.EM_PROCESSAMENTO:
      return {
        color: "#FCF0DF",
        backgroundColor: "#DB7C2F"
      }
    case VendaStatus.PAGAMENTO_RECUSADO:
      return {
        color: "#FCEDEF",
        backgroundColor: "#BF3028"
      }
    case VendaStatus.CADASTRO_PARCIAL:
      return {
        color: "#3D281E",
        backgroundColor: "#F7F3F1"
      }
    case VendaStatus.FINALIZADA:
      return {
        color: "#2D2CDF",
        backgroundColor: "#EEE7FC"
      }
      default:
        return {
          color: "#000000",
          backgroundColor: "#FFFFFF"
        }
  }
}
