import React, { useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { TextField } from '../../../../components/TextField';
import string from '../../../../utils/string';
import InputPhoneDdi from '../../../../components/input/InputPhoneDdi';
import { FormGroup } from './styles';

const FormDadosUnidade = (props) => {
  const { setFormData, formData, handleDadosInvalidos } = props;
  
  useEffect(() => {
    const isCamposValidos =
      !formData.razaoSocial ||
      !formData.nomeFantasia ||
      !validaTelefone(formData.telefone, formData.telefoneDDI) ||
      !formData.email;

    handleDadosInvalidos(isCamposValidos);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    handleDadosInvalidos,
    formData.razaoSocial,
    formData.nomeFantasia,
    formData.telefone,
    formData.email,
  ]);

  const handleChangeCampos = (e, field) => {
    setFormData({
      ...formData,
      [field]: e.target.value,
    });
  };

  const onTelefonePrincipalComDDIChange = (value, data) => {
    setFormData({
      ...formData,
      telefone: value,
      telefoneDDI: data.dialCode,
    });

    validaTelefone(value, data.dialCode);
  };

  const validaTelefone = (value, dialCode) => {
    const isTelefoneValido = string.validaTelefoneDDI(value, dialCode);

    return isTelefoneValido;
  };

  return (
    <Grid container direction="row" spacing={2}>
      <FormGroup item xs={12}>
        <Typography color="black" component="label">
          Razão Social *
        </Typography>
        <TextField
          value={formData.razaoSocial}
          onChange={(e) => handleChangeCampos(e, 'razaoSocial')}
          error={!formData.razaoSocial}
          helperText={!formData.razaoSocial && 'Campo obrigatório'}
        />
      </FormGroup>
      <FormGroup item xs={12}>
        <Typography color="black" component="label">
          Nome Fantasia *
        </Typography>
        <TextField
          value={formData.nomeFantasia}
          onChange={(e) => handleChangeCampos(e, 'nomeFantasia')}
          error={!formData.nomeFantasia}
          helperText={!formData.nomeFantasia && 'Campo obrigatório'}
        />
      </FormGroup>
      <FormGroup item xs={12}>
        <Typography color="black" component="label">
          Inscrição Estadual
        </Typography>
        <TextField
          value={formData.inscricaoEstadual}
          onChange={(e) => handleChangeCampos(e, 'inscricaoEstadual')}
        />
      </FormGroup>
      <FormGroup item xs={12}>
        <InputPhoneDdi
          onTelefonePrincipalComDDIChange={onTelefonePrincipalComDDIChange}
          telefone={formData.telefone}
          label="Telefone *"
        />
      </FormGroup>
      <FormGroup item xs={12}>
        <Typography color="black" component="label">
          Código CNES
        </Typography>
        <TextField
          value={formData.codigoCnes}
          onChange={(e) => handleChangeCampos(e, 'codigoCnes')}
        />
      </FormGroup>
      <FormGroup item xs={12}>
        <Typography color="black" component="label">
          Email *
        </Typography>
        <TextField
          value={formData.email}
          onChange={(e) => handleChangeCampos(e, 'email')}
          error={!formData.email}
          helperText={!formData.email && 'Campo obrigatório'}
        />
      </FormGroup>
    </Grid>
  );
};

export default FormDadosUnidade;
