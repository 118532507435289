import React, { useState } from "react"
import { Divider, IconButton, Tooltip, withStyles } from "@material-ui/core"
import { SelectSearch } from "../../../../components/select/SelectSearch";
import { InputPhoneEmailMaskForm } from "../../../../components/input/inputPhoneEmailForm";
import InputDateForm from "../../../../components/input/InputDateForm";
import { Add, Remove } from "@material-ui/icons";
import Button from "../../../../components/button";
import { ArrowDownloadIcon } from "../../../../components/icons/ArrowDownload";
import { Send } from "@material-ui/icons";
import { useMutation } from "react-apollo";
import { CREATE_CONTRATO } from "../../../../graphql/mutations";

const ContratoForm = ({classes}) =>{
const [contratoData, setContratoData] = useState({phoneOrEmail:{
  person1:""
}})

const [createContrato, { loading: isLoading }] = useMutation(CREATE_CONTRATO, {
  onError: (error) => console.error(error),
});
const handleChangePhoneOrEmail = (key, value ) =>{

  setContratoData((data)=>({...data, phoneOrEmail:{...data.phoneOrEmail,
    [key]: value}}))
}
const handleChangeContratoData = (key, value) =>{

    setContratoData((data)=>({...data, [key]: value}))
}

const handleAddInput = () =>{
  if(isLoading) return
  const totalPhoneOrEmail = Object.keys(contratoData.phoneOrEmail).length
  setContratoData((data)=>({...data, phoneOrEmail:{
    ...data.phoneOrEmail,
    [`person${totalPhoneOrEmail + 1}`]: ""}}))

}
const handleDeleteInput = (arrayKey) =>{
  if(isLoading) return
  const {phoneOrEmail} = contratoData
  const newPhoneOrEmail = {}
  delete phoneOrEmail[arrayKey]

  const arrayValue = Object.values(phoneOrEmail)
  arrayValue.forEach((value, index)=>{
    newPhoneOrEmail[`person${index + 1}`] = value
  })


  setContratoData((data)=>({...data,
    phoneOrEmail: newPhoneOrEmail }))

}


const handleSendContrato = () =>{
  if(isLoading) return
  createContrato({variables:contratoData})
}

return(
<>
<div style={{height:"75%", padding:"16px 32px 32px 32px"}}>
  <div className={classes.content}>
  <div className={classes.inputContainer}>
  <span className={classes.inputLabel}>Tipo de contrato</span>
  <SelectSearch
  placeholder="Selecione"
  value={contratoData.tipo}
  onChange={(e) => handleChangeContratoData("tipo", e.target.value)} />
  </div>
  { Object.keys(contratoData.phoneOrEmail).map((key, index)=>{
    return(
      <div className={classes.inputContainer} key={index}>
  <span className={classes.inputLabel}>Email ou telefone</span>
      <div
      style={{display:"flex", flexDirection:"row", alignItems:"center", justifyContent:"space-between", width:"100%"}}>
      <InputPhoneEmailMaskForm
        value={contratoData?.phoneOrEmail?.[key]  || ""}
        onChange={(e) => handleChangePhoneOrEmail(key, e.target.value) }
        placeholder={"Para quem deseja enviar?"}
        name={key}
        />
      {key !== "person1" && <Tooltip title="Deletar campo de email/telefone">
      <IconButton
        className={classes.iconButton}
        onClick={()=>handleDeleteInput(key)}
      >
      <Remove />
      </IconButton>
      </Tooltip>}
      </div>
      {key === "person1" &&
        <Tooltip title="Adicionar campo de email/telefone">
        <Button
          className={classes.addButton}
          onClick={handleAddInput}
        >
           <p>Novo</p>
        <Add style={{color:"#4daeac"}}/>
        </Button>
        </Tooltip>
      }
      </div>
    )
  })}


  <div className={classes.inputContainer}>
  <span className={classes.inputLabel}>Data de validade</span>
  <InputDateForm
          placeholder="Data de validade"
          variant="outlined"
          fullWidth
          value={contratoData?.dataHoraVencimento || ""}
          onChange={(e) => handleChangeContratoData("dataHoraVencimento", e)} />
  </div>
  </div>
</div>
<Divider />
  <div className={classes.buttonContainer}>
          <Button className={classes.buttonBaixar}>
            <p>Baixar</p>
            <ArrowDownloadIcon  className={classes.iconButtonBaixar} />
          </Button>
          <Button
          className={classes.buttonEnviar}
          onClick={handleSendContrato}
          >
            <p>Enviar</p>
            <Send className={classes.iconButtonEnviar}/>
          </Button>
  </div>
</>
)

}
const styles = () => ({
  content:{
    height:"90%",
    display:"flex",
    flexDirection:"column",
    gap:"10px",
    width:"100%",
    overflowY:"auto"
  },
  iconButton:{
    width:"fit-content",
    background:"#ffff !important",
    padding:"0px"
  },
  addButton:{
    background:"#ffff !important",
    color:"#219A97 !important",
    border:"2px solid #219A97 !important",
    width:"fit-content !important",
    height:"30px !important",
    fontSize:"11px !important",
    display:"flex",
    flexDirection:"row",
    alignItems:"center",
    justifyContent:"center"
  },
  inputContainer:{
    display:"flex",
    flexDirection:"column",
    gap:"5px",
    width:"100%"
  },
  inputLabel:{
    color: "#868686",
  },
  buttonContainer:{
    display:"flex",
    flexDirection:"row",
    justifyContent:"space-between",
    alignItems:"center",
    padding:"15px"
  },
  buttonBaixar:{
    background:"#ffff !important",
    color:"#219A97 !important",
    border:"2px solid #219A97 !important",
    width:"200px !important",
    height:"30px !important",
    fontSize:"11px !important",
    display:"flex",
    flexDirection:"row",
    alignItems:"center",
    justifyContent:"center"
  },
  buttonEnviar:{
    width:"200px !important",
    height:"30px !important",
    fontSize:"11px !important",
    display:"flex",
    flexDirection:"row",
    alignItems:"center",
    justifyContent:"center"
  },
  iconButtonBaixar:{
    fontSize:"12px !important",
  },
  iconButtonEnviar:{
    fontSize:"18px !important",
  }
});

export default withStyles(styles)(ContratoForm)

