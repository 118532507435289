import BaseAxios from 'axios';

export const axiosAuthPublic = BaseAxios.create({
	baseURL: process.env.REACT_APP_BASE_URL_AUTH,
	headers: {
		'Content-Type': 'application/x-www-form-urlencoded',
		Authorization: `Basic ${process.env.REACT_APP_OAUTH_TOKEN_PUBLIC}`
	},
});

export const axiosAuthClientePagamento = BaseAxios.create({
	baseURL: process.env.REACT_APP_BASE_URL_AUTH,
	headers: {
		'Content-Type': 'application/x-www-form-urlencoded',
		Authorization: `Basic ${process.env.REACT_APP_OAUTH_TOKEN_CLIENTE_PAGAMENTO}`
	},
});

const axiosAuth = BaseAxios.create({
	baseURL: process.env.REACT_APP_BASE_URL_AUTH,
	headers: {
		'Content-Type': 'application/x-www-form-urlencoded',
		Authorization: `Basic ${process.env.REACT_APP_OAUTH_TOKEN}`
	},
});

export const Api = BaseAxios.create({
	baseURL: process.env.REACT_APP_BASE_URL_GQL,
	headers: {
		'Content-Type': 'application/json'
	},
});

export const apiAuth = BaseAxios.create({
	baseURL: `${process.env.REACT_APP_BASE_URL_AUTH}/api/v1/graphql`,
	headers: {
		'Content-Type': 'application/json',
	},
});

export const logoutUrl = BaseAxios.create({
	baseURL: `${process.env.REACT_APP_BASE_URL_AUTH}/api/v1/logout`,
	headers: {
		'Content-Type': 'application/json',
	},
});

export default axiosAuth;
