import React from 'react';
import styled from '@emotion/styled';
import Grid from '@material-ui/core/Grid';
import { SwitchLabel } from '../switchLabel';
import { adicionaisSwitch } from '../../constants';

export const AdicionaisProfissionalSaude = ({
  dadosTemp,
  id,
  handleChangeField,
  whitelabel
}) => {

  return (
    <Content>
      <Title>Adicionais</Title>
      <ContentValues>
        {adicionaisSwitch({ dados: dadosTemp, id, whitelabel }).map((item) => {
          const { title, field, value, show, disabled } = item;

          return (
            show && (
              <Grid item xs={6}>
                <SwitchLabel
                  title={title}
                  value={value}
                  onChange={() =>
                    handleChangeField(field, !value)
                  }
                  disabled={disabled}
                />
              </Grid>
            )
          );
        })}
      </ContentValues>
    </Content>
  );
};

const Content = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #f2f2f2;
  border-radius: 10px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 16px;
  width: calc(100% - 52px);
  margin-left: 10px;
`;

const Title = styled.label`
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  color: #000;
`;

const ContentValues = styled.div`
  display: flex;
  flex-wrap: wrap;
`;
