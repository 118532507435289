import React, { useEffect } from 'react';
import styled from '@emotion/styled';
import { useParams } from 'react-router-dom';
import { columnsProfissionaisSaude } from '../constants';
import List from '../../../components/list/list';

const ProfissionaisSaude = ({
  pageableDTO,
  profissionais,
  findProfissionaisSaudeByVenda,
  handleEditProfissionalSaude,
  loadingProfissionaisSaudeVenda,
  withMarginTop
}) => {
  const params = useParams();

  useEffect(() => {
    findProfissionaisSaudeByVenda({
      variables: {
        venda: {id: params.vendaId},
        pageableDTO,
      },
    });

  }, []);

  useEffect(() => {
    findProfissionaisSaudeByVenda({
      variables: {
        venda: {id: params.vendaId},
        pageableDTO,
      },
    })

  }, []);

  return (
    <Content withMarginTop={withMarginTop}>
      <Header> Profissionais de saúde </Header>
      <ListProfissionais
        columns={columnsProfissionaisSaude({ vendaId: params.vendaId })}
        items={profissionais?.findProfissionaisSaudeByVenda?.content}
        loading={loadingProfissionaisSaudeVenda}
        last={true}
        onClickItem={handleEditProfissionalSaude}
      />
    </Content>
  );
};

export default ProfissionaisSaude;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: ${props => props.withMarginTop ? '24px' : '0'};
`;

const Header = styled.label`
  font-size: 18px;
  font-weight: 700;
  line-height: 27px;
`;

const ListProfissionais = styled(List)`
  overflow: auto;
  max-height: 800px;
`;
