import styled from '@emotion/styled';
import React, { useEffect, useState } from 'react';
import { Step, StepLabel, Stepper } from '@material-ui/core';
import Button from '../../../../../../components/button';
import ModalSlider from '../../../../../../components/modal-slider/modal-slider';
import {
  dadosPlanoDefault,
  stepsPlanos,
  tiposPagamentoPlanos,
} from '../../../../constants';
import {
  CREATE_PLANO,
  UPDATE_PLANO,
} from '../../../../../../graphql/mutations';
import { useMutation, useQuery } from 'react-apollo';
import string from '../../../../../../utils/string';
import { FIND_ALL_MODULO } from '../../../../../../graphql/queryes';
import Number from '../../../../../../utils/number';

export const StepPlano = ({ openModal, onCloseModal, setNotification, carregarPlanos, plano }) => {
  const [dados, setDados] = useState(dadosPlanoDefault);
  const [stepSelected, setStepSelected] = useState(0);
  const [todasFuncionalidades, setTodasFuncionalidades] = useState([]);
  const [loadingSave, setLoadingSave] = useState(false);

  const { data: dataFuncionalidades, loading: loadingFuncionalidades } = useQuery(FIND_ALL_MODULO, {
    variables: {
      ativo: true,
      pageableDTO: {
        sortDir: 'ASC',
        sortField: 'funcionalidade',
      },
    },
    fetchPolicy: 'no-cache',
  });
  const [savePlano] = useMutation(dados.id ? UPDATE_PLANO : CREATE_PLANO);

  const titlePrimeiroStep = stepSelected === 0 && dados?.id ? 'Alterar plano' : 'Novo plano';

  useEffect(() => {
    plano?.id ?
      getPlanoSelecionado()
      :
      setDados({
        ...dadosPlanoDefault,
        funcionalidades: todasFuncionalidades
      });
  }, [todasFuncionalidades]);

  useEffect(() => {
    !!dataFuncionalidades?.findAllModulo && setTodasFuncionalidades(dataFuncionalidades.findAllModulo)
    
  }, [dataFuncionalidades]);
  
  const getPlanoSelecionado = () => {
    const { id, nome, ativo } = plano;

    const funcionalidadesValores = getFuncionalidadesValores();

    setDados({
      id,
      nome,
      ativo,
      ...funcionalidadesValores,
    })
  };

  const getFuncionalidadesValores = () => {
    const { tipoPagamento } = plano;
    let funcionalidades = {};
    let valores = {
      ANUAL: 0,
      FIDELIDADE: 0,
      MENSAL: 0,
    };
    let tiposPagamentoId = {};

    tipoPagamento.forEach(tipo => {
      const { descricao, valor, modulos } = tipo;
      valores[descricao] = Number.currencyFormat(String(valor));
      tiposPagamentoId = {
        ...tiposPagamentoId,
        [descricao]: tipo.id,
      }
      
      modulos.forEach((modulo) => {
        if(modulo.id in funcionalidades) {
          const tipoDescricao = descricao.toLowerCase();
          funcionalidades[modulo.id] = {
            ...funcionalidades[modulo.id],
            [tipoDescricao]: true,
          }

        } else {
          funcionalidades = {
            ...funcionalidades,
            [modulo.id]: getTiposPagamento(descricao),
          }
        }
      })
    });

    const funcionalidadesStatusTipo = todasFuncionalidades.map((funcionalidade) => {
      const statusTipoPagamento = funcionalidades[funcionalidade.id]

      return {
        ...funcionalidade,
        ...statusTipoPagamento,
      }
    })
        
    return {
      funcionalidades:funcionalidadesStatusTipo,
      tiposPagamentoId,
      ...valores
    };
  }

  const getTiposPagamento = (tipoPagamento) => {

    let tiposPagamento = {};
    tiposPagamentoPlanos.forEach(item =>{
      tiposPagamento = {
        ...tiposPagamento,
        [`${item.value.toLowerCase()}`]: item.value === tipoPagamento
      }
    })

    return tiposPagamento;
  };

  const handleDados = (value, field) => {
    setDados({
      ...dados,
      [field]: value,
    });
  };

  const renderContent = () => {
    const step = stepsPlanos[stepSelected];
    const Component = step.component;

    return (
      <Component
        dados={dados}
        handleDados={handleDados}
        stepSelected={stepSelected}
        loadingFuncionalidades={loadingFuncionalidades}
      />
    );
  };

  const goBack = () => {
    setStepSelected(stepSelected - 1);
  };

  const verificaErrors = () => {
    if (stepSelected === 0) {
      return !dados.nome;
    }
    return false;
  };

  const handleProximo = () => {
    stepSelected === 2 ? handleSavePlano() : setStepSelected(stepSelected + 1);
  };

  const handleSavePlano = async () => {
    try {
      setLoadingSave(true);
      const { id, nome, funcionalidades, tiposPagamentoId } = dados;

      const tipoPagamento = funcionalidades.reduce(
        (acumulador, funcionalidade) => {
          if (!acumulador?.ANUAL) {
            acumulador.ANUAL = [];
          }

          if (!acumulador?.MENSAL) {
            acumulador.MENSAL = [];
          }

          if (!acumulador?.FIDELIDADE) {
            acumulador.FIDELIDADE = [];
          }

          funcionalidade.anual &&
            acumulador.ANUAL.push({ id: funcionalidade.id });
          funcionalidade.mensal &&
            acumulador.MENSAL.push({ id: funcionalidade.id });
          funcionalidade.fidelidade &&
            acumulador.FIDELIDADE.push({ id: funcionalidade.id });

          return acumulador;
        },
        {}
      );

      const plano = {
        ...(id && { id }),
        nome,
        tipoPagamento: tiposPagamentoPlanos.map((item) => {
          return {
            descricao: item.value,
            ...(tiposPagamentoId && { id: tiposPagamentoId[item.value]}),
            modulos: tipoPagamento[item.value],
            valor: dados[item.value]
              ? string.currencyMaskToFloat(dados[item.value])
              : 0,
          };
        }),
      };
      await savePlano({ variables: { plano } });
      await carregarPlanos();

      setStepSelected(0);
      setDados(dadosPlanoDefault);
      onCloseModal();
    } catch (error) {
      setNotification({
        variant: 'error',
        isOpen: true,
        message: error.message,
      });
    } finally {
      setLoadingSave(false);
    }
  };

  return (
    <ModalSlider
      isOpen={openModal}
      onClose={() => {
        setDados(dadosPlanoDefault);
        onCloseModal();
      }}
      title={stepsPlanos[stepSelected].label || titlePrimeiroStep}
      goBack={stepSelected !== 0 && goBack}
    >
      <ContentModal>
      <Content>{renderContent()}</Content>
      <Footer>
        <StepperStyle activeStep={stepSelected} alternativeLabel>
          <Step key={'dados_plano'}>
            <StepLabel>Dados do plano</StepLabel>
          </Step>
          <Step key={'funcionalidades'}>
            <StepLabel>Funcionalidades</StepLabel>
          </Step>
          <Step key={'confirmar'}>
            <StepLabel>Confirmar</StepLabel>
          </Step>
        </StepperStyle>
        <Button disabled={verificaErrors() || loadingSave} onClick={() => handleProximo()}>
          {stepSelected === 2 ? 'Salvar' : 'Próximo'}
        </Button>
      </Footer>
      </ContentModal>
    </ModalSlider>
  );
};

const ContentModal = styled.div`
    grid-template-rows: 1fr 80px;
    display: grid;
    height: 100%;
`;

const Content = styled.div`
  height: 100%;
  height: calc(100vh - 200px);
`;

const Footer = styled.div`
  min-height: max-content;
  padding-top: 16px;
  display: grid;
  gap: 16px;
  grid-template-columns: 3fr 1fr;
  align-items: end;
`;

const StepperStyle = styled(Stepper)`
  padding: 0px !important;
`;
