import React from 'react';
import styled from '@emotion/styled';

export const Field = ({ title, value }) => {
  return (
    <Content>
      <Title>{title}</Title>
      <Label>{value}</Label>
    </Content>
  );
};

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const Title = styled.label`
  font-size: 12px;
  line-height: 18px;
  color: #000;
`;

const Label = styled.label`
  color: #868686;
  font-size: 14px;
  color: #505050;
`;
