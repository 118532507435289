export const removeTypename = (obj, key = '__typename') => {
  try {
    const keys = Object.keys(obj);
    const newObj = {};
    keys.forEach((i) => {
      if (i !== key) {
        const val = obj[i];
        if (val instanceof Date) newObj[i] = val;
        else if (Array.isArray(val))
          newObj[i] = removeTypenameOfChildrenArray(val, key);
        else if (typeof val === 'object' && val !== null)
          newObj[i] = removeTypename(val, key);
        else newObj[i] = val;
      }
    });
    return newObj;
  } catch (e) {
    return obj;
  }
};

const removeTypenameOfChildrenArray = (arr, key) => {
  return arr.map((val) => {
    if (Array.isArray(val)) return removeTypenameOfChildrenArray(val, key);
    if (typeof val === 'object') return removeTypename(val, key);
    return val;
  });
};
