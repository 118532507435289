import React from 'react';
import { withStyles } from '@material-ui/core';
import { TabSelectorStyles } from './styles';

const TabSelector = ({
  classes,
  tabsOptions,
  selectedColor,
  baseColor,
  onSelect,
  selectedTabIndex,
  borderRadius,
}) => {
  const renderTabs = () => {
    return (
      <>
        {tabsOptions.map((tab, index) => (
          <div
            key={index}
            className={
              index === selectedTabIndex ? classes.selectedTab : classes.tab
            }
            style={{
              backgroundColor:
                index === selectedTabIndex ? selectedColor : baseColor,
              borderRadius: borderRadius || '100px',
            }}
            onClick={() => onSelect(index)}
          >
            <span className={classes.tabLabel}>{tab.label}</span>
          </div>
        ))}
      </>
    );
  };

  return (
    <div
      className={classes.tabs}
      style={{
        backgroundColor: baseColor,
        borderRadius: borderRadius || '100px',
      }}
    >
      {renderTabs()}
    </div>
  );
};

export default withStyles(TabSelectorStyles)(TabSelector);
