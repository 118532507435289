import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import styled from '@emotion/styled';
import { withStyles, CircularProgress, Grid, Switch } from '@material-ui/core';
import ButtonAlert from '../button/ButtonAlert';
import ButtonClearPrimary from '../button/ButtonClearPrimary';
import colors from '../../theme/Colors';
import InputForm from '../input/InputForm';
import Colors from "../../theme/Colors";




const buttonStyle = {
  maxWidth: 158,
};

const styles = () => ({
  alertContainer: {
    minWidth: 500,
  },
  alertTitle: {

    '& h2': {
      fontSize: 22,
      fontWeight: 600,
      textAlign: 'center',
      color: colors.commons.darkGray,
    },
  },
  alertDescription: {
    lineHeight: 'normal',
    textAlign: 'center',
    fontSize: 12,
    color: colors.commons.darkGray,
  },
  containerButtons: {
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 15,
  },
  buttonCancel: {
    ...buttonStyle,
    marginRight: 5,
  },
  buttonOk: {
    ...buttonStyle,
    marginLeft: 5,
  },
});
const InputDialog = styled(InputForm)`
  display: flex;
  width: 100%;

  label {
    margin-bottom: 8px;
    color: #666666;
    transform: none !important;
  };
  &:focus-within label {
    color: ${Colors.primary.main};

  }
`;
const AlertWithInputSwitch = ({
  open,
  onClose,
  onCancel,
  onOk,
  alertDescription,
  alertTitle,
  classes,
  loadingOk,
  loadingCancel,
  arrayInputs,
  handleSwitchChange,
  switchLabel,
  switchValue,
  buttonOkLabel,
  hiddenSwitch,
}) => {


  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <div className={classes.alertContainer}>
        {alertTitle && (
          <DialogTitle className={classes.alertTitle}>{alertTitle}</DialogTitle>
        )}
        {alertDescription && (
          <DialogContent>
            <DialogContentText className={classes.alertDescription}>
              {alertDescription}
            </DialogContentText>
            {!hiddenSwitch && 
            <DialogActions>
              <p className={classes.alertDescription}>{switchLabel}</p>
             <Switch color="primary" onChange={handleSwitchChange} name={"status"} checked={switchValue} />
            </DialogActions>}

            {arrayInputs?.map((input) => (
              <div style={{ width: '100%', marginBottom: "14px" }}>
                <InputDialog
                  id={input.id}
                  name={input.name}
                  label={input.label}
                  onChange={input.onChange}
                  fullWidth
                  disabled={!switchValue}
                  value={input.value}
                />
              </div>
            ))}

          </DialogContent>
        )}
        <DialogActions style={{ textAlign: 'center' }}>
          <Grid container className={classes.containerButtons}>
            <Grid item xs={12}>
              <ButtonClearPrimary
                className={classes.buttonCancel}
                onClick={onCancel}
                disabled={loadingCancel}
              >
                <React.Fragment>
                  Não
                  {loadingCancel && (
                    <CircularProgress
                      color="primary"
                      size={12}
                      style={{ marginLeft: 10 }}
                    />
                  )}
                </React.Fragment>
              </ButtonClearPrimary>
              <ButtonAlert
                className={classes.buttonOk}
                onClick={onOk}
                autoFocus
                disabled={loadingOk}
              >
                <React.Fragment>
                  {buttonOkLabel}
                  {loadingOk && (
                    <CircularProgress
                      color="inherit"
                      size={12}
                      style={{ marginLeft: 10 }}
                    />
                  )}
                </React.Fragment>
              </ButtonAlert>
            </Grid>
          </Grid>
        </DialogActions>
      </div>
    </Dialog>
  )
};

export default withStyles(styles)(AlertWithInputSwitch);
