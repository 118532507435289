import React, { useState } from 'react';
import styled from '@emotion/styled';
import { AdicionaisList, PlanosList } from './components';
import { withBaseLayout } from '../../layout/base-layout';
import Notification from '../../components/notification/Notification';

const notificationDefault = {
  variant: '',
  message: '',
  isOpen: false,
};

const PlanosEAdicionais = () => {
  const [notification, setNotification] = useState(notificationDefault);

  return(
    <Container>
      <PlanosList />
      <AdicionaisList setNotification={setNotification} />
      <Notification
				close={() => {
					setNotification({
						...notificationDefault,
					});
				}}
				reset={() => {
					setNotification({
						...notificationDefault,
					});
				}}
				isOpen={notification.isOpen}
				variant={notification.variant}
				message={notification.message}
			/>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 22px;
`

export default withBaseLayout(PlanosEAdicionais);