export const buildLinkPerfilPublico = (perfilPublico, frontWebUrl) => {
  const { unidade, idPublico, nomeLinkPublico } = perfilPublico || {};

  const profileAs = unidade?.id ? '/unidade' : '/profissional';
  const nameWithoutSpaces = nomeLinkPublico
    ?.trim()
    .toLocaleLowerCase()
    .replace(/\s/g, '-');
  const nameNormalized = nameWithoutSpaces
    ?.normalize('NFD')
    ?.replace(/[\u0300-\u036f]/g, '');
  const idWithName = `${nameNormalized}-${idPublico}`;

  const profileHref = profileAs + `/${encodeURIComponent(idWithName)}`;

  return frontWebUrl + profileHref;
};
