import React from 'react';
import styled from '@emotion/styled';
import Number from '../../../../utils/number';

const CardPlano = ({ plano, onClick, tipoCobranca, selected }) => {
  const { nome, tipoPagamento } = plano;
  const planoTipoPagamento = tipoPagamento.find(
    (item) => item.descricao === tipoCobranca
  );
  const { modulos, valor } = planoTipoPagamento || {};

  return (
    <Card selected={selected} onClick={onClick}>
      <Title> {nome} </Title>
      <ListaFuncionalidades>
        {modulos?.map(({ funcionalidade }) => (
          <Funcionalidade>{funcionalidade}</Funcionalidade>
        ))}
      </ListaFuncionalidades>
      <Row>
        <ValorStyle>Total:</ValorStyle>
        <ValorStyle>{Number.currencyFormat(valor)}</ValorStyle>
      </Row>
    </Card>
  );
};

const Card = styled.div`
  width: 195px;
  height: 225px;
  border-radius: 16px;
  padding: 16px;
  border: 1px solid #e5e5e5;
  background-color: ${(props) => (props.selected ? '#fff' : '#F2F2F2')};
  display: flex;
  flex-direction: column;
`;

const Title = styled.h1`
  font-family: 'Poppins';
  font-weight: 700;
  font-size: 18px;
`;

const ListaFuncionalidades = styled.div`
  height: 100%;
  overflow-y: auto;
`;

const Funcionalidade = styled.p`
  color: #505050;
  font-size: 12px;
  font-weight: 500;
  margin: 4px 0;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const ValorStyle = styled.p`
  color: #09101d;
  font-size: 14px;
  font-weight: 500;
`;

export default CardPlano;
