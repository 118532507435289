import React from 'react';
import { SvgIcon } from '@material-ui/core';
export const ArrowSyncCircle = (props) => (
  <SvgIcon {...props} viewBox="0 0 17 16">
    <path
      d="M8.66602 16C4.24774 16 0.666016 12.4183 0.666016 8C0.666016 3.58172 4.24774 0 8.66602 0C13.0843 0 16.666 3.58172 16.666 8C16.666 12.4183 13.0843 16 8.66602 16ZM12.1659 8C12.442 8 12.6659 7.77614 12.6659 7.5V5.5C12.6659 5.22386 12.442 5 12.1659 5C11.8898 5 11.6659 5.22386 11.6659 5.5V6.19617C11.0537 5.18015 9.93975 4.5 8.66607 4.5C7.62944 4.5 6.69755 4.9513 6.05739 5.66654C5.87323 5.87231 5.89074 6.1884 6.0965 6.37257C6.30227 6.55673 6.61836 6.53922 6.80253 6.33346C7.26101 5.8212 7.9257 5.5 8.66607 5.5C9.69055 5.5 10.5722 6.11648 10.9582 7H10.1659C9.88975 7 9.66589 7.22386 9.66589 7.5C9.66589 7.77614 9.88975 8 10.1659 8H12.1659ZM4.66614 8.5V10.5C4.66614 10.7761 4.89 11 5.16614 11C5.44228 11 5.66614 10.7761 5.66614 10.5V9.80366C6.27833 10.8198 7.39231 11.5 8.66607 11.5C9.7027 11.5 10.6346 11.0487 11.2747 10.3335C11.4589 10.1277 11.4414 9.8116 11.2356 9.62743C11.0299 9.44327 10.7138 9.46078 10.5296 9.66654C10.0711 10.1788 9.40644 10.5 8.66607 10.5C7.64159 10.5 6.75995 9.88352 6.37394 9H7.16614C7.44228 9 7.66614 8.77614 7.66614 8.5C7.66614 8.22386 7.44228 8 7.16614 8H5.16614C4.89 8 4.66614 8.22386 4.66614 8.5Z"
      fill="currentColor"
    />
  </SvgIcon>
);
