import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Zoom from '@material-ui/core/Zoom';
import AddIcon from '@material-ui/icons/Add';
import Fab from "@material-ui/core/Fab";
import { Tooltip } from '@material-ui/core';


const styles = theme => ({
	fab: {
		position: 'absolute',
		bottom: '4vh',
		right: '2vw',
	},
});

class FloatingActionButtonZoom extends React.Component {
	state = {
		value: 0,
	};


	render() {
		const { classes, theme, title } = this.props;
		const transitionDuration = {
			enter: theme.transitions.duration.enteringScreen,
			exit: theme.transitions.duration.leavingScreen,
		};

		return (
			<Zoom
				in={true}
				timeout={transitionDuration}
				unmountOnExit
			>
				<Tooltip title={title} placement="left">
					<Fab onClick={this.props.onClick} className={classes.fab} color={'primary'}>
						{this.props.icon || <AddIcon />}
					</Fab>
				</Tooltip>
			</Zoom>
		);
	}
}

FloatingActionButtonZoom.propTypes = {
	classes: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(FloatingActionButtonZoom);
