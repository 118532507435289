import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {ApolloProvider} from 'react-apollo';
import {ApolloClient} from 'apollo-client';
import {createHttpLink} from 'apollo-link-http';
import {defaultDataIdFromObject, InMemoryCache} from 'apollo-cache-inmemory';

import {logoutSuccess} from '../redux';
import customFetch from './custom-fetch';
import {useHistory} from 'react-router-dom';
import {SIGNUP} from '../router/names';

const ApolloInstance = ({children}) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [client, setClient] = React.useState(null);
  const {token} = useSelector(state => state.Auth);

  const onLogout = () => {
    dispatch(logoutSuccess());
    history.replace(SIGNUP);
  };

  const configureApollo = () => {
    const httpLink = createHttpLink({
      uri: process.env.REACT_APP_BASE_URL_GQL,
      fetch: customFetch(token, onLogout)
    });

    const cache = new InMemoryCache({
      dataIdFromObject: object => {
        switch (object.__typename) {
          case 'Review':
            return object.reviewId;
          default:
            return defaultDataIdFromObject(object)
        }
      }
    });

    const client = new ApolloClient({
      cache,
      link: httpLink,
    });

    setClient(client);
  };

  React.useEffect(() => {
    token && configureApollo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  return (
    !client ? <div/> :
      <ApolloProvider client={client}>
        {children}
      </ApolloProvider>
  )
};

export default ApolloInstance;
