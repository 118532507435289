import React, {useState} from 'react';
import Grid from '@material-ui/core/Grid';

import InputSearch from '../../../components/input/Input';
import styled from '@emotion/styled';
import {SelectSearch} from '../../../components/select/SelectSearch';
import {obterUsuarioLogadoLocal} from "../../../services/usuario-service";

const Search = ({
                  onDataSearch,
                  niveisEntidades = [],
                  entidadesSimplesFilhas = {},
                  ativoSearch = [{name: 'Ativo', value: 'true'}, {name: 'Inativos', value: 'false'}]
                }) => {
  const [typingTimeout, setTypingTimeout] = useState();
  const [searchText, setSearchText] = useState('');
  const [entidadePaiSelected, setEntidadePaiSelected] = useState();
  const [nivelSelected, setNivelSelected] = useState();
  const [ativo, setAtivo] = useState(null);
  const usuarioLogado = obterUsuarioLogadoLocal();

  const handleChangeInput = (field, e) => {
    let dataSearch = {};
    if (field === 'entidadePai') {
      setEntidadePaiSelected(e);

      const selected = e ? entidadesSimplesFilhas.content?.find(entidade => e.value === entidade.id) : null;
      dataSearch = {
        entidadePai: selected ? {id: selected.id, tipo: selected.tipo} : undefined,
      };
    }
    if (field === 'nivel') {
      setNivelSelected(e);

      dataSearch = {
        nivel: e && e.value ? {id: e.value} : undefined,
      };
    }
    if (field === 'status') {
      setAtivo(e);

      dataSearch = {
        ativo: e?.value,
      };
    }

    onDataSearch(dataSearch);
  };

  const handleSearchTextChange = (e) => {
    const text = e.target.value;
    setSearchText(text);

    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }

    setTypingTimeout(setTimeout(() => {
      onDataSearch({
        search: text,
      });
    }, 500));
  };

  return (
    <SearchContainer>
    <Grid container spacing={2}>
      <Grid item xs={4}>
        <InputSearch placeholder="Buscar por nome..." onChange={handleSearchTextChange} value={searchText}/>
      </Grid>
      {!usuarioLogado?.authorities?.includes("ROLE_PLATAFORMA") ?
        <Grid item xs={3}>
          <SelectSearchStyled
            name="nivel"
            placeholder="Nível"
            value={nivelSelected}
            elements={niveisEntidades}
            onChange={(e) => handleChangeInput('nivel', e)}
          />
        </Grid>
        : null}
      <Grid item xs={3}>
        <SelectSearchStyled
          name="entidadePai"
          placeholder="Entidade Pai"
          value={entidadePaiSelected}
          elements={entidadesSimplesFilhas.content || []}
          onChange={(e) => handleChangeInput('entidadePai', e)}
        />
      </Grid>
      <Grid item xs={2}>
        <SelectSearchStyled
          name="status"
          placeholder="Status"
          value={ativo}
          elements={ativoSearch || []}
          onChange={(e) => handleChangeInput('status', e)}
        />
      </Grid>
    </Grid>
    </SearchContainer>
  );
};

const SelectSearchStyled = styled(SelectSearch)`
	width: 100%;
`;

const SearchContainer = styled.div`
  background-color: #fff;
  border-radius: 16px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 16px;

  & > div {
    @media (max-width: 768px) {
      flex-direction: column;

      & > div {
        max-width: 100%;
      }
    }
  }
`

export default Search;
