import styled from '@emotion/styled';
import { Edit } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import string from '../../../../utils/string';
import Number from '../../../../utils/number';
import { resumoVenda } from '../criar-venda/constants';
import Accordion from '../../../../components/Accordion';

const Resumo = ({ dados, setPassoAtual, handleAtualizarDados }) => {
  const [expanded, setExpanded] = useState('');

  const { profissionaisQuantidade, plano, tipoPagamento } = dados;

  useEffect(() => {
    calculaValor();
  }, []);

  const calculaValor = () => {
    const quantidadeProfissionalAtendente = profissionaisQuantidade.find(
      (item) => item.id === 1
    ).quantidade;

    let valorVenda = {
      valorVendaRecorrente: plano.valor * quantidadeProfissionalAtendente,
      valorModuloUnico: 0,
    };

    dados.modulos.forEach((modulo) => {
      if (!modulo?.selected) return;

      const valorModulo = string.currencyMaskToFloat(String(modulo?.valor));
      const periodoCobranca =
        modulo?.periodoCobranca === 'UNICO'
          ? 'valorModuloUnico'
          : 'valorVendaRecorrente';
      const tipoAnualcaCobrancaMensal =
        tipoPagamento.descricao === 'ANUAL' &&
        modulo?.periodoCobranca === 'MENSAL';
      let valorModuloTotal = valorModulo;

      if (modulo.tipoCobranca === 'PROFISSIONAL') {
        const quantidadeProfissional = profissionaisQuantidade.find(
          (item) => item.label === 'Profissional de saúde'
        ).quantidade;
        valorModuloTotal = valorModuloTotal * quantidadeProfissional;

        if (tipoAnualcaCobrancaMensal) {
          valorModuloTotal *= 12;
        }

        valorVenda[periodoCobranca] += valorModuloTotal;
      } else {
        if (tipoAnualcaCobrancaMensal) {
          valorModuloTotal *= 12;
        }
        valorVenda[periodoCobranca] += valorModuloTotal;
      }
    });

    handleAtualizarDados({
      valor: valorVenda.valorVendaRecorrente,
      valorUnico: valorVenda.valorModuloUnico,
    });
  };

  const handleEdit = (e, step) => {
    e.stopPropagation();
    setPassoAtual(step);
  };

  const getTitle = ({ title, step }) => {
    return (
      <TitleAccordion>
        {title}
        <ButtonEdit onClick={(e) => handleEdit(e, step)}>
          <Edit />
        </ButtonEdit>
      </TitleAccordion>
    );
  };

  return (
    <ContentResumo>
      <ContentValorTotal>
        <Title> Valor recorrente </Title>
        <Label> {Number.currencyFormat(dados?.valor)} </Label>
      </ContentValorTotal>
      <ContentValorTotal>
        <Title> Valor adicional único </Title>
        <Label> {Number.currencyFormat(dados?.valorUnico)} </Label>
      </ContentValorTotal>
      <RowPlano>
        <ContentPlano>
          <Title> Plano </Title>
          <Label> {dados?.plano?.nome} </Label>
        </ContentPlano>
        <ContentPlano>
          <Title> Valor </Title>
          <Label> {Number.currencyFormat(dados?.plano?.valor)} </Label>
        </ContentPlano>
      </RowPlano>
      {resumoVenda({ dados }).map((item) => {
        const { title, step, children, panel } = item;

        return (
          <Accordion
            title={getTitle({ title, step })}
            expanded={expanded}
            panel={panel}
            setExpanded={setExpanded}
          >
            {children}
          </Accordion>
        );
      })}
    </ContentResumo>
  );
};

export default Resumo;

const TitleAccordion = styled.div`
  display: flex;
  flex-direction: row;
  gap: 4px;
  align-items: center;
  font-weight: 600;
  font-size: 16px;
`;

const ButtonEdit = styled.button`
  cursor: pointer;
  border: none;
  border-radius: 100px;
  width: 32px;
  height: 32px;
  &:hover {
    background-color: #d9d9d9;
  }
`;

const ContentResumo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const RowPlano = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
`;

const ContentPlano = styled.div`
  display: flex;
  flex-direction: column;
`;

const Title = styled.label`
  font-weight: 600;
  font-size: 16px;
`;

const Label = styled.label`
  font-size: 16px;
`;

const ContentValorTotal = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-bottom: 16px;
  & > label {
    font-size: 20px;
  }
`;
