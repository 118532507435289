import React from 'react';
import styled from '@emotion/styled';
import { useHistory } from 'react-router-dom';

import Flex from '../../components/flex';
import { SIGNUP } from '../../router/names';
import Profile from './profile';
import { useSelector, useDispatch } from 'react-redux';
import { logout } from '../../redux';

const HeaderTitle = () => {
  const { pageTitle, pageDescription } = useSelector((state) => state.Page);

  return (
    <div>
      <PageTitle>{pageTitle}</PageTitle>
      <PageDescription>{pageDescription}</PageDescription>
    </div>
  );
};

const HeaderProfile = () => {
  const dispatch = useDispatch();
  const { replace } = useHistory();
  const { usuarioLogado } = useSelector((state) => state.Auth);
  const handleLogout = () => {
    dispatch(logout());
    replace(SIGNUP);
  };

  return (
    <Profile
      username={usuarioLogado ? usuarioLogado.nome : ''}
      onLogout={handleLogout}
    />
  );
};

const Header = styled(Flex)`
  justify-content: space-between;
  align-items: center;
  padding: 0 3% 0 2.3%;
  height: 80px;
`;

const PageTitle = styled.h3`
  color: #000000;
  font-size: 18px;
  font-weight: bold;
  margin: 0;

  @media (max-width: 768px) {
    font-size: 16px;
  }
`;

const PageDescription = styled.p`
  color: #333333;
  font-size: 14px;
  margin: 0;

  @media (max-width: 768px) {
    font-size: 12px;
  }
`;

export default Header;

export { HeaderTitle, HeaderProfile };
