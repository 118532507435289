import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { useLazyQuery, useQuery } from '@apollo/react-hooks';
import { useMutation } from "react-apollo";
import { useHistory } from 'react-router-dom';

import { FIND_ALL_MODULO_VENDA, LIST_VENDAS } from '../../graphql/queryes';
import { CREATE_VENDA } from "../../graphql/mutations";

import {
  ArrowDropUp as ArrowDropUpIcon,
  ArrowDropDown as ArrowDropDownIcon,
} from "@material-ui/icons";
import { IconButton } from '@material-ui/core';

import Box from '../../components/box';
import Search from './components/search';
import List from '../../components/list/list';
import Number from '../../utils/number';
import ButtonFloat from '../../components/button/ButtonFloat';
import { VISUALIZAR_VENDA } from '../../router/names';
import string from "../../utils/string";
import canCriarVenda from '../../services/authority-service/can-criar-venda';
import ModalSlider from "../../components/modal-slider/modal-slider";
import StepperCriarVenda from "./components/criar-venda/stepperCriarVenda";
import Notification from "../../components/notification/Notification";
import styled from "@emotion/styled";
import { checkUserRole } from '../../utils/checkUserRole';
import { obterEntidadeAtualLocal } from '../../services/usuario-service';
import getVendaStatusColors from '../../utils/getVendaStatusColors';
import { notificationDefault, titles, profissionaisQuantidadeDefault } from './components/criar-venda/constants';
import { withBaseLayout } from '../../layout/base-layout';

const formaDataDefault = {
  id: null,
  plano: null,
  tipoPagamento: null,
  paisAtuacao: null,
  documentoTipo: null,
  responsavelNome: '',
  responsavelCargo: '',
  responsavelDocumento: '',
  nomeFantasia: '',
  razaoSocial: '',
  cnpj: '',
  inscricaoEstadual: '',
  telefone: '',
  codigoCnes: '',
  email: '',
  endereco: {
    cep: '',
    tipoLogradouro: {
      id: ''
    },
    nomeLogradouro: '',
    numero: '',
    bairro: '',
    complemento: '',
    municipio: {
      id: ''
    },
    estado: {
      id: ''
    }
  },
  tempoFuncionamentoEmpresa: 0,
  numeroPacientes: 0,
  haveraMigracaoSistema: false,
  sistemaASerMigrado: '',
  especialidades: [],
  profissionaisQuantidade: profissionaisQuantidadeDefault,
  modulos: [],
  valor: '',
  valorUnico: '',
}

const ListVendas = () => {
  const history = useHistory();
  const [searchDTO, setSearchDTO] = useState({});

  const [isCriarVendaOpen, setIsCriarVendaOpen] = useState(false);

  const [order, setOrder] = useState({ field: 'dataLancamento', asc: false });
  const [passoAtual, setPassoAtual] = useState(0);

  const orderDataLancamento = order.field === 'dataLancamento';
  const orderDataVencimento = order.field === 'unidade.dataVencimento';

  const [criarVenda] = useMutation(CREATE_VENDA);

  const [notification, setNotification] = useState({
    ...notificationDefault,
  });

  const [pageableDTO, setPageableDTO] = useState({
    pageNumber: 0,
    pageSize: 20,
    sortField: "dataLancamento",
    sortDir: "Desc"
  });

  const [utilizaAzulControle, setUtilizaAzulControle] = useState(false)
  const [isAuthoritySistema, setIsAuthoritySistema] = useState(false)

  const { data, loading, error, refetch, fetchMore } = useQuery(LIST_VENDAS, {
    variables: {
      searchDTO,
      pageableDTO,
    },
    fetchPolicy: 'no-cache'
  });

  const [getModulos, {
    data: modulos,
  }] = useLazyQuery(FIND_ALL_MODULO_VENDA,
    {
      fetchPolicy: 'no-cache',
    }
  );

  const [vendas, setVendas] = useState([]);
  const [loadingSave, setLoadingSave] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);

  const [formData, setFormData] = useState(formaDataDefault);

  useEffect(() => {
    verificaListaVendas();
  }, []);

  useEffect(() => {

    modulos?.findAllModulo?.length > 0 && setFormData({
      ...formData,
      modulos: modulos?.findAllModulo.map(item => ({
        ...item,
        valor: item.valor.toLocaleString("pt-br", {
          style: "currency",
          currency: "BRL",
        }),
        selected: false,
      })) || []
    })
  }, [modulos]);

  const verificaListaVendas = async () => {
    const isAuthoritySistema = await checkUserRole("ROLE_SISTEMA");
    const isAuthorityPlataforma = await checkUserRole("ROLE_PLATAFORMA");
    const isComercial = await checkUserRole('ROLE_COMERCIAL');

    setIsAuthoritySistema(isAuthoritySistema);

    const entidadeAtual = obterEntidadeAtualLocal();

    (isAuthorityPlataforma || isComercial) && setSearchDTO({
      entidade: {
        id: entidadeAtual.id,
        tipo: entidadeAtual.tipo
      }
    });
  };

  const handleSubmit = async () => {
    try {
      setLoadingSave(true);
      const contratante = { ...formData };

      const vendaQuantidadeTipoProfissionalSaude = formData.profissionaisQuantidade.map(item => ({
        quantidade: item.quantidade,
        tipoProfissionalSaude: {
          id: item.id
        }
      }));

      const modulos = formData.modulos.filter(item => (item.selected)).map(item => {
        delete item.selected;
        delete item.__typename;

        return ({
          ...item,
          valor: string.currencyMaskToFloat(String(item?.valor)),
        })
      });

      delete contratante.tipoPagamento;
      delete contratante.id;
      delete contratante.plano;
      delete contratante.modulos;
      delete contratante.profissionaisQuantidade;
      delete contratante.valor;
      delete contratante.pagamento;
      delete contratante.meioPagamentoPlataforma;
      delete contratante.valorUnico;

      const { data } = await criarVenda({
        variables: {
          planoTipoPagamentoId: formData.tipoPagamento.id,
          modulos,
          vendaQuantidadeTipoProfissionalSaude,
          valorUnico: formData.valorUnico,
          contratante: {
            ...contratante,
            telefone: formData.telefone.substring(2),
            responsavelDocumento: string.removeSpecialChars(formData.responsavelDocumento),
            cnpj: formData.cnpj ? string.removeSpecialChars(formData.cnpj) : null,
            especialidades: formData.especialidades.map(e => ({ id: e.value })),
            endereco: {
              nomeLogradouro: formData.endereco.nomeLogradouro,
              numero: formData.endereco.numero,
              bairro: formData.endereco.bairro,
              complemento: formData.endereco.complemento,
              cep: string.removeSpecialChars(formData.endereco.cep),
              tipoLogradouro: formData.paisAtuacao?.descricao === "BRASIL" ? {
                id: formData.endereco.tipoLogradouro.value
              } : null,
              estado: formData.paisAtuacao?.descricao === "BRASIL" ? {
                id: formData.endereco?.estado?.value
              } : null,
              municipio: formData.paisAtuacao?.descricao === "BRASIL" ? {
                id: formData.endereco.municipio.value
              } : null,
            },
            paisAtuacao: {
              id: formData.paisAtuacao?.id
            },
            espanhaMunicipio: formData.paisAtuacao?.descricao === "ESPANHA" ? {
              id: formData.espanhaMunicipio?.id
            } : null,
            documentoTipo: formData.paisAtuacao?.descricao === "ESPANHA" ? {
              id: formData.documentoTipo?.value
            } : null,
          }
        }
      });

      const vendaId = data?.createVenda?.id;
      history.push(`${VISUALIZAR_VENDA.replace(':vendaId', vendaId)}`);

      if (data?.createVenda?.id) {
        setNotification({
          variant: 'success',
          message: 'Venda criada com sucesso!',
          isOpen: true
        });
      }
    } catch ({ graphQLErrors }) {
      setNotification({
        variant: 'error',
        message: 'Não foi possível salvar esta venda! Verifique os campos novamente.',
        isOpen: true
      });
    } finally {
      setLoadingSave(false);
    }
  };

  const handleChangeByDataLancamento = () => {
    setOrder({ field: 'dataLancamento', asc: orderDataLancamento ? !order.asc : false });
  };

  const handleChangeByDataVencimento = () => {
    setOrder({ field: 'unidade.dataVencimento', asc: orderDataVencimento ? !order.asc : false });
  };

  const ArrowOrderDataLancamento = () => {
    if (orderDataLancamento) {
      return order.asc ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />;
    }
    return (
      <ArrowUpAndDownContent>
        <ArrowUpper />
        <ArrowDropDownIcon />
      </ArrowUpAndDownContent>
    );
  };

  const ArrowOrderDataVencimento = () => {
    if (orderDataVencimento) {
      return order.asc ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />;
    }
    return (
      <ArrowUpAndDownContent>
        <ArrowUpper />
        <ArrowDropDownIcon />
      </ArrowUpAndDownContent>
    );
  };

  const changeOrder = () => {
    setPageableDTO({
      pageNumber: 0,
      pageSize: 20,
      sortField: order.field,
      sortDir: order.asc ? "Asc" : "Desc"
    })
  }

  const handleClickCriarVenda = () => {
    setIsCriarVendaOpen(true);
    setFormData(formaDataDefault);
    setPassoAtual(0);
  };

  const carregaModulos = (tipoPagamentoId) => {
    getModulos({
      variables: {
        ativo: true,
        tipoPagamentoDTO: {
          differentTipoPagamento: {
            id: tipoPagamentoId
          }
        },
        pageableDTO: {
          sortDir: 'ASC',
          sortField: 'funcionalidade',
        },
      },
    });

  };

  const handleCloseCriarVenda = () => {
    setIsCriarVendaOpen(false);

  };

  const handleClickVenda = venda => {
    history.push(`${VISUALIZAR_VENDA.replace(':vendaId', venda.id)}`);
  };

  const atualizarDados = (dadosAtualizados, utilizaAzulControle) => {
    dadosAtualizados?.tipoPagamento?.id && carregaModulos(dadosAtualizados?.tipoPagamento?.id);
    utilizaAzulControle ?
      setUtilizaAzulControle(dadosAtualizados)
      :
      setFormData({
        ...formData,
        ...dadosAtualizados
      });
  };

  const handleLoadMore = () => {
    if (!data?.findAllVenda?.last) {
      const pageable = {
        ...pageableDTO,
        pageNumber: pageNumber,
      };
      fetchMore({
        variables: {
          pageableDTO: {
            ...pageable,
          },
          searchDTO: {
            ...searchDTO,
          },
        },
        updateQuery: (prev, { fetchMoreResult }) => {
          if (!fetchMoreResult) return prev;
          setVendas({
            ...vendas,
            ...fetchMoreResult.findAllVenda,
            content: [
              ...vendas.content,
              ...fetchMoreResult.findAllVenda.content
            ]
          });
        }
      });
      if (!data?.findAllVenda?.last && data?.findAllVenda?.totalPages > pageNumber) {
        setPageNumber(pageNumber + 1);
      }
    }
  };

  useEffect(() => {
    changeOrder();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order]);

  useEffect(() => {
    setVendas(data?.findAllVenda);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <Box>
      <Search onSearch={refetch} showSelectEntidade={isAuthoritySistema} searchDTO={searchDTO} onDataSearch={s => setSearchDTO({ ...searchDTO, ...s })} />
      <List
        columns={[
          {
            title: 'Status',
            value: (item) => {
              const status = item.status === "FINALIZADA" ? "CANCELADA" : item.status;

              return <StatusVenda status={item.status}>{
                string.capitalize(status?.toLowerCase().replace('_', ' ') || '')
              } </StatusVenda>
            },
          },
          {
            title: 'Id Unidade',
            value: (item) => item?.unidade?.id,
          },
          {
            title: 'Razão Social',
            value: (item) => item?.unidade?.nome,
          },
          {
            title:
              <FilteredTitle>
                <IconButton aria-label="expand row" size="small" onClick={handleChangeByDataLancamento}>
                  <ArrowOrderDataLancamento />
                </IconButton>
                Lançamento
              </FilteredTitle>,
            value: (item) => item?.dataLancamento ? moment(item.dataLancamento).format('MM/YYYY') : '-',
          },
          {
            title: 'Valor',
            value: (item) => Number.currencyFormat(item?.valorTotalComDesconto),
          },
          {
            title:
              <FilteredTitle>
                <IconButton aria-label="expand row" size="small" onClick={handleChangeByDataVencimento}>
                  <ArrowOrderDataVencimento />
                </IconButton>
                Data de vencimento
              </FilteredTitle>,
            value: (item) => moment(item?.unidade?.dataVencimento).format("DD/MM/YYYY"),
          },
          {
            title: 'Vendedor',
            value: (item) => item?.vendedor ? item.vendedor.nome : '-',
          },
        ]}
        items={vendas?.content}
        loading={loading}
        error={error}
        last={vendas?.last}
        onLoadMore={handleLoadMore}
        onClickItem={handleClickVenda}
      />
      {canCriarVenda() && <ButtonFloat title="Criar Venda" onClick={handleClickCriarVenda} />}

      <ModalSlider
        isOpen={isCriarVendaOpen}
        onClose={handleCloseCriarVenda}
        title={titles[passoAtual]}
      >
        <StepperCriarVenda
          dados={formData}
          utilizaAzulControle={utilizaAzulControle}
          handleAtualizarDados={atualizarDados}
          handleFinalizarVenda={handleSubmit}
          loadingSave={loadingSave}
          passoAtual={passoAtual}
          setPassoAtual={setPassoAtual}
          handleCloseCriarVenda={handleCloseCriarVenda}
          setNotification={setNotification}
        />
        <Notification
          close={() => {
            setNotification({
              ...notificationDefault,
            });
          }}
          reset={() => {
            setNotification({
              ...notificationDefault,
            });
          }}
          isOpen={notification.isOpen}
          variant={notification.variant}
          message={notification.message}
        />
      </ModalSlider>
    </Box>
  )
};

export default withBaseLayout(ListVendas);

const ArrowUpAndDownContent = styled.span`
  display : flex;
  flex-direction: column;
  padding-top: 4px;
`;

const FilteredTitle = styled.div`
  font-size: 12px;
`;

const ArrowUpper = styled(ArrowDropUpIcon)`
  position: absolute;
  bottom: 10px
`;

const StatusVenda = styled.div`
  text-align: center;
  border-radius: 8px;
  padding-block: 4px;
  ${props => `
    background-color: ${getVendaStatusColors(props.status).backgroundColor};
    border: 1px solid ${getVendaStatusColors(props.status).color};
  `}
`;
