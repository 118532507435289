export const TabSelectorStyles = {
  tabs: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    height: '32px',
    padding: '2px',
    borderRadius: '100px',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  tab: {
    display: 'flex',
    justifyContent: 'center',
    flex: 1,
    alignItems: 'center',
    textAlign: 'center',
    height: '100%',
    borderRadius: '100px',
  },
  selectedTab: {
    display: 'flex',
    justifyContent: 'center',
    flex: 1,
    textAlign: 'center',
    alignItems: 'center',
    borderRadius: '100px',
    height: '100%',
  },
  tabLabel: {
    fontSize: '14px !important',
    fontWeight: '600 !important',
    color: '#000',
  },
};
