export const RECEBIMENTO_CONFIG_FORM_DATA_DEFAULT = {
  dadoBancario: {
    banco: null,
    agencia: "",
    digitoAgencia: "",
    conta: "",
    digitoVerificador: "",
    tipoConta: "CONTA_CORRENTE",
    documento: "",
    operacao: ""
  },
  endereco: {
    bairro: "",
    cep: "",
    complemento: "",
    estado: null,
    municipio: null,
    nomeLogradouro: "",
    numero: ""
  },
  id: null,
  documentoTipo: "CNPJ",
  documento: "",
  nomeFantasia: "",
  razaoSocial: "",
  status: null,
  responsavelTelefone: "",
  responsavelNome: "",
  email: "",
};

export const ERROR_SCHEMA = {
  documento: '',
  razaoSocial: '',
  nomeFantasia: '',
  responsavelNome: '',
  responsavelTelefone: '',
  email: '',
  banco: '',
  conta: '',
  agencia: '',
  bairro: '',
  cep: '',
  estado: '',
  cidade: '',
  numero: '',
  nomeLogradouro: '',
};
