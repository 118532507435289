import styled from '@emotion/styled';
import React, { useEffect, useState } from 'react';
import { useMutation } from 'react-apollo';
import AssignmentIcon from '@material-ui/icons/Assignment';
import { opcoesParcela } from '../constants';
import { TextField } from '../../../../../components/TextField';
import { GERAR_BOLETO } from '../../../../../graphql/mutations';
import InputDateForm from '../../../../../components/input/InputDateForm';
import { SelectSearch } from '../../../../../components/select/SelectSearch';
import moment from 'moment';
import { BoletosEmitidos } from '../../../../visualizar-venda/components/financeiro/boletos-emitidos';
import { Switch } from '@material-ui/core';

const Boleto = ({ handleMeioPagamento, boleto, dados, setNotification }) => {
  const { id, valorSubtotal } = dados;
  const { quantidadeParcelas, dataVencimento, emailPagador } = boleto;

  const [loadingBoleto, setLoadingBoleto] = useState(false);
  const [idBoleto, setIdBoleto] = useState(null);
  const [switchValue, setSwitchValue] = useState(true);

  const [gerarBoleto] = useMutation(GERAR_BOLETO);
  const verificarCamposObrigatorios = () => {
    if (quantidadeParcelas?.value === 0 || !dataVencimento || !emailPagador)
      return true;

    return false;
  };

  const buttonDisabled =
    loadingBoleto || verificarCamposObrigatorios() || !!idBoleto;

  const handleGerarBoleto = async () => {
    try {
      setLoadingBoleto(true);
      const response = await gerarBoleto({
        variables: {
          comercialNotificacaoRequestDTO: {
            vendaId: id,
            valor: valorSubtotal,
            quantidadeParcelas: quantidadeParcelas.value,
            dataVencimento: moment(dataVencimento).format(
              'YYYY-MM-DDThh:mm:ss'
            ),
            emailPagador,
            gerarBoleto: switchValue,
          },
        },
      });

      const { id: idBoleto } = response.data?.adicionarBoletoAVenda;
      setIdBoleto(idBoleto);
    } catch ({ graphQLErrors }) {
      if (graphQLErrors) {
        setNotification({
          variant: 'error',
          message: graphQLErrors[0].message,
          isOpen: true,
        });
      }

      setLoadingBoleto(false);
    }
  };

  const handleSwitch = () => {
    setSwitchValue(!switchValue)
  }

  return idBoleto ? (
    <BoletosEmitidos dados={dados} gerarBoleto={handleGerarBoleto}/>
  ) : (
    <ContentBoleto>
      <Row>
        <ContentField>
          <LabelField>Selecione o número de parcelas</LabelField>
          <SelectSearch
            value={quantidadeParcelas}
            elements={opcoesParcela}
            onChange={(e) => handleMeioPagamento('quantidadeParcelas', e)}
          />
        </ContentField>
        <ContentField>
          <LabelField>Data de vencimento</LabelField>
          <InputDateForm
            variant="outlined"
            fullWidth
            value={dataVencimento || ''}
            onChange={(e) => handleMeioPagamento('dataVencimento', e)}
          />
        </ContentField>
      </Row>
      <TextField
        label="E-mail do pagador"
        value={emailPagador}
        onChange={(e) => {
          handleMeioPagamento('emailPagador', e.target.value);
        }}
      />
      <ContentButton>
        <ContentSwitch>
          <LabelFieldSwitch fontSize={"14px"}>
            Emitir e enviar o boleto automaticamente
          </LabelFieldSwitch>
          <Switch checked={switchValue} onClick={handleSwitch} color={"primary"} />
        </ContentSwitch>
        <Button onClick={handleGerarBoleto} disabled={buttonDisabled}>
          <LabelButton disabled={buttonDisabled}>Salvar</LabelButton>
        </Button>
      </ContentButton>
    </ContentBoleto>
  );
};
export default Boleto;

const LabelFieldSwitch = styled.label`
 color: #676767;
  font-size: 14px;
  height: 16px;

`

const ContentSwitch = styled.div`
  display:flex ;
  justify-Content: center;
  align-items:center;
`;

const ContentBoleto = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
`;

const ContentField = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const LabelField = styled.label`
  color: #868686;
  font-size: 12px;
  height: 16px;
`;

const ContentButton = styled.div`
  display: flex;
justify-content: space-between;
align-items: center;
`;

const Button = styled.button`
  display: flex;
  align-items: center;
  width: 160px;
  height: 40px;
  background-color: #505050;
  border-radius: 100px;
  border: 2px solid rgba(220, 220, 220, 0.2);
  gap: 8px;
  justify-content: center;
  padding: 0 16px;
  color: #fff;
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  ${(props) => props.disabled && 'opacity: 0.5'};
`;

const LabelButton = styled.label`
  font-weight: 600;
  font-size: 14px;
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  display:flex;
  align-items: center;
  justify-content:center;
`;
