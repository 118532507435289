import React, { useState } from 'react';
import styled from '@emotion/styled';
import moment from 'moment';
import Grid from '@material-ui/core/Grid';
import Colors from '../../../../theme/Colors';
import { useMutation } from 'react-apollo';
import { CRIAR_PROFISSIONAL_SAUDE, UPDATE_PROFISSIONAL_SAUDE, UPDATE_PROFISSIONAL_SAUDE_GRUPO_PERMISSAO } from '../../../../graphql/mutations';
import string from '../../../../utils/string';
import DadosEndereco from './dadosEndereco';
import MobileStepper from '@material-ui/core/MobileStepper';
import DadosBase from './dadosBase';
import { LIST_ESPECIALIDADES } from '../../../../graphql/queryes';
import { useQuery } from '@apollo/react-hooks';

const StepperProfissionalSaude = ({
  dados,
  onClose,
  criandoProfissional,
  setNotification,
  disabled
}) => {
  const [passoAtual, setPassoAtual] = useState(0);
  const [criando, setCriando] = useState(false);
  const responseEspecialidades = useQuery(LIST_ESPECIALIDADES, {
    fetchPolicy: 'no-cache',
    variables: {
      pageableDTO: {
        sortField: 'especialidade',
        sortDir: 'ASC',
      },
    },
  });

  const getEnderecoContratante = () => {
    const {
      nomeLogradouro,
      numero,
      bairro,
      cep,
      complemento,
      municipio,
      estado
    } = dados.contratante?.endereco || {};

    const enderecoContratante = {
      nomeLogradouro,
      numero,
      bairro,
      cep,
      complemento,
      municipio,
      estado
    }

    return enderecoContratante
  };

  const formDataDefault = {
    nome: '',
    cpf: '',
    cns: '',
    dataNascimento: null,
    email: '',
    numeroConselho: '',
    ufConselho: null,
    grupoPermissoes: null,
    especialidades: [],
    possuiAgenda: false,
    utilizaAssinaturaDigital: true,
    utilizaTelemedicina: true,
    utilizaSecretariaVirtual: true,
    prontuarioExclusivo: false,
    utilizaVIZI: false,
    permiteAvaliacaoVizi: false,
    endereco: getEnderecoContratante(),
  }


  const [formData, setFormData] = useState(dados.profissional?.id ? dados.profissional : formDataDefault);
  const [criarProfissional] = useMutation(
    CRIAR_PROFISSIONAL_SAUDE
  );
  const [editarProfissionalSaude] = useMutation(UPDATE_PROFISSIONAL_SAUDE);
  const [editarGrupoPermissao] = useMutation(
    UPDATE_PROFISSIONAL_SAUDE_GRUPO_PERMISSAO
  );
  const [formDataErrors, setFormDataErrors] = useState({
    possuiAgenda: false,
    nome: false,
    cpf: false,
    dataNascimento: false,
    email: false,
    especialidades: false,
  });
  const [dadosInvalidos, setDadosInvalidos] = useState(true);

  const atualizarDadosErrors = (dadosAtualizadosErrors) => {
    setFormDataErrors({
      ...formDataErrors,
      ...dadosAtualizadosErrors,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      setCriando(true);
      const dadosProfissional = {
        ativo: dados.profissional?.id ? formData.ativo : true,
        nome: formData.nome,
        cpf: string.removeSpecialChars(formData.cpf),
        cns: formData.cns,
        dataNascimento: moment(formData.dataNascimento).format('YYYY-MM-DD'),
        endereco: {
          nomeLogradouro: formData.endereco?.nomeLogradouro,
          numero: formData.endereco?.numero,
          bairro: formData.endereco?.bairro,
          cep: string.removeSpecialChars(formData.endereco?.cep),
          complemento: formData.endereco?.complemento,
          municipio: formData.endereco?.municipio
            ? { id: formData.endereco?.municipio?.value }
            : null,
          estado: formData.endereco?.estado
            ? { id: formData.endereco?.estado?.value }
            : null,
        },
        tipo: formData.tipo?.value,
        numeroConselho: formData.numeroConselho,
        ufConselho: formData.ufConselho?.value,
        possuiAgenda: formData.possuiAgenda,
        especialidades: formData.especialidades?.map((e) => ({ id: e.value })),
        email: formData.email,
        utilizaAssinaturaDigital: formData.utilizaAssinaturaDigital,
        utilizaTelemedicina: formData.utilizaTelemedicina,
        utilizaSecretariaVirtual: formData.utilizaSecretariaVirtual,
        prontuarioExclusivo: formData.prontuarioExclusivo,
        utilizaVIZI: formData.utilizaVIZI,
        permiteAvaliacaoVizi: formData.permiteAvaliacaoVizi,
      };

      const gruposPermissoes = formData.grupoPermissoes?.map((group) => {
        return { id: group?.value };
      });

      dados.profissional?.id ? await handleEditar(dadosProfissional, gruposPermissoes) : await handleCriar(dadosProfissional, gruposPermissoes);

    } catch ({ graphQLErrors }) {
      setCriando(false);
      if (graphQLErrors) {
        setNotification({
          variant: 'error',
          isOpen: true,
          message: graphQLErrors[0].message,
        });
      }
    } finally {
      setCriando(false);
    }
  };

  const handleCriar = async (dadosProfissional, gruposPermissoes) => {
    try {
      const dadosBase = {
        venda: {
          id: dados.id,
        },
        gruposPermissoes: gruposPermissoes,
        profissionalSaude: dadosProfissional,
      };
  
      await criarProfissional({
        variables: dadosBase,
      });

      handleClose();

    } catch ({graphQLErrors}) {
      setNotification({
        variant: 'error',
        isOpen: true,
        message: graphQLErrors[0].message,
      });
    }
  }

  const handleEditar = async (dadosProfissional, gruposPermissoes) => {
    try {
      await editarProfissionalSaude({
        variables: {
          vendaId: dados.vendaId,
          profissionalSaude: {
            id: dados.profissional.id,
            ...dadosProfissional,
            usuario: {
              email: dadosProfissional.email,
            },
          }
        }
      });
      
      await editarGrupoPermissao({
        variables: {
          profissionalSaudeId: dados.profissional.id,
          unidadeId: dados.unidade.id,
          groups: gruposPermissoes,
        },
      });
      handleClose();

    } catch ({graphQLErrors}) {
      setNotification({
        variant: 'error',
        isOpen: true,
        message: graphQLErrors[0].message,
      });
    }
  }

  const handleLoadEspecialidades = () => {
    return responseEspecialidades?.data?.findAllEspecialidadeMedicaList;
  };

  const handleClose = () => {
    onClose && onClose();
  };

  const handleAtualizarDados = (dadosAtualizados) => {
    setFormData({
      ...formData,
      ...dadosAtualizados,
    });
  };

  const verificaDadosInvalidos = (isDadosValidos) => {
    setDadosInvalidos(isDadosValidos);
  };

  const proximoPasso = () => {
    setPassoAtual((prevPassoAtual) => {
      return prevPassoAtual + 1;
    });
  };

  const voltar = () => {
    setPassoAtual((prevPassoAtual) => {
      return prevPassoAtual - 1;
    });
  };

  const renderButton = (label, handleFunction, disabled) => (
    <>
      <Button type="button" disabled={disabled} onClick={handleFunction}>
        {label}
      </Button>
    </>
  );

  const getNextButton = () => {
    if(formData.tipo?.value === 1) {
      return passoAtual === 1
      ? renderButton(
          'Finalizar',
          handleSubmit,
          dadosInvalidos || criando
        )
      : renderButton('Proximo', proximoPasso, dadosInvalidos)

    } else {
      return renderButton('Finalizar', handleSubmit, dadosInvalidos || criando || disabled)
    }
  }

  return (
    <FlexGrid direction="row" spacing={2}>
      <Passos item xs={11} sm={11} md={11} ls={11}>
        {passoAtual === 0 && (
          <DadosBase
            dados={formData}
            unidade={dados.unidade}
            dadosErrors={formDataErrors}
            handleAtualizarDadosErrors={atualizarDadosErrors}
            handleAtualizarDados={handleAtualizarDados}
            criandoProfissional={criandoProfissional}
            handleDadosInvalidos={verificaDadosInvalidos}
            dadosEspecialidades={handleLoadEspecialidades()}
          />
        )}

        {passoAtual === 1 && (
          <DadosEndereco
            dados={formData.endereco}
            unidade={dados.unidade}
            dadosErrors={formDataErrors}
            handleAtualizarDadosErrors={atualizarDadosErrors}
            handleAtualizarDados={handleAtualizarDados}
            handleDadosInvalidos={verificaDadosInvalidos}
          />
        )}
      </Passos>

      <div>
        <ButtonsContainer>
          <Guia
            variant="progress"
            steps={2}
            activeStep={passoAtual}
            position={'bottom'}
            backButton={renderButton('Voltar', voltar, passoAtual - 1 < 0)}
            nextButton={
             getNextButton()
            }
          />
        </ButtonsContainer>
      </div>
    </FlexGrid>
  );
};

const FlexGrid = styled(Grid)`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  height: 100%;
`;

const FormGroup = styled(Grid)`
  margin: 0 auto !important;
`;

const Passos = styled(FormGroup)`
  max-height: calc(100vh - 110px) !important;
  overflow-y: auto;
  overflow-x: hidden;
`;

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  flex-basis: 0;
`;

const Button = styled.button`
  border: 0;
  background-color: ${Colors.primary.main};
  color: #fff;
  padding: 5px 10px;
  box-sizing: border-box;
  height: 34px;
  border-radius: 6px;
  margin: 8px 0;
  cursor: pointer;

  &:disabled {
    background-color: ${Colors.commons.gray};
  }
`;

const Guia = styled(MobileStepper)`
  width: 600;
  flex-grow: 1;
  align-self: center;
  justify-content: center;
`;


export default StepperProfissionalSaude;
