import React, { useEffect, useState } from 'react';
import { useLazyQuery } from 'react-apollo';
import Grid from '@material-ui/core/Grid';
import { useMutation } from '@apollo/react-hooks';
import { Checkbox, CircularProgress, Typography } from '@material-ui/core';
import { PROFISSIONAL_SAUDE_AUTHORITY_SECTION_LIST } from '../../../../graphql/queryes';
import {
  CREATE_GROUP_PERMISSAO,
  UPDATE_GROUP_PERMISSAO,
} from '../../../../graphql/mutations';
import { withStyles } from '@material-ui/core';
import List from '../../../../components/list/list';
import RenderTextField from '../../../../components/TextField/RenderTextField';
import ButtonClearPrimary from '../../../../components/button/ButtonClearPrimary';
import { SelectSearch } from '../../../../components/select/SelectSearch';

const listaStatus = [
  { value: true, name: 'Ativo', label: 'Ativo' },
  { value: false, name: 'Inativo', label: 'Inativo' },
];

const ListaPermissao = (props) => {
  const { classes, listaPermissao, onClose, newGroup, idRede } = props;

  const [nome, setNome] = useState(null);
  const [listaGrupoPermissao, setListaGrupoPermissao] = useState([]);
  const [loadingSave, setLoadingSave] = useState(false);
  const [status, setStatus] = useState(listaStatus[0]);
  const [disabledButtonSalvar, setDisabledButtonSalvar] = useState(false);

  const columns = [
    {
      title: 'Permissão',
      value: (item) => item.nome,
    },
    {
      title: 'Criar',
      value: (item) => {
        return possuiItem(item, 1);
      },
    },
    {
      title: 'Visualizar',
      value: (item) => {
        return possuiItem(item, 2);
      },
    },
    {
      title: 'Editar',
      value: (item) => {
        return possuiItem(item, 3);
      },
    },
    {
      title: 'Remover',
      value: (item) => {
        return possuiItem(item, 4);
      },
    },
    {
      title: 'Visualizar de outros profissionais',
      value: (item) => {
        return possuiItem(item, 5);
      },
    },
    {
      title: 'Atendimento',
      value: (item) => {
        return possuiItem(item, 6);
      },
    },
    {
      title: 'Unificar',
      value: (item) => {
        return possuiItem(item, 7);
      },
    },
    {
      title: 'Visualizar relatórios',
      value: (item) => {
        return possuiItem(item, 8);
      },
    },
    {
      title: 'Editar outros profissionais',
      value: (item) => {
        return possuiItem(item, 9);
      },
    },
  ];

  const [
    listarProfissionalSaudeAuthoritySection,
    { data: listaProfissionalSaudeAuthoritySection },
  ] = useLazyQuery(PROFISSIONAL_SAUDE_AUTHORITY_SECTION_LIST);

  useEffect(() => {
    listarProfissionalSaudeAuthoritySection();
    setNome(listaPermissao?.nome);
    !newGroup && setStatus(listaPermissao.ativo ? listaStatus[0] : listaStatus[1]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (listaProfissionalSaudeAuthoritySection) {
      newGroup ? listarPermissoesNovoGrupo() : listarPermissoesChecked();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listaProfissionalSaudeAuthoritySection]);

  useEffect(() => {
    verificaDadosPreenchidos();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nome]);

  const [saveGrupoPermissao] = useMutation(
    listaPermissao.id ? UPDATE_GROUP_PERMISSAO : CREATE_GROUP_PERMISSAO
  );

  const listarPermissoesNovoGrupo = () => {
    setListaGrupoPermissao(
      listaProfissionalSaudeAuthoritySection.findAllProfissionalSaudeAuthoritySectionList
    );
  };

  const possuiItem = (item, ordem) => {
    const itemAuthority = item.authorities.find(
      (authority) => authority.ordem === ordem
    );

    return (
      itemAuthority && (
        <Checkbox
          color="primary"
          checked={itemAuthority.checked}
          onChange={() => checkedPermissao(itemAuthority)}
        />
      )
    );
  };

  const listarPermissoesChecked = () => {
    const listaChecked =
      listaProfissionalSaudeAuthoritySection?.findAllProfissionalSaudeAuthoritySectionList.map(
        (item) => {
          const authorities = item.authorities.map((authority) => {
            const itemAuthority = item.authorities.find(
              (itemAuthority) => itemAuthority.authority === authority.authority
            );

            const isChecked = listaPermissao.authorities.some(
              (authorityListaPermissao) => {
                return (
                  authorityListaPermissao.authority === itemAuthority.authority
                );
              }
            );

            return {
              ...authority,
              checked: isChecked,
            };
          });
          return {
            ...item,
            authorities,
          };
        }
      );

    setListaGrupoPermissao(listaChecked);
  };

  const handleChangeFieldNome = (e) => {
    setNome(e.target.value);
  };

  const getAuthoritiesChecked = () => {
    let listaAuthoriesAdaptado = [];

    const authoritiesChecked = listaGrupoPermissao.map((item) => {
      const authoritiesChecked = item.authorities.filter((itemAuthority) => {
        return itemAuthority.checked;
      });
      return {
        ...item,
        authorities: authoritiesChecked,
      };
    });

    authoritiesChecked.forEach((item) => {
      item.authorities.forEach((itemAuthority) => {
        listaAuthoriesAdaptado.push({
          authority: itemAuthority.authority,
        });
      });
    });

    return listaAuthoriesAdaptado;
  };

  const onSave = async () => {
    try {
      setLoadingSave(true);
      const id = listaPermissao?.id;
      const authorities = getAuthoritiesChecked();

      const group = {
        id,
        nome,
        ativo: status.value,
        authorities,
        rede: { id: idRede },
      };

      await saveGrupoPermissao({
        variables: { group },
      });

      onClose();
    } finally {
      setLoadingSave(false);
    }
  };

  const checkedPermissao = (authority) => {
    const grupoPermissao = listaGrupoPermissao;

    const listaPermissaoCheckedTrue = grupoPermissao.map((item) => {
      const authoritiesCheckedTrue = item.authorities.map((itemAuthority) => {
        const checked =
          itemAuthority.authority === authority.authority
            ? !itemAuthority.checked
            : itemAuthority.checked;
        return {
          ...itemAuthority,
          checked: checked,
        };
      });

      return {
        ...item,
        authorities: authoritiesCheckedTrue,
      };
    });

    setListaGrupoPermissao(listaPermissaoCheckedTrue);
  };

  const handleChangeStatus = (e) => {
    setStatus(e);
  };

  const verificaDadosPreenchidos = () => {
    if (!nome || nome === '') {
      setDisabledButtonSalvar(true);
      return;
    }
    setDisabledButtonSalvar(false);
  };

  return (
    <div className={classes.dialog}>
      <div className={classes.contentHeader}>
        <div className={classes.nome}>
          <RenderTextField
            label="Nome:"
            field={nome}
            handleChange={handleChangeFieldNome}
          />
        </div>
        <div className={classes.status}>
          <Typography color="primary" component="label">
            Status:
          </Typography>
          <SelectSearch
            className={classes.selectStatus}
            name="status"
            value={status}
            elements={listaStatus}
            onChange={handleChangeStatus}
            isClearable={false}
          />
        </div>
      </div>

      <Grid className={classes.contentList}>
        {listaGrupoPermissao.length > 0 ? (
          <List
            items={listaGrupoPermissao}
            columns={columns}
            loading={false}
            last={true}
          />
        ) : (
          <div className={classes.mensagemListaVazia}>
            Nenhum item encontrado
          </div>
        )}
      </Grid>
      <Grid className={classes.contentSave}>
        <ButtonClearPrimary
          onClick={onSave}
          disabled={loadingSave || disabledButtonSalvar}
          className={classes.buttonSave}
        >
          Salvar
          {loadingSave && (
            <CircularProgress size={12} style={{ marginLeft: 10 }} />
          )}
        </ButtonClearPrimary>
      </Grid>
    </div>
  );
};

const styles = () => ({
  dialog: {
    minWidth: 500,
    overflow: 'hidden',
    height: '100%',
    display: 'grid',
    gridTemplateRows: '72px 1fr 88px',

    '& th': {
      textAlign: 'left',
      top: 0,
      position: 'sticky',
      whiteSpace: 'normal',
      background: '#fff',
      zIndex: 1,
      height: '57px',
    },
    '& td': {
      textAlign: 'center',

      '&:first-child': {
        textAlign: 'left',
      },
    },
  },
  contentList: {
    overflow: 'auto',
    '&>div': {
      height: '100%',
    }
  },
  contentHeader: {
    display: 'flex',
    padding: '0 24px',
  },
  nome: {
    paddingRight: '24px',
    width: '50%',
    '& input': {
      height: '21px',
      color: '#333333',
    },
  },
  mensagemListaVazia: {
    color: '#00B0AE',
    display: 'flex',
    marginTop: 24,
    justifyContent: 'center',
  },
  contentSave: {
    display: 'flex',
    justifyContent: 'end',
  },
  buttonSave: {
    background: '#00B0AE',
    color: '#fff',
    marginRight: 24,
    width: 170,
    height: '32px',
    alignSelf: 'center',

    '&:hover': {
      color: '#00B0AE',
    },
  },
  selectStatus: {
    width: '100%',
    '& input': {
      fontSize: '16px',
    },
  },
  status: {
    width: '50%',
    zIndex: 10,
  },
});

export default withStyles(styles)(ListaPermissao);
