import React from 'react';
import styled from '@emotion/styled';
import { Tab, Tabs } from '../../../../components/tabs';
import Cartao from '../../../list-vendas/components/criar-venda/tabsPagamento/cartao';
import Boleto from '../../../list-vendas/components/criar-venda/tabsPagamento/boleto';
import { tabsPagamento } from './constants';

const MeioPagamentoTab = ({
  id,
  dados,
  tabSelected,
  cartaoCredito,
  setNotification,
  handleCloseModal,
  handleMeioPagamento,
}) => {
  if (tabSelected === 'CIELO') {
    return (
      <Cartao
        vendaId={id}
        cartao={cartaoCredito}
        handleMeioPagamento={(field, value) => {
          handleMeioPagamento('cartaoCredito', field, value);
        }}
        setNotification={setNotification}
        handleCloseModal={handleCloseModal}
      />
    );
  }

  return (
    <Boleto
      boleto={dados.boleto}
      dados={dados}
      handleMeioPagamento={(field, value) => {
        handleMeioPagamento('boleto', field, value);
      }}
      setNotification={setNotification}
    />
  );
};

export const Pagamento = ({
  dados,
  setNotification,
  setTabSelected,
  tabSelected,
  handleMeioPagamento,
  handleCloseModal,
}) => {
  const { id, cartaoCredito } = dados;

  return (
    <div>
      <Tabs
        onChange={(newValue) => {
          setTabSelected(newValue);
        }}
        value={tabSelected}
      >
        {tabsPagamento.map(({ name, value }) => (
          <TabStyled value={value}>{name}</TabStyled>
        ))}
      </Tabs>
      <MeioPagamentoTab
        id={id}
        dados={dados}
        tabSelected={tabSelected}
        cartaoCredito={cartaoCredito}
        setNotification={setNotification}
        handleCloseModal={handleCloseModal}
        handleMeioPagamento={handleMeioPagamento}
      />
    </div>
  );
};

const TabStyled = styled(Tab)`
  width: 100%;
`;
