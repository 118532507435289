import React, { useState } from 'react';
import { useMutation } from 'react-apollo';
import { ALTERAR_DADOS_RESPONSAVEL } from '../../../graphql/mutations';
import string from '../../../utils/string';
import FormDadosContratante from './editar-contratante/FormDadosContratante';
import {
  Button,
  ContainerButton,
} from './editar-contratante/styles';

const EditarDadosResponsavel = ({ venda, onClose }) => {
  const [formData, setFormData] = useState({
    paisAtuacao: venda.contratante?.paisAtuacao,
    cnpj: venda.contratante?.cnpj,
    inscricaoEstadual: venda.contratante?.inscricaoEstadual,
    responsavelCargo: venda.contratante?.responsavelCargo,
    documentoTipo: venda.contratante?.documentoTipo,
    responsavelDocumento: venda.contratante?.responsavelDocumento,
    responsavelNome: venda.contratante?.responsavelNome,
  });
  const [loadingSave, setLoadingSave] = useState(false);
  const [dadosInvalidos, setDadosInvalidos] = useState(false);

  const [updateDadosResponsavel] = useMutation(ALTERAR_DADOS_RESPONSAVEL);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoadingSave(true);

    await updateDadosResponsavel({
      variables: {
        venda: {
          id: venda.id,
        },
        cnpj: string.removeSpecialChars(formData.cnpj),
        cargo: formData.responsavelCargo,
        nome: formData.responsavelNome,
        documento: string.removeSpecialChars(
          formData.responsavelDocumento
        ),
        pais: {id: formData.paisAtuacao.id},
      },
    });

    setLoadingSave(false);
    handleClose();
  };

  const handleClose = () => {
    onClose && onClose();
  };

  const verificaDadosInvalidos = (isDadosValidos) => {
    setDadosInvalidos(isDadosValidos);
  };

  return (
    <>
      <FormDadosContratante
        formData={formData}
        setFormData={setFormData}
        handleDadosInvalidos={verificaDadosInvalidos}
      />
      <ContainerButton>
        <Button
          type="button"
          disabled={dadosInvalidos || loadingSave}
          onClick={handleSubmit}
        >
          Salvar
        </Button>
      </ContainerButton>
    </>
  );
};

export default EditarDadosResponsavel;
