import React, {useEffect, useState} from 'react';
import Grid from "@material-ui/core/Grid";
import Flex from '../../components/flex';
import { useQuery, useMutation, useLazyQuery } from 'react-apollo';
import {
  GET_ENTITY_FISICA_ID,
  GET_ENTITY_JURIDICA_ID,
  GET_ENTITY_NIVEL_ROLE_BY_CODE,
  LIST_BANK_SIMPLE,
} from '../../graphql/queryes';
import { useParams, useHistory } from 'react-router-dom';
import {
  UPDATE_ENTIDADE_FISICA,
  UPDATE_ENTIDADE_JURIDICA,
  CREATE_ENTIDADE_JURIDICA_HAWK,
  CREATE_ENTIDADE_FISICA_HAWK,
  CREATE_ENTIDADE_FISICA_PUBLIC,
  CREATE_ENTIDADE_JURIDICA_PUBLIC,
} from '../../graphql/mutations';
import { DASHBOARD } from '../../router/names';
import { createOrUpdatePessoa } from './service';
import canEditarEntidade from '../../services/authority-service/can-editar-entidade';
import Notification from '../../components/notification/Notification';
import string from '../../utils/string';
import { obterUsuarioLogadoLocal } from '../../services/usuario-service';
import DadosBancarios from './components/dadosBancarios';
import CamposEntidadeFisica from './components/camposEntidadeFisica';
import CamposEntideJuridica from './components/camposEntidadeJuridica';
import DadosResponsavel from './components/dadosResponsavel';
import DadosDemonstracao from './components/dadosDemonstracao';
import {
  Button,
  ButtonsContainer,
  FormGroup,
  FormHeading,
  ToggleButtonEsquerdo,
  ToggleButtonDireito,
} from './constraints/constantesEntidade';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import styled from '@emotion/styled';
import { withBaseLayout } from '../../layout/base-layout';
import WhitelabelConfig from './components/whitelabelConfig';

import { checkUserRole } from '../../utils/checkUserRole';

const notificationDefault = {
  variant: '',
  message: '',
  isOpen: false,
};

const EditEntity = ({ codeEntity }) => {
  const { idEntity, tipoEntity } = useParams();
  const { push } = useHistory();

  const roleEntidadeCodigo = useQuery(GET_ENTITY_NIVEL_ROLE_BY_CODE, {
    variables: {
      codigo: codeEntity,
    },
  });

  const [notification, setNotification] = useState({
    ...notificationDefault,
  });
  const [criando, setCriando] = useState(false);
  const [formEditavel, setFormEditavel] = useState(false);
  const [mainTabSelected, setMainTabSelected] = useState('USUARIO');
  const [whitelabelId, setWhitelabelId] = useState(null);
  const [isOperacional, setIsOperacional] = useState(null);

  const [formData, setFormData] = useState({
    codigo: '',
    nome: '',
    tipo: 'FISICA',
    razaoSocial: '',
    documento: '',
    nomeFantasia: '',
    cpf: '',
    cnpj: '',
    email: '',
    ativo: true,
    documentoBancario: '',
    banco: null,
    conta: '',
    agencia: '',
    responsavelNome: '',
    responsavelCpf: '',
  });

  const isFisica = () => {
    return formData?.tipo === 'FISICA';
  };

  const isJuridica = () => {
    return formData?.tipo === 'JURIDICA';
  };

  const { data: dataBanks } = useQuery(LIST_BANK_SIMPLE);

  const [mUptadePessoaFisica] = useMutation(UPDATE_ENTIDADE_FISICA);
  const [mCreateEntidadeFisicaHawk] = useMutation(
    !!codeEntity ? CREATE_ENTIDADE_FISICA_PUBLIC : CREATE_ENTIDADE_FISICA_HAWK
  );

  const [mUpdatePessoaJuridica] = useMutation(UPDATE_ENTIDADE_JURIDICA);
  const [mCreateEntidadeJuridicaHawk] = useMutation(
    !!codeEntity
      ? CREATE_ENTIDADE_JURIDICA_PUBLIC
      : CREATE_ENTIDADE_JURIDICA_HAWK
  );

  const [getEntityById, { loading, data }] = useLazyQuery(
    tipoEntity === 'JURIDICA' ? GET_ENTITY_JURIDICA_ID : GET_ENTITY_FISICA_ID,
    {
      fetchPolicy: 'no-cache',
    }
  );

  useEffect(() => {
    if (idEntity !== 'new' && idEntity !== undefined && !codeEntity) {
      getEntityById({ variables: { id: idEntity } });
      setFormEditavel(canEditarEntidade({ id: idEntity }));
    } else {
      setFormEditavel(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idEntity]);

  useEffect(() => {
    const dadosRecuperados =
      tipoEntity === 'JURIDICA'
        ? data?.findByIdEntidadeJuridica
        : data?.findByIdEntidadeFisica;
    if (!loading && idEntity !== 'new' && dadosRecuperados) {
      setFormData({
        ...formData,
        tipo: dadosRecuperados?.tipo,
        codigo: dadosRecuperados?.codigo,
        email: dadosRecuperados?.email,
        ativo: dadosRecuperados?.ativo,
        responsavelCpf: dadosRecuperados?.responsavelCpf,
        responsavelNome: dadosRecuperados?.responsavelNome,
        documentoBancario: dadosRecuperados?.dadoBancario?.documento,
        agencia: dadosRecuperados?.dadoBancario?.agencia,
        conta: dadosRecuperados?.dadoBancario?.conta,
        banco: {
          value: dadosRecuperados?.dadoBancario?.banco?.id,
          label: dadosRecuperados?.dadoBancario?.banco?.nome,
        },
        razaoSocial: dadosRecuperados?.razaoSocial || undefined,
        nomeFantasia: dadosRecuperados?.nomeFantasia || undefined,
        cnpj: dadosRecuperados?.cnpj || undefined,
        cpf: dadosRecuperados?.cpf || undefined,
        nome: dadosRecuperados?.nome || undefined,
      });

      const whitelabel = dadosRecuperados?.whitelabel;
      if (whitelabel) {
        setWhitelabelId(whitelabel?.id);
        loadUserRole()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  const loadUserRole = async () => {
    try {
      const isOperacional = await checkUserRole('ROLE_OPERACIONAL');
      setIsOperacional(isOperacional);

      if (isOperacional) {
        setMainTabSelected('WHITELABEL');
      }

    } catch(error) {
      console.error(error);
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setCriando(true);
      const data = {
        codigo: string.isEmpty(formData?.codigo) ? undefined : formData.codigo,
        tipo: formData?.tipo,
        email: formData?.email,
        ativo: formData?.ativo,
        dadoBancario: {
          agencia: formData?.agencia,
          conta: formData?.conta,
          codigoVerificador: formData?.codigoVerificador,
          documento: string.removeSpecialChars(formData?.documentoBancario),
          banco: formData?.banco?.value
            ? {
                id: formData?.banco?.value,
              }
            : null,
        },
      };

      if (isFisica()) {
        data.documento = string.removeSpecialChars(formData?.cpf);
        data.nome = formData?.nome;
      }

      if (isJuridica()) {
        data.documento = string.removeSpecialChars(formData?.cnpj);
        data.nome = formData?.razaoSocial || undefined;
        data.nomeFantasia = formData.nomeFantasia;
        data.responsavelNome = formData?.responsavelNome;
        data.responsavelCpf =
          string.removeSpecialChars(formData?.responsavelCpf) || undefined;
      }

      if (idEntity !== 'new') {
        data.id = idEntity;
      } else {
        data.id = undefined;
      }

      const banco = dataBanks?.findAllBanco?.content.find(
        (b) => b.id === data?.dadoBancario?.banco?.id
      );

      if (!!banco) {
        data.dadoBancario = {
          ...data.dadoBancario,
          nome: data.nome,
          banco: {
            id: banco.id,
            nome: banco.nome,
            codigo: banco.codigo,
          },
        };
      }

      await createOrUpdatePessoa(
        data,
        {
          mCreateEntidadeFisicaHawk,
          mUptadePessoaFisica,
          mCreateEntidadeJuridicaHawk,
          mUpdatePessoaJuridica,
        },
        codeEntity
      );

      setNotification({
        variant: 'success',
        isOpen: true,
        message: 'Entidade salva com sucesso',
      });
      setTimeout(() => push(DASHBOARD), 1000);
    } catch ({ graphQLErrors }) {
      if (graphQLErrors) {
        setNotification({
          variant: 'error',
          isOpen: true,
          message: graphQLErrors[0].message,
        });
      }
      setCriando(false);
    }
  };

  if (loading) {
    return (
      <Flex flexDirection="column">
        <Grid container direction="row" spacing={2}>
          <Grid item xs={12}>
            <FormHeading>Carregando...</FormHeading>
          </Grid>
        </Grid>
      </Flex>
    );
  }

  const handleTextFieldChange = (field, event) => {
    setFormData({
      ...formData,
      [field]: event.target.value,
    });
  };

  const handleSelectFieldChange = (field, event) => {
    setFormData({
      ...formData,
      [field]: event,
    });
  };

  const handleTipoChange = (event, tipo) => {
    if (tipo) {
      setFormData({
        ...formData,
        tipo: tipo,
      });
    }
  };

  const isCodigoPermitido = () => {
    if (codeEntity) {
      const authority =
        roleEntidadeCodigo?.data?.findEntidadeNivelAuthorityByCodigo;
      return authority !== 'ROLE_PLATAFORMA';
    }

    const entidadeLogada = obterUsuarioLogadoLocal();

    return !entidadeLogada?.authorities?.some(
      (a) =>
        a.authority === 'ROLE_PLATAFORMA' || a.authority === 'ROLE_COMERCIAL'
    );
  };

  return (
    <StyledFlex flexDirection="column">
      <StyledToggleButton>
        {!isOperacional && (
          <ToggleButtonEsquerdo
            key={1}
            value={'USUARIO'}
            label={'Dados Usuário'}
            selected={mainTabSelected === 'USUARIO'}
            handleClick={() => setMainTabSelected('USUARIO')}
          />
        )}
        <ToggleButtonDireito
          key={2}
          value={'WHITELABEL'}
          label={'Dados Whitelabel'}
          selected={mainTabSelected === 'WHITELABEL'}
          handleClick={() => setMainTabSelected('WHITELABEL')}
        />
      </StyledToggleButton>
      {mainTabSelected === 'USUARIO' && (
        
      <form onSubmit={handleSubmit}>
        <Grid container spacing={2} direction="column" alignItems="center">
          <Grid item>
            <ToggleButtonEsquerdo
              key={1}
              value={'FISICA'}
              label={'Física'}
              selected={isFisica()}
              handleClick={handleTipoChange}
              disabled={!formEditavel}
            />
            <ToggleButtonDireito
              key={2}
              value={'JURIDICA'}
              label={'Jurídica'}
              selected={isJuridica()}
              handleClick={handleTipoChange}
              disabled={!formEditavel}
            />
          </Grid>
        </Grid>

        {isFisica() ? (
          <CamposEntidadeFisica
            formEditavel={!formEditavel}
            fieldChange={handleTextFieldChange}
            dados={formData}
            isCodigoPermitido={isCodigoPermitido()}
          />
        ) : (
          <>
            <CamposEntideJuridica
              formEditavel={!formEditavel}
              fieldChange={handleTextFieldChange}
              dados={formData}
              isCodigoPermitido={isCodigoPermitido()}
            />

            <DadosResponsavel
              fieldChange={handleTextFieldChange}
              formEditavel={!formEditavel}
              dados={formData}
            />
          </>
        )}

        <FormGroup item xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                checked={formData.ativo}
                onChange={() =>
                  setFormData({ ...formData, ativo: !formData.ativo })
                }
                color="primary"
                disabled={true}
              />
            }
            label="Ativo?"
            labelPlacement="start"
            style={{ marginLeft: 0, marginTop: 0 }}
          />
        </FormGroup>

        <DadosBancarios
          formEditavel={!formEditavel}
          textFieldChange={handleTextFieldChange}
          selectFieldChange={handleSelectFieldChange}
          dados={formData}
          dadosbancarios={formData.dadosBancarios}
        />

        {isFisica() ? (
          <DadosDemonstracao responsavelCpf={formData.cpf} />
        ) : (
          <DadosDemonstracao responsavelCpf={formData.responsavelCpf} />
        )}

        {formEditavel && (
          <FormGroup item xs={12}>
            <ButtonsContainer>
              <Button type="submit" disabled={criando}>
                {criando ? 'Salvando...' : 'Salvar'}
              </Button>
            </ButtonsContainer>
          </FormGroup>
        )}
        <Notification
          close={() => {
            setNotification({
              ...notificationDefault,
            });
          }}
          reset={() => {
            setNotification({
              ...notificationDefault,
            });
          }}
          isOpen={notification.isOpen}
          variant={notification.variant}
          message={notification.message}
        />
      </form>
      )}

      {mainTabSelected === 'WHITELABEL' && (
        <WhitelabelConfig whitelabelId={whitelabelId} />
      )}
    </StyledFlex>
  );
};


const StyledFlex = styled(Flex)`
  background-color: #fff;
  border-radius: 16px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 16px;
  margin-top: 32px;
`
const StyledToggleButton = styled.div`
  display: flex;
  align-self: center;
`

export default withBaseLayout(EditEntity);
