import React from 'react';
import { usePerfilPublicoContext } from '../../perfil-publico-context';
import {
  FIND_ALL_ESTADOS,
} from '../../../../graphql/queryes';
import { Card } from '../../../../components/Card';
import { TextField, TextFieldSearch } from '../../../../components/TextField';
import Textarea from '../../../../components/Textarea';
import FormControl from '../../../../components/FormControl';
import { useAsyncSelectPaginated } from '../../../../hooks/useAsyncSelectPaginated';

export const PerfilDadosProfissionais = () => {
  const { setField, values, errors } = usePerfilPublicoContext();

  const handleSetDadosProfissionais = (name, value) => {
    setField('dadosProfissionais.' + name, value);
  };

  const { loadOptions: loadEstadosOptions } = useAsyncSelectPaginated(
    FIND_ALL_ESTADOS,
    {
      variables: {
        searchDTO: {
          ativo: true,
        },
        pageableDTO: {
          pageNumber: 0,
          pageSize: 20,
        },
      },
      dataKey: 'findAllEstado',
    }
  );

  const dadosProfissionais = values.dadosProfissionais || {};
  const dadosProfissionaisErrors = errors.dadosProfissionais || {};

  return (
    <Card>
      <h2>Dados profissionais</h2>

      <TextField
        label="Numero Conselho"
        value={dadosProfissionais.numeroConselho}
        error={dadosProfissionaisErrors.numeroConselho}
        onChange={(e) =>
          handleSetDadosProfissionais('numeroConselho', e.target.value)
        }
      />

      <FormControl label="UF" error={dadosProfissionaisErrors.uf}>
        <TextFieldSearch
          withPaginate
          loadOptions={loadEstadosOptions}
          debounceTimeout={300}
          additional={{
            page: 0,
          }}
          getOptionLabel={(option) => option.uf}
          getOptionValue={(option) => option.uf}
          getValue={(option) => option.uf}
          value={
            dadosProfissionais?.uf
              ? [
                  {
                    uf: dadosProfissionais?.uf,
                  },
                ]
              : []
          }
          onChange={(opt) => handleSetDadosProfissionais('uf', opt?.uf)}
        />
      </FormControl>

      <div>
        <Textarea
          label="Formação"
          value={dadosProfissionais.formacao}
          error={dadosProfissionaisErrors.formacao}
          onChange={(e) =>
            handleSetDadosProfissionais('formacao', e.target.value)
          }
          maxLength={500}
        />
      </div>
      <div>
        <Textarea
          label="Experiencia"
          value={dadosProfissionais.experiencia}
          error={dadosProfissionaisErrors.experiencia}
          onChange={(e) =>
            handleSetDadosProfissionais('experiencia', e.target.value)
          }
          maxLength={250}
        />
      </div>
    </Card>
  );
};
