import React, {useEffect, useState} from "react";
import Grid from "@material-ui/core/Grid";
import {useLazyQuery, useApolloClient} from "react-apollo";
import {useQuery} from '@apollo/react-hooks';
import styled from "@emotion/styled";
import RenderTextField from "../../../../components/TextField/RenderTextField";
import string from "../../../../utils/string";
import {
  CONSULTA_CNPJ, 
  POSSUI_VENDAS_COM_OUTRO_VENDEDOR, 
  FIND_ALL_PAIS, 
  FIND_BRASIL,
  TIPOS_DOCUMENTO,
} from "../../../../graphql/queryes";
import Notification from "../../../../components/notification/Notification";
import { TextFieldSearch } from "../../../../components/TextField";
import { SelectSearch } from "../../../../components/select/SelectSearch";
import { Typography } from "@material-ui/core";
import validaDocumentoEspanha from "../../../../utils/validaDocumentoEspanhol";

const notificationDefault = {
  variant: '',
  message: '',
  isOpen: false,
};

const DadosResponsavelContratante = ({dados, dadosErrors, handleAtualizarDadosErrors, handleAtualizarDados, handleDadosInvalidos, disabled}) => {
  const {data: paisPadrao} = useQuery(FIND_BRASIL);
  
  const [dadosTemp, setDadosTemp] = useState({
    paisAtuacao: dados.paisAtuacao,
    cnpj: dados.cnpj || '',
    responsavelNome: dados.responsavelNome || '',
    responsavelCargo: dados.responsavelCargo || '',
    documentoTipo: dados.documentoTipo || '',
    responsavelDocumento: dados.responsavelDocumento || ''
  });

  const client = useApolloClient();

  const [getConsultaCnpj, {data: dadosConsultaCnpj}] = useLazyQuery(CONSULTA_CNPJ);
  const [getTiposDocumento, {data: tiposDocumento}] = useLazyQuery(TIPOS_DOCUMENTO);

  const [consultaVendaExistente, {data: dadosConsultaVenda}] = useLazyQuery(POSSUI_VENDAS_COM_OUTRO_VENDEDOR);

  const [notification, setNotification] = useState({
    ...notificationDefault,
  });

  useEffect(() => {
    disabled && setDadosTemp(
      {
        paisAtuacao: dados.paisAtuacao,
        cnpj: dados.cnpj || '',
        responsavelNome: dados.responsavelNome || '',
        responsavelCargo: dados.responsavelCargo || '',
        documentoTipo: dados.documentoTipo || '',
        responsavelDocumento: dados.responsavelDocumento || ''
      }
    )

  }, [dados]);

  const consultaDadosPeloCnpj = async (cnpj) => {
    await getConsultaCnpj({
      variables: {
        cnpj: cnpj
      }
    });
  };

  const consultaVendaPeloCnpj = async (cnpj) => {
    await consultaVendaExistente({
      variables: {
        cnpj: cnpj
      }
    });
  };

  const handleCnpjField = (value) => {
    let cnpjSemMascara = string.removeSpecialChars(value);
    setDadosTemp({
      ...dadosTemp,
      cnpj: value
    });

    handleAtualizarDadosErrors({
      cnpj: value && !string.validaCNPJ(value)
    });

    if (cnpjSemMascara.length === 14) {
      consultaDadosPeloCnpj(cnpjSemMascara);
      consultaVendaPeloCnpj(cnpjSemMascara);
    }

    handleAtualizarDados({
      cnpj: value,
    })
  };

  const handleChangeField = (campo, value) => {
    setDadosTemp({
      ...dadosTemp,
      [campo]: value
    });

    handleAtualizarDados({
      [campo]: value
    });

    handleAtualizarDadosErrors({
      [campo]: value === undefined || value === ''
    });
  };

  const handleChangeDocumentoTipo = (value) => {
    setDadosTemp({
      ...dadosTemp,
      documentoTipo: value,
      responsavelDocumento: '',
    });

    handleAtualizarDados({
      documentoTipo: value,
      responsavelDocumento: '',
    });
  };

  const validaDocumento = (value) => {
    if(dadosTemp.paisAtuacao?.descricao === "ESPANHA"){
      if(dadosTemp.documentoTipo) {
        return dadosTemp.documentoTipo?.label === "CPF" ? !string.validaCPF(value) : !validaDocumentoEspanha(value);
      };
    };
    
    return !string.validaCPF(value)
  };

  const handleChangeDocumento = (value) => {
    setDadosTemp({
      ...dadosTemp,
      responsavelDocumento: value
    });

    handleAtualizarDadosErrors({
      responsavelDocumento: validaDocumento(value)
    });

    handleAtualizarDados({
      responsavelDocumento: value
    });
  };

  const verificaCampoDocumentoPreenchido = () => {
    if(dadosTemp.paisAtuacao?.descricao === "ESPANHA") {
      if(dadosTemp.documentoTipo?.label === "DNI" || dadosTemp.documentoTipo?.label === "NIE"){
        return string.removeSpecialChars(dadosTemp.responsavelDocumento).length < 9;
      };
    }
    return string.removeSpecialChars(dadosTemp.responsavelDocumento).length < 11
  };

  const isCamposPreenchidos = () => {
    return dadosTemp.responsavelNome === '' ||
      dadosTemp.responsavelCargo === '' ||
      verificaCampoDocumentoPreenchido() ||
      dadosErrors?.cnpj;
  };

  useEffect(() => {
    getTiposDocumento({
      variables: {
        pageableDTO:{
          sortField: "descricao", 
          sortDir: "ASC"
        }
      }
    });  
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if(tiposDocumento && dados.paisAtuacao?.descricao === "BRASIL") {
      const tipoCPF = tiposDocumento.findAllDocumentoTipo?.find(item => item.descricao === "CPF");
  
      setDadosTemp({
        ...dadosTemp,
        documentoTipo: tipoCPF
      }); 
    };

  }, [tiposDocumento]);

  useEffect(() => {
    if(!dados.paisAtuacao) {
      setDadosTemp({
        ...dadosTemp,
        paisAtuacao: paisPadrao?.findBrasil
      });
  
      handleAtualizarDados({
        paisAtuacao: paisPadrao?.findBrasil
      });
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paisPadrao]);

  useEffect(() => {
    handleDadosInvalidos(isCamposPreenchidos());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleAtualizarDados]);

  useEffect(() => {
    if (dadosConsultaCnpj && !disabled) {
      handleAtualizarDados({
        razaoSocial: dadosConsultaCnpj?.consultaDeCnpj?.razaoSocial,
        nomeFantasia: dadosConsultaCnpj?.consultaDeCnpj?.nomeFantasia,
        endereco: {
          cep: dadosConsultaCnpj?.consultaDeCnpj?.cep,
          numero: dadosConsultaCnpj?.consultaDeCnpj?.numeroEndereco
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dadosConsultaCnpj]);

  useEffect(() => {
    let possuiVendasComOutroVendedor = dadosConsultaVenda?.possuiVendasComOutroVendedor;

    if (possuiVendasComOutroVendedor) {
      setNotification({
        variant: 'error',
        message: 'Já existe uma venda com esse CNPJ',
        isOpen: true,
      });

      handleAtualizarDadosErrors({
        cnpj: true
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dadosConsultaVenda]);

  const handlePaisAtuacaoLoadOptions = async (search, loadedOptions, {page}) => {
    return handleLoadMoreOptions({
      loadedOptions,
      variables: {
        searchDTO: {
          search
        },
        pageableDTO: {
          pageSize: 45,
          pageNumber: page,
          sortDir: "ASC",
          sortField: "descricao"
        }
      },
      query: FIND_ALL_PAIS,
      data: { page }
    });
  };

  const handleLoadMoreOptions = async ({variables, query, data}) => {
    const response = await client.query({
      query,
      variables
    });

    let options = response?.data?.findAllPais?.content || [];

    options = options.map(item => ({
      ...item,
      label: string.capitalize(item.descricao),
    }));

    return {
      options,
      hasMore: !response?.data?.findAllPais?.last,
      additional: {
        page: data.page + 1
      }
    };
  };

  const handleChangePaisAtuacao = value => {
    setDadosTemp({
      ...dadosTemp,
      paisAtuacao: value,
      documentoTipo: '',
      responsavelDocumento: '',
    });

    handleAtualizarDados({
      paisAtuacao: value,
      documentoTipo: '',
      responsavelDocumento: '',
    });
  };

  const verificaMask = () => {
    if(dadosTemp.paisAtuacao?.descricao === "ESPANHA"){
      if(dadosTemp.documentoTipo){
        return dadosTemp.documentoTipo?.label?.toLowerCase();
      };
    };
    
    return 'cpf';
  };

  return (
    <Container>
      <Grid container direction="row">
        <FormGroup item xs={12} sm={12} md={12} ls={12}>
          {disabled ? 
          <RenderTextField
            label={"País *"}
            field={dadosTemp?.paisAtuacao?.label}
            disabled={disabled}
          />
          :
          <>
            <Typography color="black" component="label">
              País *
            </Typography>
            <TextFieldSearch
              placeholder=""
              loadOptions={handlePaisAtuacaoLoadOptions}
              withPaginate
              value={dadosTemp.paisAtuacao}
              onChange={handleChangePaisAtuacao}
              debounceTimeout={300}
              additional={{
                page: 0
              }}
              disabled={disabled}
              />          
          </>}
        </FormGroup>
        <FormGroup item xs={12} sm={12} md={12} ls={12}>
          <RenderTextField label={"CNPJ"}
                           field={dadosTemp.cnpj}
                           mask={'cnpj'}
                           error={dadosErrors?.cnpj}
                           errorText={"CNPJ inválido."}
                           handleChange={(e) => handleCnpjField(e.target.value)}
                           disabled={disabled}/>
        </FormGroup>

        <FormGroup item xs={12} sm={12} md={12} ls={12}>
          <RenderTextField label={"Nome do Responsável *"}
                           field={dadosTemp.responsavelNome}
                           error={dadosErrors?.responsavelNome}
                           errorText={"Este campo é obrigatório."}
                           handleChange={(e) => handleChangeField('responsavelNome', e.target.value)}
                           disabled={disabled}/>
        </FormGroup>
        {dadosTemp.paisAtuacao?.descricao === "ESPANHA" && <FormGroup item xs={12} sm={12} md={12} ls={12}>
          {disabled ? 
          <RenderTextField
            label="Tipo documento"
            field={dadosTemp.documentoTipo?.label || ""}
            disabled={disabled}
          />
          :
          <>
            <Typography color="primary" component="label">
                Tipo documento
            </Typography>
            <SelectSearchStyled
              placeholder=""
              value={dadosTemp.documentoTipo}
              elements={tiposDocumento?.findAllDocumentoTipo || []}
              onChange={handleChangeDocumentoTipo}
            />
          </>
        }
        </FormGroup>}
        <FormGroup item xs={12} sm={12} md={12} ls={12}>
          <RenderTextField label={"Documento *"}
                           field={dadosTemp.responsavelDocumento}
                           error={dadosErrors?.responsavelDocumento}
                           errorText={"Documento inválido."}
                           mask={verificaMask()}
                           handleChange={(e) => handleChangeDocumento(e.target.value)}
                           disabled={disabled}/>
        </FormGroup>
        <FormGroup item xs={12} sm={12} md={12} ls={12}>
          <RenderTextField label={"Cargo *"}
                           field={dadosTemp.responsavelCargo}
                           error={dadosErrors?.responsavelCargo}
                           errorText={"Este campo é obrigatório."}
                           handleChange={(e) => handleChangeField('responsavelCargo', e.target.value)}
                           disabled={disabled}/>
        </FormGroup>
      </Grid>
      <Notification
        close={() => {
          setNotification({
            ...notificationDefault,
          });
        }}
        reset={() => {
          setNotification({
            ...notificationDefault,
          });
        }}
        isOpen={notification.isOpen}
        variant={notification.variant}
        message={notification.message}
      />
    </Container>
  );
};

export default DadosResponsavelContratante;

const Container = styled.div`
  width: 100%;
  padding: 10px 0;
`;

const FormGroup = styled(Grid)`
  margin-bottom: 10px !important;
`;

const SelectSearchStyled = styled(SelectSearch)`
  width: 100%;
`;