import React from 'react';
import { SvgIcon } from '@material-ui/core';
export const MoneyIcon = (props) => (
  <SvgIcon {...props} viewBox="0 0 28 28">
    <path d="M3.80445 8.629C3.79164 7.66258 4.56468 6.86876 5.5311 6.85595L19.0299 6.677C19.9963 6.66419 20.7901 7.43723 20.803 8.40365L20.9156 16.9029C20.9284 17.8693 20.1554 18.6631 19.189 18.6759L5.69017 18.8549C4.72375 18.8677 3.92994 18.0947 3.91712 17.1282L3.80445 8.629ZM6.79756 8.08927L6.81081 9.08918C6.8163 9.50336 6.485 9.84357 6.07082 9.84906L5.07091 9.86232L5.09079 11.3622L6.0907 11.3489C7.33324 11.3325 8.32715 10.3118 8.31068 9.0693L8.29742 8.06939L6.79756 8.08927ZM12.3932 15.2657C13.6357 15.2493 14.6296 14.2286 14.6132 12.9861C14.5967 11.7436 13.5761 10.7497 12.3335 10.7661C11.091 10.7826 10.0971 11.8032 10.1136 13.0458C10.13 14.2883 11.1506 15.2822 12.3932 15.2657ZM5.15044 15.8618L6.15036 15.8485C6.56453 15.843 6.90474 16.1743 6.91023 16.5885L6.92349 17.5884L8.42336 17.5686L8.4101 16.5686C8.39363 15.3261 7.373 14.3322 6.13047 14.3487L5.13056 14.3619L5.15044 15.8618ZM17.9093 16.4427C17.9038 16.0285 18.2351 15.6883 18.6493 15.6828L19.6492 15.6696L19.6293 14.1697L18.6294 14.183C17.3868 14.1994 16.3929 15.2201 16.4094 16.4626L16.4227 17.4625L17.9225 17.4426L17.9093 16.4427ZM17.8098 8.94337L17.7966 7.94346L16.2967 7.96334L16.31 8.96325C16.3265 10.2058 17.3471 11.1997 18.5896 11.1832L19.5895 11.17L19.5696 9.67011L18.5697 9.68336C18.1555 9.68885 17.8153 9.35755 17.8098 8.94337ZM6.36134 20.3461C6.8919 21.2359 7.86932 21.8263 8.97965 21.8116L19.2287 21.6757C21.8519 21.6409 23.9501 19.4863 23.9154 16.8631L23.8458 11.6136C23.8311 10.5033 23.215 9.54183 22.3115 9.03505L22.4155 16.883C22.4393 18.6778 21.0036 20.152 19.2089 20.1758L6.36134 20.3461Z" fill="currentColor"/>
  </SvgIcon>
)




