import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Colors from '../../../../theme/Colors';

const styles = () => ({
  container: {
    borderRadius: '10px',
    boxShadow: '0 0 1em rgba(0, 0, 0, 0.1)',
    marginBottom: '10px',
  },
  header: {
    backgroundColor: Colors.primary.main,
    borderRadius: '10px',
    textAlign: 'center',
    padding: '8px',
  },
  headerTitle: {
    color: '#FFF',
  },
  headerSubTitle: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  content: {
    padding: '0px',
  },
  item: {
    color: '#969696',
    wordWrap: 'break-word',
  },
  key: {
    fontWeight: 'bold',
  },
});

const InfoBox = ({ classes, title, data, subTitle, isEspecialidades }) => {
  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <span className={classes.headerTitle}>{title}</span>
      </div>
      {subTitle && (
        <div className={classes.headerSubTitle}>
          <h1 style={{ fontSize: '1rem' }}>{subTitle}</h1>
        </div>
      )}
      <div className={classes.content}>
        {data && data?.length > 0 && (
          <ul className={classes.list}>
            {!isEspecialidades &&
              data.map((item, index) => {
                return (
                  <li className={classes.item} key={index}>
                    <span className={classes.key}>{`${item[0]}: `}</span>
                    <span>{item[1]}</span>
                  </li>
                );
              })}
            {isEspecialidades &&
              data.map((item, index) => {
                return (
                  <li className={classes.item} key={index}>
                    <span className={classes.key}>{item.especialidade}</span>
                  </li>
                );
              })}
          </ul>
        )}
      </div>
    </div>
  );
};

export default withStyles(styles)(InfoBox);
