import React, { useEffect } from 'react';
import styled from '@emotion/styled';
import Grid from '@material-ui/core/Grid';
import { useLazyQuery } from '@apollo/react-hooks';
import { FIND_ALL_PLANO_TIPO_PAGAMENTO } from '../../../../graphql/queryes';
import CardPlano from './cardPlano';
import { TipoCobrancaFilter } from '../../../planos-e-adicionais/components';

const Plano = ({
  dados,
  handleAtualizarDados,
  handleDadosInvalidos,
  tipoCobranca,
  setTipoCobranca,
}) => {
  const [getPlanos, { data: planos, refetch }] = useLazyQuery(
    FIND_ALL_PLANO_TIPO_PAGAMENTO
  );

  useEffect(() => {
    handleDadosInvalidos && handleDadosInvalidos(!dados?.plano?.id);
    getPlanos({
      variables: {
        tipoPagamento: tipoCobranca,
        pageableDTO: {
          pageNumber: 0,
          pageSize: 30,
          sortDir: 'DESC',
          sortField: 'dataLancamento',
        },
      },
    });
  }, []);

  const handleChangePlano = async (plano) => {
    const tipoPagamento = plano?.tipoPagamento.find(
      (item) => item.descricao === tipoCobranca
    );

    const { valor } = tipoPagamento || {};

    handleAtualizarDados({
      valor,
      tipoPagamento,
      plano: { ...plano, valor },
    });
    handleDadosInvalidos && handleDadosInvalidos(false);
  };

  const handleTipoCobranca = (tipoCobranca) => {
    setTipoCobranca(tipoCobranca);

    handleDadosInvalidos && handleDadosInvalidos(true); 
    handleAtualizarDados({
      plano: null,
    });
  };

  return (
    <PageContainer container>
      <TipoCobrancaFilter
        value={tipoCobranca}
        onChange={(tipo) => handleTipoCobranca(tipo)}
      />
      <ContentPlanos>
        {planos &&
          planos.findAllPlanoTipoPagamento.content.map((plano) => {
            return (
              <CardPlano
                onClick={() => handleChangePlano(plano)}
                key={plano.id}
                plano={plano}
                tipoCobranca={tipoCobranca}
                selected={plano.id === dados?.plano?.id}
              />
            );
          })}
      </ContentPlanos>
    </PageContainer>
  );
};

export default Plano;

const PageContainer = styled(Grid)`
  width: 100%;
  align-items: center;
  padding-left: 8px;
  &>  div {
    background-color: #fff;
  }
`;

const ContentPlanos = styled.div`
  display: flex;
  gap: 16px;
  flex-wrap: wrap;
  overflow: auto;
  height: calc(100% - 46px);
  width: 100%;
  height: 100%;
`;
