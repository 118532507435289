import React from 'react';
import Modal from 'react-modal-slider';
import 'react-modal-slider/lib/main.css';
import { withStyles } from "@material-ui/core/styles";
import styled from '@emotion/styled';
import CloseIcon from '@material-ui/icons/Close';
import { ArrowBackIos } from '@material-ui/icons';

const styles = () => ({
  overlay: {
    zIndex: '1300',
  },
  modal: ({ width = '560px' }) => ({
    height: '100%',
    borderRadius: '16px 0px 0px 16px',
    minWidth: '560px',

    "&> div": {
      height: '100%',
      padding: '34px 32px',
      width: 'auto !important',

      [`@media (max-width: ${width.includes('%') ? '560px' : width})`]: {
        padding: '34px 16px',
      }
    },

    [`@media (max-width: ${width.includes('%') ? '560px' : width})`]: {
      maxWidth: '100%',
      minWidth: '100%',
    },
  })
});

const ModalSlider = ({children, ...props}) => {
  const {classes, title, onClose, goBack} = props;

  return (
    <Modal
      className={classes.modal}
      overlayClassName={classes.overlay}
      directionFrom={'right'}
      setAppElement={'#root'}
      ariaHideApp={true}
      maxMediaWidth={1024}
      {...props}
    >

          <div style={{
            display: 'flex',
            flexDirection: 'column',
          }}>
            {title && (
              <ModalHeaderContainer>
                <ContentTitle>
                  {goBack && <ArrowBackIos onClick={goBack}/>}
                  {title}
                </ContentTitle>

            {onClose && (
              <button onClick={onClose}>
                <CloseIcon />
              </button>
            )}
          </ModalHeaderContainer>
        )}
        {children}
      </div>
    </Modal>
  );
};

ModalSlider.defaultProps = {
  width: '560px',
}

const ModalHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;

  button {
    background: transparent;
    border: none;
    outline: none;
    cursor: pointer;
    width: 24px;
    height: 24px;
    padding: 0;
  }
`

const ContentTitle = styled.div`
  font-size: 24px;
  font-weight: 600;
  display: flex;
  align-items: center;
`;

export default withStyles(styles)(ModalSlider);
