import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import List from '../../../../../../components/list/list';
import {
  columnsFuncionalidades,
  tiposPagamentoPlanos,
} from '../../../../constants';

export const FuncionalidadesPlano = ({ dados, handleDados, stepSelected, loadingFuncionalidades }) => {
  const [funcionalidades, setFuncionalidades] = useState(dados?.funcionalidades || []);

  const isUltimoStep = stepSelected === 2;

  useEffect(() => {
    handleDados(funcionalidades, 'funcionalidades');
  }, [funcionalidades]);

  const changeStatus = (value, tipo) => {
    setFuncionalidades((prevState) => {
      return prevState.map((item) => {
        if (item.id === value.id) {
          return {
            ...item,
            [tipo]: !item[tipo],
          };
        }
        return item;
      });
    });
  };

  return (
    <Content>
      {isUltimoStep && (
        <ContentValor>
          <Title> Valor total </Title>
          <RowValores>
            {tiposPagamentoPlanos.map((tipo, index) => {
              return (
                <ValueTipoPagamento key={index}>
                  <Label> {tipo.label} </Label>
                  <Value>{dados[`${tipo.value}`]}</Value>
                </ValueTipoPagamento>
              );
            })}
          </RowValores>
        </ContentValor>
      )}

      <List
        columns={columnsFuncionalidades({ changeStatus, isUltimoStep })}
        items={funcionalidades || []}
        loading={loadingFuncionalidades}
        last={true}
      />
    </Content>
  );
};

const Content = styled.div`
  height: calc(100vh - 200px);
  overflow-y: auto;

  & table {
    & th {
      &:first-of-type {
        padding-left: 8px;
      }
      padding: 0px;
    }

    & td {
      &:first-of-type {
        padding-left: 8px;
        text-align: left;
      }
      padding: 0px;
      text-align: center;
    }
  }
`;

const Title = styled.h1`
  font-family: 'Poppins';
  font-weight: 500;
  font-size: 18px;
  color: #505050;
`;

const Label = styled.p`
  font-size: 10px;
  margin: 0;
`;

const Value = styled.p`
  font-size: 24px;
  font-weight: 600;
  margin: 0;
`;

const ContentValor = styled.div`
  display: flex;
  flex-direction: column;
`;

const RowValores = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const ValueTipoPagamento = styled.div`
  display: flex;
  flex-direction: column;
`;
