import React, { useState } from 'react';
import { useMutation } from 'react-apollo';
import { ALTERAR_ESPECIALIDADES } from '../../../graphql/mutations';
import FormDadosEspecialidadeMedica from './editar-contratante/FormDadosEspecialidadeMedica';
import { ContainerButton, Button } from './editar-contratante/styles';

const EditarEspecialidades = ({ venda, onClose }) => {
  const [formData, setFormData] = useState({
    especialidades: venda.contratante?.especialidades,
    utilizaAzulControle: venda.utilizaAzulControle,
  });
  const [loadingSave, setLoadingSave] = useState(false);

  const [updateEspecialidades] = useMutation(ALTERAR_ESPECIALIDADES);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoadingSave(true);

    await updateEspecialidades({
      variables: {
        venda: {
          id: venda.id,
        },
        utilizaAzulControle: formData.utilizaAzulControle,
        especialidades: formData.especialidades.map((e) => ({
          id: e.value,
        })),
      },
    });

    setLoadingSave(false);
    onClose();
  };

  return (
    <>
      <FormDadosEspecialidadeMedica
        setFormData={setFormData}
        formData={formData}
      />
      <ContainerButton>
        <Button
          type="button"
          disabled={loadingSave}
          onClick={handleSubmit}
        >
          Salvar
        </Button>
      </ContainerButton>
    </>
  );
};

export default EditarEspecialidades;
