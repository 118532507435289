import React, { useEffect ,useState} from 'react';
import styled from '@emotion/styled';
import moment from 'moment';
import { useLazyQuery } from 'react-apollo';
import { BOLETOS_EMITIDOS_VENDA } from '../../../../graphql/queryes';
import List from '../../../../components/list/list';
import Number from '../../../../utils/number';
import { ButtonsBoletosEmitidos } from './buttons-boletos-emitidos';
import AssignmentIcon from '@material-ui/icons/Assignment';
import { Tooltip } from '@material-ui/core';


export const BoletosEmitidos = ({ dados,}) => {
  const { meioPagamentoPlataforma, id } = dados;

  const [getBoletosEmitidos, { data: boletosEmitidos }] = useLazyQuery(
    BOLETOS_EMITIDOS_VENDA,
    {
      fetchPolicy: 'no-cache',
    }
  );
  const [loadingBoleto, setLoadingBoleto] = useState(false);
  const [idBoleto, setIdBoleto] = useState(null);

  useEffect(() => {
    meioPagamentoPlataforma === 'BOLETO' &&
      getBoletosEmitidos({
        variables: {
          vendaId: id,
        },
      });
  }, []);

  return (
    <Container>
      <List
        columns={[
          {
            title: 'Dt. vencimento',
            value: (item) =>
              item.dataVencimento
                ? moment(item.dataVencimento).format('DD/MM/YYYY')
                : '-',
          },
          {
            title: 'Situação',
            value: (item) => (
              <SituacaoBoleto pago={item.pago}>
                {item.pago ? 'Pago' : 'Pendente'}
              </SituacaoBoleto>
            ),
          },
          {
            title: 'Valor',
            value: (item) => {
              const valor = item.valorPago || item.valor;

              return Number.currencyFormat(valor);
            },
          },
          {
            title: '',
            value: (item) => {
              if(item.possuiBoleto){
                return <ButtonsBoletosEmitidos dados={item} vendaId={id} />;
              }
            },
          },
        ]}
        loading={false}
        items={boletosEmitidos?.findPagamentosVenda}
        last={true}
      />
    </Container>
  );
};

const Container = styled.div`
  height: calc(100% - 38px);
  overflow: auto;
`;

const SituacaoBoleto = styled.div`
  text-align: center;
  border-radius: 8px;
  padding: 4px;
  font-weight: 400;
  font-size: 12px;
  ${(props) => `
    background-color: ${props.pago ? '#D4EEEE' : '#FFC107'};
    border: 1px solid ${props.pago ? '#00B0AE' : '#FEF2E3'};`};
`;

const Button = styled.button`

display: flex;
flex-direction: row;
border: 1px solid rgba(0, 0, 0, 0.1);
cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
height: 40px;
width: 40px;
border-radius: 100px;
color: #f2f2f2;
background: #868686;
align-items: center;
justify-content: center;
${({ disabled }) => disabled && 'opacity: 0.5;'}
`;
