import React from 'react';
import { SvgIcon } from '@material-ui/core';
export const BoxSearchIcon = (props) => (
  <SvgIcon {...props} viewBox="0 0 20 20">
    <path
      d="M8.3334 5.96137L11.3621 4.74988L3.862 1.74984L1.27617 2.78418C1.14344 2.83726 1.02137 2.90794 0.912192 2.99288L8.3334 5.96137ZM0.368671 3.85251C0.345405 3.9576 0.333252 4.06624 0.333252 4.17689V11.8228C0.333252 12.4362 0.706679 12.9877 1.27617 13.2155L7.03338 15.5184C7.29268 15.6221 7.56128 15.6936 7.8334 15.7329V13.5639C7.51377 12.9457 7.33325 12.2439 7.33325 11.5C7.33325 10.7561 7.51377 10.0543 7.8334 9.43611V6.8384L0.368671 3.85251ZM16.3333 11.4272V4.17689C16.3333 4.06628 16.3211 3.95767 16.2979 3.85262L8.8334 6.8384V8.14571C9.62952 7.43323 10.6808 7 11.8333 7C14.2942 7 16.2944 8.97552 16.3333 11.4272ZM15.7544 2.99296L12.7084 4.21137L5.20829 1.21132L7.03338 0.481289C7.86782 0.147513 8.79868 0.147513 9.63312 0.481289L15.3903 2.78418C15.5231 2.83728 15.6452 2.90798 15.7544 2.99296ZM14.6365 13.5961C15.0741 13.0118 15.3333 12.2862 15.3333 11.5C15.3333 9.567 13.7662 8 11.8333 8C9.90026 8 8.33325 9.567 8.33325 11.5C8.33325 13.433 9.90026 15 11.8333 15C12.6194 15 13.3451 14.7408 13.9294 14.3032L16.4797 16.8536C16.675 17.0488 16.9915 17.0488 17.1868 16.8536C17.3821 16.6583 17.3821 16.3417 17.1868 16.1465L14.6365 13.5961ZM14.3333 11.5C14.3333 12.8807 13.214 14 11.8333 14C10.4525 14 9.33325 12.8807 9.33325 11.5C9.33325 10.1193 10.4525 9 11.8333 9C13.214 9 14.3333 10.1193 14.3333 11.5Z"
      fill="currentColor"
    />
  </SvgIcon>
);
