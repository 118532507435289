import React from 'react';
import { usePerfilPublicoContext } from '../../perfil-publico-context';
import { Card } from '../../../../components/Card';
import Textarea from '../../../../components/Textarea';

export const PerfilDadosClinica = () => {
  const { setField, values, errors } = usePerfilPublicoContext();

  const handleSetDadosPessoais = (name, value) => {
    setField('dadosPessoais.' + name, value);
  };

  const dadosPessoais = values.dadosPessoais || {};
  const dadosPessoaisErrors = errors.dadosPessoais || {};

  return (
    <Card>
      <h2>Dados da Clínica</h2>

      <div>
        <Textarea
          label="Sobre"
          error={dadosPessoaisErrors.sobre}
          value={dadosPessoais.sobre}
          onChange={(e) => handleSetDadosPessoais('sobre', e.target.value)}
          maxLength={500}
        />
      </div>

      <div>
        <Textarea
          label="Horário atendimento"
          error={errors.horarioAtendimento}
          value={values.horarioAtendimento}
          onChange={(e) => setField('horarioAtendimento', e.target.value)}
        />
      </div>
    </Card>
  );
};
