import moment from 'moment';
import React, { useEffect } from 'react';

import { createContext } from 'react';
import { z } from 'zod';
import Number from '../../utils/number';
import Notification from '../../components/notification/Notification';

const PerfilPublicoContext = createContext({});

const emptyStringOr = (schema) => z.union([z.literal(''), schema]);
const onlyInProfissional = (isUnidade, schema) =>
  isUnidade ? z.any() : schema;
const onlyInUnidade = (isUnidade, schema) => (isUnidade ? schema : z.any());

const formSchema = (isUnidade) =>
  z.object({
    dadosPessoais: z.object({
      nome: z
        .string({
          message: 'Nome é obrigatório',
          required_error: 'Nome é obrigatório',
        })
        .min(3, {
          message: 'Nome deve ter pelo menos 3 caracteres',
          required_error: 'Nome deve ter pelo menos 3 caracteres',
        }),
      razaoSocial: onlyInUnidade(
        isUnidade,
        z
          .string({
            message: 'Razão social é obrigatória',
            required_error: 'Razão social é obrigatória',
          })
          .min(3, {
            message: 'Razão social deve ter pelo menos 3 caracteres',
            required_error: 'Razão social deve ter pelo menos 3 caracteres',
          })
      ),
      sobre: z.string().nullable(),
      dataNascimento: onlyInProfissional(
        isUnidade,
        z.object(
          {},
          {
            message: 'Data de nascimento é obrigatória',
            required_error: 'Data de nascimento é obrigatória',
          }
        )
      ),
      telefone: z
        .string({
          message: 'Telefone é obrigatório',
          required_error: 'Telefone é obrigatório',
        })
        .refine(
          (value) => {
            return value.replaceAll(/\D/g, '').length >= 10;
          },
          {
            message: 'Telefone inválido',
            required_error: 'Telefone inválido',
          }
        ),
      telefoneSecundario: z
        .string()
        .refine(
          (value) => {
            if (!value) return true;

            return value.replaceAll(/\D/g, '').length >= 10;
          },
          {
            message: 'Telefone inválido',
            required_error: 'Telefone inválido',
          }
        )
        .nullish(),
      codigoCnes: onlyInUnidade(isUnidade, z.string().nullable()),
      email: emptyStringOr(
        z
          .string({
            message: 'Email é inválido',
            required_error: 'Email é inválido',
          })
          .email({
            message: 'Email é inválido',
            required_error: 'Email é inválido',
          })
      ),
    }),

    dadosProfissionais: onlyInProfissional(
      isUnidade,
      z.object({
        codigoCBO: z
          .object({
            value: z.string(),
          })
          .nullish(),
        numeroConselho: z.string().nullish(),
        uf: z.string().nullish(),
        formacao: z.string().nullish(),
        experiencia: z.string().nullish(),
      })
    ),

    horarioAtendimento: z.string().nullish(),
    medidasSeguranca: onlyInUnidade(isUnidade, z.string().nullish()),
    redesSociais: z
      .object({
        instagram: emptyStringOr(
          z
            .string()
            .url({
              message: 'Instagram inválido',
              required_error: 'Instagram inválido',
            })
            .nullish()
        ),
        facebook: emptyStringOr(
          z
            .string()
            .url({
              message: 'Facebook inválido',
              required_error: 'Facebook inválido',
            })
            .nullish()
        ),
        linkedin: emptyStringOr(
          z
            .string()
            .url({
              message: 'Linkedin inválido',
              required_error: 'Linkedin inválido',
            })
            .nullish()
        ),
        twitter: emptyStringOr(
          z
            .string()
            .url({
              message: 'Twitter inválido',
              required_error: 'Twitter inválido',
            })
            .nullish()
        ),
      })
      .nullish(),
    color: z.string().nullish(),
    fotoPerfilId: z.string().nullish(),
    bannerId: z.string().nullish(),
    especialidades: z.array(
      z
        .object({
          id: z.number(),
          valorOnline: z.string().nullish(),
          valorPresencial: z.string().nullish(),
        })
        .refine((value) => {
          const valorOnline = Number.currencyToFloat(value.valorOnline);
          const valorPresencial = Number.currencyToFloat(value.valorPresencial);

          const isOnlineValid = !isNaN(valorOnline) && valorOnline >= 0;
          const isPresencialValid = !isNaN(valorPresencial) && valorPresencial >= 0;

          return isOnlineValid || isPresencialValid;
        })
    ),
  });

const defaultState = {
  form: {
    values: {
      dadosPessoais: {
        nome: '',
        razaoSocial: '',
        codigoCnes: '',
        sobre: '',
        dataNascimento: null,
        telefone: '',
        telefoneSecundario: '',
        email: '',
      },
      dadosProfissionais: {
        numeroConselho: '',
        uf: null,
        formacao: '',
        experiencia: '',
      },
      funcionalidades: {
        atendimentoPresencial: false,
        atendimentoOnline: false,
        atendeCrianca: false,
        perfilVisivel: false,
        buscaGoogle: false,
        permiteProfissionalUnidade: false,
        perfilVitrine: false,
      },
      idiomas: [],
      meiosPagamento: [],
      medidasSeguranca: '',
      horarioAtendimento: '',
      redesSociais: {
        instagram: '',
        facebook: '',
        linkedin: '',
        twitter: '',
      },
      color: '',
      fotoPerfilId: null,
      bannerId: null,
      especialidades: [],
    },
    status: 'idle',
    submitCount: 0,
    errors: {},
  },
};

const isFieldInDefaultFormSchema = (name) => {
  const [key, subKey] = name.split('.');

  if (subKey) {
    return Object.keys(defaultState.form.values[key]).indexOf(subKey) !== -1;
  }

  return Object.keys(defaultState.form.values).indexOf(key) !== -1;
};

const reducer = (state, action) => {
  const { isUnidade } = action.payload || {};

  switch (action.type) {
    case 'SET':
      const settledState = handleSetField(action.payload, state);
      const errors = handleValidate(settledState, isUnidade);
      const alreadySubmitted = settledState.form.submitCount > 0;

      if (!isFieldInDefaultFormSchema(action.payload.name)) {
        throw new Error(`Field ${action.payload.name} is not in the schema`);
      }

      return {
        ...settledState,
        form: {
          ...settledState.form,
          status:
            Object.keys(errors).length > 0
              ? 'error'
              : alreadySubmitted
              ? 'success'
              : 'idle',
          errors,
        },
      };
    case 'SUBMIT':
      const submitErrors = handleValidate(state, isUnidade);

      return {
        ...state,
        form: {
          ...state.form,
          status: Object.keys(submitErrors).length > 0 ? 'error' : 'success',
          errors: submitErrors,
          submitCount: state.form.submitCount + 1,
        },
      };

    case 'RESET':
      return {
        ...defaultState,
        form: {
          ...defaultState.form,
          values: {
            ...defaultState.form.values,
            ...(action.payload || {}),
          },
        },
      };
    default:
      return state;
  }
};

const defaultNotification = {
  isOpen: false,
  variant: 'success',
  message: '',
};

export const PerfilPublicoProvider = ({
  children,
  perfilPublico,
  profissionalSaude,
  unidade,
  venda,
}) => {
  const [state, dispatch] = React.useReducer(reducer, { ...defaultState });
  const [notification, setNotification] = React.useState(defaultNotification);

  const isUnidade = Boolean(unidade);

  const setField = (name, value) => {
    dispatch({ type: 'SET', payload: { name, value, isUnidade } });
  };

  const handleSubmit = async (callback) => {
    const errors = handleValidate(state, isUnidade);

    dispatch({
      type: 'SUBMIT',
      payload: {
        isUnidade,
      },
    });

    if (Object.keys(errors).length > 0) {
      setNotification({
        isOpen: true,
        variant: 'error',
        message: 'Há campos inválidos no formulário',
      });
      return;
    }

    callback(state.form.values);
  };

  const values = state.form.values;
  const errors = state.form.errors;
  const isError = state.form.status === 'error';
  const isSuccess = state.form.status === 'success';

  useEffect(() => {
    if (profissionalSaude || unidade) {
      const { informacao } = profissionalSaude || {};

      const defaultName = unidade?.nomeFantasia || profissionalSaude?.nome;
      const defaultTelefonePrimario =
        unidade?.telefonePrincipal || informacao?.telefonePrincipal;
      const defaultTelefoneSecundario =
        unidade?.telefoneSecundario || informacao?.telefoneSecundario;
      const defaultEmail = profissionalSaude?.email;
      const defaultAtendimentoOnline = profissionalSaude?.utilizaTelemedicina;
      const defaultAtendePresencial = profissionalSaude?.atendePresencial;

      dispatch({
        type: 'RESET',
        payload: {
          isUnidade,
          dadosPessoais: {
            nome: perfilPublico?.nome || defaultName,
            razaoSocial: unidade?.nome,
            codigoCnes: unidade?.codigoCnes,
            sobre: perfilPublico?.sobre,
            dataNascimento:
              profissionalSaude?.dataNascimento &&
              moment(profissionalSaude.dataNascimento),
            telefone:
              perfilPublico?.telefonePrincipal || defaultTelefonePrimario,
            telefoneSecundario:
              perfilPublico?.telefoneSecundario || defaultTelefoneSecundario,
            email: perfilPublico?.email || defaultEmail || '',
          },

          dadosProfissionais: profissionalSaude && {
            numeroConselho: profissionalSaude.numeroConselho,
            uf: profissionalSaude.ufConselho,
            formacao: perfilPublico?.formacao,
            experiencia: perfilPublico?.experiencia,
          },

          funcionalidades: {
            atendimentoPresencial:
              perfilPublico?.atendePresencial || defaultAtendePresencial,
            atendimentoOnline:
              perfilPublico?.utilizaTelemedicina || defaultAtendimentoOnline,
            atendeCrianca: perfilPublico?.atendeCrianca,
            perfilVisivel: perfilPublico?.permiteBuscaProfissional,
            buscaGoogle: perfilPublico?.permiteBuscaGoogle,
            permiteProfissionalUnidade:
              perfilPublico?.permiteProfissionalUnidade,
            perfilVitrine: perfilPublico?.perfilVitrine,
          },

          idiomas: (perfilPublico?.idiomas || informacao?.idiomas)?.map(
            (idioma) => ({
              value: idioma.id,
              label: idioma.descricao,
            })
          ),
          meiosPagamento: (
            perfilPublico?.formasPagamento || informacao?.formasPagamento
          )?.map((meioPagamento) => ({
            value: meioPagamento.id,
            label: meioPagamento.nome,
          })),

          redesSociais: {
            instagram: perfilPublico?.instagram,
            facebook: perfilPublico?.facebook,
            linkedin: perfilPublico?.linkedin,
            twitter: perfilPublico?.twitter,
          },

          color:
            perfilPublico?.corPersonalizada ||
            venda?.unidade?.rede?.whitelabel?.corPrincipal,
          fotoPerfilId: perfilPublico?.fotoPerfil,
          bannerId: perfilPublico?.banner,

          horarioAtendimento: perfilPublico?.horarioAtendimento,
          medidasSeguranca: unidade?.medidasSeguranca,

          especialidades:
            perfilPublico?.especialidades
              ?.filter(
                (especialidade) => !!profissionalSaude || especialidade.ativo
              )
              ?.map(
                ({
                  especialidadeMedica: especialidade,
                  valorOnline,
                  valorPresencial,
                }) => ({
                  id: especialidade.id,
                  descricao: especialidade.especialidade,
                  valorOnline: Number.format(valorOnline),
                  valorPresencial: Number.format(valorPresencial),
                })
              ) || [],
        },
      });
    }
  }, [perfilPublico, profissionalSaude, unidade]);

  return (
    <PerfilPublicoContext.Provider
      value={{
        setField,
        values,
        errors,
        isError,
        isSuccess,
        handleSubmit,
        perfilPublico,
        profissionalSaude,
        unidade,
        venda,
      }}
    >
      <Notification
        close={() => {
          setNotification({
            ...defaultNotification,
          });
        }}
        reset={() => {
          setNotification({
            ...defaultNotification,
          });
        }}
        isOpen={notification.isOpen}
        variant={notification.variant}
        message={notification.message}
      />
      {children}
    </PerfilPublicoContext.Provider>
  );
};

export const usePerfilPublicoContext = () => {
  const context = React.useContext(PerfilPublicoContext);

  if (context === undefined) {
    throw new Error(
      'usePerfilPublicoContext must be used within a PerfilPublicoProvider'
    );
  }

  return context;
};

const handleSetField = (payload, state) => {
  const { name, value } = payload;

  const [key, subKey] = name.split('.');

  return {
    ...state,
    form: {
      ...state.form,
      values: {
        ...state.form.values,
        [key]: subKey
          ? {
              ...state.form.values[key],
              [subKey]: value,
            }
          : value,
      },
    },
  };
};

const handleValidate = (state, isUnidade) => {
  const errors = {};

  const values = state.form.values;

  try {
    formSchema(isUnidade).parse(values);
  } catch (error) {
    error.errors.map((err) => {
      const paths = err.path;
      paths.reduce((acc, path, index) => {
        acc[path] = index === paths.length - 1 ? err.message : acc[path] || {};
        return acc[path];
      }, errors);
    });
  }

  return errors;
};
