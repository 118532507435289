import React from 'react';
import styled from '@emotion/styled';

const  Table = ({children, columns = []}) => {
  return (
    <div style={{
      overflowX: 'auto',
    }}>
      <TableStyled>
        <thead>
        <tr>
          {columns.map((column, index) => (
            <th key={index}>
              {column.title}
            </th>
          ))}
        </tr>
        </thead>
        <tbody>
        {children}
        </tbody>
      </TableStyled>
    </div>
  )
};

const TableStyled = styled.table`
	width: 100%;
    display: table;

    border-spacing: 0px;
  border-radius: 16px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  overflow: hidden;
  margin-top: 16px;

	& th, td {
		color: #505050;
		display: table-cell;
		padding: 4px 16px 4px 24px;
		text-align: left;
		border-bottom: 1px solid rgba(224, 224, 224, 1);
		vertical-align: inherit;
		white-space: nowrap
	}


	& th {
    background-color: #F9F9F9;
		font-size: 0.75rem;
    font-weight: 600;
		text-transform: uppercase;
	}

	& tr {
		color: inherit;
		height: 48px;
		display: table-row;
		outline: none;
		vertical-align: middle;
	}

  & td {
    background-color: #FFFFFF;

  }



    & thead tr {
        height: 56px;
    }
`;

export default Table;
