import React, {useState} from 'react';
import moment from 'moment';
import {useQuery} from '@apollo/react-hooks';

import Box from '../../components/box';
import {LIST_MOVIMENTACAO_FINANCEIRA} from '../../graphql/queryes';

import Search from './components/search';
import List from '../../components/list/list';
import Number from '../../utils/number';
import { withBaseLayout } from '../../layout/base-layout';

const ListMovimentacaoFinanceira = () => {
  const [searchDTO, setSearchDTO] = useState({
    dataInicial: moment().startOf('month').format('YYYY-MM-DD'),
    dataFinal: moment().endOf('month').format('YYYY-MM-DD'),
  });
  const [pageableDTO] = useState({
    pageNumber: 0,
    pageSize: 150,
  });

  const {data, loading, error, refetch, fetchMore} = useQuery(LIST_MOVIMENTACAO_FINANCEIRA, {
    variables: {
      searchDTO,
      pageableDTO,
    },
    fetchPolicy: 'no-cache'
  });

  const handleLoadMore = () => {
    if (!data?.findAllMovimentacaoFinanceira?.last) {
      const pageable = {
        ...pageableDTO,
        pageNumber: data.findAllMovimentacaoFinanceira.number + 1,
      };
      fetchMore({
        variables: {
          pageableDTO: {
            ...pageable,
          },
          searchDTO: {
            ...searchDTO,
          },
        },
        updateQuery: (prev, {fetchMoreResult}) => {
          if (!fetchMoreResult) return prev;

          return ({
            findAllMovimentacaoFinanceira: {
              ...prev.findAllMovimentacaoFinanceira,
              ...fetchMoreResult.findAllMovimentacaoFinanceira,
              content: [
                ...prev.findAllMovimentacaoFinanceira.content,
                ...fetchMoreResult.findAllMovimentacaoFinanceira.content
              ]
            }
          });
        }
      });
    }
  };

  return (
    <Box>
      <Search onSearch={refetch} onDataSearch={s => setSearchDTO({...searchDTO, ...s})}/>
      <List
        columns={[
          {
            title: 'Data',
            value: (item) => item.dataLancamento ? moment(item.dataLancamento).format('DD/MM/YYYY HH:mm') : '-',
          },
          {
            title: 'Valor Cheio',
            value: (item) => Number.currencyFormat(item.valorCheio),
          },
          {
            title: 'Valor Repasse',
            value: (item) => Number.currencyFormat(item.valorRepasse),
          },
          {
            title: 'Percentual Repasse',
            value: (item) => Number.format(item.percentualRepasse),
          },
        ]}
        items={data?.findAllMovimentacaoFinanceira?.content}
        loading={loading}
        error={error}
        last={data?.findAllMovimentacaoFinanceira?.last}
        onLoadMore={handleLoadMore}
      />
    </Box>
  )
};

export default withBaseLayout(ListMovimentacaoFinanceira);
