import React, { useEffect, useState } from 'react';
import { TaskListIcon } from '../../../../components/icons';
import Button from '../../../../components/button';
import { AddCircle } from '@material-ui/icons';

import {
  EmptyListContainer,
  EmptyListIconContainer,
  HeaderList,
  HeaderListTitle,
} from '../../styled';
import styled from '@emotion/styled';

import { FIND_BY_MODULO_ID, GET_ADICIONAIS } from '../../../../graphql/queryes';
import { useLazyQuery } from 'react-apollo';
import List from '../../../../components/list/list';
import { columnsAdicionais } from '../../constants';
import { ModalAdicional } from './components/modal-adicional';
import InputSearch from '../../../../components/input/Input';

export const AdicionaisList = ({ setNotification }) => {
  const [search, setSearch] = useState('');
  const [openModal, setOpenModal] = useState(false);
  const [moduloSelecionado, setModuloSelecionado] = useState(false);

  const [
    getAdicionais,
    {
      data: adicionais,
      loading: loadingAdicionais,
      error: errorAdicionais,
      refetch,
    },
  ] = useLazyQuery(GET_ADICIONAIS);

  const [getModuloSelecionado, { data: modulo }] =
    useLazyQuery(FIND_BY_MODULO_ID, {
      fetchPolicy: 'no-cache',
      onCompleted: () => {
        setModuloSelecionado(modulo.findByModuloId);
        setOpenModal(true)
      }
    });

  useEffect(() => {
    loadAdicionais()
  }, []);

  useEffect(() => {
    loadAdicionais();

  }, [search]);

  const loadAdicionais = () => {
    getAdicionais({
      variables: {
        search,
        pageableDTO: {
          pageNumber: 0,
          pageSize: 999,
          sortDir: 'ASC',
          sortField: 'funcionalidade',
        },
      }
    });
  };

  const handleSearch = (e) => {
    const value = e.target.value;
    setSearch(value);
  };

  const onClickAddAdicional = () => {
    setModuloSelecionado(null);
    setOpenModal(true);
  };

  const onCloseModal = () => {
    setOpenModal(false);
  };

  const handleClickAdicional = async (adicional) => {
    getModuloSelecionado({
      variables: {
        moduloId: adicional.id,
      },
    });
  };

  return (
    <section>
      <HeaderList>
        <HeaderFilterContainer>
          <HeaderListTitle>Adicionais</HeaderListTitle>
          <RowFilter>
            <InputSearchStyle
              placeholder="Pesquisar"
              onChange={handleSearch}
              value={search}
            />
            <Button onClick={onClickAddAdicional}>
              Criar adicional
              <AddCircle />
            </Button>
          </RowFilter>
        </HeaderFilterContainer>
      </HeaderList>
      {adicionais?.findAllModulo?.length > 0 && (
        <List
          columns={columnsAdicionais}
          items={adicionais?.findAllModulo}
          loading={loadingAdicionais}
          error={errorAdicionais}
          onClickItem={handleClickAdicional}
          last
        />
      )}
      {!loadingAdicionais && adicionais?.findAllModulo?.length === 0 && (
        <EmptyListContainer>
          <EmptyListIconContainer>
            <TaskListIcon />
          </EmptyListIconContainer>
          <p>Nenhum adicional foi criado</p>
          <Button onClick={onClickAddAdicional}>
            Criar adicional
            <AddCircle />
          </Button>
        </EmptyListContainer>
      )}
      {
        loadingAdicionais && (
          <div> Carregando... </div>
        )
      }

      {openModal && (
        <ModalAdicional
          onCloseModal={onCloseModal}
          openModal={openModal}
          refetch={refetch}
          moduloSelecionado={moduloSelecionado || null}
          setNotification={setNotification}
        />
      )}
    </section>
  );
};

const HeaderFilterContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const RowFilter = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const InputSearchStyle = styled(InputSearch)`
  max-width: 500px;
  width: 100%;
  &>div {
    background-color: #fff;
  }
`;
