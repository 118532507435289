import React from 'react';
import styled from '@emotion/styled';
import { Field } from './field';
import moment from 'moment';
import { BoletosEmitidos } from './boletos-emitidos';

export const DadosFinanceiro = ({ dados }) => {
  const { meioPagamentoPlataforma, meiosPagamento } = dados;
  const { cardNumber, expirationDate, holder } = meiosPagamento;

  return (
    <Container>
      <ContentPagamento>
        {meioPagamentoPlataforma === 'BOLETO' ? (
          <>
            <Field title="Tipo" value="Boleto" />
            <BoletosEmitidos dados={dados}/>
          </>
        ) : (
          <Cartao>
            <Field title="Tipo" value="Cartão" />
            <Field title="Nome no cartão" value={holder} />
            <Row>
              <Field title="Número no cartão" value={cardNumber} />
              <Field
                title="Data de validade"
                value={
                  expirationDate ? moment(expirationDate).format('MM/YYYY') : ''
                }
              />
            </Row>
          </Cartao>
        )}
      </ContentPagamento>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const ContentPagamento = styled.div`
  background: #f2f2f2;
  border-radius: 8px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 16px;
  margin: 16px 0;
  height: calc(100vh - 380px);
`;

const Cartao = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;
