import React, { useEffect, useState } from "react"
import { Box, Button, Grid, Typography, withStyles } from "@material-ui/core"
import { ArrowDownloadIcon } from "../../../../components/icons/ArrowDownload";
import { useLazyQuery } from "react-apollo";
import { FIND_ALL_CONTRATOS } from "../../../../graphql/queryes";

const ContratosList = ({classes,setNotification}) =>{
  const [findAllContratos]=useLazyQuery(FIND_ALL_CONTRATOS)

  const [contratos, setContratos] = useState({});
  const [search, setSearch] = useState('');

  useEffect(async()=>{
    const variables = {
      pageableDto: {
        pageNumber: 0,
        pageSize: 30,
        sortField: 'nome',
        sortDir: 'ASC',
      },
      search,
      status: true,
    }
    try{
      const Contratos = findAllContratos({variables})
      setContratos(Contratos)
    }catch(error){
      console.log(error)
      setNotification({variant:'error', isOpen:true ,message:"Erro ao carregar contratos"})
    }
  })

  const dataTest = [{
    nome: "patricia",
    status: "Assinado",
    nomeContrato:"Nome do contrato001 - Adesão",
    dataContrato: "07/08/2025"
  },
  {
    nome: "patricia",
    status: "Em processo",
    nomeContrato:"Nome do contrato002 - Adesão",
    dataContrato: "07/08/2025"
  },
  {
    nome: "patricia",
    status: "Cancelado",
    nomeContrato:"Nome do contrato003 - Adesão",
    dataContrato: "07/08/2025"
  },
]

const checkStatusColor = (status) =>{
  switch (status) {
    case "Em processo":
      return {background:"#FCF0DF", borderColor:"#DB7C2F"};
    case "Cancelado":
      return {background:"#FCEDEF", borderColor:"#BF3028"}
    default:
      return {backgroundColor:"#E1F3F4", borderColor:"#007F79",}
  }
}

return(
<Grid container className={classes.containerList} >

 {dataTest.map((contratoItem)=><Grid item xs={12} className={classes.itemList}>
  <Box className={classes.rowItemList}>
  <Typography className={classes.headetText}>{contratoItem.nome}</Typography>
  <Typography className={classes.headetText}>{contratoItem.dataContrato}</Typography>
  </Box>
  <Box className={classes.rowItemList}>
    <Box className={classes.containerNomeStatusContrato}>
      <Box className={classes.containerStatus} style={{...checkStatusColor(contratoItem.status)}}>
      <Typography className={classes.statusText}>{contratoItem.status}</Typography>
      </Box>
      <Typography className={classes.nomeContrato}>{contratoItem.nomeContrato}</Typography>
    </Box>
    <Button className={classes.button} endIcon={ <ArrowDownloadIcon className={classes.downloadIcon}/>}>
    Baixar
   </Button>
  </Box>
 </Grid>)}
</Grid>


)

}
const styles = () => ({
  containerList:{
    gap:"10px",
    height:"75%",
    padding:"16px 32px 32px 32px"
  },
  itemList:{
    backgroundColor:"#f2f2f2",
    padding:"15px",
    boxSizing:"border-box",
    gap: "8px",
    borderRadius:"15px",
    display:"flex",
    flexDirection:"column",
  },
  rowItemList:{
    display:"flex",
    flexDirection:"row",
    justifyContent:"space-between",
    alignItems:"center"
  },
  headetText:{
    fontFamily:"Poppins",
    fontWeight: 400,
    fontSize: "10px",
    color:"#000000"
  },
  containerNomeStatusContrato:{
    display:"flex",
    flexDirection:"column",
    justifyContent:"space-between",
    gap:"4px"
  },
  containerStatus:{
    backgroundColor:"#E1F3F4",
    border:"1px solid",
    width:"fit-content",
    padding:"2px 4px 2px 4px",
    borderRadius:"9px",
  },
  statusText:{
    fontFamily:"Poppins",
    fontWeight: 400,
    fontSize: "10px",
    color:"#000000"
  },
  nomeContrato:{
    fontFamily:"Poppins",
    fontWeight:600,
    fontSize:"13px",
    color:"#000000"
  },
  button:{
    backgroundColor:"#ffff",
    color:"#219A97",
    border:"1px solid #219A97",
    borderRadius:"100px",
    padding:"4px 16px 4px 24px",
    height: "fit-content",
    width: "fit-content",
    "& .MuiButton-label":{
      fontSize:"10px !important",
      fontWeight: 600
    }
  },
  downloadIcon:{
    width:"12px",
    height: "12px"
  }
});

export default withStyles(styles)(ContratosList)
