import React, { useEffect } from 'react';
import moment from 'moment';
import { useState } from 'react';
import styled from '@emotion/styled';
import { useMutation } from 'react-apollo';
import { useParams } from 'react-router-dom';
import CopyToClipboard from 'react-copy-to-clipboard';
import { TextField } from '../../../../../components/TextField';
import { ADICIONAR_CARTAO_CREDITO } from '../../../../../graphql/mutations';
import { CopyIcon } from '../../../../../components/icons/Copy';

const Cartao = ({
  handleMeioPagamento,
  cartao,
  vendaId,
  setNotification,
  handleCloseModal,
}) => {
  const { holder, cardNumber, expirationDate, securityCode } = cartao || {};

  const [adicionarCartaoCredito] = useMutation(ADICIONAR_CARTAO_CREDITO);
  const [loading, setLoading] = useState(false);
  const [linkPagamentoCopiado, setLinkPagamentoCopiado] = useState(false);
  const [urlPagamentoVenda, setUrlPagamentoVenda] = useState('');

  const params = useParams();

  useEffect(() => {
    const newURL =
      window.location.protocol +
      '//' +
      window.location.host +
      '/pagamentos/' +
      params.vendaId;
    setUrlPagamentoVenda(newURL);
  }, []);

  const onCopyLinkPagamento = () => {
    setLinkPagamentoCopiado(true);
  };

  const handleRealizarPagamento = async () => {
    try {
      setLoading(true);
      await adicionarCartaoCredito({
        variables: {
          vendaId,
          cartaoCredito: {
            holder,
            cardNumber,
            expirationDate: moment(expirationDate, 'MM/YYYY')
              .startOf('month')
              .format('YYYY-MM-DD'),
            securityCode,
          },
        },
      });

      setNotification({
        variant: 'success',
        message: 'Pagamento realizado com sucesso!',
        isOpen: true,
      });

      handleCloseModal();
    } catch (error) {
      setNotification({
        variant: 'error',
        message: error.message,
        isOpen: true,
      });
    } finally {
      setLoading(false);
    }
  };

  const isDadosValidos =
    !!holder && !!cardNumber && !!expirationDate && !!securityCode;

  return (
    <ContentCartao>
      <TextField
        label="Nome no cartão de crédito"
        placeholder="Digite o nome"
        value={holder}
        onChange={(e) => handleMeioPagamento('holder', e.target.value)}
      />
      <TextField
        label="Número no cartão de crédito"
        placeholder="0000 0000 0000 000"
        mask={'creditCard'}
        value={cardNumber}
        onChange={(e) => handleMeioPagamento('cardNumber', e.target.value)}
      />
      <Row>
        <TextField
          label="Código de segurança"
          placeholder="CVV"
          value={securityCode}
          mask={'creditCardSecureCode'}
          onChange={(e) => handleMeioPagamento('securityCode', e.target.value)}
        />
        <TextField
          label="Data de validade"
          placeholder="MM/AAAA"
          mask={'dataValidade'}
          value={expirationDate}
          onChange={(e) =>
            handleMeioPagamento('expirationDate', e.target.value)
          }
        />
      </Row>
      <LinkPagamento>
        <ContentLink>{urlPagamentoVenda}</ContentLink>
        <CopyToClipboard text={urlPagamentoVenda} onCopy={onCopyLinkPagamento}>
          <ButtonCopy>
            <CopyIcon/>
          </ButtonCopy>
        </CopyToClipboard>
      </LinkPagamento>

      {linkPagamentoCopiado && (
        <div>
          <MensagemLinkPagamento>
            Link copiado para a área de transferência.
          </MensagemLinkPagamento>
        </div>
      )}
      <Button
        onClick={handleRealizarPagamento}
        disabled={loading || !isDadosValidos}
      >
        <LabelButton>Realizar pagamento</LabelButton>
      </Button>
    </ContentCartao>
  );
};

export default Cartao;

const ContentCartao = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
`;

const Button = styled.button`
  display: flex;
  align-items: center;
  width: 160px;
  height: 40px;
  background-color: #505050;
  border-radius: 100px;
  border: 2px solid rgba(220, 220, 220, 0.2);
  gap: 8px;
  justify-content: space-between;
  padding: 0 16px;
  color: #fff;
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  ${(props) => props.disabled && 'opacity: 0.5'};
`;

const LabelButton = styled.label`
  font-weight: 600;
  font-size: 14px;
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
`;

const MensagemLinkPagamento = styled.div`
  color: #505050;
  justify-content: center;
  font-size: 1rem;
`;

const LinkPagamento = styled.div`
  background-color: #f2f2f2;
  border-radius: 8px;
  border: 1px solid #dcdcdc33;
  display: flex;
  align-items: center;
  justify-content: start;
  padding: 0 4px;
  height: 32px;
  justify-content: space-between;
  max-width: calc(100% - 12px);
`;

const ButtonCopy = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;

`;

const ContentLink = styled.div`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;
