import React from 'react';
import styled from '@emotion/styled';
import { Edit } from '@material-ui/icons';

export const CardDado = ({ children, title, value, handleEdit, canEdit }) => {
  return (
    <Content>
      <Title>
        {title}
        {canEdit && <Edit className='edit' onClick={handleEdit} />}
      </Title>
      {children || <Label>{value}</Label>}
    </Content>
  );
};

const Content = styled.div`
  display: flex;
  padding: 16px;
  flex-direction: column;
  border-radius: 8px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  min-width: 131px;
`;

const Title = styled.div`
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  .edit {
    font-size: 14px;
    margin-left: 4px;
    cursor: pointer;
  }
`;

const Label = styled.label`
  color: #868686;
  font-size: 14px;
`;

export const CardDados = ({ children, title, handleEdit, infoTitle, canEdit }) => {
  return (
    <ContentCardDados>
      <Header>
        <TitleCardDados>
          {title}
          {canEdit && <Edit className='edit' onClick={handleEdit} />}
        </TitleCardDados>
        {infoTitle && infoTitle}
      </Header>
      {children}
    </ContentCardDados>
  );
};

const ContentCardDados = styled.div`
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 16px;
  border-radius: 8px;
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const TitleCardDados = styled.div`
  display: flex;
  flex-direction: row;
  gap: 4px;
  font-weight: 700;
  font-size: 18px;
  .edit {
    font-size: 14px;
    margin-left: 4px;
    cursor: pointer;
  }
`;
