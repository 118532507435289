import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import { useQuery } from 'react-apollo';
import { LIST_ESPECIALIDADES } from '../../../../graphql/queryes';
import InputSearch from '../../../../components/input/Input';
import SmallTable from '../../../../components/Table/SmallTable/SmallTable';
import { EspecialidadeSelecionada, Grid2 } from './styles';
import { Checkbox, Typography } from '@material-ui/core';

const FormDadosEspecialidadeMedica = (props) => {
  const { setFormData, formData } = props;
  const { utilizaAzulControle } = formData;

  const [search, setSearch] = useState('');

  const [especialidades, setEspecialidades] = useState(formData.especialidades);

  const { data } = useQuery(LIST_ESPECIALIDADES, {
    variables: {
      search: {
        search: search,
      },
      pageableDTO: {
        sortDir: 'ASC',
        sortField: 'especialidade',
      }
    },
  });

  useEffect(() => {
    setFormData({
      ...formData,
      especialidades
    });

  }, [especialidades]);

  const handleAtualizaUtilizaAzulControle = (value) => {
    setFormData({
      ...formData,
      utilizaAzulControle: value,
    });
  };

  const handleSearch = (event) => {
    setSearch(event.target.value);
  };

  const handleDelete = (especialidade) => {
    setEspecialidades(
      especialidades.filter((item) => item.value !== especialidade.value)
    );
    especialidade.isInvisivel = false;
  };

  const renderEspecilidade = (especialidade) =>
    !especialidade.isInvisivel && (
      <SmallTable.Row
        key={especialidade.value}
        onClick={() => handleClickTableItem(especialidade)}
      >
        <SmallTable.Data size={9} value={especialidade.label} noBorder />
      </SmallTable.Row>
    );

  const handleClickTableItem = (item) => {
    if (!especialidades.includes(item) && item) {
      setEspecialidades([...especialidades, item]);

      item.isInvisivel = true;
    }
  };

  return (
    <Grid container direction={'row'}>
      <Grid item xs={12} sm={12} md={12} ls={12}>
        <InputSearch
          placeholder="Buscar por nome..."
          onChange={(event) => handleSearch(event)}
          value={search}
        />
      </Grid>
      <Grid2 item xs={12} sm={12} md={12} ls={12}>
        {especialidades.map((especialidade) => (
          <EspecialidadeSelecionada
            key={especialidade.value}
            label={especialidade.label}
            onClick={() => handleDelete(especialidade)}
            onDelete={() => handleDelete(especialidade)}
            color={'primary'}
          />
        ))}
      </Grid2>

      <Grid item xs={12} sm={12} md={12} ls={12}>
        <SmallTable>
          <SmallTable.Head>
            <SmallTable.HeadData size={12} value="Especialidade" noBorder />
          </SmallTable.Head>
          <SmallTable.Body>
            {data?.findAllEspecialidadeMedicaList?.map(renderEspecilidade)}
          </SmallTable.Body>
        </SmallTable>
      </Grid>
      <Grid2>
        <Typography color="black" component="label">
          Utiliza azul controle?
        </Typography>
        <Checkbox
          checked={utilizaAzulControle}
          color="primary"
          onChange={(e) =>
            handleAtualizaUtilizaAzulControle(e.target.checked)
          }
        />
      </Grid2>
    </Grid>
  );
};

export default FormDadosEspecialidadeMedica;
