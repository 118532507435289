import React from 'react';
import styled from '@emotion/styled';
import Chip from '@material-ui/core/Chip';
import Typography from '@material-ui/core/Typography';
import { Field } from './field';
import { CardDado } from './card';
import {
  dadosEmpresariaisCard,
  dadosResponsavelCard,
  enderecoCard,
} from '../constants';
import Button from '../../../components/button';
import { PERFIL_PUBLICO_UNIDADE_VENDA, CONFIGURACAO_RECEBIMENTOS_UNIDADE } from '../../../router/names';

import { useQuery } from 'react-apollo';
import { FIND_PERFIL_PUBLICO_UNIDADE_BY_VENDA_ID } from '../../../graphql/queryes';

import { useParams, Link } from 'react-router-dom';

const buttonStyle = {
  marginTop: '4px',
}

export const DadosUnidade = ({ dados, functions, canEdit, isAuthoritySistema }) => {
  const { utilizaAzulControle } = dados;
  const { vendaId } = useParams();

  const { data } = useQuery(FIND_PERFIL_PUBLICO_UNIDADE_BY_VENDA_ID, {
    variables: {
      vendaId,
    },
  });
  const { findPerfilPublicoUnidadeByVendaId: perfilPublico } = data || {}; 
  const unidadeHasPerfilPublico = !!perfilPublico?.id;

  return (
    <ContentCardDadosUnidade>
      <CardDado
        title="Dados do responsável"
        handleEdit={functions.handleClickEditarContratante}
        canEdit={canEdit}
      >
        <Content>
          {dadosResponsavelCard({ dados }).map((item) => {
            const { title, value } = item;
            return <Field key={title} title={title} value={value} />;
          })}
        </Content>
      </CardDado>
      <CardDado
        title="Dados empresarial"
        handleEdit={functions.toggleEditDadosEmpresarial}
        canEdit={canEdit}
      >
        <Content>
          {dadosEmpresariaisCard({ dados }).map((item) => {
            const { title, value } = item;
            return <Field key={title} title={title} value={value} />;
          })}
        </Content>
      </CardDado>
      <CardDado
        title="Endereço"
        handleEdit={functions.toggleEditEndereco}
        canEdit={canEdit}
      >
        <Content>
          {enderecoCard({ dados }).map((item) => {
            const { title, value } = item;
            return <Field key={title} title={title} value={value} />;
          })}
        </Content>
      </CardDado>
      <CardDado
        title="Especialidade"
        handleEdit={functions.toggleEditEspecialidades}
        canEdit={canEdit}
      >
        <ContentEspecialidade>
          {dados.contratante?.especialidades?.length > 0 ? (
            dados.contratante.especialidades.map((item) => {
              return <Especialidade key={item.value} label={item.label} />;
            })
          ) : (
            <Typography color="black" component="label">
              Sem especialidade
            </Typography>
          )}
        </ContentEspecialidade>
        <ContentUtilizaAzulControle>
          <Field
            title="Utiliza financeiro completo"
            value={utilizaAzulControle ? 'Sim' : 'Não'}
          />
        </ContentUtilizaAzulControle>

        <ContentPerfilPublico>
          <Field
            title="Perfil Público"
            value={
              <Link
                to={PERFIL_PUBLICO_UNIDADE_VENDA.replace(':vendaId', vendaId)}
                style={{ textDecoration: 'none' }}
              >
                <Button
                  style={buttonStyle}
                  >
                  Acessar
                </Button>
              </Link>
            }
            />
          {unidadeHasPerfilPublico && isAuthoritySistema && (
            <Field 
              title="Configuração Dados bancários"
              value={
                <Link 
                  to={CONFIGURACAO_RECEBIMENTOS_UNIDADE.replace(':vendaId', vendaId)}
                  style={{ textDecoration: 'none' }}
                >
                  <Button 
                    style={buttonStyle}
                  >
                    Acessar
                  </Button>
                </Link>
              }
            />
          )}
        </ContentPerfilPublico>
      </CardDado>
    </ContentCardDadosUnidade>
  );
};

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const Especialidade = styled(Chip)`
  margin: 1px 2px !important;
  background: #f8f8f8;
  border: 1px solid rgba(0, 0, 0, 0.1);
  color: #505050;
  font-size: 14px;
  width: fit-content;
`;

const ContentEspecialidade = styled.div`
  display: flex;
  flex-direction: row;
  gap: 4px;
  flex-wrap: wrap;
`;

const ContentCardDadosUnidade = styled.div`
  display: grid;
  grid-template-columns: 2fr 2fr 2fr 3fr;
  gap: 8px;
  margin-top: 16px;
  @media (max-width: 760px) {
    display: flex;
    flex-direction: column;
  }
`;

const ContentUtilizaAzulControle = styled.div`
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  margin: 16px 0;
  padding-top: 16px;
`;

const ContentPerfilPublico = styled.div`
  display: flex;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  margin: 16px 0;
  padding-top: 16px;
  gap: 24px;
`;
