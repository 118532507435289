import { hydrateSession } from "../redux";

const sessionVerify = dispatch => {
	const usuarioLogado = window.localStorage.getItem('usuarioLogado');
	const entidadeAtual = window.localStorage.getItem('entidadeAtual');
	const entidadesAtivasDoUsuario = window.localStorage.getItem('entidadesAtivasDoUsuario');
	const token = window.localStorage.getItem('token');

	if (token) {
		dispatch(hydrateSession({
			token,
			usuarioLogado,
			entidadesAtivasDoUsuario,
			entidadeAtual
		}));
			
		return;
	}

	window.localStorage.clear();
};

export default sessionVerify;