import React from 'react';
import CancelIcon from '@material-ui/icons/Cancel';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import AtivoSwitch from '../list-entity/components/ativoSwitch';
import { DadosPlano } from './components/planos-list/components/dados-plano';
import { FuncionalidadesPlano } from './components/planos-list/components/funcionalidades-plano';
import styled from '@emotion/styled';
import string from '../../utils/string';
import Number from '../../utils/number';

export const dadosPlanoDefault = {
  id: null,
  nome: '',
  ativo: true,
  funcionalidades: [],
  ANUAL: '',
  FIDELIDADE: '',
  MENSAL: '',
  tiposPagamentoId: null,
};

export const tiposPagamentoPlanos = [
  {
    label: 'Anual',
    value: 'ANUAL',
    field: 'ANUAL',
  },
  {
    label: 'Fidelidade',
    value: 'FIDELIDADE',
    field: 'FIDELIDADE',
  },
  {
    label: 'Mensal',
    value: 'MENSAL',
    field: 'MENSAL',
  },
];

export const stepsPlanos = [
  {
    label: '',
    value: 0,
    component: DadosPlano,
  },
  {
    label: 'Funcionalidades',
    value: 1,
    component: FuncionalidadesPlano,
  },
  {
    label: 'Confirmar',
    value: 2,
    component: FuncionalidadesPlano,
  },
];

export const columnsFuncionalidades = ({ changeStatus, isUltimoStep }) => [
  {
    title: 'Funcionalidade',
    value: (item) => item.funcionalidade,
  },
  {
    title: 'Mensal',
    value: (item) => getValueTipoPagamento(item.mensal, isUltimoStep),
    onClick: (e) => changeStatus(e, 'mensal'),
  },
  {
    title: 'Fidelidade',
    value: (item) => getValueTipoPagamento(item.fidelidade, isUltimoStep),
    onClick: (e) => changeStatus(e, 'fidelidade'),
  },
  {
    title: 'Anual',
    value: (item) => getValueTipoPagamento(item.anual, isUltimoStep),
    onClick: (e) => changeStatus(e, 'anual'),
  },
];

const getValueTipoPagamento = (value, isUltimoStep) => {
  if (isUltimoStep) {
    return value ? <CheckCircleIconStyle /> : <CancelIconStyle />;
  }
  return <AtivoSwitch ativo={value} />;
};

const CheckCircleIconStyle = styled(CheckCircleIcon)`
  color: #24d475;
`;

const CancelIconStyle = styled(CancelIcon)`
  color: #ff0505;
`;

export const notificationDefault = {
  variant: '',
  message: '',
  isOpen: false,
};

export const columnsAdicionais = [
  {
    title: 'Funcionalidade',
    value: (item) => item.funcionalidade,
  },
  {
    title: 'Período de cobrança',
    value: (item) => string.capitalize(item.periodoCobranca),
  },
  {
    title: 'Tipo',
    value: (item) => string.capitalize(item.tipoCobranca),
  },
  {
    title: 'Valor',
    value: (item) => {
      const { valor } = item;
      return valor === 0 ? 'Grátis' : Number.currencyFormat(valor);
    },
  },
  {
    title: 'Permissão',
    value: (item) => {
      const { group } = item;
      const groupNome = group.map((element) =>
        element === 'ATENDENTE'
          ? 'Profissional de saúde'
          : string.capitalize(element)
      );
      const permissao = groupNome.join('/ ');

      return permissao;
    },
  },
];

export const periodosCobranca = [
  {
    name: 'Mensal',
    value: 'MENSAL',
  },
  {
    name: 'Único',
    value: 'UNICO',
  },
];

export const groups = [
  {
    name: 'Administrador(a)',
    value: 'ADMINISTRADOR',
    checked: true,
  },
  {
    name: 'Profissional de saúde',
    value: 'ATENDENTE',
    checked: true,
  },
  {
    name: 'Secretário(a)',
    value: 'SECRETARIO',
    checked: true,
  },
];

export const adicionalDefault = {
  id: null,
  funcionalidade: '',
  valor: '0',
  tipoCobranca: 'PROFISSIONAL',
  periodoCobranca: 'MENSAL',
  group: groups,
  financeiroCompleto: false,
  alterarValor: false,
};
