import React, { useEffect, useState } from "react";
import 'react-phone-input-2/lib/style.css';
import Grid from "@material-ui/core/Grid";
import styled from "@emotion/styled";
import RenderTextField from "../../../../components/TextField/RenderTextField";
import string from "../../../../utils/string";
import InputPhoneDdi from "../../../../components/input/InputPhoneDdi";

const DadosBasicosContratante = ({ dados, handleAtualizarDados, dadosErrors, handleAtualizarDadosErrors, handleDadosInvalidos, disabled }) => {
  const [dadosTemp, setDadosTemp] = useState({
    razaoSocial: dados.razaoSocial || '',
    nomeFantasia: dados.nomeFantasia || '',
    inscricaoEstadual: dados.inscricaoEstadual || '',
    telefone: dados.telefoneDDI ? `${dados.telefoneDDI} ${dados.telefone}` : (dados.telefone || '55'),
    telefoneDDI: dados.telefoneDDI || '55',
    codigoCnes: dados.codigoCnes || '',
    email: dados.email || ''
  });

  const handleChangeField = (campo, value) => {
    setDadosTemp({
      ...dadosTemp,
      [campo]: value
    });

    handleAtualizarDadosErrors({
      [campo]: !value
    });

    handleAtualizarDados({
      [campo]: value
    });

  };

  useEffect(() => {
    handleDadosInvalidos(isCamposPreenchidos());
  }, []);

  useEffect(() => {
    disabled && setDadosTemp({
      razaoSocial: dados.razaoSocial || '',
      nomeFantasia: dados.nomeFantasia || '',
      inscricaoEstadual: dados.inscricaoEstadual || '',
      telefone: dados.telefoneDDI ? `${dados.telefoneDDI} ${dados.telefone}` : (dados.telefone || '55'),
      telefoneDDI: dados.telefoneDDI || '55',
      codigoCnes: dados.codigoCnes || '',
      email: dados.email || ''
    });

  }, [dados]);

  const handleInscricaoEstadualField = (value) => {
    setDadosTemp({
      ...dadosTemp,
      inscricaoEstadual: value
    });

    handleAtualizarDados({
      inscricaoEstadual: value
    });
  };

  const onTelefonePrincipalComDDIChange = (value, data) => {
    setDadosTemp({
      ...dadosTemp,
      telefone: value,
      telefoneDDI: data.dialCode
    });

    validaTelefone(value, data.dialCode);
    
    handleAtualizarDados({
      telefone: value,
      telefoneDDI: data.dialCode
    });
  };
  
  const validaTelefone = (value, dialCode) => {
    const isTelefoneValido = string.validaTelefoneDDI(value, dialCode);

    handleAtualizarDadosErrors && handleAtualizarDadosErrors({
      telefone: isTelefoneValido
    });

    return isTelefoneValido;
  };

    const handleEmailField = (value) => {
      setDadosTemp({
        ...dadosTemp,
        email: value
      });

      handleAtualizarDadosErrors({
        email: !value || !string.validaEmail(value)
      });

      handleAtualizarDados({
        email: value
      });
    };

    const isCamposPreenchidos = () => {
      return dadosTemp.razaoSocial === '' ||
        dadosTemp.nomeFantasia === '' ||
        !string.validaEmail(dadosTemp.email) ||
        !validaTelefone(dadosTemp.telefone, dadosTemp.telefoneDDI);
    };

    useEffect(() => {
      handleDadosInvalidos(isCamposPreenchidos());
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [handleAtualizarDados]);

    return (
      <Container>
        <Grid container direction="row">
          <FormGroup item xs={12} sm={12} md={12} ls={12}>
            <RenderTextField label={"Razão Social *"}
              field={dadosTemp.razaoSocial}
              error={dadosErrors?.razaoSocial}
              errorText={"Este campo é obrigatório."}
              handleChange={(e) => handleChangeField('razaoSocial', e.target.value)}
              disabled={disabled} />
          </FormGroup>
          <FormGroup item xs={12} sm={12} md={12} ls={12}>
            <RenderTextField label={"Nome Fantasia *"}
              field={dadosTemp.nomeFantasia}
              error={dadosErrors?.nomeFantasia}
              errorText={"Este campo é obrigatório."}
              handleChange={(e) => handleChangeField('nomeFantasia', e.target.value)}
              disabled={disabled} />
          </FormGroup>

          <FormGroup item xs={12} sm={12} md={12} ls={12}>
            <RenderTextField label={"Inscrição Estadual"}
              field={dadosTemp.inscricaoEstadual}
              mask={'inscricaoEstadual'}
              handleChange={(e) => handleInscricaoEstadualField(e.target.value)}
              disabled={disabled} />
          </FormGroup>
          <FormGroup item xs={12} sm={12} md={12} ls={12}>
            <InputPhoneDdi
              disabled={disabled}
              error={dadosErrors?.telefone}
              onTelefonePrincipalComDDIChange={onTelefonePrincipalComDDIChange}
              telefone={dadosTemp.telefone}
              label="Telefone *"
            />
          </FormGroup>
          <FormGroup item xs={12} sm={12} md={12} ls={12}>
            <RenderTextField label={"Código CNES"}
              field={dadosTemp.codigoCnes}
              handleChange={(e) => handleChangeField('codigoCnes', e.target.value)}
              disabled={disabled} />
          </FormGroup>
          <FormGroup item xs={12} sm={12} md={12} ls={12}>
            <RenderTextField label={"Email *"}
              field={dadosTemp.email}
              error={dadosErrors?.email}
              errorText={"Este campo é obrigatório."}
              handleChange={(e) => handleEmailField(e.target.value)}
              disabled={disabled} />
          </FormGroup>
        </Grid>
      </Container>
    );

  };

  export default DadosBasicosContratante;

  const Container = styled.div`
    width: 100%;
    height: 100%;
  `;

  const FormGroup = styled(Grid)`
  margin-bottom: 10px !important;
`;
