const NETWORKS = {
  FACEBOOK: 'Facebook',
  TWITTER: 'Twitter',
  INSTAGRAM: 'Instagram',
  LINKEDIN: 'LinkedIn',
};

const networksUrls = {
  [NETWORKS.FACEBOOK]: 'https://facebook.com/',
  [NETWORKS.TWITTER]: 'https://twitter.com/',
  [NETWORKS.INSTAGRAM]: 'https://instagram.com/',
  [NETWORKS.LINKEDIN]: 'https://linkedin.com/',
};

export const SocialNetworkBuildUrls = {
  buildUrl: (network, username) => {
    const socialNetworkUrl = networksUrls[network];
    return socialNetworkUrl && username ? socialNetworkUrl + username : '';
  },
  networks: NETWORKS,
  networksUrls,
};
