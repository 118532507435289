import styled from '@emotion/styled';
import React from 'react';
import InputSearch from '../../../../components/input/Input';
import InputDateForm from '../../../../components/input/InputDateForm';
import moment from 'moment';

const Search = ({ setFilters, filters, loadRelatorio }) => {

  const { search, dataFinal, dataInicial } = filters;

  const handleChange = (e, field) => {
    setFilters({
      ...filters,
      [field]: e,
    });
    loadRelatorio();
  };

  return (
      <HeaderContainer>
        <InputSearch
          placeholder="Buscar por unidade"
          onChange={(e) => handleChange(e.target.value, 'search')}
          value={search}
        />
        <InputDateForm
          placeholder="Data inicio"
          variant="outlined"
          fullWidth
          value={dataInicial}
          onChange={(e) => handleChange(moment(e).format('YYYY-MM-DD'), 'dataInicial')}
        />
        <InputDateForm
          placeholder="Data fim"
          variant="outlined"
          fullWidth
          value={dataFinal}
          onChange={(e) => handleChange(moment(e).format('YYYY-MM-DD'), 'dataFinal')}
        />
      </HeaderContainer>
  );
};

const HeaderContainer = styled.div`
  background-color: #fff;
  border-radius: 16px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 16px;
  display: flex;
  gap: 16px;
`;

export default Search;
