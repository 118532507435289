import { useState } from 'react';
import { nestorService } from '../services/nestor';

export const useNestor = () => {
  const [status, setStatus] = useState('idle');
  const [file, setFile] = useState(null);
  const [uploadId, setUploadId] = useState(null);

  const upload = async (file) => {
    setStatus('loading');

    try {
      const id = await nestorService.upload(file);
      setUploadId(id);
      setFile(file);
      return id;
    } catch (error) {
      setStatus('error');
    } finally {
      setStatus('idle');
    }
  };

  const isLoading = status === 'loading';
  const isError = status === 'error';

  return [
    upload,
    {
      data: uploadId && {
        url: nestorService.url(uploadId),
        tempUrl: nestorService.urlTemp(uploadId),
        localUrl: file && URL.createObjectURL(file),
        id: uploadId,
      },
      isLoading,
      isError,
      status,
    },
  ];
};
