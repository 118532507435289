import React, { createContext, useContext, useState } from 'react';
import { useLazyQuery, useMutation, useQuery } from 'react-apollo';
import { parseSchemaAndValidate } from './constants/zodSchemas';

import {
  RECEBIMENTO_CONFIG_FORM_DATA_DEFAULT,
  ERROR_SCHEMA,
} from './constants/recebimentoConfigConstants';

import {
  CONSULTA_CEP,
  FIND_BY_DADOSBRASPAG_UNIDADE,
} from '../../graphql/queryes';
import { CREATE_DADOS_RECEBIMENTO, UPDATE_DADOS_RECEBIMENTO } from '../../graphql/mutations';
import { removeTypename } from '../../utils/removeTypename';
import { removeMaskedFields } from '../../utils/removeMask';

const RecebimentosContext = createContext();

export const RecebimentosProvider = ({ children }) => {
  const [recebimentoFormData, setRecebimentoFormData] = useState(
    RECEBIMENTO_CONFIG_FORM_DATA_DEFAULT
  );
  const [errors, setErrors] = useState(ERROR_SCHEMA);
  const [unidadeId, setUnidadeId] = useState(null);

  const [consultaCep] = useLazyQuery(CONSULTA_CEP);
  const [createDadosRecebimentos, { loading: isLoading }] = useMutation(CREATE_DADOS_RECEBIMENTO, {
    onCompleted: (data) => {
      const { id } = data.createDadosBraspagUnidadeSaudeComercial;
      setRecebimentoFormData({ id, ...recebimentoFormData });
    },
    onError: (error) => console.error(error),
  });

  const [updateDadosRecebimento] = useMutation(UPDATE_DADOS_RECEBIMENTO, {
    onError: (error) => console.error(error),
  })
  
  const {refetch: refetchDadosBraspag} = useQuery(FIND_BY_DADOSBRASPAG_UNIDADE, {
    variables: {
      unidade: unidadeId,
    },
    skip: !unidadeId,
    onCompleted: (data) => {
      if (data.findByDadosBraspagUnidade)
      setRecebimentoFormData(data?.findByDadosBraspagUnidade);
    }
  });

  const handleChangeRecebimentoField = (field, value) => {
    setRecebimentoFormData({
      ...recebimentoFormData,
      [field]: value,
    });
  };

  const handleChangeDadoBancarioField = (field, value) => {
    setRecebimentoFormData({
      ...recebimentoFormData,
      ...(field === 'documento' ? { documento: value } : {}),
      dadoBancario: {
        ...recebimentoFormData.dadoBancario,
        [field]: value,
      },
    });
  };

  const handleChangeEnderecoField = (field, value) => {
    setRecebimentoFormData({
      ...recebimentoFormData,
      endereco: {
        ...recebimentoFormData.endereco,
        [field]: value,
      },
    });
  };

  const handleSaveRecebimentosConfig = async ({ perfilPublicoId, callback }) => {
    
    try {
      const { id: recebimentoId, status, ...rest } = recebimentoFormData;
      const formWithoutMask = removeMaskedFields({
        documento: recebimentoFormData.dadoBancario.documento,
        ...rest,
      });
      const error = parseSchemaAndValidate(formWithoutMask, ERROR_SCHEMA);
      setErrors(error);

      const anyError = Object.values(error).some((value) => value);
      if (anyError) {
        throw new Error('Revise os campos obrigatórios');
      }

      if (recebimentoId) {
        updateDadosRecebimento({
          variables: {
            dadosRecebimento: {
              id: recebimentoId,
              documentoTipo: 'CNPJ',
              ...removeTypename(formWithoutMask),
            }
          }
        });
      } else {
        createDadosRecebimentos({
          variables: {
            dadosRecebimento: removeTypename(formWithoutMask),
            perfilPublicoId,
          },
        });
      }
      refetchDadosBraspag();
      callback({ type: 'NOTIFY', payload: { status: 'success', message: 'Configurações de recebimentos salvas.' } });
    } catch (error) {
      console.error(error);
      callback({ type: 'NOTIFY', payload: { status: 'error', message: 'Falha ao salvar as configurações.' } });
    }
  };

  const getCEPInfo = async (cep) => {
    try {
      const response = consultaCep({
        variables: {
          cep,
        },
      });
  
      if (response.data.data.consultaDeCep) {
        const { cep, logradouro, bairro, uf, localidade } = response.data.data.consultaDeCep;
        setRecebimentoFormData({
          ...recebimentoFormData,
          cep,
          bairro,
          estado: uf,
          cidade: localidade,
          nomeLogradouro: logradouro,
        });
      }
    } catch(error) {
      console.error(error);
    }
  };

  return (
    <RecebimentosContext.Provider
      value={{
        recebimentoFormData,
        handleChangeRecebimentoField,
        handleChangeDadoBancarioField,
        handleChangeEnderecoField,
        handleSaveRecebimentosConfig,
        setUnidadeId,
        getCEPInfo,
        isLoading,
        errors,
      }}
    >
      {children}
    </RecebimentosContext.Provider>
  );
};

export const useRecebimentos = () => {
  return useContext(RecebimentosContext);
};
