export const styles = ({
  paper: {
    overflow: 'hidden',
    height: '220px',
    width: '350px',
    justifyContent: 'space-between',
    padding: '24px'
  },
  content: {
    flexDirection: 'column',
    display: 'flex',
  },
  buttonSave: {
    background: '#00B0AE',
    color: '#fff',
    width: 170,
    height: '40px',
    alignSelf: 'center',
    marginBottom: '8px',

    '&:hover': {
      color: '#00B0AE',
    },
  },
  contentButton: {
    display: 'flex',
    alignItems: 'end',
    justifyContent: 'end',
  },
  inputLabel: {
    fontSize: '18px !important',
    color: '#505050',
    fontWeight: '600',
    marginBottom: '10px'
  }
});
