import React, { createContext, useContext, useState } from 'react';
import { ViziAPI } from '../../apollo/setupViziClient';

import { FIND_PERFIS_PUBLICO_BY_UNIDADE } from '../../graphql/queryes';
import { PERFIS_UNIDADE_PAGINATION_SETTINGS_DEFAULT } from './constants/perfisUnidadePaginationSettingsDefault';
import { SET_DADOS_IUGU_BY_PROFISSIONAIS_SAUDE } from '../../graphql/mutations';

const ConfigReplicaContext = createContext();

export const ConfigReplicaProvider = ({ children }) => {
  const [selectedPerfis, setSelectedPerfis] = useState([]);
  const [loadedPerfis, setLoadedPerfis] = useState([]);
  const [paginationSetup, setPaginationSetup] = useState(PERFIS_UNIDADE_PAGINATION_SETTINGS_DEFAULT);
  const [lastPage, setLastPage] = useState(false);

  const loadPerfisUnidade = async ({ unidadeId, reset }) => {
    try {
      const response = await ViziAPI.post("", {
        query: FIND_PERFIS_PUBLICO_BY_UNIDADE,
        variables: {
          pageableDTO: !reset ? paginationSetup : PERFIS_UNIDADE_PAGINATION_SETTINGS_DEFAULT,
          unidade: {
            id: unidadeId
          }
        }
      });

      if (response.data.data.findPefisPublicosByUnidade) {
        const { content, last } = response.data.data.findPefisPublicosByUnidade;
        const { pageNumber } = paginationSetup;

        const updatedContent = pageNumber > 0 ? [...loadedPerfis, ...content] : content;
        setLoadedPerfis(updatedContent);
        setPaginationSetup({
          ...paginationSetup,
          pageNumber: pageNumber + 1,
        });
        setLastPage(last);
      }
    } catch(error) {
      console.error(error);
    }
  };

  const resetPaginationSettings = () => {
    setPaginationSetup(PERFIS_UNIDADE_PAGINATION_SETTINGS_DEFAULT);
  };

  const saveConfig = async ({ unidadeId, callback, whitelabelName }) => {
    try {
      ViziAPI.defaults.headers['whitelabel'] = whitelabelName;
      const response = await ViziAPI.post("", {
        query: SET_DADOS_IUGU_BY_PROFISSIONAIS_SAUDE,
        variables: {
          unidade: {
            id: unidadeId
          },
          perfisPublicos: selectedPerfis.map((id) => ({ id }))
        }
      });

      if (response.data.data.setDadosIuguByProfissionaisSaude) {
        await loadPerfisUnidade({ unidadeId, reset: true });
        callback({ status: 'success', message: 'Configurações de recibimentos salvas nos perfis.' });
      }
    } catch(error) {
      console.error(error);
      callback({ status: 'error', message: 'Falha ao salvar as configurações.' });
    }
  };

  const values = {
    loadPerfisUnidade,
    saveConfig,
    resetPaginationSettings,
    selectedPerfis,
    setSelectedPerfis,
    loadedPerfis,
    lastPage,
  };

  return (
    <ConfigReplicaContext.Provider value={values}>
      {children}
    </ConfigReplicaContext.Provider>
  );
};

export const useConfigReplica = () => {
  return useContext(ConfigReplicaContext);
};
