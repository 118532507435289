import string from '../utils/string';

export const TiposCobranca = {
  MENSAL: 'MENSAL',
  FIDELIDADE: 'FIDELIDADE',
  ANUAL: 'ANUAL',
}

export const getTiposCobrancaList = () => {
  return Object.keys(TiposCobranca).map(value => ({
    value,
    name: string.capitalize(value)
  }));
}

