import React from "react";
import { TextField as TextFieldDefault } from "@material-ui/core";
import { InputMask } from "../../utils/masks";
import { withStyles } from '@material-ui/core/styles';

const styleInputSearch = theme => ({
  root: {
    width: '100%',
  },
  inputRoot: {
    border: theme.commons.inputBorder,
    borderRadius: theme.commons.inputBorderRadius,
    padding: "0 10px",
    fontSize: 16,
    background: theme.commons.inputBackgroundColor,
  },
  input: {
    padding: '7px 0',
    color: '#000000',
  },
  inputFocused: {
    borderColor: '#000000',
    color: '#000000',
    boxShadow: 'none',
    outline: 'none',
  },
  inputDisabled: {
    opacity: 0.5
  }
});



const TextField = ({classes, classInputRoot, classInput, startAdornment, endAdornment,  ...props}) => {

  const defaultProps = {
    disableUnderline: true,
    classes: {
      root: `${classes.inputRoot} ${classInputRoot}`,
      input: `${classes.input} ${classInput}`,
      focused: classes.inputFocused,
      disabled: classes.inputDisabled
    },
  }

  return (
    <TextFieldDefault
      {...props}
      id="input-with-icon-textfield"

      fullWidth
      className={classes.root}
      InputProps={props.mask ? {
        ...props.InputProps,
        ...defaultProps,
        inputProps: {
          mask: props.mask,
        },
        inputComponent: InputMask,
      } : {
        ...props.InputProps,
        ...defaultProps,
        inputProps: {
          maxLength: props.maxLength,
        }
      }}
      InputLabelProps={{
        shrink: true,
      }}
    />
  );
};

export default withStyles(styleInputSearch)(
  React.forwardRef((props, ref) => {
    return <TextField {...props} inputRef={ref} />;
  })
);
