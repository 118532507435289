import React from 'react';
import List from '../../../../../../components/list/list';

import DownloadIcon from '@material-ui/icons/ArrowDownwardOutlined';
import { buildUrlDownload } from '../../config/buildUrlDownload';

const BraspagAnexos = ({ dadosBraspagProfissional }) => {
  const downloadActionButton = (action) => (
    <button
      onClick={action}
      style={{
        cursor: 'pointer',
        border: 'none',
        background: 'none',
        outline: 'none',
      }}
    >
      <DownloadIcon fontSize="small" />
    </button>
  );

  const formatSize = (size) => {
    if (!size) return '00.00 B';

    if (size < 1024) {
      return `${size} B`;
    } else if (size < 1024 * 1024) {
      return `${(size / 1024).toFixed(2)} KB`;
    } else if (size < 1024 * 1024 * 1024) {
      return `${(size / 1024 / 1024).toFixed(2)} MB`;
    } else {
      return `${(size / 1024 / 1024 / 1024).toFixed(2)} GB`;
    }
  };

  const downloadImage = async (nomeDownload, urlImagem) => {
    const image = await fetch(urlImagem);
    const imageBlog = await image.blob();
    const imageURL = URL.createObjectURL(imageBlog);

    const link = document.createElement('a');
    link.href = imageURL;
    link.download = nomeDownload;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <List
      columns={[
        {
          title: 'NOME DO ARQUIVO',
          value: (item) => item?.amazonS3Objeto?.nome,
        },
        {
          title: 'TIPO',
          value: (item) => `.${item?.amazonS3Objeto?.extensao}`,
        },
        {
          title: 'TAMANHO',
          value: (item) => formatSize(item?.amazonS3Objeto?.tamanho),
        },
        {
          title: '',
          value: (item) =>
            downloadActionButton(() => {
              const accessToken = window.localStorage.getItem('token');
              const urlDownload = buildUrlDownload(
                item?.urlDownload,
                accessToken
              );
              if (item?.arquivoTipo === 'IMAGEM') {
                downloadImage(item?.amazonS3Objeto?.nome, urlDownload);
              } else {
                window.open(urlDownload, '_blank');
              }
            }),
        },
      ]}
      items={dadosBraspagProfissional}
      loading={false}
      last={true}
    />
  );
};

export default BraspagAnexos;
