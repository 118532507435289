import React from 'react';
import styled from '@emotion/styled';

export const InformacaoAccordion = ({ title, value }) => {

  return (
    <Content>
      <Title>{title}</Title>
      <Value>{value}</Value>
    </Content>
  );
};

const Content = styled.div`
  display: flex;
  flex-direction: column;
`;

const Title = styled.label`
  color: #868686;
  font-weight: 600;
  font-size: 14px;
`;

const Value = styled.label`
  color: #000;
  font-weight: 500;
  font-size: 16px;
`;
