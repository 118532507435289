import styled from '@emotion/styled';

import Box from '../box';
import Colors from '../../theme/Colors';

const Button = styled(Box)`
  border: 2px solid rgba(220, 220, 220, 0.2);
  border-radius:100px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px 24px;
  gap: 8px;

  height: 40px;

  background-color: ${Colors.primary.dark};
  color: #fff;
  font-weight: 600;
  font-size: 14px;

  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }

  &:disabled {
    opacity: 0.5;
  }

  transition: all 0.2s ease-in-out;
`;

Button.defaultProps = {
	as: "button"
};

export default Button;
