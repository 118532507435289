import React, { useEffect } from 'react';
import styled from '@emotion/styled';

import Menu from './components/menu';
import Header, { HeaderProfile, HeaderTitle } from './components/header';
import Colors from '../theme/Colors';

import logoMenu from '../assets/images/logoMenu.png';
import MenuIcon from '@material-ui/icons/Menu';

const BaseLayoutContext = React.createContext();

const BaseLayout = ({ children, hasMenu = true, hasSubMenu }) => {
  const menuRef = React.useRef();

  const handleOpenMenu = () => {
    const menuIsOpen = menuRef.current.dataset.open === 'true';
    if (menuIsOpen) {
      return (menuRef.current.dataset.open = 'false');
    }
    return (menuRef.current.dataset.open = 'true');
  };

  const handleClickOutside = (event) => {
    const targetName = event.target.getAttribute?.('name');
    const isCloseMenu = targetName === 'close-menu';
    if (
      menuRef.current &&
      !menuRef.current.contains(event.target) &&
      !isCloseMenu
    ) {
      menuRef.current.dataset.open = 'false';
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);

  return (
    <BaseLayoutContext.Provider value={{ handleOpenMenu, hasSubMenu }}>
      <Container>
        <MenuContainer ref={menuRef} data-open={false}>
          <Menu hasMenu={hasMenu} />
        </MenuContainer>
        <ContentContainer>{children}</ContentContainer>
      </Container>
    </BaseLayoutContext.Provider>
  );
};

export const BaseLayoutHeader = ({ children }) => {
  const { handleOpenMenu } = React.useContext(BaseLayoutContext);

  return (
    <HeaderContainer>
      <MobileHeader>
        <Logo src={logoMenu} alt="logo" />
        <MenuIcon name="close-menu" onClick={handleOpenMenu} />
      </MobileHeader>
      {children}
    </HeaderContainer>
  );
};

export const BaseLayoutContent = ({ children }) => {
  const { hasSubMenu } = React.useContext(BaseLayoutContext);

  return <Content hasSubMenu={!!hasSubMenu}>{children}</Content>;
};

const Logo = styled.img`
  width: 40px;
  height: 40px;
`;

const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100%;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;
const MenuContainer = styled.div`
  width: 80px;
  min-width: 80px;
  height: 100vh;
  background-color: #00aca9;
  box-sizing: border-box;
  flex-direction: column;
  display: flex;

  position: sticky;
  top: 0;
  left: 0;

  @media (max-width: 768px) {
    &[data-open='false'] {
      left: -80px;
    }

    position: absolute;
    z-index: 20;
    transition: left 0.2s ease-in-out;
  }
`;

const MobileHeader = styled.div`
  background-color: ${Colors.primary.main};
  padding-inline: 16px;
  padding-block: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  color: white;

  @media (min-width: 768px) {
    display: none;
  }
`;

const ContentContainer = styled.div`
  width: 100%;
  overflow-y: auto;

  @media (max-width: 768px) {
    z-index: 10;
  }
`;
const HeaderContainer = styled.div`
  width: 100%;

  position: sticky;

  top: 0;
  background: white;
  z-index: 999;
`;
const Content = styled.div`
  width: 100%;
  box-sizing: border-box;
  padding: ${({ hasSubMenu }) => (hasSubMenu ? '0px' : '0 3% 1.9% 2.3%')};
  overflow: auto;
  background-color: #f5f5f5;
  height: calc(100% - 80px);
`;

export default BaseLayout;

export const withBaseLayout = (Component, baseLayoutProps) => {
  return (props) => (
    <BaseLayout {...baseLayoutProps}>
      <BaseLayoutHeader>
        <Header>
          <HeaderTitle />
          <HeaderProfile />
        </Header>
      </BaseLayoutHeader>
      <BaseLayoutContent>
        <Component {...props} />
      </BaseLayoutContent>
    </BaseLayout>
  );
};
