export const VendaStatus = {
    ABERTA: 'ABERTA',
    ATIVA: 'ATIVA',
    EM_PROCESSAMENTO: 'EM_PROCESSAMENTO',
    CANCELADA: 'FINALIZADA',
    PAGAMENTO_RECUSADO: 'PAGAMENTO_RECUSADO',
    CADASTRO_PARCIAL: 'CADASTRO_PARCIAL',
    PERIODO_GRATIS : 'PERIODO_GRATIS'
};

export const getVendaStatusList = () => {
    return Object.keys(VendaStatus).map(value => ({
        value,
        name: value
    }));
};
