import React from 'react';
import { SvgIcon } from '@material-ui/core';
export const LockIcon = (props) => (
  <SvgIcon {...props} viewBox="0 0 16 16">
    <path
      d="M6 0C7.65685 0 9 1.34315 9 3V4H10C11.1046 4 12 4.89543 12 6V13C12 14.1046 11.1046 15 10 15H2C0.89543 15 0 14.1046 0 13V6C0 4.89543 0.89543 4 2 4H3V3C3 1.34315 4.34315 0 6 0ZM6 8.5C5.44772 8.5 5 8.94772 5 9.5C5 10.0523 5.44772 10.5 6 10.5C6.55228 10.5 7 10.0523 7 9.5C7 8.94772 6.55228 8.5 6 8.5ZM6 2C5.44772 2 5 2.44772 5 3V4H7V3C7 2.44772 6.55228 2 6 2Z"
      fill="currentColor"
    />
  </SvgIcon>
);
