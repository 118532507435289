import Colors from '../../../../theme/Colors';

export const ModalDetalhesProfissionalStyles = () => ({
  container: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    height: '60%',
    width: '60%',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#FFFFFF',
    borderRadius: '10px',
    padding: '20px',
    overflow: 'hidden',
  },
  header: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: '16px 0px',
  },
  headerTitle: {
    fontSize: '18px !important',
    fontWeight: 'bold',
    color: '#969696',
  },
  gridContainer: {
    height: 'calc(100% - 100px)',
    width: '100%',
    overflowY: 'auto',
    marginTop: '10px',
    padding: '10px',
  },
  content: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, minmax(200px, 1fr))',
    width: '100%',
    gridGap: '20px',
    overflowY: 'scroll',
  },
  contentSecondaryTab: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, minmax(100%, 1fr))',
    width: '100%',
    gridGap: '20px',
    overflowY: 'scroll',
  },
  closeIcon: {
    color: 'white',
    backgroundColor: Colors.primary.main,
    fontSize: '12px',
    minWidth: 25,
    minHeight: 25,
    width: 25,
    height: 25,
  },
});
