import React, { useState } from 'react';
import DadosBasicosContratante from './dadosBasicosContratante';
import DadosEnderecoContratante from './dadosEnderecoContratante';
import Grid from '@material-ui/core/Grid';
import styled from '@emotion/styled';
import Colors from '../../../../theme/Colors';
import MobileStepper from '@material-ui/core/MobileStepper';
import DadosResponsavelContratante from './dadosResponsavelContratante';
import DadosEspecialidadesContratante from './dadosEspecialidadesContratante';
import string from '../../../../utils/string';
import Plano from './plano';
import AdicionarProfissionais from './adicionarProfissionais';
import Modulos from './modulos';
import Resumo from '../resumo/resumo';
import { TiposCobranca } from '../../../../enum/TiposCobranca';

const StepperCriarVenda = ({
  dados,
  handleAtualizarDados,
  handleFinalizarVenda,
  loadingSave,
  setPassoAtual,
  passoAtual,
}) => {
  const [dadosInvalidos, setDadosInvalidos] = useState(true);
  const [tipoCobranca, setTipoCobranca] = useState(TiposCobranca.MENSAL);
  const [secretariaType, setSecretariaType] = useState(null)


  const [formDataErrors, setFormDataErrors] = useState({
    responsavelNome: false,
    responsavelCargo: false,
    responsavelCpf: false,
    nomeFantasia: false,
    razaoSocial: false,
    cnpj: false,
    telefone: false,
    email: false,
    cep: false,
    nomeLogradouro: false,
    numero: false,
    bairro: false,
    municipio: false,
    estado: false,
    tempoFuncionamentoEmpresa: false,
    numeroPacientes: false,
    haveraMigracaoSistema: false,
    sistemaASerMigrado: false,
    modulos: false,
  });

  const atualizarDadosErrors = (dadosAtualizadosErrors) => {
    setFormDataErrors({
      ...formDataErrors,
      ...dadosAtualizadosErrors,
    });
  };

  let proximoPasso = () => {
    if (
      passoAtual === 1 &&
      string.validaTelefoneDDI(dados.telefone, dados.telefoneDDI)
    ) {
      handleAtualizarDados({
        telefone: string
          .removeSpecialChars(dados.telefone)
          .substr(dados.telefoneDDI.length),
      });
    }
    setPassoAtual((prevPassoAtual) => prevPassoAtual + 1);
  };

  let voltar = () => {
    setPassoAtual((prevPassoAtual) => prevPassoAtual - 1);
  };

  const renderButton = (label, handleFunction, disabled) => (
    <Button
      type="button"
      disabled={disabled || loadingSave}
      onClick={handleFunction}
    >
      {label}
    </Button>
  );

  const verificaDadosInvalidos = (isDadosValidos) => {
    setDadosInvalidos(isDadosValidos);
  };
  const handleSecretariaType = (type) => {
    setSecretariaType(type)
  }
  const renderButtonFinalizar = () => {
    const button = passoAtual === 7 ? {
      label: 'Finalizar',
      disabled: dadosInvalidos,
    } : {
      label: 'Finalizado',
      disabled: true,
    }

    return renderButton(button.label, handleFinalizarVenda, button.disabled)
  }

  return (
    <FlexGrid container id="stepp">
      <Passos item>
        {passoAtual === 0 && (
          <Plano
            dados={dados}
            handleAtualizarDados={handleAtualizarDados}
            handleDadosInvalidos={verificaDadosInvalidos}
            setTipoCobranca={setTipoCobranca}
            tipoCobranca={tipoCobranca}
          />
        )}
        {passoAtual === 1 && (
          <DadosResponsavelContratante
            dados={dados}
            dadosErrors={formDataErrors}
            handleAtualizarDadosErrors={atualizarDadosErrors}
            handleAtualizarDados={handleAtualizarDados}
            handleDadosInvalidos={verificaDadosInvalidos}
            disabled={false}
          />
        )}
        {passoAtual === 2 && (
          <DadosBasicosContratante
            dados={dados}
            dadosErrors={formDataErrors}
            handleAtualizarDadosErrors={atualizarDadosErrors}
            handleAtualizarDados={handleAtualizarDados}
            handleDadosInvalidos={verificaDadosInvalidos}
            disabled={false}
          />
        )}
        {passoAtual === 3 && (
          <DadosEnderecoContratante
            dados={dados}
            dadosErrors={formDataErrors}
            handleAtualizarDadosErrors={atualizarDadosErrors}
            handleAtualizarDados={handleAtualizarDados}
            handleDadosInvalidos={verificaDadosInvalidos}
            disabled={false}
          />
        )}
        {passoAtual === 4 && (
          <DadosEspecialidadesContratante
            dados={dados}
            handleAtualizarDados={handleAtualizarDados}
            handleDadosInvalidos={verificaDadosInvalidos}
            disabled={false}
          />
        )}
        {passoAtual === 5 && (
          <AdicionarProfissionais
            dados={dados}
            handleAtualizarDados={handleAtualizarDados}
            handleDadosInvalidos={verificaDadosInvalidos}
          />
        )}
        {passoAtual === 6 && (
          <Modulos
            dados={dados}
            handleAtualizarDados={handleAtualizarDados}
            handleDadosInvalidos={verificaDadosInvalidos}
            selectedSecretaria={secretariaType}
            handleSelectSecretaria={handleSecretariaType}
          />
        )}
        {passoAtual === 7 && (
          <Resumo
            dados={dados}
            setPassoAtual={setPassoAtual}
            handleAtualizarDados={handleAtualizarDados}
          />
        )}
      </Passos>

      <ButtonsContainer>
        <Guia
          variant="progress"
          steps={8}
          activeStep={passoAtual}
          position={'bottom'}
          backButton={renderButton('Voltar', voltar, passoAtual - 1 < 0)}
          nextButton={
            passoAtual < 7
              ? renderButton('Próximo', proximoPasso, dadosInvalidos)
              : renderButtonFinalizar()
          }
        />
      </ButtonsContainer>
    </FlexGrid>
  );
};

export default StepperCriarVenda;

const FlexGrid = styled(Grid)`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  height: calc(100vh - 160px);
`;

const Passos = styled(Grid)`
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;
  width: 100%;
  margin: 0 !important;
`;

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
`;

const Button = styled.button`
  border: 0;
  background-color: ${Colors.primary.main};
  color: #fff;
  padding: 5px 10px;
  box-sizing: border-box;
  height: 34px;
  border-radius: 6px;
  margin: 8px 0;
  cursor: pointer;
  &:disabled {
    background-color: ${Colors.commons.gray};
  }
`;

const Guia = styled(MobileStepper)`
  width: 600;
  flex-grow: 1;
  align-self: center;
  justify-content: center;
`;
