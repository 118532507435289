import { ViziAPI } from '../apollo/setupViziClient';
import { FIND_OR_CREATE_CONFIGURACAO_WHITELABEL_BY_WHITELABEL } from '../graphql/queryes';

export const findOrCreateConfiguracaoWhitelabelByWhitelabel = async (whitelabelId) => {
  const { data } = await ViziAPI.post("", {
    query: FIND_OR_CREATE_CONFIGURACAO_WHITELABEL_BY_WHITELABEL,
    variables: {
      whitelabel: {
        id: whitelabelId
      }
    }
  });

  if (data?.errors) {
    throw new Error(data.errors[0].message);
  }

  return data.data.findOrCreateConfiguracaoWhitelabelByWhitelabel;
}