import React, { useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { useLazyQuery, useApolloClient } from 'react-apollo';
import TextFieldSearch from '../../../../components/TextField/TextFieldSearch';
import { TextField } from '../../../../components/TextField';
import { TIPOS_DOCUMENTO, FIND_ALL_PAIS } from '../../../../graphql/queryes';
import RenderTextField from '../../../../components/TextField/RenderTextField';
import string from '../../../../utils/string';
import validaDocumentoEspanha from '../../../../utils/validaDocumentoEspanhol';
import {
    FormGroup,
    SelectSearchStyled
} from './styles'

const FormDadosContratante = (props) => {
  const { setFormData, formData, handleDadosInvalidos } = props;

  const client = useApolloClient();
  const [getTiposDocumento, { data: tiposDocumento }] =
    useLazyQuery(TIPOS_DOCUMENTO);

  useEffect(() => {
    getTiposDocumento({
      variables: {
        pageableDTO: {
          sortField: 'descricao',
          sortDir: 'ASC',
        },
      },
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const isCamposValidos =
      !formData.paisAtuacao ||
      !formData.responsavelNome ||
      validaDocumento() ||
      (formData.paisAtuacao?.label === 'ESPANHA' && !formData.documentoTipo) ||
      !formData.responsavelCargo ||
      !formData.responsavelDocumento;

    handleDadosInvalidos(isCamposValidos);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    handleDadosInvalidos,
    formData.paisAtuacao,
    formData.responsavelNome,
    formData.documentoTipo,
    formData.responsavelCargo,
    formData.responsavelDocumento,
  ]);

  const handleChangeDocumentoTipo = (value) => {
    setFormData({
      ...formData,
      documentoTipo: value,
      responsavelDocumento: '',
    });
  };

  const handleChangeDocumento = (e) => {
    setFormData({
      ...formData,
      responsavelDocumento: e,
    });
  };

  const verificaMask = () => {
    if (formData.paisAtuacao?.label === 'ESPANHA') {
      if (formData?.documentoTipo) {
        return formData?.documentoTipo?.descricao?.toLowerCase();
      }
    }

    return 'cpf';
  };

  const validaDocumento = () => {
    if (formData.paisAtuacao?.label === 'ESPANHA') {
      if (formData.documentoTipo) {
        return formData.documentoTipo?.label === 'CPF'
          ? !string.validaCPF(formData.responsavelDocumento)
          : !validaDocumentoEspanha(formData.responsavelDocumento);
      }
    }

    return !string.validaCPF(formData.responsavelDocumento);
  };

  const handleChangeCampos = (e, field) => {
    setFormData({
      ...formData,
      [field]: e.target.value,
    });
  };

  const handlePaisLoadOptions = async (search, loadedOptions, { page }) => {
    return handleLoadMoreOptions({
      loadedOptions,
      variables: {
        searchDTO: {
          search,
        },
        pageableDTO: {
          pageSize: 10,
          pageNumber: page,
          sortDir: 'ASC',
          sortField: 'descricao',
        },
      },
      responseObject: 'findAllPais',
      query: FIND_ALL_PAIS,
    });
  };

  const handleLoadMoreOptions = async ({
    variables,
    query,
    responseObject,
  }) => {
    const response = await client.query({
      query,
      variables,
    });

    const pageNumber = variables?.searchDTO?.pageNumber || 0;

    let options = response?.data?.[responseObject]?.content || [];

    return {
      options,
      hasMore: !response?.data?.[responseObject]?.last,
      additional: {
        page: pageNumber + 1,
      },
    };
  };

  return (
    <Grid container direction="row" spacing={2}>
      <FormGroup item xs={12}>
        <Typography color="black" component="label">
          País
        </Typography>
        <TextFieldSearch
          placeholder=""
          loadOptions={handlePaisLoadOptions}
          withPaginate
          value={formData.paisAtuacao}
          onChange={(e) =>
            setFormData({
              ...formData,
              paisAtuacao: e,
            })
          }
          debounceTimeout={300}
          additional={{
            page: 0,
          }}
        />
      </FormGroup>
      <FormGroup item xs={12}>
        <Typography color="black" component="label">
          CNPJ
        </Typography>
        <TextField
          value={formData.cnpj}
          onChange={(e) => handleChangeCampos(e, 'cnpj')}
        />
      </FormGroup>
      <FormGroup item xs={12}>
        <Typography color="black" component="label">
          Nome do Responsável *
        </Typography>
        <TextField
          value={formData.responsavelNome}
          onChange={(e) =>
            setFormData({
              ...formData,
              responsavelNome: e.target.value,
            })
          }
          error={!formData.responsavelNome}
          helperText={!formData.responsavelNome && 'Campo obrigatório'}
        />
      </FormGroup>
      {formData.paisAtuacao?.label === 'ESPANHA' && (
        <FormGroup item xs={12}>
          <Typography color="black" component="label">
            Tipo documento *
          </Typography>
          <SelectSearchStyled
            placeholder=""
            value={formData.documentoTipo}
            elements={tiposDocumento?.findAllDocumentoTipo || []}
            onChange={handleChangeDocumentoTipo}
          />
        </FormGroup>
      )}
      <FormGroup item xs={12}>
        <RenderTextField
          label={'Documento *'}
          field={formData.responsavelDocumento}
          error={validaDocumento()}
          errorText={'Documento inválido.'}
          mask={verificaMask()}
          handleChange={(e) => handleChangeDocumento(e.target.value)}
        />
      </FormGroup>
      <FormGroup item xs={12}>
        <Typography color="black" component="label">
          Cargo
        </Typography>
        <TextField
          value={formData.responsavelCargo}
          onChange={(e) => handleChangeCampos(e, 'responsavelCargo')}
          error={!formData.responsavelCargo}
          helperText={!formData.responsavelCargo && 'Campo obrigatório'}
        />
      </FormGroup>
    </Grid>
  );
};

export default FormDadosContratante;


