import React from 'react';
import { SobrePlano } from './sobrePlano';
import Plano from '../../../list-vendas/components/criar-venda/plano';
import Modulos from '../../../list-vendas/components/criar-venda/modulos';

export const stepsSobrePlano = ({
  setStepSelectedSobrePlano,
  venda,
  handleSelectPlano,
  handleSelectModulos,
  loadingModulos,
  setTipoCobranca,
  tipoCobranca,
  handleSecretariaType,
  secretariaType
}) => {

  return ([
    {
      title: 'Sobre o plano',
      content: (
        <SobrePlano
          setStepSelectedSobrePlano={setStepSelectedSobrePlano}
          venda={venda}
          loadingModulos={loadingModulos}
        />
      ),
    },
    {
      title: 'Adicionais',
      content: <Modulos dados={venda} handleAtualizarDados={handleSelectModulos} handleSelectSecretaria={handleSecretariaType} selectedSecretaria={secretariaType} />,
    },
    {
      title: 'Alterar plano',
      content: <Plano
        dados={venda}
        handleAtualizarDados={handleSelectPlano}
        setTipoCobranca={setTipoCobranca}
        tipoCobranca={tipoCobranca}
      />
    },
  ]);
}
export const titleStep = [
  "Sobre o plano",
  "Adicionais",
  "Alterar plano"
];
