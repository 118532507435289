import React, {useState} from 'react';
import Grid from "@material-ui/core/Grid";
import InputSearch from '../../../components/input/Input'
import {getVendaStatusList} from '../../../enum/VendaStatus';
import {useApolloClient, useQuery} from 'react-apollo';
import {DROPDOWN_ENTIDADES, DROPDOWN_MUNICIPIOS} from '../../../graphql/queryes';
import {SelectSearch} from '../../../components/select/SelectSearch';
import styled from '@emotion/styled';
import InputDateForm from '../../../components/input/InputDateForm';
import { TextFieldSearch } from '../../../components/TextField';

const Search = ({onDataSearch, showSelectEntidade, searchDTO}) => {
  const [vendaStatusSelected, setVendaStatusSelected] = useState();
  const [entidadeSelected, setEntidadeSelected] = useState();
  const [typingTimeout, setTypingTimeout] = useState();
  const [searchText, setSearchText] = useState('');
  const [municipioSelected, setMunicipioSelected] = useState('');

  const client = useApolloClient();

  const {data: dropdownEntidadesData} = useQuery(DROPDOWN_ENTIDADES, {
    variables: {
      searchDTO: {},
      pageableDTO: {
        pageSize: 50,
        pageNumber: 0
      }
    }
  });

  const handleChangeInput = (field, e) => {
    if (field === 'vendaStatus') {
      let value = e?.value;
      
      if(e) {
        value = e.value === "CANCELADA" ? "FINALIZADA" : e.value;
      }
      setVendaStatusSelected(e);
      onDataSearch({
        vendaStatus: e && e.value ? value : undefined,
      });
    }

    if (field === 'entidade') {
      setEntidadeSelected(e);
      onDataSearch({
        entidade: e && e.value ? {id: e.value, tipo: e.tipo || 'FISICA'} : undefined,
      });
    }

    if (field === 'dataVencimento') {
      onDataSearch({
        dataVencimento: e.format("YYYY-MM-DD") || undefined,
      });
    }
  }

  const handleSearchTextChange = (e) => {
    const text = e.target.value;
    setSearchText(text);

    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }

    setTypingTimeout(setTimeout(() => {
      onDataSearch({
        search: text,
      });
    }, 500));
  }

  const handleMunicipiosLoadOptions = async (search, loadedOptions, {page}) => {
    return handleLoadMoreOptions({
      loadedOptions,
      variables: {
        searchDTO: {
          pageSize: 10,
          pageNumber: page,
          search
        }
      },
      responseObject: 'findAllMunicipio',
      query: DROPDOWN_MUNICIPIOS
    });
  };

  const handleLoadMoreOptions = async ({variables, query, responseObject}) => {
    const response = await client.query({
      query,
      variables
    });

    const pageNumber = variables?.searchDTO?.pageNumber || 0;

    let options = response?.data?.[responseObject]?.content || [];

    if (responseObject === 'findAllMunicipio') {
      options = options.map(o => ({
        ...o,
        label: `${o.label} - ${o.uf}`,
      }));
    }

    return {
      options,
      hasMore: !response?.data?.[responseObject]?.last,
      additional: {
        page: pageNumber + 1
      }
    };
  };

  const handleChangeMunicipioField = (municipio) => {
    setMunicipioSelected(municipio);
    onDataSearch({
      municipio: { id: municipio?.value }
    });
  }

  const entidades = dropdownEntidadesData?.findAllEntidadeByOauthClient?.content || [];

  return (
    <SearchContainer>
    <Grid container spacing={2} >
      <Grid item xs={2}>
        <SelectSearchStyled
          placeholder="Status da Venda"
          value={vendaStatusSelected}
          elements={[...getVendaStatusList()]}
          onChange={(e) => handleChangeInput('vendaStatus', e)}
        />
      </Grid>
      {showSelectEntidade && <Grid item xs={2}>
        <SelectSearchStyled
          placeholder="Entidade"
          value={entidadeSelected}
          elements={entidades}
          onChange={(e) => handleChangeInput('entidade', e)}
        />
      </Grid>}
      <Grid item xs={2}>
      <TextFieldSearch 
        placeholder="Município"
        loadOptions={handleMunicipiosLoadOptions}
        withPaginate
        value={municipioSelected}
        onChange={e => handleChangeMunicipioField(e)}
        debounceTimeout={300}
        additional={{
          page: 0
        }}
      />
      </Grid>
      <Grid item xs={4}>
        <InputSearch onChange={handleSearchTextChange} value={searchText} placeholder="Pesquisar"/>
      </Grid>
      <Grid item xs={2}>
        <InputDateForm
          placeholder="Data vencimento"
          variant="outlined"
          fullWidth
          value={searchDTO?.dataVencimento || ""}
          onChange={(e) => handleChangeInput('dataVencimento', e)}
        />
      </Grid>
    </Grid>
    </SearchContainer>
  );
};

const  SelectSearchStyled = styled(SelectSearch)`
	width: 100%;
`;

const SearchContainer = styled.div`
  background-color: #fff;
  border-radius: 16px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 16px;

  & > div {
    @media (max-width: 768px) {
      flex-direction: column;

      & > div {
        max-width: 100%;
      }
    }
  }
`

export default Search;
