import Axios from "axios";
import {axiosAuthPublic} from '../services/axios';

const customFetch = (token, onLogout) => async (uri, options) => {

  var customHeaders = {
    "accept": "*/*",
    "content-type": "application/json",
    authorization: `Bearer ${token}`
  };

  const finalResult = (data) => {
    var result = {};
    result.ok = true;
    result.json = () => new Promise(function (resolve) {
      resolve(data);
    });
    result.text = () => new Promise(function (resolve) {
      resolve(JSON.stringify(data));
    });
    return result
  };

  const checkToken = async () => {
    try {
      await axiosAuthPublic.post(`/oauth/check_token?token=${token}`);
    } catch (error) {
      if (error.response.data.error === 'invalid_token') {
        onLogout();
      }

    }
  }

  window.numberRefresh = 0;

  const fetchAxios = (headers, refetch) => {
    return Axios.post(uri, options.body, {headers})
      .then(({data}) => {
        if (refetch && window.numberRefresh === 0) {
          return fetchAxios(headers)
        }
        setTimeout(() => {
          window.numberRefresh = 0;
        }, 2000);

        return finalResult(data)
      }).catch(err => {
        if (err.response && err.response.status === 401) {
          checkToken()
        }
      })

  };

  return fetchAxios(customHeaders);
};

export default customFetch;
