import React from 'react';
import { SvgIcon } from '@material-ui/core';
export const AddSquareIcon = (props) => (
  <SvgIcon {...props} viewBox="0 0 15 14">
    <path
      d="M2.75 0C1.64543 0 0.75 0.89543 0.75 2V12C0.75 13.1046 1.64543 14 2.75 14H12.75C13.8546 14 14.75 13.1046 14.75 12V2C14.75 0.895431 13.8546 0 12.75 0H2.75ZM8.25018 3.50018V6.50018H11.2502C11.5263 6.50018 11.7502 6.72404 11.7502 7.00018C11.7502 7.27633 11.5263 7.50018 11.2502 7.50018H8.25018V10.5002C8.25018 10.7763 8.02633 11.0002 7.75018 11.0002C7.47404 11.0002 7.25018 10.7763 7.25018 10.5002V7.50018H4.25018C3.97404 7.50018 3.75018 7.27633 3.75018 7.00018C3.75018 6.72404 3.97404 6.50018 4.25018 6.50018H7.25018V3.50018C7.25018 3.22404 7.47404 3.00018 7.75018 3.00018C8.02633 3.00018 8.25018 3.22404 8.25018 3.50018Z"
      fill="currentColor"
    />
  </SvgIcon>
);
