import React, { useState } from 'react';
import { useMutation } from 'react-apollo';
import { ALTERAR_DADOS_EMPRESARIAL } from '../../../graphql/mutations';
import string from '../../../utils/string';
import FormDadosUnidade from './editar-contratante/FormDadosUnidade';
import { Button, ContainerButton } from './editar-contratante/styles';

const EditarDadosEmpresarial = ({ venda, onClose }) => {
  const [formData, setFormData] = useState({
    razaoSocial: venda.contratante?.razaoSocial,
    nomeFantasia: venda.contratante?.nomeFantasia,
    inscricaoEstadual: venda.contratante?.inscricaoEstadual,
    telefone: `${venda.contratante?.telefoneDDI}${venda.contratante?.telefone}`,
    telefoneDDI: venda.contratante?.telefoneDDI,
    codigoCnes: venda.contratante?.codigoCnes,
    email: venda.contratante?.email,
  });
  const [loadingSave, setLoadingSave] = useState(false);
  const [dadosInvalidos, setDadosInvalidos] = useState(false);

  const [updateDadosEmpresarial] = useMutation(ALTERAR_DADOS_EMPRESARIAL);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoadingSave(true);

    await updateDadosEmpresarial({
      variables: {
        venda: {
          id: venda.id,
        },
        telefone: string
          .removeSpecialChars(formData.telefone)
          .substr(formData.telefoneDDI.length),
        codigoCnes: formData.codigoCnes,
        inscricaoEstadual: formData.inscricaoEstadual,
        nomeFantasia: formData.nomeFantasia,
        razaoSocial: formData.razaoSocial,
        email: formData.email,
      },
    });

    setLoadingSave(false);
    onClose()
  };

  const verificaDadosInvalidos = (isDadosValidos) => {
    setDadosInvalidos(isDadosValidos);
  };

  return (
    <>
      <FormDadosUnidade
        setFormData={setFormData}
        formData={formData}
        handleDadosInvalidos={verificaDadosInvalidos}
      />

      <ContainerButton>
        <Button
          type="button"
          disabled={dadosInvalidos || loadingSave}
          onClick={handleSubmit}
        >
          Salvar
        </Button>
      </ContainerButton>
    </>
  );
};

export default EditarDadosEmpresarial;
