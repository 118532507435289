import React from 'react';
import styled from '@emotion/styled';
import { Switch } from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';

export const SwitchLabel = ({ value, onChange, title, disabled }) => {
  return (
    <FormControlLabelStyled
      control={<Switch disabled={disabled} onChange={onChange} checked={value} color="primary" />}
      label={title}
      labelPlacement="start"
    />
  );
};

const FormControlLabelStyled = styled(FormControlLabel)`
  display: flex;
  margin-left: 0px !important;
  justify-content: space-between;
  width: 100%;
`;
