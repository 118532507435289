import React from 'react';
import Fab from '@material-ui/core/Fab';
import Grid from '@material-ui/core/Grid';
import CloseIcon from '@material-ui/icons/Close';
import { withStyles } from "@material-ui/core/styles/index";

const styles = (theme) => ({
	root: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between'
	},
	title: {
		fontSize: '24px !important',
		color: theme.palette.primary.main,
		fontWeight: 'bold'
	},
	btnCloseMini: {
		minHeight: 26,
		height: 26,
		width: 26,
		boxShadow: 'none'
	}
});

const DialogHeader = (props) => {
	const { classes, title, closeButton, actionClose } = props;

	return (
		<Grid className={classes.root} item xs={12} container wrap={"nowrap"}>

			<span className={classes.title}>
				{title}
			</span>

			{closeButton ?
				<Fab
					onClick={actionClose}
					classes={{
						sizeSmall: classes.btnCloseMini,
						extendedFab: classes.extendedFab
					}} size="small" color="primary" aria-label="close">
					<CloseIcon />
				</Fab>
				:
				<div />
			}
		</Grid>
	);

};

export default withStyles(styles)(DialogHeader)
