import React from 'react';
import PhoneInput from 'react-phone-input-2';
import pt from 'react-phone-input-2/lang/pt.json';
import 'react-phone-input-2/lib/style.css';
import { Typography, withStyles } from '@material-ui/core';

const styles = () => ({
  telefoneInputContainer: {
    display: 'flex',
    flexDirection: 'row',
    columnGap: '4px !important',
  },
  telefoneInput: {
    width: '100% !important',
    border: '1px solid #969696 !important',
    height: '35px !important',
    color: '#969696',
    fontSize: '16px !important',
  },
});

const InputPhoneDdi = ({
  classes,
  telefone,
  onTelefonePrincipalComDDIChange,
  error,
  disabled,
  label
}) => {
  return (
    <>
      <Typography color="black" component="label">
        {label}
      </Typography>
      <PhoneInput
        value={telefone}
        onChange={(phone, data) => onTelefonePrincipalComDDIChange(phone, data)}
        placeholder=""
        countryCodeEditable={false}
        preferredCountries={['br', 'es']}
        localization={pt}
        masks={{ br: '(..) .....-....' }}
        containerClass={classes.telefoneInputContainer}
        inputClass={classes.telefoneInput}
        error={error}
        disabled={disabled}
      />
    </>
  );
};

export default withStyles(styles)(InputPhoneDdi);
