import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	pageTitle: '',
  pageDescription: '',
};

const updatePageTitleReducer = (state, { payload }) => {
	state.pageTitle = payload;
};

const updatePageDescriptionReducer = (state, { payload }) => {
  state.pageDescription = payload;
}

const pageSlice = createSlice({
	name: 'page',
	initialState,
	reducers: {
		updatePageTitle: updatePageTitleReducer,
    updatePageDescription: updatePageDescriptionReducer,
	}
});

export const { updatePageTitle, updatePageDescription } = pageSlice.actions;

export const reducer = pageSlice.reducer;
