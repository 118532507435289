import React, { useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Close from '@material-ui/icons/Close';
import Fab from '@material-ui/core/Fab';
import { useQuery } from 'react-apollo';

import TabSelector from './components/TabSelector';
import InfosPrincipais from './components/InfosPrincipais';
import DadosBancarios from './components/DadosBancarios';
import BraspagAnexos from './components/BraspagAnexos';

import Colors from '../../../../theme/Colors';
import { ModalDetalhesProfissionalStyles } from './styles.js';

import { FIND_DADOS_BRASPAG_PROFISSIONAL_SAUDE } from '../../../../graphql/queryes';

const ModalDetalhesProfissionalSaude = ({
  profissionalDeSaude,
  onClose,
  classes,
}) => {
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);

  const responseBraspagProfissionalSaude = useQuery(
    FIND_DADOS_BRASPAG_PROFISSIONAL_SAUDE,
    {
      variables: {
        profissionalSaudeId: profissionalDeSaude?.id,
      },
      skip: !profissionalDeSaude?.id,
    }
  );

  const dadosBraspagProfissional =
    responseBraspagProfissionalSaude?.data?.findByDadosBraspagProfissionalSaude;

  const TAB_OPTIONS = [
    { label: 'Informações principais' },
    { label: 'Dados bancários' },
    { label: 'Documentos' },
  ];

  if (!dadosBraspagProfissional) {
    TAB_OPTIONS.pop();
  }

  if (!dadosBraspagProfissional?.profissionalSaudeBraspagAnexo?.length) {
    TAB_OPTIONS.pop();
  }

  const tabContentByIndex = {
    0: (
      <InfosPrincipais
        profissionalDeSaude={profissionalDeSaude}
        dadosBraspagProfissional={dadosBraspagProfissional}
      />
    ),
    1: <DadosBancarios dadosBraspagProfissional={dadosBraspagProfissional} />,
    2: (
      <BraspagAnexos
        dadosBraspagProfissional={
          dadosBraspagProfissional?.profissionalSaudeBraspagAnexo
        }
      />
    ),
  };
  const renderTabContent = tabContentByIndex[selectedTabIndex || 0];

  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <span className={classes.headerTitle}>{profissionalDeSaude.nome}</span>
        <Fab className={classes.closeIcon} onClick={onClose}>
          <Close fontSize="small" />
        </Fab>
      </div>
      <TabSelector
        selectedColor={Colors.primary.contrastText}
        baseColor={
          !dadosBraspagProfissional
            ? Colors.primary.contrastText
            : Colors.commons.gray6
        }
        onSelect={(index) => setSelectedTabIndex(index)}
        selectedTabIndex={selectedTabIndex}
        tabsOptions={TAB_OPTIONS}
      />
      <div className={classes.gridContainer}>
        <div
          className={
            selectedTabIndex === 0
              ? classes.content
              : classes.contentSecondaryTab
          }
        >
          {renderTabContent}
        </div>
      </div>
    </div>
  );
};

export default withStyles(ModalDetalhesProfissionalStyles)(
  ModalDetalhesProfissionalSaude
);
