import React from 'react';
import { SvgIcon } from '@material-ui/core';
export const LockOpenIcon = (props) => (
  <SvgIcon {...props} viewBox="0 0 16 16">
    <path
      d="M6.14282 0C7.43476 0 8.56603 0.808095 8.97922 2.00532C9.11435 2.39687 8.90649 2.82383 8.51494 2.95896C8.15602 3.08284 7.76734 2.91849 7.60096 2.58865L7.56129 2.49468C7.35663 1.90167 6.79432 1.5 6.14282 1.5C5.36313 1.5 4.72237 2.09489 4.64969 2.85554L4.64282 3L4.64182 5H10.1428C11.2474 5 12.1428 5.89543 12.1428 7V14C12.1428 15.1046 11.2474 16 10.1428 16H2.14282C1.03825 16 0.142822 15.1046 0.142822 14V7C0.142822 5.89543 1.03825 5 2.14282 5H3.14182L3.14282 3C3.14282 1.34315 4.48597 0 6.14282 0ZM6.14282 9.5C5.59054 9.5 5.14282 9.94772 5.14282 10.5C5.14282 11.0523 5.59054 11.5 6.14282 11.5C6.69511 11.5 7.14282 11.0523 7.14282 10.5C7.14282 9.94772 6.69511 9.5 6.14282 9.5Z"
      fill="currentColor"
    />
  </SvgIcon>
);
