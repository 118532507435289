import styled from '@emotion/styled';
export const HeaderList = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const HeaderListTitle = styled.h2`
  font-size: 18px;
  font-weight: 700;
  color: black;
`

export const EmptyListContainer = styled.div`
    min-width: 100%;
    display: grid;
    place-content: center;
    place-items: center;
    background-color: #fff;
    border-radius: 10px;
    padding-block: 32px;
    border: 1px solid rgba(0, 0, 0, 0.1);

    p {
      font-size: 24px;
    }
  `

export const EmptyListIconContainer = styled.div`
  svg {
    font-size: 64px;
  }
`


