/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState} from 'react';
import {useMutation, useQuery} from '@apollo/react-hooks';
import {useHistory} from 'react-router-dom';

import Box from '../../components/box';
import {LIST_ENTITY} from '../../graphql/queryes';
import {CREATE_ENTITY, EDIT_ENTITY} from '../../router/names';
import Search from './components/search';
import List from '../../components/list/list';
import ButtonFloat from '../../components/button/ButtonFloat';
import canCriarEntidade from '../../services/authority-service/can-criar-entidade';
import {cpfCnpjFormatted} from '../../utils/masks';
import {UPDATE_ENTIDADE_STATUS} from '../../graphql/mutations';
import Notification from '../../components/notification/Notification';
import AtivoSwitch from './components/ativoSwitch';
import {obterUsuarioLogadoLocal} from "../../services/usuario-service";
import { withBaseLayout } from '../../layout/base-layout';

const notificationDefault = {
  variant: '',
  message: '',
  isOpen: false,
};

const ListUnits = () => {
  const {push} = useHistory();
  const [searchDTO, setSearchDTO] = useState({
    ativo: null,
    sortField: 'nivel.nome'
  });
  const [notification, setNotification] = useState({
    ...notificationDefault,
  });

  const {data, loading, error, refetch, fetchMore} = useQuery(LIST_ENTITY, {
    variables: {
      ...searchDTO,

    },
    fetchPolicy: 'no-cache'
  });

  const [fetchChangeStatus] = useMutation(UPDATE_ENTIDADE_STATUS);

  const usuarioLogado = obterUsuarioLogadoLocal();

  const handleClickCriarEntidade = () => {
    push(`${CREATE_ENTITY}`);
  };

  const handleClickEntidade = entidade => {
    push(`${EDIT_ENTITY.replace(':idEntity', entidade.id).replace(':tipoEntity', entidade.tipo )}`);
  };

  const handleLoadMore = e => {
    e.preventDefault();
    if (!data?.findAllEntidade?.last) {
      fetchMore({
        variables: {
          page: data.findAllEntidade.number + 1,
          ...searchDTO
        },
        updateQuery: (prev, {fetchMoreResult}) => {
          if (!fetchMoreResult) return prev;

          return ({
            findAllEntidade: {
              ...prev.findAllEntidade,
              ...fetchMoreResult.findAllEntidade,
              content: [
                ...prev.findAllEntidade.content,
                ...fetchMoreResult.findAllEntidade.content
              ]
            }
          });
        }
      });
    }
  };

  const changeStatusEntity = async ({nivel, __typename, ...entity}, event) => {
    event.preventDefault();
    try {
      await fetchChangeStatus({
        variables: {
          entidade: entity, ativo: !entity.ativo
        },
      });
      refetch();
      setNotification({
        variant: 'success',
        isOpen: true,
        message: `Entidade ${entity.ativo ? 'desativada' : 'ativada'} com sucesso`,
      });
    } catch ({graphQLErrors}) {
      if (graphQLErrors) {
        setNotification({
          variant: 'error',
          isOpen: true,
          message: graphQLErrors[0]?.message,
        });
      }
    }
  };


  return (
    <Box>
      <Search
        niveisEntidades={data?.findAllEntidadeNiveisVisiveisEntidadeLogada}
        entidadesSimplesFilhas={data?.findAllEntidadesSimple}
        onSearch={refetch} onDataSearch={s => setSearchDTO({...searchDTO, ...s})}/>
      <List
        columns={[
          {
            title: 'Status',
            value: (item) => <AtivoSwitch ativo={item.ativo}> </AtivoSwitch>,
            onClick: (item, e) => changeStatusEntity(item, e)
          },
          {
            title: 'Nome',
            value: (item) => item.nome,
          },
          !usuarioLogado?.authorities?.map(i=> i.authority).includes("ROLE_PLATAFORMA") ? {
            title: 'Nível',
            value: (item) => item.nivel ? item.nivel.nome : '-',
          } : '',
          {
            title: 'CPF/CNPJ',
            value: (item) => cpfCnpjFormatted(item.documento),
          }
        ]}
        items={data?.findAllEntidade?.content}
        loading={loading}
        error={error}
        last={data?.findAllEntidade?.last}
        onLoadMore={handleLoadMore}
        onClickItem={handleClickEntidade}
      />
      {canCriarEntidade() && <ButtonFloat title="Criar Nova Entidade" onClick={handleClickCriarEntidade}/>}
      <Notification
        close={() => {
          setNotification({
            ...notificationDefault,
          });
        }}
        reset={() => {
          setNotification({
            ...notificationDefault,
          });
        }}
        isOpen={notification.isOpen}
        variant={notification.variant}
        message={notification.message}
      />
    </Box>
  )
};

export default withBaseLayout(ListUnits);
