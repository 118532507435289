import React from 'react';
import { SvgIcon } from '@material-ui/core';
export const GiftIcon = (props) => (
  <SvgIcon {...props} viewBox="0 0 16 16">
    <path
      d="M7.07153 9V16H4.07153C2.74605 16 1.66149 14.9685 1.57685 13.6644L1.57153 13.5V9H7.07153ZM13.5715 9V13.5C13.5715 14.8255 12.54 15.91 11.2359 15.9947L11.0715 16H8.07153V9H13.5715ZM9.57153 0C10.9522 0 12.0715 1.11929 12.0715 2.5C12.0715 3.06324 11.8853 3.58297 11.571 4.00097L13.5715 4C14.1238 4 14.5715 4.44772 14.5715 5V7C14.5715 7.55228 14.1238 8 13.5715 8H8.07153V4H7.07153V8H1.57153C1.01925 8 0.571533 7.55228 0.571533 7V5C0.571533 4.44772 1.01925 4 1.57153 4L3.57208 4.00097C3.25779 3.58297 3.07153 3.06324 3.07153 2.5C3.07153 1.11929 4.19082 0 5.57153 0C6.38992 0 7.11646 0.393235 7.57251 1.00106C8.02661 0.393235 8.75315 0 9.57153 0ZM9.57153 1C8.74311 1 8.07153 1.67157 8.07153 2.5V4H9.57153C10.4 4 11.0715 3.32843 11.0715 2.5C11.0715 1.67157 10.4 1 9.57153 1ZM5.57153 1C4.74311 1 4.07153 1.67157 4.07153 2.5C4.07153 3.2797 4.66642 3.92045 5.42707 3.99313L5.57153 4H7.07153V2.5L7.06467 2.35554C6.99198 1.59489 6.35123 1 5.57153 1Z"
      fill="currentColor"
    />
  </SvgIcon>
);
