import React, { useState } from "react"
import { Dialog, Divider, IconButton, Typography, withStyles } from "@material-ui/core"
import CloseIcon from '@material-ui/icons/Close';
import { Tab, Tabs } from "../../../../components/tabs";
import ContratosList from "./ContratosList";
import ContratoForm from "./ContratoForm";



const TABS = {
  LISTA_DE_CONTRATOS: 'Lista de contratos',
  ENVIAR_CONTRATO: 'Enviar contrato',
};

const ModalContratos = ({open, classes, onClose,setNotification}) =>{

  const [activeTab, setActiveTab] = useState(TABS.LISTA_DE_CONTRATOS);

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  const renderTabContent = () => {
    switch (activeTab) {
      case TABS.LISTA_DE_CONTRATOS:
        return <ContratosList setNotification={setNotification}/>;
      case TABS.ENVIAR_CONTRATO:
        return <ContratoForm />;
      default:
        return null

  };
}
return(

    <Dialog
    open={open}
    PaperProps={{
      className: classes.container
    }}>
      <div className={classes.headerContainer}>
        <Typography className={classes.title}>
          Contratos
        </Typography>
        <IconButton
					  aria-label="close"
            className={classes.iconClose}
            onClick={()=>onClose()}>
					<CloseIcon />
				</IconButton>
      </div>
      <Divider  />
      <div className={classes.containerTabs}>
      <Tabs onChange={handleTabChange} value={activeTab} className={classes.tabs} >
            {Object.values(TABS).map((tab) => (
              <Tab
                key={tab}
                value={tab}
                style={{
                  flex: 1,
                  padding:"2px",
                  height:"fit-content"
                }}
              >
                {tab}
              </Tab>
            ))}
          </Tabs>

          </div>
          <div className={classes.containerContent}>
          {renderTabContent()}
          </div>
    </Dialog>

)

}
const styles = () => ({
	container:{
    width:"450px",
    height:"570px"
  },
  headerContainer:{
    display:"flex",
    flexDirection:"row",
    justifyContent:"space-between",
    height:"76px",
    alignItems:"end",
    padding:"0px 32px 0px 32px"
  },
  title:{
    color:"#000000",
    fontFamily:"Poppins",
    fontWeight:600,
    fontSize:"24px"
  },
  iconClose:{
    color:"#000000",
    backgroundColor:"#ffff !important"
  },
  containerTabs:{
    boxSizing:"border-box",
    display:"flex",
    flexDirection:"column",
    gap:"10px",
    padding:"16px 32px 0px 32px"
  },
  containerContent:{
    boxSizing:"border-box",
    display:"flex",
    flexDirection:"column",
    gap:"10px",
    flex:1
  },
  tabs:{
    height:"20px !important",
  },

});

export default withStyles(styles)(ModalContratos)
