import React from 'react';
import styled from '@emotion/styled';
import { useLazyQuery } from 'react-apollo';
import GetAppIcon from '@material-ui/icons/GetApp';
import { downloadUrl } from '../../../../utils/download';
import { PrintIcon } from '../../../../components/icons';
import { IMPRIMIR_BOLETO } from '../../../../graphql/queryes';

export const ButtonsBoletosEmitidos = ({ dados, vendaId }) => {
  const { pago, parcelaIdAzulControle } = dados;

  const [getBoleto, { data: boletoEmitido }] = useLazyQuery(IMPRIMIR_BOLETO, {
    onCompleted: () => {
      const url = `data:application/pdf;base64,${boletoEmitido.imprimirBoletoDaVenda}`;
      downloadUrl('boleto', url);
    },
  });

  const handleDownload = () => {
    getBoleto({
      variables: {
        vendaId,
        parcelaIdAzulControle: parcelaIdAzulControle,
      },
    });
  };

  return (
    <Container>
      <Button disabled={pago} onClick={handleDownload}>
        <GetAppIcon />
      </Button>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: row;
  gap: 4px;
`;

const Button = styled.button`
  display: flex;
  flex-direction: row;
  border: 1px solid rgba(0, 0, 0, 0.1);
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  height: 40px;
  width: 40px;
  border-radius: 100px;
  color: #565656;
  background: #f2f2f2;
  align-items: center;
  justify-content: center;
  ${({ disabled }) => disabled && 'opacity: 0.5;'}
`;
