import styled from '@emotion/styled';
import { useMutation } from 'react-apollo';
import React, { useEffect, useState } from 'react';
import { ALTERAR_QUANTIDADE_TIPO_PROFISSIONAL_SAUDE } from '../../../../graphql/mutations';
import { profissionaisQuantidadeDefault } from '../../../list-vendas/components/criar-venda/constants';
import AdicionarProfissionais from '../../../list-vendas/components/criar-venda/adicionarProfissionais';

const ModalQuantidadeTipoProfissional = ({ dados, onClose, setNotification }) => {
  const [quantidadeTipo, setQuantidadeTipo] = useState(null);
  const [disabled, setDisabled] = useState(false);
  const [loadingSave, setLoadingSave] = useState(false);

  const { profissionaisQuantidade } = dados;

  const [updateVendaQuantidadeProfissionais] = useMutation(
    ALTERAR_QUANTIDADE_TIPO_PROFISSIONAL_SAUDE
  );

  useEffect(() => {
    getQuantidadeTipoProfissionais();
  }, []);

  const getQuantidadeTipoProfissionais = () => {
    const quantidade = profissionaisQuantidadeDefault.map(
      (profissionalQuantidade) => {
        const tipoProfissional = profissionaisQuantidade.find(
          (item) => item.tipoProfissionalSaude.id === profissionalQuantidade.id
        );

        return {
          id: profissionalQuantidade.id,
          label: profissionalQuantidade.label,
          infomacao: profissionalQuantidade.informacao,
          quantidade: tipoProfissional.quantidade,
        };
      }
    );

    setQuantidadeTipo(quantidade);
  };

  const handleDadosInvalidos = (dadosInvalidos) => {
    setDisabled(dadosInvalidos);
  };

  const handleAtualizarDados = (dadosAtualizados) => {
    setQuantidadeTipo(dadosAtualizados.profissionaisQuantidade);
  };

  const handleSave = async () => {
    try {
      setLoadingSave(true);
      const vendaQuantidadeTipoProfissionalSaude = quantidadeTipo.map(
        (item) => ({
          quantidade: item.quantidade,
          tipoProfissionalSaude: {
            id: item.id,
          },
        })
      );

      await updateVendaQuantidadeProfissionais({
        variables: {
          venda: { id: dados.id },
          vendaQuantidadeTipoProfissionalSaude,
        },
      });

      onClose();
    } catch ({ graphQLErrors }) {
      if(graphQLErrors) {
        setNotification({
          variant: 'error',
          isOpen: true,
          message: graphQLErrors[0].message
        })
      }
    } finally {
      setLoadingSave(false);
    }
  };

  return (
    <Container>
      <AdicionarProfissionais
        dados={{
          ...dados,
          profissionaisQuantidade: quantidadeTipo,
        }}
        handleDadosInvalidos={handleDadosInvalidos}
        handleAtualizarDados={handleAtualizarDados}
      />
      <ButtonSave disabled={disabled || loadingSave} onClick={handleSave}>
        {' '}
        Salvar{' '}
      </ButtonSave>
    </Container>
  );
};

export default ModalQuantidadeTipoProfissional;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  align-items: end;
`;

const ButtonSave = styled.button`
  cursor: pointer;
  border-radius: 100px;
  height: 40px;
  width: 100%;
  max-width: 240px;
  border: 2px solid #dcdcdc33;
  background-color: #219a97;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-weight: 600;
  font-size: 14px;
  ${({ disabled }) => disabled && 'opacity: 0.5; cursor: not-allowed'};
`;
