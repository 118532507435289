import Switch from '@material-ui/core/Switch';
import {green, red} from '@material-ui/core/colors';
import {withStyles} from '@material-ui/core/styles';
import React from "react";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Colors from '../../../theme/Colors';

const SwitchAtivo = withStyles( (theme) => ({
  switchBase: {
    color: 'white',
    '&$checked': {
      color: 'white',
    },
    '&$checked + $track': {
      backgroundColor: Colors.primary.main,
      opacity: 1,
    },
  },
  checked: {},
  track: {},
}))(Switch);

const AtivoSwitch = (props) => {
  const {ativo, handleClick} = props;

  return (
    <FormControlLabel
      control={
        <SwitchAtivo
          checked={ativo}
          onChange={handleClick}
          value={ativo}
        />
      }
      placeholder={ativo ? "Ativo" : "Inativo"}
    />
  )
};

export default AtivoSwitch;
