import React, { useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import styled from '@emotion/styled';
import Typography from '@material-ui/core/Typography';
import { useQuery } from '@apollo/react-hooks';
import RenderTextField from '../../../../components/TextField/RenderTextField';
import string from '../../../../utils/string';
import InputDateForm from '../../../../components/input/InputDateForm';
import {
  DROPDOWN_GROUP_BY_REDE_ID,
  DROPDOWN_UF,
  FIND_ALL_TIPO_PROFISSIONAL_SAUDE_LIST,
} from '../../../../graphql/queryes';
import TextFieldSearch from '../../../../components/TextField/TextFieldSearch';
import { useApolloClient } from 'react-apollo';
import { obterEntidadeAtualLocal } from '../../../../services/usuario-service';
import { SelectSearch } from '../../../../components/select/SelectSearch';
import { AdicionaisProfissionalSaude } from './adicionaisProfissionalSaude';
import { Chip } from '@material-ui/core';

const DadosBase = ({
  dados,
  unidade,
  handleAtualizarDados,
  handleAtualizarDadosErrors,
  dadosErrors,
  handleDadosInvalidos,
  disabled,
  criandoProfissional,
  dadosEspecialidades,
}) => {
  const { data: tiposProfissionalSaude } = useQuery(
    FIND_ALL_TIPO_PROFISSIONAL_SAUDE_LIST
  );

  const {
    nome,
    cpf,
    cns,
    dataNascimento,
    email,
    confirmarEmail,
    grupoPermissoes,
    ativo,
    utilizaAssinaturaDigital,
    utilizaTelemedicina,
    utilizaSecretariaVirtual,
    prontuarioExclusivo,
    utilizaVIZI,
    permiteAvaliacaoVizi,
    tipo,
    perfilPublico,
    possuiAgenda,
    numeroConselho,
    ufConselho,
    especialidades,
  } = dados;

  const [dadosTemp, setDadosTemp] = useState({
    nome,
    cpf,
    cns,
    dataNascimento,
    email,
    confirmarEmail,
    grupoPermissoes,
    ativo,
    utilizaAssinaturaDigital,
    utilizaTelemedicina,
    utilizaSecretariaVirtual,
    prontuarioExclusivo,
    utilizaVIZI,
    permiteAvaliacaoVizi,
    tipo,
    perfilPublico,
    possuiAgenda,
    numeroConselho,
    ufConselho,
    especialidades,
  });
  const [entidadeLogada, setEntidadeLogada] = useState(null);

  const client = useApolloClient();
  const whitelabel = unidade?.rede?.whitelabel?.nomeEmpresa

  const getEntidadeLogada = async () => {
    return await obterEntidadeAtualLocal();
  };

  const handleChangeField = (campo, value) => {
    setDadosTemp({
      ...dadosTemp,
      [campo]: value,
    });

    handleAtualizarDadosErrors({
      [campo]: !value,
    });

    handleAtualizarDados({
      [campo]: value,
    });
  };

  const handleCpfField = (value) => {
    setDadosTemp({
      ...dadosTemp,
      cpf: value,
    });

    handleAtualizarDadosErrors({
      cpf:
        !string.validaCPF(value) ||
        string.removeSpecialChars(value) === entidadeLogada?.responsavelCpf,
    });

    handleAtualizarDados({
      cpf: value,
    });
  };

  const handleEmailField = (value) => {
    setDadosTemp({
      ...dadosTemp,
      email: value,
    });

    handleAtualizarDadosErrors({
      email: !value || !string.validaEmail(value),
      confirmarEmail:
        dadosTemp.confirmarEmail && value !== dadosTemp.confirmarEmail,
    });

    handleAtualizarDados({
      email: value,
    });
  };

  const handleChangeTipoField = (tipo) => {
    const tipoProfissional = tipo.value;
    setDadosTemp({
      ...dadosTemp,
      tipo: tipo,
      possuiAgenda: tipoProfissional === 1,
    });

    handleAtualizarDados({
      tipo: tipo,
      possuiAgenda: tipoProfissional === 1,
    });
  };

  const handleConfirmarEmailField = (value) => {
    setDadosTemp({
      ...dadosTemp,
      confirmarEmail: value,
    });

    handleAtualizarDadosErrors({
      confirmarEmail: value !== dadosTemp.email,
    });

    handleAtualizarDados({
      confirmarEmail: value,
    });
  };

  const emailErrorMessage = () => {
    if (dadosTemp.email === '') return 'Este campo é obrigatório.';

    if (!string.validaEmail(dadosTemp.email)) return 'Email inválido.';

    if (dadosTemp.email !== dadosTemp.confirmarEmail)
      return 'O email e confirmação do email devem ser iguais.';

    return '';
  };

  const handleLoadMoreOptions = async ({
    variables,
    query,
    responseObject,
  }) => {
    const response = await client.query({ query, variables });

    const pageNumber = variables?.searchDTO?.pageNumber || 0;

    return {
      options: response?.data?.[responseObject] || [],
      hasMore: false,
      additional: {
        page: pageNumber + 1,
      },
    };
  };

  const handleGrupoPermissoesLoadOptions = async (loadedOptions) => {
    return handleLoadMoreOptions({
      loadedOptions,
      variables: {
        idRede: unidade?.rede?.id,
        ativo: true,
      },
      responseObject: 'findGroupByRedeId',
      query: DROPDOWN_GROUP_BY_REDE_ID,
    });
  };

  const validarCpfUsuarioLogado = () => {
    return string.removeSpecialChars(dadosTemp.cpf) ===
      entidadeLogada?.responsavelCpf
      ? 'CPF igual o do usuário logado'
      : 'CPF inválido';
  };

  const handleUFLoadOptions = async (loadedOptions) => {
    return handleLoadMoreOptions({
      loadedOptions,
      variables: {},
      responseObject: 'findAllEstadoList',
      query: DROPDOWN_UF,
    });
  };

  const handleDelete = (especialidade) => {
    const especialidadeAtualizada = especialidades.filter(
      (item) => item.value !== especialidade.value
    );
    handleChangeField('especialidades', especialidadeAtualizada);
  };

  const handleChangeFieldEspecialidades = (value) => {
    const especialidadesAtualizadas = [...especialidades, ...value];
    handleChangeField('especialidades', especialidadesAtualizadas);
  };

  useEffect(() => {
    const isCamposPreenchidos =
      dadosTemp.nome === '' ||
      dadosTemp.cpf === '' ||
      !string.validaCPF(dadosTemp.cpf) ||
      string.removeSpecialChars(dadosTemp.cpf) ===
        entidadeLogada?.responsavelCpf ||
      !dadosTemp.dataNascimento ||
      !string.validaEmail(dadosTemp.email) ||
      (criandoProfissional && !dadosTemp.grupoPermissoes) ||
      (criandoProfissional && dadosTemp.confirmarEmail !== dadosTemp.email);

    handleDadosInvalidos(isCamposPreenchidos);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleDadosInvalidos, dadosTemp]);

  useEffect(() => {
    handleAtualizarDados({
      especialidades: especialidades,
    });

    handleDadosInvalidos && handleDadosInvalidos(especialidades.length < 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [especialidades]);

  useEffect(() => {
    getEntidadeLogada().then((response) => {
      setEntidadeLogada(response);
    });
  }, []);

  return (
    <Container>
      <Grid container direction="row" spacing={2}>
        <Grid item xs={12} sm={12} md={12} ls={12}>
          <TypographyStyled color="black" component="label">
            Tipo profissional saúde *
          </TypographyStyled>
          <SelectSearchStyled
            isClearable={false}
            name="tipo"
            placeholder=""
            value={dadosTemp.tipo}
            elements={
              tiposProfissionalSaude?.findAllTipoProfissionalSaudeList || []
            }
            onChange={(e) => handleChangeTipoField(e)}
            disabled={disabled}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12} ls={12}>
          <RenderTextField
            label={'Nome *'}
            field={dadosTemp.nome}
            error={dadosErrors?.nome}
            errorText={'Este campo é obrigatório!'}
            handleChange={(e) => handleChangeField('nome', e.target.value)}
            disabled={disabled}
            fontSize="12px"
          />
        </Grid>
        <Grid item xs={6} sm={6} md={6} ls={6}>
          <RenderTextField
            label={'CPF *'}
            field={dadosTemp.cpf}
            error={dadosErrors?.cpf}
            mask={'cpf'}
            errorText={validarCpfUsuarioLogado()}
            handleChange={(e) => handleCpfField(e.target.value)}
            disabled={disabled}
            fontSize="12px"
          />
        </Grid>
        <Grid item xs={6}>
          <TypographyStyled color="black" component="label">
            Data de Nascimento *
          </TypographyStyled>
          <InputDateForm
            disableFuture
            variant="outlined"
            fullWidth
            value={dadosTemp.dataNascimento}
            onChange={(e) => handleChangeField('dataNascimento', e)}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12} ls={12}>
          <RenderTextField
            label={'CNS'}
            field={dadosTemp.cns}
            handleChange={(e) => handleChangeField('cns', e.target.value)}
            disabled={disabled}
            fontSize="12px"
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12} ls={12}>
          <RenderTextField
            label={'Email *'}
            field={dadosTemp.email}
            error={dadosErrors?.email}
            errorText={emailErrorMessage()}
            handleChange={(e) => handleEmailField(e.target.value)}
            disabled={disabled}
            fontSize="12px"
          />
        </Grid>
        {criandoProfissional && (
          <Grid item xs={12} sm={12} md={12} ls={12}>
            <RenderTextField
              label={'Confirme o Email *'}
              field={dadosTemp.confirmarEmail}
              error={dadosErrors?.confirmarEmail}
              errorText={emailErrorMessage()}
              handleChange={(e) => handleConfirmarEmailField(e.target.value)}
              disabled={disabled}
              fontSize="12px"
            />
          </Grid>
        )}
        <Grid item xs={12} sm={12} md={12} ls={12}>
          <TypographyStyled color="black" component="label">
            Grupo de Permissões *
          </TypographyStyled>
          <TextFieldSearch
            placeholder=""
            loadOptions={handleGrupoPermissoesLoadOptions}
            withPaginate
            isMulti
            value={dadosTemp.grupoPermissoes}
            onChange={(e) => handleChangeField('grupoPermissoes', e)}
            debounceTimeout={300}
            additional={{
              page: 0,
            }}
          />
        </Grid>
        {dadosTemp.possuiAgenda && (
          <>
            <Grid item xs={6} sm={6} md={6} ls={6}>
              <RenderTextField
                label={'Número Conselho *'}
                field={numeroConselho}
                error={dadosErrors?.numeroConselho}
                errorText={'Este campo é obrigatório!'}
                handleChange={(e) =>
                  handleChangeField('numeroConselho', e.target.value)
                }
                disabled={disabled}
                fontSize="12px"
              />
            </Grid>
            <Grid item xs={6} sm={6} md={6} ls={6}>
              <TypographyStyled color="black" component="label">
                UF *
              </TypographyStyled>
              <TextFieldSearch
                placeholder=""
                loadOptions={handleUFLoadOptions}
                withPaginate
                value={dadosTemp.ufConselho}
                onChange={(e) => handleChangeField('ufConselho', e)}
                debounceTimeout={300}
                additional={{
                  page: 0,
                }}
                menuInTop
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} ls={12}>
              <TypographyStyled color="black" component="label">
                Especialidade
              </TypographyStyled>
              <TextFieldSearch
                placeholder=""
                options={dadosEspecialidades || []}
                isMulti
                value={null}
                onChange={handleChangeFieldEspecialidades}
                menuInTop
              />
              {especialidades.map((especialidade) => (
                <EspecialidadeSelecionada
                  key={especialidade.value}
                  label={especialidade.label}
                  onClick={() => handleDelete(especialidade)}
                  onDelete={() => handleDelete(especialidade)}
                  color={'black'}
                  disabled={disabled}
                />
              ))}
            </Grid>
          </>
        )}

        <AdicionaisProfissionalSaude
          dadosTemp={dadosTemp}
          whitelabel={whitelabel}
          id={dados.id}
          handleChangeField={handleChangeField}
        />
      </Grid>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  padding: 10px 0;
  min-height: 530px;
`;

const SelectSearchStyled = styled(SelectSearch)`
  width: 100%;
`;

const TypographyStyled = styled(Typography)`
  font-size: 12px !important;
`;

const EspecialidadeSelecionada = styled(Chip)`
  margin: 1px 2px !important;
`;

export default DadosBase;
