import React, { useEffect, useState } from 'react';
import { BoxDismissIcon } from '../../../../components/icons';
import Button from '../../../../components/button';
import { AddCircle } from '@material-ui/icons';

import { TipoCobrancaFilter } from '../tipo-cobranca-filter';
import {
  EmptyListContainer,
  EmptyListIconContainer,
  HeaderList,
  HeaderListTitle,
} from '../../styled';
import { TiposCobranca } from '../../../../enum/TiposCobranca';
import styled from '@emotion/styled';
import { StepPlano } from './components/modal-plano';
import Notification from '../../../../components/notification/Notification';
import { notificationDefault } from '../../constants';
import { useLazyQuery } from 'react-apollo';
import { FIND_ALL_PLANO_TIPO_PAGAMENTO, FIND_PLANO_BY_ID } from '../../../../graphql/queryes';
import { CardPlano } from './components/card-plano';

export const PlanosList = () => {
  const [tipoCobranca, setTipoCobranca] = useState(TiposCobranca.MENSAL);
  const [openModal, setOpenModal] = useState(false);
  const [notification, setNotification] = useState(notificationDefault);
  const [planoSelecionado, setPlanoSelecionado] = useState(null);

  const [getPlanos, {data: planos, refetch, loading}] =
    useLazyQuery(FIND_ALL_PLANO_TIPO_PAGAMENTO);

  const [getPlanoSelecionado, {data: plano}] =
    useLazyQuery(FIND_PLANO_BY_ID, {
      fetchPolicy: 'no-cache',
      onCompleted: () => {
        setPlanoSelecionado(plano.findPlanoById);
        setOpenModal(true)
      }
    });

  useEffect(() => {
    getPlanos({
      variables: {
        tipoPagamento: tipoCobranca,
        pageableDTO: {
          pageNumber: 0,
          pageSize: 30,
          sortDir: 'DESC',
          sortField: 'dataLancamento',
        },
      },
    });

  }, []);

  const onCloseModal = () => {
    setOpenModal(false);
  };

  const onClickAddPlano = () => {
    setPlanoSelecionado(null);
    setOpenModal(true);
  };

  const handleTipoCobranca = (tipo) => {
    setTipoCobranca(tipo);
  };

  const handlePlano = (id) => {
    getPlanoSelecionado({
      variables: {
        id
      }
    });
  };

  const renderPlanos = () => {

    return planos.findAllPlanoTipoPagamento.content.map((plano) => {
      return <CardPlano onClick={() => handlePlano(plano.id)} key={plano.id} plano={plano} tipoCobranca={tipoCobranca} />
    });
  };

  return (
    <section>
      <HeaderList>
        <HeaderListTitle>Planos</HeaderListTitle>
        <HeaderFilterContainer>
          <TipoCobrancaFilter
            onChange={handleTipoCobranca}
            value={tipoCobranca}
          />
          <Button onClick={onClickAddPlano}>
            Criar plano
            <AddCircle />
          </Button>
        </HeaderFilterContainer>
      </HeaderList>
      {planos?.findAllPlanoTipoPagamento?.content?.length > 0 && (
        <ContentCards>
          {renderPlanos()}
        </ContentCards>
      )}
      {
        !loading && planos?.findAllPlanoTipoPagamento?.content?.length === 0 && (
          <EmptyListContainer>
          <EmptyListIconContainer>
            <BoxDismissIcon />
          </EmptyListIconContainer>
          <p>Nenhum plano foi criado</p>
          <Button onClick={onClickAddPlano}>
            Criar plano
            <AddCircle />
          </Button>
        </EmptyListContainer>
        )
      }
      {
        loading && (
          <div> Carregando... </div>
        )
      }
      { openModal && <StepPlano
        plano={planoSelecionado}
        openModal={openModal}
        onCloseModal={onCloseModal}
        setNotification={setNotification}
        carregarPlanos={refetch}
      />}
      <Notification
        close={() => setNotification(notificationDefault)}
        reset={() => setNotification(notificationDefault)}
        isOpen={notification.isOpen}
        variant={notification.variant}
        message={notification.message}
      />
    </section>
  );
};

const HeaderFilterContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

const ContentCards = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
  flex-wrap: wrap;
  overflow-y: auto;
  max-height: 545px;
`;