import React from 'react';
import Select, { components } from 'react-select';
import AsyncSelect from 'react-select/lib/Async';
import Colors from "../../theme/Colors";
import FormControl from '../FormControl';

function noOptionsMessage() {
  return 'Digite para pesquisar';
}

function loadingMessage() {
  return 'Carregando...';
}

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected
      ? '#EEEEEE'
      : state.isFocused
      ? '#EEEEEE'
      : '#fff',
    color: 'black',
    cursor: 'pointer',
    fontSize: '14px',
    width: '100%',
    ':active': {
      backgroundColor: state.isSelected ? '#EEEEEE' : 'white',
    },
  }),
  control: (provided, state) => ({
    ...provided,
    width: '100%',
    minHeight: 0,
    padding: '1px 0 1px 8px',
    fontSize: '14px',
    color: 'black !important',
    backgroundColor: '#F2F2F2',
    borderColor: state.isDisabled
      ? null
      : state.isFocused
      ? '#000000'
      : 'rgba(220, 220, 220, 0.2)',
    borderRadius: '8px',
    borderWidth: '0.5',
    boxShadow: state.isFocused ? `0 0 0 0.5 ${Colors.primary.main}` : null,
    cursor: 'pointer',
    '&:hover': {
      borderColor: state.isFocused ? '#000000' : 'rgba(220, 220, 220, 0.2)',
    },
  }),
  placeholder: (provided) => ({
    ...provided,
    color: Colors.commons.placeholderColor,
  }),
  input: (provided) => ({
    ...provided,
    color: '#000000',
  }),
  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = 'opacity 300ms';
    const color = Colors.commons.fontColor;

    return { ...provided, opacity, transition, color };
  },
};

const DropdownIndicator = (props) => {
  return (
    components.DropdownIndicator && (
      <components.DropdownIndicator {...props}>
        <i
          className={`material-icons ${props.className} `}
          style={{
            color: 'black',
            top: 'calc(50% - 8px)',
            fontSize: '16px',
            borderRadius: '50%',
            right: '-3px',
          }}
        >
          {props.selectProps.menuIsOpen ? 'expand_less' : 'expand_more'}
        </i>
      </components.DropdownIndicator>
    )
  );
};

const ClearIndicator = (props) => {
  return (
    components.ClearIndicator && (
      <components.ClearIndicator {...props}>
        <i
          className={`material-icons ${props.className} `}
          style={{
            backgroundColor: 'black',
            color: 'white',
            fontSize: '16px',
            borderRadius: '50%',
          }}
        >
          {'close'}
        </i>
      </components.ClearIndicator>
    )
  );
};

export const SelectSearch = (props) => {
  const {
    className,
    elements,
    placeholder,
    isClearable = true,
    ...other
  } = props;

  return (
    <FormControl className={className} label={props.label} error={props.error}>
      <Select
        {...other}
        styles={customStyles}
        placeholder={placeholder}
        isClearable={isClearable}
        components={{
          IndicatorSeparator: null,
          DropdownIndicator,
          ClearIndicator,
        }}
        noOptionsMessage={noOptionsMessage}
        loadingMessage={loadingMessage}
        options={elements?.map((c) => ({ value: c.value, label: c.name }))}
        menuPlacement="auto"
      />
    </FormControl>
  );
};

export const SelectSearchAsync = (props) => {
  const { className, placeholder, ...other } = props;

  return (
    <FormControl className={className} label={props.label} error={props.error}>
      <AsyncSelect
        {...other}
        styles={customStyles}
        cacheOptions
        isClearable={true}
        placeholder={placeholder}
        noOptionsMessage={noOptionsMessage}
        loadingMessage={loadingMessage}
        components={{
          IndicatorSeparator: null,
          DropdownIndicator,
          ClearIndicator,
        }}
        menuPlacement="auto"
      />
    </FormControl>
  );
};
