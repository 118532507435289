import styled from "@emotion/styled";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  flex: 1;
  padding: 16px;
`;

export const Tab = styled.div`
  display: flex;
  text-align: center;
  padding: 8px 16px;
  border-radius: 8px;
  cursor: pointer;
  background-color: ${(props) => (props.selected ? '#fff' : '#f2f2f2')};
  color: ${(props) => (props.selected ? '#000' : '#868686')};
  font-weight: ${(props) => (props.selected ? '600' : '500')};
  font-size: 14px;
  border: 1px solid #e5e5e5;
  flex: 1;
  height: 24px;
`;

export const TabContainer = styled.div`
  display: flex;
  gap: 16px;
  @media (max-width: 768px) {
    flex-direction: column-reverse;
  }
`;

export const FormContainer = styled.div`
position: relative;
  display: flex;
  gap: 24px;
  flex: 1;
  align-self: center;
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 16px;
  border: solid 1px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 16px;
  background-color: #fff;
  flex: 1;

  @media (max-width: 768px) {
    width: calc(100% - 32px);
  }
`;

export const FormSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const FormTitle = styled.div`
  font-size: 18px;
  font-weight: 700;
  color: #505050;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
`;

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  ${({ flex }) => flex && 'flex: 1;'}
  ${({ width }) => width && `width: ${width};`}
  gap: 4px;

  & > div {
    gap: 4px;
  }
`;

export const Label = styled.label`
  color: #868686;
  font-size: 14px;
  font-weight: 600;
  margin-left: 4px;
`;

export const TableContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  flex: 1;
  padding: 16px;
  overflow-y: hidden;
`;

export const Table = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  flex: 1;
  overflow-y: auto;
  overflow-x: hidden;
  & > div > table {
    border: none
  };
`;
