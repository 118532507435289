import React from 'react';
import { SvgIcon } from '@material-ui/core';
export const PeopleTeamIcon = (props) => (
  <SvgIcon {...props} viewBox="0 0 20 20">
    <path
      d="M11.1667 2.5C11.1667 3.88071 10.0475 5 8.66675 5C7.28604 5 6.16675 3.88071 6.16675 2.5C6.16675 1.11929 7.28604 0 8.66675 0C10.0475 0 11.1667 1.11929 11.1667 2.5ZM16.1667 3C16.1667 4.10457 15.2713 5 14.1667 5C13.0622 5 12.1667 4.10457 12.1667 3C12.1667 1.89543 13.0622 1 14.1667 1C15.2713 1 16.1667 1.89543 16.1667 3ZM3.16675 5C4.27132 5 5.16675 4.10457 5.16675 3C5.16675 1.89543 4.27132 1 3.16675 1C2.06218 1 1.16675 1.89543 1.16675 3C1.16675 4.10457 2.06218 5 3.16675 5ZM4.66675 7.25C4.66675 6.55964 5.22639 6 5.91675 6H11.4167C12.1071 6 12.6667 6.55964 12.6667 7.25V12C12.6667 14.2091 10.8759 16 8.66675 16C6.45761 16 4.66675 14.2091 4.66675 12V7.25ZM3.66675 7.25C3.66675 6.78746 3.80632 6.35752 4.04564 6H1.91675C1.22639 6 0.666748 6.55964 0.666748 7.25V11C0.666748 12.6569 2.00989 14 3.66675 14C3.80387 14 3.93884 13.9908 4.07108 13.973C3.81087 13.3677 3.66675 12.7007 3.66675 12V7.25ZM13.6667 12C13.6667 12.7007 13.5226 13.3677 13.2624 13.973C13.3947 13.9908 13.5296 14 13.6667 14C15.3236 14 16.6667 12.6569 16.6667 11V7.25C16.6667 6.55964 16.1071 6 15.4167 6H13.2879C13.5272 6.35752 13.6667 6.78746 13.6667 7.25V12Z"
      fill="currentColor"
    />
  </SvgIcon>
);
