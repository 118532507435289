import React, { useEffect, useState } from "react";
import { useApolloClient, useLazyQuery } from "react-apollo";
import styled from "@emotion/styled";
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography";
import string from "../../../../utils/string";
import { DROPDOWN_MUNICIPIOS, CONSULTA_CEP } from "../../../../graphql/queryes";
import RenderTextField from "../../../../components/TextField/RenderTextField";
import TextFieldSearch from "../../../../components/TextField/TextFieldSearch";

const DadosEndereco = ({ dados, handleAtualizarDados, dadosErrors, handleAtualizarDadosErrors, handleDadosInvalidos, disabled }) => {

  const [dadosTemp, setDadosTemp] = useState({
    nomeLogradouro: dados?.nomeLogradouro || '',
    numero: dados?.numero || '',
    bairro: dados?.bairro || '',
    cep: dados?.cep || '',
    complemento: dados?.complemento || '',
    municipio: dados?.municipio ? { ...dados.municipio, label: `${dados.municipio?.label.split('-')[0]} - ${dados.municipio?.uf}` } : null,
    estado: dados?.estado || null
  });

  const [getConsultaCep, { data: dadosConsultaCep }] = useLazyQuery(CONSULTA_CEP);

  const client = useApolloClient();

  const handleChangeField = (campo, value) => {
    setDadosTemp({
      ...dadosTemp,
      [campo]: value
    });

    handleAtualizarDadosErrors({
      [campo]: value === undefined || value === ''
    });

    handleAtualizarDados({
      endereco: {
        ...dadosTemp,
        [campo]: value
      }
    });
  };

  const handleChangeMunicipioField = (value) => {
    setDadosTemp({
      ...dadosTemp,
      municipio: value,
      estado: value?.estado ? value?.estado : {}
    });

    handleAtualizarDadosErrors({
      municipio: value === null,
      estado: value?.estado === undefined
    });

    handleAtualizarDados({
      endereco: {
        ...dadosTemp,
        municipio: value,
        estado: value?.estado
      }
    });
  };

  const handleCepField = (value) => {
    let cepSemMascara = string.removeSpecialChars(value);

    setDadosTemp({
      ...dadosTemp,
      cep: value
    });

    if (cepSemMascara.length === 8) {
      consultaCep(cepSemMascara);
    }

    handleAtualizarDadosErrors({
      cep: cepSemMascara.length < 8
    });

    handleAtualizarDados({
      endereco: {
        ...dadosTemp,
        cep: value
      }
    });
  };

  const consultaCep = async (cep) => {
    await getConsultaCep({
      variables: {
        cep: cep
      }
    });
  };

  const handleLoadMoreOptions = async ({ variables, query, responseObject }) => {
    const response = await client.query({
      query,
      variables
    });

    const pageNumber = variables?.searchDTO?.pageNumber || 0;

    let options = response?.data?.[responseObject]?.content || [];

    if (responseObject === 'findAllMunicipio') {
      options = options.map(o => ({
        ...o,
        label: `${o.label} - ${o.uf}`,
      }));
    }

    return {
      options,
      hasMore: !response?.data?.[responseObject]?.last,
      additional: {
        page: pageNumber + 1
      }
    };
  };

  const handleMunicipiosLoadOptions = async (search, loadedOptions, { page }) => {
    return handleLoadMoreOptions({
      loadedOptions,
      variables: {
        searchDTO: {
          pageSize: 10,
          pageNumber: page,
          search
        }
      },
      responseObject: 'findAllMunicipio',
      query: DROPDOWN_MUNICIPIOS
    });
  };

  const clearDados = () => {
    setDadosTemp({
      ...dadosTemp,
      bairro: '',
      nomeLogradouro: '',
      numero: '',
      municipio: null,
      cep: null,
      complemento: ''
    });

    handleAtualizarDados({
      ...dadosTemp
    });
  };

  const isCamposPreenchidos = () => {
    return dadosTemp.cep?.length < 8 ||
      !dadosTemp.bairro ||
      !dadosTemp.nomeLogradouro ||
      !dadosTemp.numero ||
      !dadosTemp.municipio
  };

  useEffect(() => {
    handleDadosInvalidos(isCamposPreenchidos());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleAtualizarDados]);

  useEffect(() => {
    if (dadosConsultaCep && !disabled) {
      setDadosTemp((prevDadosTemp) => ({
        ...prevDadosTemp,
        nomeLogradouro: dadosConsultaCep?.consultaDeCep?.logradouro,
        bairro: dadosConsultaCep?.consultaDeCep?.bairro,
        municipio: dadosConsultaCep.consultaDeCep?.municipio ? { ...dadosConsultaCep.consultaDeCep?.municipio, label: `${dadosConsultaCep.consultaDeCep?.municipio?.label} - ${dadosConsultaCep.consultaDeCep?.municipio?.uf}` } : null,
        estado: dadosConsultaCep.consultaDeCep?.municipio?.estado
      })
      );

      handleAtualizarDados({
        endereco: {
          ...dadosTemp,
          nomeLogradouro: dadosConsultaCep?.consultaDeCep?.logradouro,
          bairro: dadosConsultaCep?.consultaDeCep?.bairro,
          municipio: dadosConsultaCep.consultaDeCep?.municipio,
          estado: dadosConsultaCep.consultaDeCep?.municipio?.estado,
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dadosConsultaCep]);

  useEffect(() => {
    if (dados?.cep) {
      consultaCep(dados?.cep);
    }

    return () => { consultaCep(dados?.cep); }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container>
      <Grid container direction="row" spacing={2}>
        <Grid item xs={12} sm={12} md={6} ls={6}>
          <RenderTextField label={"CEP *"}
            field={dadosTemp.cep}
            mask={'cep'}
            error={dadosErrors?.cep}
            errorText={"Este campo é obrigatório!"}
            handleChange={(e) => handleCepField(e.target.value)}
            disabled={disabled} />
        </Grid>
        <Grid item xs={12} sm={12} md={6} ls={6}>
          <RenderTextField label={"Número *"}
            field={dadosTemp.numero}
            error={dadosErrors?.numero}
            errorText={"Este campo é obrigatório!"}
            handleChange={(e) => handleChangeField('numero', e.target.value)}
            disabled={disabled} />
        </Grid>
        <Grid item xs={12} sm={12} md={12} ls={12}>
          <RenderTextField label={"Logradouro *"}
            field={dadosTemp.nomeLogradouro}
            error={dadosErrors?.nomeLogradouro}
            errorText={"Este campo é obrigatório!"}
            handleChange={(e) => handleChangeField('nomeLogradouro', e.target.value)}
            disabled={disabled} />
        </Grid>
        <Grid item xs={12} sm={12} md={12} ls={12}>
          <RenderTextField label={"Bairro *"}
            field={dadosTemp.bairro}
            error={dadosErrors?.bairro}
            errorText={"Este campo é obrigatório!"}
            handleChange={(e) => handleChangeField('bairro', e.target.value)}
            disabled={disabled} />
        </Grid>
        <Grid item xs={12} sm={12} md={12} ls={12}>
          <RenderTextField label={"Complemento"}
            field={dadosTemp.complemento}
            handleChange={(e) => handleChangeField('complemento', e.target.value)}
            disabled={disabled} />
        </Grid>

        {disabled ?
          <Grid item xs={12} sm={12} md={12} ls={12}>
            <RenderTextField label={"Municipio"}
              field={dadosTemp.municipio?.label}
              disabled={disabled} />
          </Grid>
          :
          <Grid item xs={12} sm={12} md={12} ls={12}>
            <Typography color="primary" component="label">
              {"Município *"}
            </Typography>
            <TextFieldSearch placeholder=""
              loadOptions={handleMunicipiosLoadOptions}
              withPaginate
              value={dadosTemp.municipio}
              onChange={e => handleChangeMunicipioField(e)}
              debounceTimeout={300}
              additional={{
                page: 0
              }}
            />
          </Grid>
        }
      </Grid>
    </Container>
  );

};

export default DadosEndereco;

const Container = styled.div`
  width: 100%;
  padding: 10px 0;
`;
