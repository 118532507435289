import React, { useState } from 'react';
import { useMutation } from 'react-apollo';
import { ALTERAR_ENDERECO_VENDA } from '../../../graphql/mutations';
import string from '../../../utils/string';
import FormDadosEndereco from './editar-contratante/FormDadosEndereco';
import { ContainerButton, Button } from './editar-contratante/styles';

const EditarEndereco = ({ venda, onClose }) => {
  const [formData, setFormData] = useState({
    cep: venda.contratante?.endereco?.cep,
    bairro: venda.contratante?.endereco?.bairro,
    municipio: venda.contratante?.endereco?.municipio,
    numero: venda.contratante?.endereco?.numero,
    nomeLogradouro: venda.contratante?.endereco?.nomeLogradouro,
    complemento: venda.contratante?.endereco?.complemento,
    tipoLogradouro: venda.contratante?.endereco?.tipoLogradouro,
    estado: venda.contratante?.endereco?.estado,
  });
  const [loadingSave, setLoadingSave] = useState(false);
  const [dadosInvalidos, setDadosInvalidos] = useState(false);

  const [updateEndereco] = useMutation(ALTERAR_ENDERECO_VENDA);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoadingSave(true);

    await updateEndereco({
      variables: {
        venda: {
          id: venda.id,
        },
        cep: string.removeSpecialChars(formData.cep),
        bairro: formData.bairro,
        municipio: formData.municipio
          ? {
              id: formData.municipio.value,
            }
          : null,
        numero: formData.numero,
        estado: formData.estado
          ? {
              id: formData.estado.value,
            }
          : null,
        nomeLogradouro: formData.nomeLogradouro,
        complemento: formData.complemento,
        tipoLogradouro: {
          id: formData.tipoLogradouro.value,
        },
      },
    });

    setLoadingSave(false);
    onClose();
  };

  const verificaDadosInvalidos = (isDadosValidos) => {
    setDadosInvalidos(isDadosValidos);
  };

  return (
    <>
      <FormDadosEndereco
        formData={formData}
        setFormData={setFormData}
        handleDadosInvalidos={verificaDadosInvalidos}
      />

      <ContainerButton>
        <Button
          type="button"
          disabled={dadosInvalidos || loadingSave}
          onClick={handleSubmit}
        >
          Salvar
        </Button>
      </ContainerButton>
    </>
  );
};

export default EditarEndereco;
