import React from 'react';
import styled from '@emotion/styled';
import Number from '../../../../utils/number';
import ButtonBox from '../../../../components/button/ButtonBox';
import { AddCircleIcon } from '../../../../components/icons/AddCircle';
import { ArrowSyncCircle } from '../../../../components/icons/ArrowSyncCircle';
import string from '../../../../utils/string';

export const SobrePlano = ({ venda, setStepSelectedSobrePlano, loadingModulos }) => {
  const { plano, vendaModulos, valor, tipoPagamento } = venda;
  const { nome } = plano || {};

  const modulosPlano = vendaModulos.filter(item => !item.adicional);
  const tipoPagamentoVenda = string.capitalize(tipoPagamento?.descricao || "")

  return (
    <Content>
      <DadosPlano>
        <HeaderCard>{ nome}</HeaderCard>
        <TipoPagamento> {tipoPagamentoVenda} </TipoPagamento>
        <Modulos>
          {
            modulosPlano.map(item => {
              return <Modulo> { item.funcionalidade } </Modulo> 
            })
          }
        </Modulos>
        <ContentValor>
          <Label> Total: </Label>
          <Label> { Number.currencyFormat(valor) } </Label>
        </ContentValor>
      </DadosPlano>
      <ContentButtons>
        <ButtonBox
          disabled={loadingModulos}
          onClick={() => setStepSelectedSobrePlano(1)}
        >
           <ContentButton>
            <AddCircleIcon/>
            <LabelButton> Adicionais </LabelButton>
           </ContentButton>
        </ButtonBox>
        <ButtonBox
          onClick={() => setStepSelectedSobrePlano(2)}
        >
          <ContentButton>
            <ArrowSyncCircle/>
            <LabelButton> Alterar plano </LabelButton>
           </ContentButton>
        </ButtonBox>
      </ContentButtons>
    </Content>
  );
}

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const DadosPlano = styled.div`
  border-radius: 16px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  overflow: auto;
`;

const HeaderCard = styled.label`
  font-size: 18px;
  font-weight: 700;
`;

const Modulos = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;
  
const Modulo = styled.label`
  color: #505050;
  font-size: 12px;
  font-weight: 500;
`;

const ContentValor = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const Label = styled.label`
  color: #09101D;
  font-size: 14px;
  font-weight: 500;
`;

const ContentButtons = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
`;

const ContentButton = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const LabelButton = styled.label`
  font-size: 14px;
  font-weight: 700;
  align-self: start;
`;

const TipoPagamento = styled.label`
  font-size: 16px;
`;