import React from 'react';
import { Global, css } from '@emotion/core';

const GlobalStyles = () => (
    <Global
      styles={styles}
    />
);

const styles = css`
    @font-face {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: bold;
        src: local('Poppins Bold'),
        local('Poppins-Bold'),
        url("../assets/fonts/Poppins-Bold.ttf") format('truetype');
    }

    @font-face {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: normal;
        src: local('Poppins Bold'),
        local('Poppins-Bold'),
        url("../assets/fonts/Poppins-Bold.ttf") format('truetype');
    }

    @font-face {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 900;
      src: url("../assets/fonts/Poppins-Black.ttf") format('truetype');
    }

    @font-face {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 700;
      src: local('Poppins Bold'),
      local('Poppins-Bold'),
      url("../assets/fonts/Poppins-Bold.ttf") format('truetype');
    }

    @font-face {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 800;
      src: url("../assets/fonts/Poppins-ExtraBold.ttf") format('truetype');
    }

    @font-face {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 200;
      src: url("../assets/fonts/Poppins-Light.ttf") format('truetype');
    }

    @font-face {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 500;
      src: url("../assets/fonts/Poppins-Medium.ttf") format('truetype');
    }

    @font-face {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 400;
      src: url("../assets/fonts/Poppins-Regular.ttf") format('truetype');
    }

    @font-face {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 600;
      src: url("../assets/fonts/Poppins-SemiBold.ttf") format('truetype');
    }

    @font-face {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 100;
      src: url("../assets/fonts/Poppins-Thin.ttf") format('truetype');
    }

    * {
        outline: none;
    }

    /* width */
    ::-webkit-scrollbar {
        width: 12px !important;
        height: 8px !important;
        -moz-appearance: none !important;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: #969696 !important;
        -moz-appearance: none !important;
        border-radius: 56px !important;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
        background: lighten(#969696, 10) !important;
        -moz-appearance: none !important;
    }

    body {
        margin: 0;
        height: 100vh;
        overflow: hidden;
    }

    #root {
        display: flex;
        height: 100%;
    }

    input,
    button,
    div,
    p,
    h1,
    h2,
    h3,
    h4,
    h5,
    label,
    span:not(.material-icons) {
        font-family: "Poppins", "Helvetica", sans-serif !important;
        font-size: 14px;
    }
`;

export default GlobalStyles;
