export const downloadUrl = async (nomeDownload, url) => {
  const pdf = await fetch(url);
  const pdfBlog = await pdf.blob();
  const pdfURL = URL.createObjectURL(pdfBlog);

  const link = document.createElement('a');
  link.href = pdfURL;
  link.download = nomeDownload;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};