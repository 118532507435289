import styled from '@emotion/styled';
import React, { useEffect, useState } from 'react';
import {
  boletoDefault,
  calculaSubTotal,
  descontoDefault,
  modalFinanceiro,
} from './constants';
import Number from '../../../../utils/number';
import ButtonBox from '../../../../components/button/ButtonBox';
import { ArrowSyncCircle } from '../../../../components/icons/ArrowSyncCircle';
import { Desconto } from './desconto';

export const Financeiro = ({
  dados,
  setNotification,
  onClose,
  contentModalFinanceiro,
  setContentModalFinanceiro,
}) => {
  const { meioPagamentoPlataforma, id, valor, valorUnico } = dados;

  const [dadosVenda, setDados] = useState({ ...dados, boleto: boletoDefault });
  const [tabSelected, setTabSelected] = useState(
    meioPagamentoPlataforma || 'CIELO'
  );

  const [desconto, setDesconto] = useState(descontoDefault(dados));

  useEffect(() => {
    const subTotal = calculaSubTotal(desconto, valor);
    handleAtualizaDesconto('valorSubtotal', subTotal);
  }, [desconto.tipoDesconto, desconto.valorDesconto]);

  const Component = modalFinanceiro[contentModalFinanceiro].content;

  const handleMeioPagamento = (meioPagamento, field, value) => {
    setDados((prev) => {
      const meioPagamentoSelecionado =
        meioPagamento === 'cartaoCredito' ? prev.cartaoCredito : prev.boleto;
      return {
        ...prev,
        meioPagamentoPlataforma: tabSelected,
        [meioPagamento]: {
          ...meioPagamentoSelecionado,
          [field]: value,
        },
      };
    });
  };

  const handleAtualizaDesconto = (field, value) => {
    const dadosDesconto =
      field === 'utilizaDesconto' && value ? descontoDefault(valor) : desconto;

    let descontoAtualizado = {
      ...dadosDesconto,
      [field]: value,
    };

    if (field === 'tipoDesconto') {
      descontoAtualizado = {
        ...dadosDesconto,
        valorDesconto: 0,
        [field]: value,
      };
    }

    setDesconto(descontoAtualizado);
  };

  return (
    <Container>
      <div>
        <ContentValores>
          <LabelTotal>Total recorrente: </LabelTotal>
          <LabelValor> {Number.currencyFormat(valor)} </LabelValor>
        </ContentValores>
        <ContentValores>
          <LabelTotal>Total adicionais pagamento único: </LabelTotal>
          <LabelValor> {Number.currencyFormat(valorUnico)} </LabelValor>
        </ContentValores>
      </div>
      <Desconto
        handleAtualizaDesconto={handleAtualizaDesconto}
        desconto={desconto}
        vendaId={id}
        setNotification={setNotification}
      />
      <Component
        setTabSelected={setTabSelected}
        tabSelected={tabSelected}
        dados={dadosVenda}
        setNotification={setNotification}
        handleMeioPagamento={handleMeioPagamento}
        handleCloseModal={onClose}
      />
      <Footer>
        {contentModalFinanceiro === 'pagamento' && (
          <ButtonBox
            onClick={() => setContentModalFinanceiro('alterarPagamento')}
          >
            <ContentButton>
              <ArrowSyncCircle />
              <LabelButton> Alterar pagamento </LabelButton>
            </ContentButton>
          </ButtonBox>
        )}
      </Footer>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const Footer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 4px;
  justify-content: end;
`;

const ContentValores = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const LabelTotal = styled.label`
  font-size: 24px;
  font-weight: 500;
`;

const LabelValor = styled.label`
  color: #09101d;
  font-size: 18px;
  font-weight: 500;
`;

const ContentButton = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const LabelButton = styled.label`
  font-size: 14px;
  font-weight: 700;
  align-self: start;
`;
