import React from 'react';
import { SvgIcon } from '@material-ui/core';
export const FilterIcon = (props) => (
  <SvgIcon {...props} viewBox="0 0 20 20">
    <path
      d="M11.7344 13.1396C12.1227 13.1396 12.4375 13.4544 12.4375 13.8428C12.4375 14.2311 12.1227 14.5459 11.7344 14.5459H7.51562C7.1273 14.5459 6.8125 14.2311 6.8125 13.8428C6.8125 13.4544 7.1273 13.1396 7.51562 13.1396H11.7344ZM13.6094 9.15527C13.9977 9.15527 14.3125 9.47007 14.3125 9.8584C14.3125 10.2467 13.9977 10.5615 13.6094 10.5615H5.64062C5.2523 10.5615 4.9375 10.2467 4.9375 9.8584C4.9375 9.47007 5.2523 9.15527 5.64062 9.15527H13.6094ZM15.4844 5.1709C15.8727 5.1709 16.1875 5.4857 16.1875 5.87402C16.1875 6.26235 15.8727 6.57715 15.4844 6.57715H3.76562C3.3773 6.57715 3.0625 6.26235 3.0625 5.87402C3.0625 5.4857 3.3773 5.1709 3.76562 5.1709H15.4844Z"
      fill="currentColor"
    />
  </SvgIcon>
);
