import React, { useEffect } from 'react';
import styled from '@emotion/styled';
import { TextField } from '../../../../components/TextField';

const Modulos = ({ dados, handleAtualizarDados, handleDadosInvalidos, selectedSecretaria, handleSelectSecretaria }) => {
  const { modulos } = dados;


  useEffect(() => {
    handleDadosInvalidos && handleDadosInvalidos(false);
  }, []);


  const handleModulo = (field, value, index) => {
    const { tipoSecretariaVirtual } = dados.modulos[index]


    const secretariaCondition = selectedSecretaria !== tipoSecretariaVirtual
    const secretariaExist = !!selectedSecretaria && !!tipoSecretariaVirtual

    if (value) {
      if (secretariaCondition && secretariaExist) {
        return
      }
      tipoSecretariaVirtual && handleSelectSecretaria(tipoSecretariaVirtual)
    }
    else {
      tipoSecretariaVirtual && handleSelectSecretaria(null)
    }


    modulos[index] = {
      ...modulos[index],
      [field]: value,
    };

    handleAtualizarDados({
      modulos: modulos,
    });
  };

  const handleClickValor = (e) => {
    e.stopPropagation();
  };


  return (
    <Content>
      {modulos?.length > 0 &&
        modulos.map((modulo, index) => {
          const { id, funcionalidade, selected, valor, alterarValor } = modulo;
          return (
            <Modulo
              key={id}
              selected={selected}
              onClick={() => handleModulo('selected', !modulo.selected, index)}
            >
              <TitleModulo>{funcionalidade}</TitleModulo>
              <ContentValor>
                <TextField
                  mask={'moeda'}
                  value={String(valor) || ''}
                  onClick={handleClickValor}
                  onChange={(e) => handleModulo('valor', e.target.value, index)}
                  disabled={!alterarValor}
                />
              </ContentValor>
            </Modulo>
          );
        })}
    </Content>
  );
};

export default Modulos;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  align-items: center;
  height: calc(100% - 72px);
  overflow: auto;
`;

const Modulo = styled.div`
  display: flex;
  padding: 8px;
  border-radius: 10px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  gap: 16px;
  background-color: ${(props) => (props.selected ? '#868686' : '#f2f2f2')};
  color: ${(props) => (props.selected ? '#fff' : '#000')};
  justify-content: space-between;
  cursor: pointer;
  width: calc(100% - 18px);
`;

const TitleModulo = styled.div`
  width: 100%;
  align-content: center;
`;

const ContentValor = styled.div`
  max-width: 150px;

  & > div > div {
    background: #fff;
  }
`;
