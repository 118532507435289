import styled from '@emotion/styled';
import React, { useState } from 'react';
import moment from 'moment';
import Search from './components/search';
import List from '../../../components/list/list';
import { cpfCnpjFormatted } from '../../../utils/masks';
import { LIST_QUANTIDADE_WHATSAPP } from '../../../graphql/queryes';
import { useQuery } from 'react-apollo';

const columns = [
  {
    title: 'Data',
    value: (item) => moment(item.data).format("DD/MM/YYYY"),
  },
  {
    title: 'ID',
    value: (item) => item.id,
  },
  {
    title: 'CPF/CNPJ',
    value: (item) => cpfCnpjFormatted(item.documento),
  },
  {
    title: 'Razão social',
    value: (item) => item.nomeFantasia,
  },
  {
    title: 'Mensagem',
    value: (item) => item.quantidade,
  },
];

const filtersDefault = {
  search: '',
  dataInicio: moment().startOf('month').format('YYYY-MM-DD'),
  dataFim: moment().endOf('month').format('YYYY-MM-DD'),
};

const pageableDTODefault = {
  pageNumber: 0,
  pageSize: 30,
  sortDir: "DESC",
  sortField: "dataLancamento",
};

const RelatorioWhatsapp = () => {
  const [filters, setFilters] = useState(filtersDefault);

  const {data, loading, error, refetch, fetchMore} = useQuery(LIST_QUANTIDADE_WHATSAPP, {
    variables: {
      pageableDTO: {
        ...pageableDTODefault,
      },
      ...filters,
    },
    fetchPolicy: 'no-cache'
  });

  const handleLoadMore = e => {
    e.preventDefault();
    if (!data?.relatorioEnvioMensagens?.last) {
      fetchMore({
        variables: {
          pageableDTO: {
            ...pageableDTODefault,
            pageNumber: data.relatorioEnvioMensagens.number + 1,
          },
          ...filters
        },
        updateQuery: (prev, {fetchMoreResult}) => {
          if (!fetchMoreResult) return prev;

          return ({
            relatorioEnvioMensagens: {
              ...prev.relatorioEnvioMensagens,
              ...fetchMoreResult.relatorioEnvioMensagens,
              content: [
                ...prev.relatorioEnvioMensagens.content,
                ...fetchMoreResult.relatorioEnvioMensagens.content
              ]
            }
          });
        }
      });
    }
  };

  return (
    <ContentRelatorioWhatsapp>
      <Search setFilters={setFilters} filters={filters} loadRelatorio={refetch}/>
      <List
        columns={columns}
        items={data?.relatorioEnvioMensagens?.content}
        loading={loading}
        error={error}
        last={data?.relatorioEnvioMensagens?.last}
        onLoadMore={handleLoadMore}
      />
    </ContentRelatorioWhatsapp>
  );
};

const ContentRelatorioWhatsapp = styled.div`
  padding: 16px;
`;

export default RelatorioWhatsapp;
