import React from 'react';
import styled from '@emotion/styled';
import { SelectSearch } from '../../../../components/select/SelectSearch';

const periodos = [
  {
    value: 7,
    name: '7 dias',
  },
  {
    value: 30,
    name: '30 dias',
  },
  {
    value: 90,
    name: '90 dias',
  },
];

export const ContentTesteGratis = ({
  title,
  periodoSelected,
  handlePeriodo,
}) => {
  return (
    <div>
      {title}
      <SelectSearchStyled
        placeholder="Periodo"
        value={periodoSelected}
        elements={periodos}
        onChange={handlePeriodo}
        menuPosition="fixed"
      />
    </div>
  );
};

const SelectSearchStyled = styled(SelectSearch)`
  width: 70%;
`;
