import React from 'react';
import { Link, useHistory } from 'react-router-dom';

import Route from 'react-router-dom/Route';
import styled from '@emotion/styled';
import Colors from '../../theme/Colors';
import { Grid } from '@material-ui/core';
import RelatorioWhatsapp from './whatsapp';
import { withBaseLayout } from '../../layout/base-layout';

const subMenus = [
  {
    title: 'WhatsApp',
    uri: '/dashboard/relatorios/whatsapp',
    component: RelatorioWhatsapp,
  },
];

const Relatorios = () => {
  const history = useHistory();

  return (
    <Content>
      <SubMenu>
        {subMenus.map((item, index) => (
          <LinkStyled
            to={item.uri}
            replace
            key={index}
            isSelected={history.location.pathname === item.uri}
          >
            {item.title}
          </LinkStyled>
        ))}
      </SubMenu>
      {subMenus?.length > 0 &&
        subMenus?.map(({ uri, component: Component }) => (
          <Route exact key={uri} path={uri} component={() => <Component />} />
        ))}
    </Content>
  );
};

export default withBaseLayout(Relatorios, { hasSubMenu: true });

const Content = styled(Grid)`
  display: grid;
  grid-template-columns: 240px 1fr;
  width: 100%;
  height: calc(100vh - 80px);
  background-color: #f5f5f5;
`;

const SubMenu = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${Colors.commons.grayLight};
`;

const LinkStyled = styled(Link)`
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: end;
  padding: 0 16px;
  margin: 16px 0 0 16px;
  border-radius: 16px 0 0 16px;
  text-decoration: none;
  color: #505050;
  background-color: ${({ isSelected }) =>
    isSelected && 'rgba(38, 172, 169, 0.1)'};
`;
