import React from 'react';
import { SvgIcon } from '@material-ui/core';
export const PersonCallIcon = (props) => (
  <SvgIcon {...props} viewBox="0 0 20 20">
    <path
      d="M7.66675 0C5.45761 0 3.66675 1.79086 3.66675 4C3.66675 6.20914 5.45761 8 7.66675 8C9.87589 8 11.6667 6.20914 11.6667 4C11.6667 1.79086 9.87589 0 7.66675 0ZM2.67547 9C1.5699 9 0.666748 9.88687 0.666748 11C0.666748 12.6912 1.49956 13.9663 2.80174 14.7966C4.08372 15.614 5.81201 16 7.66675 16C8.54666 16 9.39811 15.9131 10.1869 15.7345C10.1269 15.3019 10.2022 14.8523 10.4149 14.4582L10.4271 14.4356L10.5098 14.2995L11.0965 13.4376C11.5409 12.7847 12.3285 12.4469 13.1129 12.614L13.1403 12.6199L13.2951 12.6621L13.8283 12.8399C13.9844 12.6721 14.1256 12.4811 14.2515 12.2646C14.3765 12.0495 14.4682 11.8408 14.5308 11.6376L14.0516 11.1827C13.5005 10.6597 13.3 9.88046 13.4803 9.17242C13.2317 9.0616 12.9564 9.00001 12.6668 9.00001L2.67547 9ZM14.5013 9.26491L14.9004 8.32365C15.0877 7.88219 15.5361 7.64207 15.9758 7.73369L16.0698 7.75845L16.5697 7.91817C17.0653 8.07654 17.4449 8.50112 17.5674 9.03427C17.8587 10.3013 17.5092 11.8439 16.519 13.6622C15.5301 15.4777 14.4464 16.5694 13.2676 16.9371C12.8099 17.0798 12.3182 16.9761 11.9484 16.6656L11.8507 16.5758L11.471 16.1947C11.142 15.8644 11.0743 15.3419 11.2949 14.9331L11.3509 14.8409L11.9231 14.0003C12.1482 13.6695 12.5334 13.513 12.9044 13.5921L13.0051 13.6195L14.0615 13.9718C14.483 13.6528 14.8345 13.2513 15.1159 12.7673C15.3571 12.3524 15.5149 11.9319 15.5892 11.5057L15.6194 11.2922L14.7399 10.4574C14.4502 10.1824 14.3449 9.75422 14.4634 9.36881L14.5013 9.26491Z"
      fill="currentColor"
    />
  </SvgIcon>
);
