import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import MuiExpansionPanel from '@material-ui/core/ExpansionPanel';
import MuiExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import MuiExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const ExpansionPanel = withStyles({
  root: {
    border: '1px solid rgba(0,0,0,.125)',
    borderRadius: '10px',
    boxShadow: 'none',
  },
  expanded: {
    margin: 'auto',
    border: '1px solid rgba(0,0,0,.125)',
    borderRadius: '10px',
  },
})(MuiExpansionPanel);

const ExpansionPanelSummary = withStyles({
  root: {
    backgroundColor: '#F2F2F2',
    padding: '0 8px',
    borderRadius: '10px',
    minHeight: '46px',
    height: '46px',
    '&$expanded': {
      minHeight: '46px',
      borderRadius: '10px 10px 0 0',
    },
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
})((props) => <MuiExpansionPanelSummary {...props} />);

ExpansionPanelSummary.muiName = 'ExpansionPanelSummary';

const ExpansionPanelDetails = withStyles((theme) => ({
  root: {
    padding: '8px',
  },
}))(MuiExpansionPanelDetails);

const Accordion = ({ children, title, expanded, setExpanded, panel }) => {
  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <ExpansionPanel
      square
      expanded={expanded === panel}
      onChange={handleChange(panel)}
    >
      <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
        {title}
      </ExpansionPanelSummary>
      <ExpansionPanelDetails>{children}</ExpansionPanelDetails>
    </ExpansionPanel>
  );
};

export default Accordion;
