import { z } from "zod";

const nonEmptyString = (errorMessage) => z.preprocess((val) => {
  if (val === null || val === undefined) {
    return "";
  }
  return val;
}, z.string().min(1, errorMessage));

const dadoBancarioSchema = z.object({
  banco: z.object({}, { message: "Escolha um banco", required_error: "Escolha um banco" }),
  agencia: nonEmptyString("Agência não pode ser vazia." ),
  conta: nonEmptyString("Conta não pode ser vazia." ),
  tipoConta: z.enum(["CONTA_CORRENTE", "CONTA_POUPANCA"]).default("CONTA_CORRENTE"),
  documento: nonEmptyString("Documento não pode ser vazio."),
  operacao: z.string().optional().default(""),
});

const enderecoSchema = z.object({
  bairro: nonEmptyString("Bairro não pode ser vazio."),
  cep: nonEmptyString("CEP não pode ser vazio."),
  complemento: z.string().optional().default(""),
  estado: z.object({}, { message: "Selecione um estado", required_error: "Selecione um estado" }),
  municipio: z.object({}, { message: "Selecione um municipio", required_error: "Selecione um municipio" }),
  nomeLogradouro: nonEmptyString("Nome do Logradouro não pode ser vazio."),
  numero: nonEmptyString("Número não pode ser vazio."),
});

export const recebimentosSchema = z.object({
  dadoBancario: dadoBancarioSchema,
  endereco: enderecoSchema,
  documentoTipo: z.enum(["CNPJ", "CPF"]).default("CNPJ"),
  nomeFantasia: nonEmptyString("Nome Fantasia não pode ser vazio."),
  razaoSocial: nonEmptyString("Razão Social não pode ser vazia."),
  status: z.string().nullable(),
  responsavelTelefone: nonEmptyString("Telefone do responsável não pode ser vazio."),
  responsavelNome: nonEmptyString("Nome do responsável não pode ser vazio."),
  email: z.string().email("E-mail inválido.").trim().min(1, { message: "E-mail não pode ser vazio." }),
});

const errorMapping = {
  'dadoBancario.banco': 'banco',
  'dadoBancario.agencia': 'agencia',
  'dadoBancario.conta': 'conta',
  'dadoBancario.documento': 'documento',
  'endereco.bairro': 'bairro',
  'endereco.cep': 'cep',
  'endereco.estado': 'estado',
  'endereco.numero': 'numero',
  'endereco.municipio': 'municipio',
  'endereco.nomeLogradouro': 'nomeLogradouro',
  'responsavelNome': 'responsavelNome',
  'responsavelTelefone': 'responsavelTelefone',
  'nomeFantasia': 'nomeFantasia',
  'razaoSocial': 'razaoSocial',
  'email': 'email',
};

export const parseSchemaAndValidate = (data, ERROR_SCHEMA) => {
  try {
    recebimentosSchema.parse(data);
  } catch (e) {
    const updatedErrorSchema = { ...ERROR_SCHEMA };

    e.errors.forEach(error => {
      const field = error.path.join('.');
      const errorKey = errorMapping[field];
      if (errorKey) {
        updatedErrorSchema[errorKey] = error.message;
      }
    });

    return updatedErrorSchema;
  }
  return ERROR_SCHEMA;
}