let string = {
    format(mask, value) {
        if (mask == null || value == null) return value;

        let formatted = '';
        let next = 0;
        let ignore = 0;
        for (let i = 0; i < mask.length; i++) {
            if (i - ignore >= value.length) break;
            if (mask[i] === '#') formatted += value[next++];
            else {
                formatted += mask[i];
                ignore++;
            }
        }
        return formatted;
    },

    removeSpecialChars(value) {
        if (value == null || value.length < 1) return value;
        return value.replace(/[^A-Z0-9]+/ig, '');
    },

    currencyMaskToFloat(value) {
        if (typeof value === 'string' && value.trim().length > 0) {
            const newValue = value.replace(/[^,0-9]+/ig, '').replace(',', '.');

            return parseFloat(newValue);
        }

        return 0;
    },

    validaCPF(cpf) {
        if (this.isEmpty(cpf)) {
            return false;
        }

        const CPFS_INVALIDOS = [
            '00000000000', '11111111111', '22222222222', '33333333333', '44444444444',
            '55555555555', '66666666666', '77777777777', '88888888888', '99999999999'];
        cpf = this.removeSpecialChars(cpf);
        if (CPFS_INVALIDOS.indexOf(cpf) > -1) return false;

        let soma = 0;
        for (let i = 1; i <= 9; i++)
            soma += parseInt(cpf.substring(i - 1, i)) * (11 - i);
        let resto = (soma * 10) % 11;

        if (resto === 10 || resto === 11)
            resto = 0;

        if (resto !== parseInt(cpf.substring(9, 10)))
            return false;

        soma = 0;
        for (let i = 1; i <= 10; i++)
            soma += parseInt(cpf.substring(i - 1, i)) * (12 - i);
        resto = (soma * 10) % 11;

        if (resto === 10 || resto === 11)
            resto = 0;

        return resto === parseInt(cpf.substring(10, 11));
    },

    validaCNPJ(cnpj) {
        cnpj = cnpj.replace(/[^\d]+/g, '');

        if (cnpj === '') return false;

        if (cnpj.length !== 14)
            return false;

        if (cnpj === "00000000000000" ||
            cnpj === "11111111111111" ||
            cnpj === "22222222222222" ||
            cnpj === "33333333333333" ||
            cnpj === "44444444444444" ||
            cnpj === "55555555555555" ||
            cnpj === "66666666666666" ||
            cnpj === "77777777777777" ||
            cnpj === "88888888888888" ||
            cnpj === "99999999999999")
            return false;

        let tamanho = cnpj.length - 2
        let numeros = cnpj.substring(0, tamanho);
        let digitos = cnpj.substring(tamanho);
        let soma = 0;
        let pos = tamanho - 7;
        let i;
        for (i = tamanho; i >= 1; i--) {
            soma += numeros.charAt(tamanho - i) * pos--;
            if (pos < 2)
                pos = 9;
        }
        let resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
        if (resultado !== parseInt(digitos.charAt(0)))
            return false;

        tamanho = tamanho + 1;
        numeros = cnpj.substring(0, tamanho);
        soma = 0;
        pos = tamanho - 7;
        for (i = tamanho; i >= 1; i--) {
            soma += numeros.charAt(tamanho - i) * pos--;
            if (pos < 2)
                pos = 9;
        }
        resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
        if (resultado !== parseInt(digitos.charAt(1)))
            return false;

        return true;
    },

    validaCartaoCredito(value) {
        if (/[^0-9-\s]+/.test(value)) return false;

        let nCheck = 0, bEven = false;
        value = value.replace(/\D/g, "");

        for (var n = value.length - 1; n >= 0; n--) {
            var cDigit = value.charAt(n),
                nDigit = parseInt(cDigit, 10);

            if (bEven && (nDigit *= 2) > 9) nDigit -= 9;

            nCheck += nDigit;
            bEven = !bEven;
        }

        return (nCheck % 10) === 0;
    },

    validaEmail(email) {
        // eslint-disable-next-line no-control-regex,no-useless-escape
        const expression = /(?!.*\.{2})^([a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+(\.[a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+)*|"((([\t]*\r\n)?[\t]+)?([\x01-\x08\x0b\x0c\x0e-\x1f\x7f\x21\x23-\x5b\x5d-\x7e\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|\\[\x01-\x09\x0b\x0c\x0d-\x7f\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))*(([\t]*\r\n)?[\t]+)?")@(([a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.)+([a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.?$/i;

        return expression.test(String(email).toLowerCase())
    },

    validaTelefone(tel) {
        if (this.isEmpty(tel)) {
            return false;
        }

        const text = this.removeSpecialChars(tel);
        const length = text.trim().length;

        return length >= 10 && length <= 11;
    },

    validaTelefoneDDI(value, dialCode) {
        const text = string.removeSpecialChars(value);
        const length = text.trim().length;

        const telBr = length >= 10 && length <= 16;
        const telEs = length === 11;

        const isTelefoneValido = dialCode === '55' ? telBr : telEs;

        return isTelefoneValido;
    },

    stringify(objFromJson) {

        if (Array.isArray(objFromJson)) {
            return objFromJson.map(item => this.stringify(item));

        } else if (typeof objFromJson !== "object") {
            // not an object, stringify using native function
            return JSON.stringify(objFromJson);
        }

        let props = Object
            .keys(objFromJson)
            .map(key => objFromJson[key] ? `${key}:${this.stringify(objFromJson[key])}` : '')
            .join(",");

        return `{${props}}`;
    },

    capitalize(text) {
        text = text.toLowerCase();
        return text.charAt(0).toUpperCase() + text.slice(1);
    },

    stringToLowerCase(string, index = 0) {
        return string.charAt(index) + string.slice(1).toLowerCase();
    },

    objToGraphQL(obj) {
        const newObj = this.removeEmpty(obj);

        const objString = JSON.stringify(newObj);
        return objString.replace(/"([^(")"]+)":/g, "$1:");
    },

    isEmpty(text) {
        if (typeof text !== 'string') {
            return true;
        }

        return text.trim().length === 0;
    },

    removeEmpty(obj) {

        const o = JSON.parse(JSON.stringify(obj)); // Clone source oect.

        Object.keys(o).forEach(key => {
            if (o[key] && typeof o[key] === 'object')
                o[key] = this.removeEmpty(o[key]);  // Recurse.
            else if (o[key] === undefined || o[key] === null)
                delete o[key]; // Delete undefined and null.
        });

        return o;
    },

    truncate(str, length, ending) {
        if (typeof str !== 'string') {
            return str;
        }
        if (length == null) {
            length = 100;
        }
        if (ending == null) {
            ending = '...';
        }
        if (str.length > length) {
            return str.substring(0, length - ending.length) + ending;
        }

        return str;
    },

    numberMaskToFloat(value) {
        if (typeof value === 'number') {
            return value;
        }
        return this.isEmpty(value) ? 0 : parseFloat(value.replace(',', '.'));
    },

    removeMask(value, sufix) {
        return parseFloat(value.replace(sufix, "")
            .replace('.', '')
            .replace(",", ".")
        )
    },

};


export default string;
