import React from 'react';

import Button from '../button';

export default function InputFile({ children, buttonProps, ...props }) {
  const inputRef = React.useRef();

  const handleClick = () => {
    inputRef.current.click();
  };

  return (
    <>
      <Button {...buttonProps} onClick={handleClick}>
        {children}
      </Button>
      <input
        {...props}
        ref={inputRef}
        type="file"
        style={{
          display: 'none',
        }}
      />
    </>
  );
}
