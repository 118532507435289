import { useApolloClient } from 'react-apollo';

const defaultGetVariables = (search, _, { page }, oldVariables) => ({
  ...oldVariables,
  searchDTO: {
    ...oldVariables?.searchDTO,
    search,
  },
  pageableDTO: {
    ...oldVariables?.pageableDTO,
    pageNumber: page,
  },
});

export const useAsyncSelectPaginated = (
  query,
  { dataKey, getVariables, ...options }
) => {
  const client = useApolloClient();

  async function loadOptions(
    search,
    loadedOptions,
    { page } = {
      page: 0,
    }
  ) {
    const getVariablesFn = getVariables || defaultGetVariables;

    const response = await client.query({
      query,
      variables: getVariablesFn(
        search,
        loadedOptions,
        { page },
        options.variables
      ),
    });

    const data = response?.data?.[dataKey];
    const pageable = data?.pageable;

    return {
      options: data?.content || [],
      hasMore: pageable?.last,
      additional: {
        page: page + 1,
      },
    };
  }

  return {
    loadOptions,
  };
};
