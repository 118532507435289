import React, { useEffect, useState } from 'react';
import { useLazyQuery } from 'react-apollo';
import Grid from '@material-ui/core/Grid';
import {
  LIST_GRUPO_PERMISSAO,
  FIND_BY_ID_GROUP,
} from '../../../../graphql/queryes';
import Dialog from '../../../../components/Dialog/dialog';
import DialogHeader from '../../../../components/Dialog/dialogHeader';
import { withStyles } from '@material-ui/core';
import List from '../../../../components/list/list';
import ListaPermissao from './listaPermissao';
import ButtonFloat from '../../../../components/button/ButtonFloat';

const columns = [
  {
    title: 'Grupo',
    value: (item) => item.nome,
  },
  {
    title: 'Status',
    value: (item) => (item.ativo ? 'Ativo' : 'Inativo'),
  },
];

const ModalGrupoPermissao = (props) => {
  const { idRede, onClose, open, classes } = props;

  const [isListaPermissao, setIsListaPermissao] = useState(false);
  const [showButtonCriar, setShowButtonCriar] = useState(true);
  const [newGroup, setNewGroup] = useState(false);

  const [
    listarGrupoPermissao,
    { data: listaGrupoPermissao, load: loadingGrupoPermissao },
  ] = useLazyQuery(LIST_GRUPO_PERMISSAO, {
    fetchPolicy: 'no-cache',
  });

  const [
    findByIdGrupoPermissao,
    { data: getDadosGrupoPermissao, loading: loadingDadosGrupoPermissao },
  ] = useLazyQuery(FIND_BY_ID_GROUP, {
    fetchPolicy: 'no-cache',
  });

  useEffect(() => {
    listarGrupoPermissao({ variables: { idRede } });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClickGrupo = (grupo) => {
    findByIdGrupoPermissao({ variables: { idGroup: grupo.id } });
    setShowButtonCriar(false);
    setIsListaPermissao(true);
  };

  const closeModal = () => {
    setIsListaPermissao(false);
    onClose();
  };

  const handleClickCriarGrupo = () => {
    setShowButtonCriar(false);
    setIsListaPermissao(true);
    setNewGroup(true);
  };

  return (
    <Dialog 
      open={open} 
      maxWidth={'md'}
      classes={{paper: classes.paper}}
    >
      <div className={classes.dialog}>
        <DialogHeader
          title="Grupo de permissão"
          closeButton={true}
          actionClose={() => closeModal()}
        />
        {loadingGrupoPermissao && (
          <div className={classes.loading}> Carregando... </div>
        )}

        {isListaPermissao && !loadingDadosGrupoPermissao ? (
          <ListaPermissao
            listaPermissao={getDadosGrupoPermissao?.findGroupById || []}
            onClose={onClose}
            newGroup={newGroup}
            idRede={idRede}
          />
        ) : (
          <Grid className={classes.contentList}>
            {!loadingGrupoPermissao &&
            listaGrupoPermissao &&
            listaGrupoPermissao.findGroupByRedeId.length > 0 ? (
              <List
                items={listaGrupoPermissao.findGroupByRedeId}
                columns={columns}
                loading={false}
                last={true}
                onClickItem={handleClickGrupo}
              />
            ) : (
              <div className={classes.loading}>Nenhum item encontrado</div>
            )}
          </Grid>
        )}
      </div>
      {showButtonCriar && (
        <ButtonFloat title="Criar grupo" onClick={handleClickCriarGrupo} />
      )}
    </Dialog>
  );
};

const styles = () => ({
  paper: {
    overflow: 'hidden',
    width: '80vw',
    height: '80vh',
    padding: '24px'
  },
  dialog: {
    height: '100%',

    '& th': {
      textAlign: 'left',
      top: 0,
      position: 'sticky',
      whiteSpace: 'normal',
      background: '#fff',
      zIndex: 1,
    },
  },
  contentList: {
    overflow: 'auto',
    maxHeight: '500px',
  },
  loading: {
    color: '#00B0AE',
    textAlign: 'center',
    marginTop: 8,
  },
});

export default withStyles(styles)(ModalGrupoPermissao);
