import React from 'react';
import InputDateForm from '../../../../components/input/InputDateForm';
import { InputPhoneForm } from '../../../../components/input/InputPhoneForm';
import { TextField } from '../../../../components/TextField';
import { usePerfilPublicoContext } from '../../perfil-publico-context';
import Textarea from '../../../../components/Textarea';
import FormControl from '../../../../components/FormControl';
import { Card } from '../../../../components/Card';

export const PerfilPublicoDadosPessoais = () => {
  const { setField, values, errors, unidade } = usePerfilPublicoContext();

  const handleSetDadosPessoais = (name, value) => {
    setField('dadosPessoais.' + name, value);
  };

  const dadosPessoais = values.dadosPessoais || {};
  const dadosPessoaisErrors = errors.dadosPessoais || {};

  return (
    <Card>
      <h2>{unidade ? 'Dados da cadastrais da Unidade' : 'Dados pessoais'}</h2>

      {unidade && (
        <div>
          <TextField
            label="Razão Social"
            value={dadosPessoais.razaoSocial}
            onChange={(e) =>
              handleSetDadosPessoais('razaoSocial', e.target.value)
            }
          />
          {dadosPessoaisErrors.razaoSocial && (
            <span
              style={{
                color: 'red',
                fontSize: '12px',
              }}
            >
              {dadosPessoaisErrors.razaoSocial}
            </span>
          )}
        </div>
      )}

      <div>
        <TextField
          label={unidade ? 'Nome Fantasia' : 'Nome'}
          value={dadosPessoais.nome}
          onChange={(e) => handleSetDadosPessoais('nome', e.target.value)}
        />
        {dadosPessoaisErrors.nome && (
          <span
            style={{
              color: 'red',
              fontSize: '12px',
            }}
          >
            {dadosPessoaisErrors.nome}
          </span>
        )}
      </div>

      {!unidade && (
        <>
          <div>
            <Textarea
              label="Sobre"
              error={dadosPessoaisErrors.sobre}
              value={dadosPessoais.sobre}
              onChange={(e) => handleSetDadosPessoais('sobre', e.target.value)}
            />
          </div>

          <FormControl error={dadosPessoaisErrors.dataNascimento}>
            <InputDateForm
              label="Data de nascimento"
              value={dadosPessoais.dataNascimento}
              onChange={(e) => handleSetDadosPessoais('dataNascimento', e)}
            />
          </FormControl>
        </>
      )}

      <FormControl label="Telefone" error={dadosPessoaisErrors.telefone}>
        <InputPhoneForm
          value={dadosPessoais.telefone}
          onChange={(e) => handleSetDadosPessoais('telefone', e.target.value)}
        />
      </FormControl>

      <FormControl
        label="Telefone Secundario"
        error={dadosPessoaisErrors.telefoneSecundario}
      >
        <InputPhoneForm
          value={dadosPessoais.telefoneSecundario}
          onChange={(e) =>
            handleSetDadosPessoais('telefoneSecundario', e.target.value)
          }
        />
      </FormControl>

      <div>
        <TextField
          label="Email"
          value={dadosPessoais.email}
          onChange={(e) => handleSetDadosPessoais('email', e.target.value)}
        />

        {dadosPessoaisErrors.email && (
          <span
            style={{
              color: 'red',
              fontSize: '12px',
            }}
          >
            {dadosPessoaisErrors.email}
          </span>
        )}
      </div>
    </Card>
  );
};
