import styled from '@emotion/styled';
import React from 'react';
import { tiposPagamentoPlanos } from '../../../../constants';
import { TextField } from '../../../../../../components/TextField';
import Number from '../../../../../../utils/number';

export const DadosPlano = ({ dados, handleDados }) => {

  return (
    <Content>
      <TextField
        label="Nome"
        placeholder="Digite o nome"
        value={dados.nome}
        onChange={(e) => handleDados(e.target.value, 'nome')}
      />
      <RowTipoPagamento>
        {tiposPagamentoPlanos.map((tipo, index) => {
          const { label, field } = tipo;

          return (
            <TextField
              key={index}
              label={label}
              mask={'moeda'}
              placeholder="Digite o valor"
              value={dados[field]}
              onChange={(e) => handleDados(e.target.value, field)}
            />
          );
        })}
      </RowTipoPagamento>
    </Content>
  );
};

const Content = styled.div`
  flex: 1;
  overflow-y: auto;
  display: grid;
  gap: 16px;
  grid-template-rows: repeat(3, max-content);
`;

const RowTipoPagamento = styled.div`
  display: grid;
  gap: 16px;
  grid-template-columns: 1fr 1fr 1fr;
`;
