import React from 'react';
import styled from '@emotion/styled';

export const Field = ({ title, value }) => {
  return (
    <Content>
      <Title>{title}</Title>
      <Label>{value}</Label>
    </Content>
  );
};

const Content = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 91px;
`;

const Title = styled.label`
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
`;

const Label = styled.label`
  color: #868686;
  font-size: 14px;
`;

export const FieldDestaque = ({ title, value }) => {
  return (
    <ContentField>
      <TitleField>{title}</TitleField>
      <Label>{value}</Label>
    </ContentField>
  );
};

const ContentField = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 91px;
`;

const TitleField = styled.label`
  font-size: 14px;
  line-height: 21px;
  color: #333333;
`;
