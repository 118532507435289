import React, { useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { useLazyQuery, useQuery, useApolloClient } from 'react-apollo';
import TextFieldSearch from '../../../../components/TextField/TextFieldSearch';
import { TextField } from '../../../../components/TextField';
import {
  CONSULTA_CEP,
  FIND_ALL_ESPANHA_COMUNIDADE_AUTONOMA,
  FIND_PROVINCIA,
  DROPDOWN_TIPO_LOGRADOURO,
  DROPDOWN_MUNICIPIOS,
  FIND_ESPANHA_MUNICIPIOS,
} from '../../../../graphql/queryes';
import string from '../../../../utils/string';
import {
  FormGroup,
  SelectSearchStyled
} from './styles';

const FormDadosEndereco = (props) => {
  const { setFormData, formData, handleDadosInvalidos } = props;

  const client = useApolloClient();
  const [getProvincias, { data: provincias }] = useLazyQuery(FIND_PROVINCIA);
  const [getConsultaCep, { data: dadosConsultaCep }] =
    useLazyQuery(CONSULTA_CEP);
  const { data: comunidadesAutonoma } = useQuery(
    FIND_ALL_ESPANHA_COMUNIDADE_AUTONOMA
  );

  useEffect(() => {
    if (
      formData.paisAtuacao?.label === 'BRASIL' &&
      dadosConsultaCep?.consultaDeCep
    ) {
      setFormData({
        ...formData,
        nomeLogradouro: dadosConsultaCep?.consultaDeCep?.logradouro,
        bairro: dadosConsultaCep?.consultaDeCep?.bairro,
        tipoLogradouro: {
          ...dadosConsultaCep?.consultaDeCep?.tipoLogradouro,
          label: `${
            dadosConsultaCep.consultaDeCep?.tipoLogradouro?.label || '-'
          }`,
        },
        municipio: {
          ...dadosConsultaCep.consultaDeCep?.municipio,
          label: `${
            dadosConsultaCep.consultaDeCep?.municipio?.label || '-'
          } - ${dadosConsultaCep.consultaDeCep?.municipio?.uf || '-'}`,
        },
        estado: {
          ...dadosConsultaCep.consultaDeCep?.municipio.estado,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dadosConsultaCep]);

  useEffect(() => {
    const isCamposValidos =
      formData.cep === undefined ||
      (formData.paisAtuacao?.label === 'ESPANHA'
        ? string.removeSpecialChars(formData.cep).length < 5
        : string.removeSpecialChars(formData.cep).length < 8) ||
      !formData.bairro ||
      !formData.nomeLogradouro ||
      (formData.paisAtuacao?.label === 'BRASIL' && !formData.municipio) ||
      (formData.paisAtuacao?.label === 'ESPANHA' && !formData.espanhaMunicipio);

    handleDadosInvalidos(isCamposValidos);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    handleDadosInvalidos,
    formData.cep,
    formData.bairro,
    formData.nomeLogradouro,
    formData.municipio,
    formData.espanhaMunicipio,
  ]);

  const errorCPF = () => {
    return formData.paisAtuacao?.label === 'ESPANHA'
      ? string.removeSpecialChars(formData.cep).length < 5
      : string.removeSpecialChars(formData.cep).length < 8;
  };

  const handleChangeCep = (e) => {
    const cpfSemMascara = string.removeSpecialChars(e.target.value);
    setFormData({
      ...formData,
      cep: e.target.value,
    });

    if (cpfSemMascara.length === 8) {
      consultaCep(cpfSemMascara);
    }
  };

  const consultaCep = async (cep) => {
    await getConsultaCep({
      variables: {
        cep: cep,
      },
    });
  };

  const handleChangeCampos = (e, field) => {
    setFormData({
      ...formData,
      [field]: e.target.value,
    });
  };

  const onEspanhaComunidadeAutonomaChange = (comunidadeAutonoma) => {
    findProvinciaBy(comunidadeAutonoma);
    setFormData({
      ...formData,
      comunidadeAutonoma: comunidadeAutonoma,
      provincia: null,
      espanhaMunicipio: null,
    });
  };

  const findProvinciaBy = (comunidadeAutonoma) => {
    getProvincias({
      variables: {
        pageableDTO: { sortField: 'descricao', sortDir: 'ASC' },
        codigoComunidadeAutonoma: comunidadeAutonoma?.value,
      },
    });
  };

  const onEspanhaProvinciaChange = (provincia) => {
    setFormData({
      ...formData,
      provincia,
      espanhaMunicipio: null,
    });
  };

  const onChangeEspanhaMunicipio = (value) => {
    setFormData({
      ...formData,
      espanhaMunicipio: value,
    });
  };

  const handleTipoLogradouroLoadOptions = async (
    search,
    loadedOptions,
    { page }
  ) => {
    return handleLoadMoreOptions({
      loadedOptions,
      variables: {
        search: {
          pageSize: 10,
          pageNumber: page,
          search,
        },
      },
      responseObject: 'findAllTipoLogradouro',
      query: DROPDOWN_TIPO_LOGRADOURO,
    });
  };
  const handleEspanhaMunicipioLoadOptions = async (
    search,
    loadedOptions,
    { page }
  ) => {
    return handleLoadMoreOptions({
      loadedOptions,
      variables: {
        pageableDTO: {
          pageSize: 10,
          pageNumber: page,
          sortDir: 'ASC',
          sortField: 'descricao',
        },
        codigoProvincia: formData.provincia?.value,
        descricao: search,
      },
      responseObject: 'findAllEspanhaMunicipio',
      query: FIND_ESPANHA_MUNICIPIOS,
    });
  };

  const handleMunicipiosLoadOptions = async (
    search,
    loadedOptions,
    { page }
  ) => {
    return handleLoadMoreOptions({
      loadedOptions,
      variables: {
        searchDTO: {
          pageSize: 10,
          pageNumber: page,
          search,
        },
      },
      responseObject: 'findAllMunicipio',
      query: DROPDOWN_MUNICIPIOS,
    });
  };

  const handleLoadMoreOptions = async ({
    variables,
    query,
    responseObject,
  }) => {
    const response = await client.query({
      query,
      variables,
    });

    const pageNumber = variables?.searchDTO?.pageNumber || 0;

    let options = response?.data?.[responseObject]?.content || [];

    if (responseObject === 'findAllMunicipio') {
      options = options.map((o) => ({
        ...o,
        label: `${o.label} - ${o.uf}`,
      }));
    }

    return {
      options,
      hasMore: !response?.data?.[responseObject]?.last,
      additional: {
        page: pageNumber + 1,
      },
    };
  };

  return (
    <Grid container direction="row" spacing={2}>
      <FormGroup item xs={12}>
        <Typography color="black" component="label">
          {formData.paisAtuacao?.label === 'ESPANHA' ? 'Código postal' : 'CEP'}
        </Typography>
        <TextField
          value={formData.cep}
          mask={
            formData.paisAtuacao?.label === 'ESPANHA' ? 'codigoPostal' : 'cep'
          }
          onChange={(e) => handleChangeCep(e)}
          error={errorCPF()}
          helperText={errorCPF() && 'CEP inválido'}
        />
      </FormGroup>
      <FormGroup item xs={12}>
        <Typography color="black" component="label">
          Número*
        </Typography>
        <TextField
          value={formData.numero}
          onChange={(e) => handleChangeCampos(e, 'numero')}
          error={!formData.numero}
          helperText={!formData.numero && 'Campo obrigatório'}
        />
      </FormGroup>
      {formData.paisAtuacao?.label === 'BRASIL' && (
        <FormGroup item xs={12}>
          <Typography color="black" component="label">
            Tipo Logradouro*
          </Typography>
          <TextFieldSearch
            placeholder=""
            loadOptions={handleTipoLogradouroLoadOptions}
            withPaginate
            value={formData.tipoLogradouro}
            onChange={(e) =>
              setFormData({
                ...formData,
                tipoLogradouro: e,
              })
            }
            debounceTimeout={300}
            additional={{
              page: 0,
            }}
          />
        </FormGroup>
      )}
      <FormGroup item xs={12}>
        <Typography color="black" component="label">
          Logradouro*
        </Typography>
        <TextField
          value={formData.nomeLogradouro}
          onChange={(e) => handleChangeCampos(e, 'nomeLogradouro')}
          error={!formData.nomeLogradouro}
          helperText={!formData.nomeLogradouro && 'Campo obrigatório'}
        />
      </FormGroup>
      <FormGroup item xs={12}>
        <Typography color="black" component="label">
          Bairro*
        </Typography>
        <TextField
          value={formData.bairro}
          onChange={(e) => handleChangeCampos(e, 'bairro')}
          error={!formData.bairro}
          helperText={!formData.bairro && 'Campo obrigatório'}
        />
      </FormGroup>
      <FormGroup item xs={12}>
        <Typography color="black" component="label">
          Complemento
        </Typography>
        <TextField
          value={formData.complemento}
          onChange={(e) => handleChangeCampos(e, 'complemento')}
        />
      </FormGroup>
      {formData.paisAtuacao?.label === 'ESPANHA' ? (
        <>
          <FormGroup item xs={12}>
            <Typography color="black" component="label">
              Comunidade autonoma *
            </Typography>
            <SelectSearchStyled
              placeholder=""
              value={formData.comunidadeAutonoma}
              elements={
                comunidadesAutonoma?.findAllEspanhaComunidadeAutonoma || []
              }
              onChange={onEspanhaComunidadeAutonomaChange}
            />
          </FormGroup>
          <FormGroup item xs={12}>
            <Typography color="black" component="label">
              Província *
            </Typography>
            <SelectSearchStyled
              placeholder=""
              value={formData.provincia}
              elements={provincias?.findAllEspanhaProvincia || []}
              onChange={onEspanhaProvinciaChange}
              isDisabled={!formData.comunidadeAutonoma}
            />
          </FormGroup>
          <FormGroup item xs={12}>
            <Typography color="black" component="label">
              Municipio *
            </Typography>
            <TextFieldSearch
              key={formData.provincia?.value}
              placeholder=""
              loadOptions={handleEspanhaMunicipioLoadOptions}
              withPaginate
              value={formData.espanhaMunicipio}
              onChange={onChangeEspanhaMunicipio}
              debounceTimeout={300}
              additional={{
                page: 0,
              }}
              isDisabled={!formData.provincia}
              menuInTop
            />
          </FormGroup>
        </>
      ) : (
        <FormGroup item xs={12}>
          <Typography color="black" component="label">
            Municipio*
          </Typography>
          <TextFieldSearch
            placeholder=""
            loadOptions={handleMunicipiosLoadOptions}
            withPaginate
            value={formData.municipio}
            onChange={(e) =>
              setFormData({
                ...formData,
                municipio: e,
              })
            }
            debounceTimeout={300}
            additional={{
              page: 0,
            }}
          />
        </FormGroup>
      )}
    </Grid>
  );
};

export default FormDadosEndereco;

