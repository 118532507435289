import styled from '@emotion/styled';
import React, { useState } from 'react';
import Number from '../../../../utils/number';
import { TextField } from '../../../../components/TextField';
import AtivoSwitch from '../../../list-entity/components/ativoSwitch';
import { SelectSearch } from '../../../../components/select/SelectSearch';
import { tiposDesconto } from '../../../list-vendas/components/criar-venda/constants';
import { useMutation } from 'react-apollo';
import { APLICAR_DESCONTO_VENDA } from '../../../../graphql/mutations';
import string from '../../../../utils/string';

export const Desconto = ({ handleAtualizaDesconto, desconto, vendaId, setNotification }) => {
  const { utilizaDesconto, tipoDesconto, valorSubtotal, valorDesconto } = desconto;

  const [loading, setLoading] = useState(false);
  const [aplicarDesconto] = useMutation(APLICAR_DESCONTO_VENDA);

  const handleAplicarDesconto = async() => {
    if(valorSubtotal < 0){
      return setNotification({
        isOpen: true,
        message: 'O valor total com desconto não pode ser negativo!',
        variant: 'error',
      });
    }
    try {
      setLoading(true);
      await aplicarDesconto({
        variables: {
          vendaId,
          desconto: {
            tipoDesconto: tipoDesconto.value,
            valorDesconto: string.currencyMaskToFloat(String(valorDesconto)),
          },
        },
      });

      setNotification({
        isOpen: true,
        message: 'Desconto aplicado com sucesso',
        variant: 'success',
      });

    } catch ({graphQLErrors}) {
      setNotification({
        isOpen: true,
        message: graphQLErrors[0].message,
        variant: 'error',
      })
    } finally {
      setLoading(false);
    }
  };

  const getFormattedValueDesconto = () => {
    if(typeof valorDesconto === 'string') {
      return valorDesconto
    
    } else {
      if (tipoDesconto?.value === 'VALOR') {
        return Number.currencyFormat(valorDesconto);
      } else {
        return String(valorDesconto).replace('.', ',');
      }

    }

  };

  return (
    <Container>
      <RowSwitchDesconto>
        <LabelDesconto>Usar desconto</LabelDesconto>
        <AtivoSwitch
          handleClick={() =>
            handleAtualizaDesconto('utilizaDesconto', !utilizaDesconto)
          }
          ativo={utilizaDesconto}
        />
      </RowSwitchDesconto>
      {utilizaDesconto && (
        <ContentDesconto>
          <ContentFieldsDesconto>
            <LabelFieldsDesconto>Tipo de desconto</LabelFieldsDesconto>
            <SelectSearch
              value={tipoDesconto}
              elements={tiposDesconto}
              onChange={(e) => handleAtualizaDesconto('tipoDesconto', e)}
            />
          </ContentFieldsDesconto>
          <TextField
            label="Desconto"
            value={getFormattedValueDesconto()}
            onChange={(e) =>
              handleAtualizaDesconto('valorDesconto', e.target.value)
            }
            mask={tipoDesconto?.value === 'VALOR' ? 'moeda' : 'percentil'}
          />
          <ContentFieldsDesconto>
            <LabelFieldsDesconto>Subtotal:</LabelFieldsDesconto>
            <ValorSubtotal>
              {Number.currencyFormat(valorSubtotal)}
            </ValorSubtotal>
          </ContentFieldsDesconto>
          <ButtonAplicarDesconto
            onClick={handleAplicarDesconto}
            disabled={loading}
          >
            Aplicar desconto
          </ButtonAplicarDesconto>
        </ContentDesconto>
      )}
    </Container>
  );
};

const Container = styled.div`
  display: grid;
  grid-template-rows: auto 1fr auto;
`;

const LabelDesconto = styled.label`
  font-weight: 500;
  font-size: 18px;
`;

const RowSwitchDesconto = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const ContentDesconto = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
  margin-bottom: 8px;
`;

const ContentFieldsDesconto = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const LabelFieldsDesconto = styled.label`
  color: #868686;
  font-size: 12px;
  height: 16px;
`;

const ValorSubtotal = styled.label`
  width: 100px;
  height: 32px;
  display: flex;
  align-items: center;
  color: #868686;
`;

const ButtonAplicarDesconto = styled.button`
  display: flex;
  height: 40px;
  border-radius: 100px;
  border: 2px solid #219a97;
  background: #00aca9;
  width: 100%;
  max-width: 165px;
  align-items: center;
  color: #fff;
  font-weight: 500;
  cursor: pointer;
  justify-content: center;
  ${({ disabled }) => disabled && 'opacity: 0.5; cursor: not-allowed;'}
`;
