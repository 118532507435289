const isValidEspecialidade = (especialidade, remoto = false) => {
  if (remoto) return !!especialidade?.valorOnline;
  return !!especialidade?.valorPresencial;
};

export const isPerfilPublicoValid = (perfilPublico) => {
  const validData = {
    valid: false,
    messages: [],
  };

  const hasOptionToSchedule =
    perfilPublico?.atendePresencial || perfilPublico?.utilizaTelemedicina;

  if (!hasOptionToSchedule) {
    validData.messages.push('Não atende presencial e não utiliza telemedicina');
  }

  if (!perfilPublico?.unidade) {
    const perfisUnidadesPermiteExibicao =
      perfilPublico?.perfilPublicoUnidades?.filter(
        (perfilUnidade) => perfilUnidade.permiteExibicao
      );

    if (!(perfisUnidadesPermiteExibicao?.length > 0)) {
      validData.messages.push('Não possui unidades');
    }
  }

  const hasFormasPagamento =
    perfilPublico?.formasPagamento?.length > 0 ||
    perfilPublico?.convenios?.length > 0 ||
    perfilPublico?.dadosIugu?.verified;

  const hasFormasPagamentoOnline =
    perfilPublico?.convenios?.length > 0 || perfilPublico?.dadosIugu?.verified;

  if (
    perfilPublico?.atendePresencial &&
    !perfilPublico?.utilizaTelemedicina &&
    !hasFormasPagamento
  ) {
    validData.messages.push(
      'Atende presencial e não possui formas de pagamento'
    );
  }

  if (
    perfilPublico?.utilizaTelemedicina &&
    !perfilPublico?.atendePresencial &&
    !hasFormasPagamentoOnline
  ) {
    validData.messages.push(
      'Utiliza telemedicina e não possui formas de pagamento online, verifique os meios de pagamentos'
    );
  }

  const perfilPublicoUnidadeEspecialidades =
    perfilPublico?.perfilPublicoUnidades
      ?.filter((perfilPublicoUnidade) => perfilPublicoUnidade?.permiteExibicao)
      ?.filter((perfilPublicoUnidade) => {
        return perfilPublicoUnidade?.perfilPublicoUnidade?.especialidades?.some(
          (especialidade) => {
            return (
              (!perfilPublico?.atendePresencial ||
                isValidEspecialidade(especialidade, false)) &&
              (!perfilPublico?.utilizaTelemedicina ||
                isValidEspecialidade(especialidade, true))
            );
          }
        );
      });

  const perfilPublicoProfissionaisEspecialidades =
    perfilPublico?.perfilPublicoProfissionais
      ?.filter(
        (perfilPublicoProfissional) =>
          perfilPublicoProfissional?.permiteExibicao
      )
      ?.filter((perfilPublicoProfissional) => {
        return perfilPublicoProfissional?.perfilPublicoProfissional?.especialidades?.some(
          (especialidade) => {
            return (
              (!perfilPublico?.atendePresencial ||
                isValidEspecialidade(especialidade, false)) &&
              (!perfilPublico?.utilizaTelemedicina ||
                isValidEspecialidade(especialidade, true))
            );
          }
        );
      });

  const perfilPublicoValidEspecialidades =
    perfilPublico?.especialidades?.filter((especialidade) => {
      return (
        (!perfilPublico?.atendePresencial ||
          isValidEspecialidade(especialidade, false)) &&
        (!perfilPublico?.utilizaTelemedicina ||
          isValidEspecialidade(especialidade, true))
      );
    });

  const hasEspecialidadesPerfilPublico = [
    perfilPublicoUnidadeEspecialidades,
    perfilPublicoProfissionaisEspecialidades,
    perfilPublicoValidEspecialidades,
  ].some((especialidades) => especialidades?.length > 0);

  if (!hasEspecialidadesPerfilPublico) {
    validData.messages.push(
      'Não possui especialidades válidas, verifique as especialidades dos perfis das unidades e profissionais'
    );
  }

  validData.valid = validData.messages.length === 0;

  return validData;
};
