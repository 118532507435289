import Axios from 'axios';
import { NESTOR_URL, TEMP_NESTOR_URL } from '../constants/env';

export const nestorService = {
  upload: async (file) => {
    const formData = new FormData();
    formData.append('file', file);

    const { id } = (await Axios.post(NESTOR_URL, formData))?.data;

    return id;
  },
  url: (id) => (id ? `${NESTOR_URL}/comercial/${id}` : ''),
  urlTemp: (id) => {
    return id ? `${TEMP_NESTOR_URL}/comercial/${id}` : '';
  },
};
