import React, { useState, useEffect } from 'react';
import InfoBox from '../../info-box';

const InfosPrincipais = ({ profissionalDeSaude, dadosBraspagProfissional }) => {
  const [dadosPessoais, setdadosPessoais] = useState([]);
  const [dadosCadastrais, setDadosCadastrais] = useState([]);
  const [configuracoesDoProfissional, setConfiguracoesDoProfissional] =
    useState([]);
  const [endereco, setEndereco] = useState([]);

  const buildDadosPessoais = () => {
    const dadosPessoais = [
      ['Nome', profissionalDeSaude?.nome || ''],
      ['Data de Nascimento', profissionalDeSaude?.dataNascimento || ''],
      ['E-mail', profissionalDeSaude?.email || ''],
    ];
    setdadosPessoais(dadosPessoais);
  };

  const buildDadosCadastrais = () => {
    const dadosCadastrais = [
      ['CNS', profissionalDeSaude?.cns || ''],
      ['Número do conselho', profissionalDeSaude?.numeroConselho || ''],
      ['UF', profissionalDeSaude?.ufConselho || ''],
      ['CPF', profissionalDeSaude?.cpf || ''],
    ];

    setDadosCadastrais(dadosCadastrais);
  };

  const buildConfiguracoesDoProfissional = () => {
    const configuracoresDoProfissional = [
      ['Ativo', profissionalDeSaude?.ativo ? 'Sim' : 'Não'],
      ['Possui agenda', profissionalDeSaude?.possuiAgenda ? 'Sim' : 'Não'],
      [
        'Utiliza assinatura digital',
        profissionalDeSaude?.utilizaAssinaturaDigital ? 'Sim' : 'Não',
      ],
      [
        'Utiliza secretária virtual',
        profissionalDeSaude?.utilizaSecretariaVirtual ? 'Sim' : 'Não',
      ],
      [
        'Utiliza telemedicina',
        profissionalDeSaude?.utilizaTelemedicina ? 'Sim' : 'Não',
      ],
    ];
    setConfiguracoesDoProfissional(configuracoresDoProfissional);
  };

  const buildEndereco = () => {
    const enderecoProfissional = [
      ['Município', profissionalDeSaude?.endereco?.municipio?.label || ''],
      ['Estado', profissionalDeSaude?.endereco?.estado?.label || ''],
      ['CEP', profissionalDeSaude?.endereco?.cep || ''],
      ['Bairro', profissionalDeSaude?.endereco?.bairro || ''],
      ['Logradouro', profissionalDeSaude?.endereco?.nomeLogradouro || ''],
      ['Número', profissionalDeSaude?.endereco?.numero || ''],
      ['Complemento', profissionalDeSaude?.endereco?.complemento || ''],
    ];
    setEndereco(enderecoProfissional);
  };

  useEffect(() => {
    if (profissionalDeSaude) {
      buildDadosPessoais();
      buildDadosCadastrais();
      buildConfiguracoesDoProfissional();
      buildEndereco();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profissionalDeSaude]);

  return (
    <>
      <InfoBox title="Dados pessoais" data={dadosPessoais} />

      <InfoBox title="Dados cadastrais" data={dadosCadastrais} />

      <InfoBox
        title="Configurações do profissional"
        data={configuracoesDoProfissional}
      />

      <InfoBox title="Endereço" data={endereco} />

      <InfoBox
        title="Especialidades"
        data={profissionalDeSaude?.especialidades}
        isEspecialidades
      />
    </>
  );
};

export default InfosPrincipais;
