import React, { useEffect, useState } from 'react';
import { stepsSobrePlano } from './constants';
import styled from '@emotion/styled';
import { UPDATE_VENDA_PLANO, ALTERAR_ADICIONAIS } from '../../../../graphql/mutations';
import { useMutation, useQuery } from 'react-apollo';
import { CircularProgress } from '@material-ui/core';
import { FIND_ALL_MODULO_VENDA } from '../../../../graphql/queryes';
import Number from '../../../../utils/number';

export const DadosPlano = ({
  venda,
  setStepSelectedSobrePlano,
  stepSelectedSobrePlano,
  setNotification,
  toggleSobrePlanoStep,
  carregaVenda,
}) => {
  const { vendaModulos, tipoPagamento } = venda;

  const [updateVendaPlano] = useMutation(UPDATE_VENDA_PLANO);
  const [adicionarModulos] = useMutation(ALTERAR_ADICIONAIS);
  const { data, loadingModulos } = useQuery(FIND_ALL_MODULO_VENDA, {
    variables: {
      ativo: true,
      tipoPagamentoDTO: {
        differentTipoPagamento: {
          id: tipoPagamento?.id
        }
      },
      pageableDTO: {
        sortDir: 'ASC',
        sortField: 'funcionalidade',
        pageSize: 999
      },
    },
    fetchPolicy: 'no-cache'
  });

  const [dados, setDados] = useState({ ...venda });
  const [loadingSave, setLoadingSave] = useState(false);
  const [tipoCobranca, setTipoCobranca] = useState(tipoPagamento?.descricao);
  const [secretariaType, setSecretariaType] = useState(null)

  useEffect(() => {
    data && setModulosSelecionado();
  }, [data])

  const handleSecretariaType = (tipoSecretariaVirtual) => {
    setSecretariaType(tipoSecretariaVirtual)
  }
  const setModulosSelecionado = () => {

    const adicionais = vendaModulos.filter(item => item.adicional);
    const modulosSelecionados = data?.findAllModulo?.map((modulo) => {
      const selected = adicionais.find(item => item.modulo?.id === modulo.id)


      delete modulo.__typename;
      return {
        ...modulo,
        valor: Number.currencyFormat(selected?.id ? selected?.valor : modulo.valor),
        selected: !!selected?.id
      }
    });
    const checkSecretaria = modulosSelecionados.filter(item => item.selected && item.tipoSecretariaVirtual)

    if (checkSecretaria) {
      handleSecretariaType(checkSecretaria[0]?.tipoSecretariaVirtual || null)
    }
    setDados({
      ...dados,
      modulos: modulosSelecionados
    });
  }

  const handleSelectPlano = (value) => {
    setDados({
      ...dados,
      plano: value.plano,
    });
  };

  const handleSelectModulos = (value) => {
    setDados({
      ...dados,
      modulos: value.modulos,
    });
  }

  const save = async () => {
    const field = stepSelectedSobrePlano === 1 ? 'adicional' : 'plano';
    setLoadingSave(true);
    try {
      if (field === 'plano') {

        const tipoPagamento = dados?.plano?.tipoPagamento.find(
          (item) => item.descricao === tipoCobranca
        )

        await updateVendaPlano({
          variables: {
            venda: { id: venda.id },
            tipoPagemento: { id: tipoPagamento.id },
          },
        });

      } else {
        const modulosSelecionados = dados.modulos.filter((modulo) => modulo.selected);
        const modulosRemoveSelected = modulosSelecionados.map(item => {
          delete item.selected;
          return {
            ...item,
            valor: Number.currencyToFloat(item.valor)
          };
        });

        await adicionarModulos({
          variables: {
            venda: { id: venda.id },
            modulos: modulosRemoveSelected
          },
        });
      }
      setNotification({
        variant: 'success',
        isOpen: true,
        message: `Sucesso ao salvar o ${field}`,
      })
      carregaVenda()
      toggleSobrePlanoStep();
    } catch ({ graphQLErrors }) {
      if (graphQLErrors) {
        setNotification({
          variant: 'error',
          isOpen: true,
          message: graphQLErrors[0].message,
        })
      }
    } finally {
      setLoadingSave(false);

    }
  };

  const step = stepsSobrePlano({
    setStepSelectedSobrePlano,
    venda: dados,
    handleSelectPlano,
    handleSelectModulos,
    loadingModulos,
    setTipoCobranca,
    tipoCobranca,
    handleSecretariaType,
    secretariaType
  });

  return (
    <ContentStep>
      {step[stepSelectedSobrePlano]?.content}
      {stepSelectedSobrePlano !== 0 && <Footer>
        <Button disabled={loadingSave} onClick={save}>
          Salvar
          {loadingSave && <CircularProgress color="#fff" size={12} />}
        </Button>
      </Footer>}
    </ContentStep>
  );
};

const ContentStep = styled.div`
  height: calc(100vh - 190px);
  display: flex;
  flex-direction: column;
  & > div:first-child {
    height: 100%;
  }
`;

const Footer = styled.div`
  position: absolute;
  bottom: 10px;
  right: 32px;
`;

const Button = styled.button`
  cursor: pointer;
  border: 2px solid #dcdcdc;
  background-color: #219a97;
  border-radius: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 150px;
  color: #fff;
  gap: 4px;

  ${({ disabled }) => disabled && 'opacity: 0.5; cursor: not-allowed;'}
`;
