import React from 'react';

import { FormLabel } from '@material-ui/core';
import { default as FormControlBase } from '@material-ui/core/FormControl/FormControl';

const FormControl = ({ label, error, children, ...rest }) => {
  return (
    <FormControlBase {...rest} error={Boolean(error)}>
      {label && (
        <FormLabel
          style={{
            transform: 'translate(0, 1.5px) scale(0.75)',
            transformOrigin: 'top left',
          }}
        >
          {label}
        </FormLabel>
      )}
      {children}
      {error && (
        <p
          style={{
            color: 'red',
            fontSize: '0.75rem',
            marginTop: '8px',
          }}
        >
          {error}
        </p>
      )}
    </FormControlBase>
  );
};

export default FormControl;
