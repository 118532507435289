import Typography from "@material-ui/core/Typography";
import {TextField} from "./index";
import React from "react";
import styled from "@emotion/styled";

const RenderTextField = (props) => {

  const { label, field, mask, error, errorText, handleChange, disabled, maxLength, fontSize } = props;

  return (
    <>
      <TypographyStyled color="black" component="label" fontSize={fontSize}>
        {label}
      </TypographyStyled>
      <TextField
        value={field}
        mask={mask}
        error={error}
        helperText={error && errorText}
        onChange={handleChange}
        disabled={disabled}
        maxLength={maxLength}
      />
    </>
  )
};

export default RenderTextField;

const TypographyStyled = styled(Typography)`
  ${({ fontSize }) => fontSize && `font-size: ${fontSize} !important;`}
`;
