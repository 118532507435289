export const getNotificationState = (_, action) => {
  const { status, message } = action.payload;
  switch (status) {
    case 'error':
      return {
        isOpen: true,
        variant: 'error',
        message
      };
    case 'success':
      return {
        isOpen: true,
        variant: 'success',
        message
      };
    case 'warning':
      return {
        isOpen: true,
        variant: 'warning',
        message
      };
    default:
      return {
        isOpen: false,
        variant: '',
        message: ''
      };
  }
};
