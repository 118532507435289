import styled from '@emotion/styled';
import React from 'react';
import Number from '../../../../../../utils/number';

export const CardPlano = ({ plano, tipoCobranca, onClick }) => {
  const { nome, tipoPagamento, ativo } = plano;
  const planoTipoPagamento = tipoPagamento.find(item => item.descricao === tipoCobranca);
  const { modulos, valor } = planoTipoPagamento || {};  

  const valorPlano = Number.currencyFormat(valor);

  return (
    <Card onClick={onClick}>
      <Row>
        <Title>{nome}</Title>
        <Status color={ativo ? '#01B574' : '#FB7676'}>
          {ativo ? 'Ativo' : 'Inativo'}
        </Status>
      </Row>
      <ListaFuncionalidades>
        {modulos?.map(({funcionalidade}) => (
          <Funcionalidade>
            {funcionalidade}
          </Funcionalidade>
        ))}
      </ListaFuncionalidades>
      <Row>
        <ValorStyle>Total:</ValorStyle>
        <ValorStyle>{valorPlano}</ValorStyle>
      </Row>
    </Card>
  );
};

const Card = styled.div`
  width: 280px;
  height: 230px;
  border-radius: 16px;
  padding: 16px;
  border: 1px solid #e5e5e5;
  background-color: #fff;
  display: flex;
  flex-direction: column;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const ListaFuncionalidades = styled.div`
  height: 100%;
  overflow-y: auto;
`;

const Title = styled.h1`
  font-family: 'Poppins';
  font-weight: 700;
  font-size: 18px;
`;

const Status = styled.div`
  display: flex;
  align-items: center;
  border-radius: 2px;
  border: ${(props) => `1px solid ${props.color}`};
  color: ${(props) => `${props.color}`};
`;

const ValorStyle = styled.p`
  color: #09101d;
  font-size: 14px;
  font-weight: 500;
`;

const Funcionalidade = styled.p`
    color: #505050;
    font-size: 12px;
    font-weight: 500;
`;
