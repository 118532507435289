import React from 'react';
import { withStyles, CircularProgress } from '@material-ui/core';
import { styles } from './ModalDesbloquearVendaStyles';

import Dialog from '../../../../components/Dialog/dialog';
import DialogHeader from '../../../../components/Dialog/dialogHeader';
import ButtonClearPrimary from '../../../../components/button/ButtonClearPrimary';
import InputDateForm from '../../../../components/input/InputDateForm';

const ModalDesbloquearVenda = ({ 
  classes, 
  open, 
  onChangeDate,
  date, 
  onClose, 
  onSave,
  loadingSave 
}) => {
  return (
    <Dialog open={open} maxWidth={'md'} classes={{ paper: classes.paper }}>
      <div className={classes.dialog}>
        <DialogHeader
          title="Data de vencimento"
          closeButton={true}
          actionClose={() => onClose()}
        />
      </div>
      <div className={classes.content}>
        <span className={classes.inputLabel}>Nova data de vencimento</span>
        <InputDateForm
          value={date}
          disablePast
          variant="outlined"
          onChange={(value) => onChangeDate(value)}
        />
      </div>
      <div className={classes.contentButton}>
        <ButtonClearPrimary
          onClick={onSave}
          disabled={loadingSave}
          className={classes.buttonSave}
        >
          Salvar
          {loadingSave && (
            <CircularProgress size={12} style={{ marginLeft: 10 }} />
          )}
        </ButtonClearPrimary>
      </div>
    </Dialog>
  )
};

export default withStyles(styles)(ModalDesbloquearVenda);