import { apiAuth, Api } from "./axios";

export const obterUsuarioLogadoLocal = () => {
	return JSON.parse(window.localStorage.getItem('usuarioLogado'));
};

export const findEntidadeLogada = async (token) => {
   const accessTokenLocalStorage = window.localStorage.getItem('token');

   const accessToken = token ? token : accessTokenLocalStorage

   const { data: response} = await Api.post('', {
   query: `
   		{
          	findEntidadeLogada {
          		id
          		nome
          		ativo
          		responsavelCpf
          		tipo
          		nivel{
                nome
              }
          	}
          }
   `,
   	variables: {},
   }, {
   	headers: {
   		authorization: `bearer ${accessToken}`
   	}
   });

   return response?.data?.findEntidadeLogada;
}

export const obterEntidadeAtualLocal = () => {
  const entidadeAtual = JSON.parse(window.localStorage.getItem('entidadeAtual'));

  if(entidadeAtual?.id) {
	  return entidadeAtual;
	}

	try {
    return findEntidadeLogada().then(response => {
      window.localStorage.setItem("entidadeAtual", JSON.stringify(response));

      return response || null;
    });
  } catch (error) {
      return null;
  }
};

export const obterUsuarioLogado = async (accessToken) => {
    try {
        const { data: obterUsuarioLogadoData } = await apiAuth.post('', {
			query: `
				query {
					obterUsuarioLogado {
						nome
						authorities {
							authority
						}
					}
				}
			`,
			variables: {},
		}, {
			headers: {
				authorization: `bearer ${accessToken}`
			}
		});

		return obterUsuarioLogadoData?.data?.obterUsuarioLogado || null;
    } catch (error) {
        return null;
    }
};

export const obterUsuarioLogadoEEntidadesAtivas = async (accessToken) => {
    try {
        const { data } = await apiAuth.post('', {
			query: `
				query {
					obterUsuarioLogado {
						nome
						authorities {
							authority
						}
					}
					obterEntidadesAtivasDoUsuario {
						id
						nome
						documento
						nivel {
							nome
						}
					}
				}
			`,
			variables: {},
		}, {
			headers: {
				authorization: `bearer ${accessToken}`
			}
		});

		return data?.data || null;
    } catch (error) {
        return null;
    }
};

export const alteraContextoAtual = async (idContexto, accessToken) => {
	try {
		const { data } = await apiAuth.post('', {
			query: `
				mutation($idContexto: UUID) {
					alterarContextoAtual(idContexto: $idContexto) {
						nome
						authorities {
							authority
						}
					}
				}
			`,
			variables: {
				idContexto
			}
		}, {
			headers: {
				authorization: `bearer ${accessToken}`
			}
		});

		return data?.data?.alterarContextoAtual || null;
	} catch (error) {
		return null;
	}
};
