import { TextareaAutosize, useTheme } from '@material-ui/core';
import React from 'react';
import FormControl from '../FormControl';

function Textarea({ id, value, onChange, label, ...rest }) {
  const { commons } = useTheme();

  return (
    <FormControl
      style={{
        width: '100%',
        display: 'grid',
      }}
      label={label}
      error={rest.error}
    >
      <TextareaAutosize
        id={id}
        onChange={onChange}
        value={value}
        rowsMin={4}
        rowsMax={6}
        {...rest}
        style={{
          border: commons.inputBorder,
          borderRadius: commons.inputBorderRadius,
          padding: '10px',
          color: commons.inputColor,
          fontSize: 16,
          background: commons.inputBackgroundColor,
        }}
      />
    </FormControl>
  );
}

export default Textarea;
