import styled from '@emotion/styled';
import Grid from '@material-ui/core/Grid';
import MobileStepper from '@material-ui/core/MobileStepper';
import Slider from '@material-ui/core/Slider';
import Chip from '@material-ui/core/Chip';
import Close from '@material-ui/icons/Close';
import Colors from '../../../../theme/Colors';
import { SelectSearch } from '../../../../components/select/SelectSearch';

export const FlexGrid = styled(Grid)`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding: 10px 10px;
`;

export const FormGroup = styled(Grid)`
  margin: 0 auto !important;
`;

export const Passos = styled(FormGroup)`
  overflow-y: auto;
  overflow-x: hidden;
`;

export const Header = styled.div`
  height: 45px;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 15px;
`;

export const HeaderTitle = styled.div`
  font-size: 18px;
  text-align: left;
  color: ${Colors.primary.main};
  margin-top: 4px;
  margin-left: 10px;
`;

export const CloseStyled = styled(Close)`
  color: ${Colors.primary.main};
  cursor: pointer;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
`;

export const Button = styled.button`
  border: 2px solid rgba(220, 220, 220, 0.2);
  background-color: ${Colors.primary.main};
  color: #fff;
  height: 40px;
  width: 100%;
  max-width: 150px;
  border-radius: 100px;
  margin: 8px 0;
  cursor: pointer;
  &:disabled {
    background-color: ${Colors.commons.gray};
    cursor: not-allowed;
  }
`;

export const Guia = styled(MobileStepper)`
  width: 600;
  flex-grow: 1;
  align-self: center;
  justify-content: center;
`;

export const FormGroupButtons = styled(Grid)`
  max-height: 56px;
`;

export const SelectSearchStyled = styled(SelectSearch)`
  width: 100%;
`;

export const GridUltimoStep = styled(Grid)`
  margin: 0px !important;
`;

export const MySlider = styled(Slider)`
  padding-right: 30px;
`;

export const EspecialidadeSelecionada = styled(Chip)`
  margin: 1px 2px !important;
`;

export const Grid2 = styled(Grid)`
  margin: 10px 0 !important;
`;

export const ContainerButton = styled.div`
  display: flex;
  justify-content: flex-end;
`;
