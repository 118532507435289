import Axios from "axios";

const createAxiosInstance = () => {
  const baseURL = process.env.REACT_APP_BASE_URL_VIZI_GQL;
  return Axios.create({
    baseURL: baseURL,
    headers: {
      'Content-Type': 'application/json'
    },
  });
};

export const ViziAPI = createAxiosInstance();
