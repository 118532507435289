import React, { useEffect, useState } from 'react';
import Dialog from '../../../../components/Dialog/dialog';
import DialogHeader from '../../../../components/Dialog/dialogHeader';
import ButtonClearPrimary from '../../../../components/button/ButtonClearPrimary';
import { Checkbox, CircularProgress, FormControlLabel, withStyles } from '@material-ui/core';
import { styles } from './ModalValorRecorrenciaStyles';
import { TextField } from '../../../../components/TextField';

export const ModalValorRecorrencia = ({classes,open,onClose,dados,onSave,onChange,handlePermiteEditarValor,checkBox,loadingSave})=>{
 
  const formatCurrency=(dados) =>{
   if (typeof dados === 'number') {
     return `R$ ${dados.toFixed(2).replace('.', ',')}`;
   }
   return String(dados);
 }
 
    return (
        <Dialog open={open} maxWidth={'md'} classes={{ paper: classes.paper }}>
          <div className={classes.dialog}>
            <DialogHeader
              title="Valor recorrente"
              closeButton={true}
              actionClose={onClose}
            />
          </div>
          <div className={classes.content}>
            <span className={classes.inputLabel}>Novo valor da recorrencia</span>
            <TextField
            value={formatCurrency(dados)|| ''}
            onChange={onChange}
            className={classes.input}
            mask={'moeda'}
            placeholder="R$ 0,00"
             />
              <div className={classes.CheckBox}>
                <FormControlLabel
                control={
                  <Checkbox
                    checked={checkBox}
                    onChange={handlePermiteEditarValor}
                    color="primary"
                  />
                }
                label={'Permite recalculo de valores?'}
                labelPlacement="end"
                            />
              </div>
          </div>
          <div className={classes.contentButton}>
            <ButtonClearPrimary
              onClick={onSave}
              disabled={loadingSave}
              className={classes.buttonSave}
            >
              Salvar
              {loadingSave && (
                <CircularProgress size={12} style={{ marginLeft: 10 }} />
              )}
            </ButtonClearPrimary>
          </div>
        </Dialog>
      )
}
export default withStyles(styles)(ModalValorRecorrencia);