import React, { useRef, useState, useEffect } from 'react';
import styled from '@emotion/styled';

import { UPLOAD_FILE } from '../../../graphql/mutations';
import { ViziAPI } from '../../../apollo/setupViziClient';
import { nestorService } from '../../../services/nestor';
import { findOrCreateConfiguracaoWhitelabelByWhitelabel } from '../../../services/whitelabel';

const WhitelabelConfig = ({ whitelabelId }) => {
  const [whitelabelConfig, setWhitelabelConfig] = useState(null);
  const { banner } = whitelabelConfig?.configuracaoDesign || {};
  const inputFileRef = useRef();

  const findWhitelabelById = async () => {
    try {
      const whitelabelConfig = await findOrCreateConfiguracaoWhitelabelByWhitelabel(whitelabelId);
      setWhitelabelConfig(whitelabelConfig);
    } catch(error) {
      console.error(error);
    }
  }

  const uploadWhitelabelBanner = async (event, clear=false) => {
    const file =  event ? event?.target?.files[0] : null;

    if (!file && !clear) return;

    try {
      let bannerId = null;
      if (!clear)
      bannerId = await nestorService.upload(file || null);

      await ViziAPI.post("", {
        query: UPLOAD_FILE,
        variables: {
          banner: bannerId,
          whitelabel: {
            id: whitelabelId
          }
        }
      });
      
      setWhitelabelConfig({
        ...whitelabelConfig,
        configuracaoDesign: {
          ...whitelabelConfig?.configuracaoDesign,
          banner: bannerId
        } 
      });
    } catch(error) {
      console.error(error);
    }
  };

  const handleClick = () => {
    inputFileRef.current.click();
  };

  useEffect(() => {
    if (whitelabelId) findWhitelabelById();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [whitelabelId]);

  return (
    <Container>
      <h1>Customização</h1>
      <Card>
        <CardTitle>Banner</CardTitle>
        {banner && <BannerImage src={nestorService.url(banner)} alt="whitelabel banner" style={{ width: '100%' }} />}
        <Button 
          onClick={handleClick}
          style={{
            backgroundColor: banner ? 'rgb(112, 124, 151)' : '#219a97',
          }}
        >
          {banner ? 'Alterar banner' : 'Selecionar imagem'}
        </Button>
        {banner && (
          <Button 
            onClick={() => uploadWhitelabelBanner(null, true)}
            style={{ backgroundColor: 'rgb(251, 118, 118)', color: '#fff' }}
          >
            Remover imagem
          </Button>
        )}
        <input 
          ref={inputFileRef} 
          style={{ display: 'none' }} 
          onChange={uploadWhitelabelBanner} 
          type="file" 
          accept="image/*"
        />
      </Card>
    </Container>
  );
};

const Container = styled.div`
  height: 100vh;
  padding: 10px 20px;
  flex: 1;
`;

const Card = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  gap: 16px;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  padding: 16px;
`;

const CardTitle = styled.span`
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #505050;
`;

const BannerImage = styled.img`
  width: 100%;
  border-radius: 16px;
`;

const Button = styled.button`
  display: flex;
  flex-direction: row;
  gap: 8px;
  cursor: pointer;
  height: 40px;
  width: 100%;
  max-width: 165px;
  border: none;
  border-radius: 16px;
  color: #fff;
  align-items: center;
  justify-content: center;
  align-self: center;
`;

export default WhitelabelConfig;
