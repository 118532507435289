import React from 'react';
import styled from '@emotion/styled';

const ButtonBox = ({ bg, children, ...rest }) => {
  return (
    <Button bg={bg} {...rest}>
      {children}
    </Button>
  );
};

export default ButtonBox;

const Button = styled.button`
  border: 1px solid rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 175px;
  height: 60px;
  background-color: ${(props) => props.bg || '#F2F2F2'};
  padding: 8px;
  border-radius: 8px;
  cursor: pointer;
`;
