import moment from 'moment';
import { DadosFinanceiro } from './dadosFinanceiro';
import { Pagamento } from './pagamento';
import string from '../../../../utils/string';

export const modalFinanceiro = {
  pagamento: {
    title: 'Pagamento',
    content: DadosFinanceiro,
  },
  semPagamento: {
    title: 'Pagamento',
    content: Pagamento,
  },
  alterarPagamento: {
    title: 'Alterar pagamento',
    content: Pagamento,
  },
};

export const tabsPagamento = [
  {
    name: 'Cartão',
    value: 'CIELO',
  },
  {
    name: 'Boleto',
    value: 'BOLETO',
  },
];

export const boletoDefault = {
  quantidadeParcelas: { value: 12, label: '1 Ano' },
  dataVencimento: moment().format('YYYY-MM-DD'),
  emailPagador: '',
};

export const descontoDefault = (dados) => { 
  const { desconto, valor } = dados;
  const possuiDesconto = desconto?.tipoDesconto;

  const valorSubtotal = possuiDesconto ? calculaSubTotal(desconto, valor) : valor;
  
  return({
  utilizaDesconto: !!desconto?.valorDesconto,
  tipoDesconto: {
    label: possuiDesconto ? string.capitalize(desconto?.tipoDesconto) : 'Percentil',
    value: desconto?.tipoDesconto || 'PERCENTIL',
  },
  valorDesconto: desconto?.valorDesconto || 0,
  valorSubtotal: valorSubtotal,
})}


export const calculaSubTotal = (desconto, valor) => {
  const { valorDesconto, tipoDesconto } = desconto;

  const valorNumber = typeof valor === 'string' ? string.currencyMaskToFloat(valor) : valor;

  let subTotal = valorNumber;

  if (valorDesconto) {
    if (tipoDesconto?.value === 'VALOR') {
      const valorDescontoNumber = typeof valorDesconto === 'number' ? valorDesconto : string.currencyMaskToFloat(
        String(valorDesconto)
      );
      subTotal = valorNumber - valorDescontoNumber;
    } else {
      const valorDescontoNumber = typeof valorDesconto === 'number' ? valorDesconto : string.removeMask(
        String(valorDesconto),
        '%'
      );
      const valorDescontoCalculado =
        (valorNumber * valorDescontoNumber) / 100;
      subTotal = valorNumber - valorDescontoCalculado;
    }
  }

  return subTotal;
};