import React, { useEffect, useState } from 'react';
import ModalSlider from '../../../../../../components/modal-slider/modal-slider';
import {
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Typography,
} from '@material-ui/core';
import styled from '@emotion/styled';
import { TextField } from '../../../../../../components/TextField';
import { Tab, Tabs } from '../../../../../../components/tabs';
import {
  adicionalDefault,
  groups,
  periodosCobranca,
} from '../../../../constants';
import Button from '../../../../../../components/button';
import { useMutation } from 'react-apollo';
import {
  UPDATE_MODULO,
  CREATE_MODULO,
} from '../../../../../../graphql/mutations';
import string from '../../../../../../utils/string';

export const ModalAdicional = ({
  openModal,
  onCloseModal,
  refetch,
  moduloSelecionado,
  setNotification,
}) => {
  const [adicional, setAdicional] = useState(adicionalDefault);
  const [loadingSave, setLoadingSave] = useState(false);

  const [saveModulo] = useMutation(
    adicional.id ? UPDATE_MODULO : CREATE_MODULO
  );

  useEffect(() => {
    if (moduloSelecionado?.id) {
      const group = groups.map((group) => {
        const groupChecked = moduloSelecionado.group.some(
          (item) => item === group.value
        );

        return {
          ...group,
          checked: groupChecked,
        };
      });

      moduloSelecionado.__typename && delete moduloSelecionado.__typename;

      const valorMoeda = moduloSelecionado?.valor.toLocaleString("pt-br", {
        style: "currency",
        currency: "BRL",
      });

      setAdicional({
        ...moduloSelecionado,
        valor: valorMoeda,
        group,
      });
    }
  }, [moduloSelecionado]);

  const handleAdicional = (value, field) => {
    setAdicional({
      ...adicional,
      [field]: value,
    });
  };

  const handleAdicionalGroups = (value) => {
    const groups = adicional.group.map((item) => {
      return item.value === value
        ? {
            ...item,
            checked: !item.checked,
          }
        : item;
    });

    handleAdicional(groups, 'group');
  };

  const dadosInvalidos = () => {
    return !adicional.funcionalidade
  }

  const save = async () => {
    try {
      setLoadingSave(true);

      if(dadosInvalidos()) {
        setNotification({
					variant: 'error',
					isOpen: true,
					message: "Favor preencher o nome.",
				});
        return
      };

      const modulo = {
        ...adicional,
        ativo: true,
        valor: string.currencyMaskToFloat(String(adicional.valor)),
        group: adicional.group
          .filter((item) => item.checked)
          .map((item) => item.value),
      };

      !modulo?.id && delete modulo.id;

      await saveModulo({ variables: { modulo } });
      onCloseModal();
      await refetch();
    } catch ({ graphQLErrors }) {
      if (graphQLErrors) {
				setNotification({
					variant: 'error',
					isOpen: true,
					message: graphQLErrors[0].message,
				});
			}
    } finally {
      setLoadingSave(false);
    }
  };

  const handlePermiteEditarValor = () => {
    setAdicional({
      ...adicional,
      alterarValor: !adicional.alterarValor,
    });
  };

  return (
    <ModalSlider
      isOpen={openModal}
      onClose={onCloseModal}
      title={adicional?.id ? "Alterar adicional" : "Novo adicional"}
    >
      <div
        style={{
          flex: 1,
          overflowY: 'auto',
        }}
      >
        <Row>
          <TextField
            label="Nome"
            placeholder="Digite o nome"
            value={adicional.funcionalidade}
            onChange={(e) => handleAdicional(e.target.value, 'funcionalidade')}
          />
          <TextField
            label="Valor"
            mask={'moeda'}
            placeholder="R$ 0,00"
            value={adicional.valor}
            onChange={(e) => handleAdicional(e.target.value, 'valor')}
          />
        </Row>

        <PlanAdicionalConfigContainer>
          <h3>Defina o período de cobrança</h3>
          <ContentPeriodoCobranca>
            <Tabs
              onChange={(newValue) =>
                handleAdicional(newValue, 'periodoCobranca')
              }
              value={adicional.periodoCobranca}
            >
              {periodosCobranca.map(({ value, name }) => (
                <Tab value={value}>{name}</Tab>
              ))}
            </Tabs>
          </ContentPeriodoCobranca>
        </PlanAdicionalConfigContainer>
        <PlanAdicionalConfigContainer>
          <h3>Permissão</h3>
          <ContentPermissao>
            {adicional.group.map(({ value, name, checked }) => (
              <div key={value}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={checked}
                      onChange={() => handleAdicionalGroups(value)}
                      color="primary"
                    />
                  }
                  label={name}
                  labelPlacement="start"
                  style={{ marginLeft: 0, marginTop: 0 }}
                />
              </div>
            ))}
          </ContentPermissao>
        </PlanAdicionalConfigContainer>
        <PlanAdicionalConfigContainer>
          <h3>Valor</h3>
          <div>
            <FormControlLabel
              control={
                <Checkbox
                  checked={adicional.alterarValor}
                  onChange={handlePermiteEditarValor}
                  color="primary"
                />
              }
              label={'Permite editar o valor na venda'}
              labelPlacement="start"
              style={{ marginLeft: 0, marginTop: 0 }}
            />
          </div>
        </PlanAdicionalConfigContainer>
      </div>
      <div
        style={{
          minHeight: 'max-content',
          paddingTop: '16px',
          display: 'grid',
          gap: '16px',
          gridTemplateColumns: '1fr 1fr',
          alignItems: 'end',
        }}
      >
        <div>
          <Typography>Tipo de cobrança, por:</Typography>
          <Tabs
            onChange={(newValue) => handleAdicional(newValue, 'tipoCobranca')}
            value={adicional.tipoCobranca}
          >
            <Tab value={'PROFISSIONAL'}>Profissional</Tab>
            <Tab value={'UNIDADE'}>Unidade</Tab>
          </Tabs>
        </div>
        <Button disabled={loadingSave} onClick={save}>
          Salvar
          {loadingSave && <CircularProgress color="#fff" size={12} />}
        </Button>
      </div>
    </ModalSlider>
  );
};

const Row = styled.div`
  display: flex;
  gap: 4px;
`;

const PlanAdicionalConfigContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-block: 23px;

  h3 {
    font-size: 16px;
    font-weight: 600;
  }
`;

const ContentPermissao = styled.div`
  display: flex;
  flex-direction: row;
  gap: 24px;
`;

const ContentPeriodoCobranca = styled.div`
  width: max-content;
`;
