import styled from '@emotion/styled';
import { useMutation } from 'react-apollo';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import React, { useEffect, useState } from 'react';
import { CircularProgress } from '@material-ui/core';
import Notification from '../../../components/notification/Notification';
import { GERAR_SENHA_PROFISSIONAL_SAUDE } from '../../../graphql/mutations';

const notificationDefault = {
  variant: '',
  message: '',
  isOpen: false,
};

const GeraSenhaButton = ({ profissional }) => {
  const { id, possuiSenhaCadastrada } = profissional || {};

  const [possuiSenha, setpossuiSenha] = useState(possuiSenhaCadastrada);
  const [notification, setNotification] = useState({
    ...notificationDefault,
  });
  const [loadingGerarSenha, setLoadingGerarSenha] = useState(false);

  const [
    gerarSenhaProfissionalSaude,
    { data: gerarSenhaProfissionalSaudeResponse },
  ] = useMutation(GERAR_SENHA_PROFISSIONAL_SAUDE);

  useEffect(() => {
    if (gerarSenhaProfissionalSaudeResponse?.gerarSenhaProfissionalSaude) {
      setpossuiSenha(true);
    }
  }, [gerarSenhaProfissionalSaudeResponse]);

  const handleClick = async (e) => {
    try {
      e.stopPropagation();

      setLoadingGerarSenha(true);
      await gerarSenhaProfissionalSaude({
        variables: { id },
      });
    } catch {
      setNotification({
        variant: 'error',
        message: 'Erro ao gerar senha',
        isOpen: true,
      });
    } finally {
      setLoadingGerarSenha(false);
    }
  };

  return (
    <>
      <Button
        onClick={handleClick}
        disabled={loadingGerarSenha}
      >
        { possuiSenha ? 'Resetar senha' : 'Gerar senha' }
        {loadingGerarSenha ? (
          <CircularProgress size={12} color="inherit" />
        ) : (
          <VpnKeyIcon fontSize="small" />
        )}
      </Button>
      <Notification
        close={() => {
          setNotification({
            ...notificationDefault,
          });
        }}
        reset={() => {
          setNotification({
            ...notificationDefault,
          });
        }}
        isOpen={notification.isOpen}
        variant={notification.variant}
        message={notification.message}
      />
    </>
  );
};

export default GeraSenhaButton;

const Button = styled.button`
  display: flex;
  flex-direction: row;
  gap: 8px;
  cursor: pointer;
  height: 40px;
  width: 100%;
  max-width: 165px;
  border: 2px solid #219a97;
  border-radius: 100px;
  color: #219a97;
  background: #fff;
  align-items: center;
  justify-content: center;
  ${({ disabled }) => disabled && 'opacity: 0.5; cursor: not-allowed;'};
`;
