import React from 'react';
import { SvgIcon } from '@material-ui/core';
export const PeopleIcon = (props) => (
  <SvgIcon {...props} viewBox="0 0 28 28">
    <path d={"M5.16424 15.2931L15.1634 15.1616C15.9426 15.1512 16.5915 15.7379 16.6743 16.4972L16.6831 16.6415L16.7096 18.6404C16.755 22.1401 13.0536 23.1892 10.2698 23.2261C7.54795 23.2622 3.92243 22.3541 3.71926 19.0422L3.71077 18.8127L3.68425 16.8129C3.67392 16.0336 4.26064 15.3847 5.01995 15.3019L5.16424 15.2931ZM17.1512 15.1342L22.1627 15.0678C22.942 15.0574 23.5909 15.6433 23.6737 16.4033L23.6825 16.5477L23.7024 18.0476C23.742 21.1094 20.8977 22.0851 18.7558 22.1135C18.0759 22.1226 17.3237 22.0365 16.6118 21.8199C17.2545 21.0611 17.6653 20.1026 17.7066 18.9072L17.7095 18.6271L17.683 16.6273C17.6765 16.133 17.5211 15.6781 17.2663 15.2943L17.1512 15.1342L22.1627 15.0678L17.1512 15.1342ZM10.018 4.2278C12.5028 4.19486 14.5443 6.18297 14.5772 8.66775C14.6102 11.1525 12.6221 13.1941 10.1373 13.227C7.65251 13.26 5.61097 11.2718 5.57803 8.78706C5.54509 6.30228 7.5332 4.26074 10.018 4.2278ZM19.0437 6.10832C20.9765 6.0827 22.5642 7.62879 22.5898 9.56162C22.6154 11.4944 21.0693 13.0821 19.1365 13.1077C17.2037 13.1333 15.616 11.5872 15.5904 9.65441C15.5648 7.72158 17.1109 6.13395 19.0437 6.10832Z"} fill={"currentColor"}/>
  </SvgIcon>
)




