import React from 'react';
import { SvgIcon } from '@material-ui/core';
export const DismissCircleIcon = (props) => (
  <SvgIcon {...props} viewBox="0 0 17 16">
    <path
      d="M8.3335 0C12.7518 0 16.3335 3.58172 16.3335 8C16.3335 12.4183 12.7518 16 8.3335 16C3.91522 16 0.333496 12.4183 0.333496 8C0.333496 3.58172 3.91522 0 8.3335 0ZM6.14293 5.11372C5.94806 4.97872 5.67864 4.99801 5.50507 5.17157L5.44721 5.24082C5.31222 5.43569 5.3315 5.70511 5.50507 5.87868L7.62639 8L5.50507 10.1213L5.44721 10.1906C5.31222 10.3854 5.3315 10.6549 5.50507 10.8284L5.57432 10.8863C5.76919 11.0213 6.03861 11.002 6.21218 10.8284L8.3335 8.70711L10.4548 10.8284L10.5241 10.8863C10.7189 11.0213 10.9884 11.002 11.1619 10.8284L11.2198 10.7592C11.3548 10.5643 11.3355 10.2949 11.1619 10.1213L9.0406 8L11.1619 5.87868L11.2198 5.80943C11.3548 5.61456 11.3355 5.34514 11.1619 5.17157L11.0927 5.11372C10.8978 4.97872 10.6284 4.99801 10.4548 5.17157L8.3335 7.29289L6.21218 5.17157L6.14293 5.11372Z"
      fill="currentColor"
    />
  </SvgIcon>
);
