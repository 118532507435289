import React, { lazy, Suspense, useReducer, useState } from 'react';
import { withBaseLayout } from '../../layout/base-layout';
import { Link, useParams } from 'react-router-dom';

import { useQuery } from 'react-apollo';
import { FIND_PERFIL_PUBLICO_UNIDADE_BY_VENDA_ID } from '../../graphql/queryes';

import * as Styled from './styles';
import { Tabs, Tab } from '../../components/tabs';
import { TABS } from './constants/tabs';
import Button from '../../components/button';
import Notification from '../../components/notification/Notification';

import { RecebimentosProvider } from './recebimentos-context';
import { ConfigReplicaProvider } from './config-replica-context';

import { VISUALIZAR_VENDA } from '../../router/names';
import { NOTIFICATION_DEFAULT } from './constants/notificationsDefault';
import { getNotificationState } from './utils/getNotificationStatus';

const ConfigRecebimentosUnidade = () => {
  const [activeTab, setActiveTab] = useState(Object.keys(TABS)[0]);
  const [notification, toggleNotification] = useReducer(getNotificationState, NOTIFICATION_DEFAULT);
  const ConfigRecebimentosUnidadeForm = lazy(() =>
    import('./tabs/configuracaoRecebimentosTab')
  );
  const ProfilesToReplicate = lazy(() =>
    import('./tabs/profilesToReplicateTab')
  );
  const { vendaId } = useParams();
  const { data, refetch } = useQuery(FIND_PERFIL_PUBLICO_UNIDADE_BY_VENDA_ID, {
    variables: {
      vendaId,
    },
  });

  const { findPerfilPublicoUnidadeByVendaId: perfilPublico } = data || {};
  const { unidade, id: perfilPublicoId, dadosIugu } = perfilPublico || {};
  const { id: unidadeId, rede } = unidade || {};
  const { whitelabel } = rede || {};

  const handleChangeTab = (tab) => {
    if (tab === 'PROFILES_TO_REPLICATE' && !dadosIugu?.id) return;
    setActiveTab(tab);
  };

  const renderTab = () => {
    switch (activeTab) {
      case 'CONFIGURACAO_RECEBIMENTOS':
        return <ConfigRecebimentosUnidadeForm 
          unidadeId={unidadeId} 
          perfilPublicoId={perfilPublicoId} 
          toggleNotification={toggleNotification}
          refetchPerfilPublicoData={refetch} 
          dadosIugu={dadosIugu}
        />;
      case 'PROFILES_TO_REPLICATE':
        return <ProfilesToReplicate 
          unidadeId={unidadeId} 
          toggleNotification={toggleNotification} 
          whitelabel={whitelabel}
          dadosIuguId={dadosIugu?.id}
        />;
      default:
        return null;
    }
  };

  const handleCloseNotification = () => {
    toggleNotification({ type: 'NOTIFY', payload: NOTIFICATION_DEFAULT });
  };

  return (
    <ConfigReplicaProvider>
      <RecebimentosProvider>
        <Suspense fallback={null}>
          <Styled.Container>
            <Link
              style={{ textDecoration: 'none', width: 'fit-content' }}
              to={VISUALIZAR_VENDA.replace(':vendaId', vendaId)}
            >
              <Button style={{ width: '200px' }}>Voltar para a venda</Button>
            </Link>
            <Tabs value={activeTab} onChange={handleChangeTab}>
              {Object.keys(TABS).map((tab) => (
                <Tab key={tab} value={tab} flex={1}>
                  <TabConent tab={TABS[tab]} />
                </Tab>
              ))}
            </Tabs>
            {renderTab()}
          </Styled.Container>
          <Notification {...notification} close={handleCloseNotification} />
        </Suspense>
      </RecebimentosProvider>
    </ConfigReplicaProvider>
  );
};

export default withBaseLayout(ConfigRecebimentosUnidade);

const TabConent = ({ tab }) => {
  return <div style={{ flex: 1 }}>{tab}</div>;
};
