import React, { useState, useEffect } from 'react';
import InfoBox from '../../info-box';
import { cpfCnpjFormatted } from '../../../../../../utils/masks';

const DadosBancarios = ({ dadosBraspagProfissional }) => {
  const [dadosBancario, setDadosBancario] = useState([]);

  const isCnpj =
    dadosBraspagProfissional?.dadoBancario?.documento.length === 14;

  const buildDadosBancario = () => {
    const formatDate = dadosBraspagProfissional?.dataNascimento
      .split('-')
      .reverse()
      .join('/');
    const dadosBancario = [
      ['Status', getStatus(dadosBraspagProfissional?.status)],
      ['Banco', dadosBraspagProfissional?.dadoBancario?.banco?.nome || ''],
      [
        'Agência',
        dadosBraspagProfissional?.dadoBancario
          ? getAgencia(dadosBraspagProfissional?.dadoBancario)
          : '',
      ],
      [
        'Conta',
        dadosBraspagProfissional?.dadoBancario
          ? getConta(dadosBraspagProfissional?.dadoBancario)
          : '',
      ],
      [
        'Tipo',
        dadosBraspagProfissional?.dadoBancario
          ? getTipoConta(dadosBraspagProfissional?.dadoBancario)
          : '',
      ],
      [
        'Documento',
        cpfCnpjFormatted(dadosBraspagProfissional?.dadoBancario?.documento),
      ],
      ['Responsável', dadosBraspagProfissional?.responsavelNome],
      ['Razão social', dadosBraspagProfissional?.razaoSocial],
      ['Nome fantasia', dadosBraspagProfissional?.nomeFantasia],
      ['Email', dadosBraspagProfissional?.email],
      ['Data nascimento', formatDate],
    ];
    if (!isCnpj) {
      dadosBancario.splice(7, 2);
    }
    setDadosBancario(dadosBancario);
  };

  const getAgencia = (dadoBancario) => {
    if (dadoBancario?.agencia && dadoBancario?.digitoAgencia) {
      return `${dadoBancario.agencia}-${dadoBancario.digitoAgencia}`;
    } else {
      return '';
    }
  };

  const getConta = (dadoBancario) => {
    if (dadoBancario?.conta && dadoBancario?.digitoVerificador) {
      return `${dadoBancario.conta}-${dadoBancario.digitoVerificador}`;
    } else {
      return '';
    }
  };

  const getTipoConta = (dadoBancario) => {
    if (dadoBancario?.tipoConta) {
      return dadoBancario?.tipoConta === 'CONTA_CORRENTE'
        ? 'Conta corrente'
        : 'Conta Poupança';
    } else {
      return '';
    }
  };

  const getStatus = (status) => {
    switch (status) {
      case 'APPROVED':
        return 'Aprovado';
      case 'APPROVEDWITHRESTRICTION':
        return 'Aprovado com restrição';
      case 'REJECTED':
        return 'Rejeitado';
      case 'UNDERANALYSIS':
        return 'Em análise';
      default:
        return '';
    }
  };

  useEffect(() => {
    if (dadosBraspagProfissional) {
      buildDadosBancario();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dadosBraspagProfissional]);

  return (
    <>
      <InfoBox
        title="Dados bancários"
        subTitle={isCnpj ? 'Pessoa jurídica' : 'Pessoa física'}
        data={dadosBancario}
      />
    </>
  );
};

export default DadosBancarios;
