import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { getTiposCobrancaList } from '../../../../enum/TiposCobranca';
import { Tab, Tabs } from '../../../../components/tabs';

const tiposCobranca = getTiposCobrancaList();
const TipoCobrancaFilter = ({value, onChange}) => {
  return(
    <Container>
      <p>
        Exibir preços por:
      </p>
      <Tabs value={value} onChange={(tipo) => onChange(tipo)}>
        {tiposCobranca.map(({name, value: tipo}) => (
          <Tab value={tipo} key={name}>
            {name}
          </Tab>
        ))}
      </Tabs>
    </Container>
  )
}

TipoCobrancaFilter.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired
}

export {
  TipoCobrancaFilter
}

const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  position: sticky;
  top: 0;
  width: 100%;

  p {
    font-size: 14px;
  }
`
