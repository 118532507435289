import React from 'react';
import Modal from '@material-ui/core/Modal';
import { withStyles } from "@material-ui/core/styles";

const SimpleModal = ({ children, open, handleOnClose }) => {
  return (
    <Modal
      open={open}
      onClose={handleOnClose}
    >
      {children}
    </Modal>
  );
};

export default SimpleModal;