import React from 'react';

import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import Search from '@material-ui/icons/Search';
import {withStyles} from "@material-ui/core/styles/index";

const styleInputSearch = theme => ({
    root: {
        width: '100%',
    },
    inputRoot: {
        border: theme.commons.inputBorder,
        borderRadius: theme.commons.inputBorderRadius,
        padding: "0 10px",
        fontSize: 16,
        background: theme.commons.inputBackgroundColor,
    },
    input: {
        padding: '7px 0',
        color: '#000000',
    },
    inputFocused: {
        borderColor: '#000000',
        color: '#000000',
        boxShadow: 'none',
        outline: 'none',
    }
});

function InputSearch(props) {
    const {
      classes,
      classInputRoot,
      classInput,
      startAdornment,
      endAdornment,
      inputType,
      ...rest
    } = props;

    return (
      <TextField
        className={classes.root}
        id="input-with-icon-textfield"
        InputProps={{
          disableUnderline: true,
          classes: {
            root: `${classes.inputRoot} ${classInputRoot}`,
            input: `${classes.input} ${classInput}`,
            focused: classes.inputFocused,
          },
          startAdornment: startAdornment || (
            <InputAdornment position="start">
              <Search />
            </InputAdornment>
          ),
          endAdornment: endAdornment || null,
          inputType,
        }}
        InputLabelProps={{
          shrink: true,
        }}
        {...rest}
      />
    );
}

export default withStyles(styleInputSearch)(
    React.forwardRef((props, ref) => {
      return <InputSearch {...props} inputRef={ref} />;
    })
);
